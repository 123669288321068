import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ActivityLoader from '../../components/Loader/ActivityLoader';
import { toast } from 'react-toastify';
import { Checkbox } from '@mui/material';

DeleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default function DeleteModal(props) {
  const [isDone, setIsDone] = useState(true);
  const [nameConfirmation, setNameConfirmation] = useState('');
  const [isForceDelete, setIsForceDelete] = useState(false);

  async function onClickDelete() {
    if (nameConfirmation === props?.title) {
      setIsDone(false);

      if (props?.isForceDeleteAllowed && isForceDelete) {
        await props?.onForceDelete();

        setIsDone(true);
      } else {
        await props.onDelete();

        setIsDone(true);
      }
    } else {
      const resourceName = props?.deleteCount
        ? `"${props?.title}"`
        : props?.modalType
          ? `${props?.modalType} name`
          : "resource's name";

      if (nameConfirmation === '') {
        toast.warning(
          `Please fill out the input field with the ${resourceName}.`
        );
      } else {
        toast.warning(
          `The input name and the 
          ${resourceName} do not match.`
        );
      }
    }
  }

  return (
    <Modal centered show={props?.show} onHide={props?.handleClose}>
      <Modal.Header>
        <h5 className="text_color_main m-0">Delete</h5>
      </Modal.Header>

      <Modal.Body>
        {props.isForceDeleteAllowed && (
          <>
            <p className="mb-0">
              The following resources failed to be deleted from your cloud
              provider account.
            </p>

            <ul>
              {props?.failedToDeleteResource?.map((data) => (
                <li key={data?.name}>{`${data?.name}: ${data?.value}`}</li>
              ))}
            </ul>

            <div className="mb-3 d-flex align-items-start">
              <Checkbox
                id="force_delete_allowed"
                color="primary"
                name="allowAllIdpUsers"
                style={{ color: 'var(--clr-text-300)' }}
                checked={isForceDelete}
                onClick={(e) => setIsForceDelete(e.target.checked)}
              />

              <label className="text-wrap" htmlFor="force_delete_allowed">
                Force Delete this {props?.modalType}.
                <br /> This will delete the Cloud Profile even if the resources
                above cannot be deleted from your cloud provider. You will need
                to delete them through your cloud provider’s interface.
              </label>
            </div>
          </>
        )}

        <p className="mb-3">
          Are you sure you wish to delete {props?.modalType} "
          <span className="fw-bold">
            {`${props?.deleteCount ? `${props?.deleteCount} ` : ''}`}
            {props?.title}
          </span>
          "?
        </p>
        <p className="mb-2">
          Enter the{' '}
          {props?.deleteCount ? (
            <span className="fw-bold">"{props?.title}"</span>
          ) : props?.modalType ? (
            `${props?.modalType} name`
          ) : (
            "resource's name"
          )}{' '}
          in the field below.
        </p>
        <input
          className="input-boxes h-3 w-100"
          type="test"
          placeholder="Enter name here..."
          data-testid="modal-input-name"
          value={nameConfirmation}
          onChange={(e) => setNameConfirmation(e.target.value)}
          required
        />
      </Modal.Body>

      <Modal.Footer>
        {isDone ? (
          <>
            <button
              className="buttonX"
              data-testid="modal-delete"
              type="submit"
              onClick={onClickDelete}
            >
              Delete
            </button>

            <button
              className="buttonX white"
              type="reset"
              onClick={props?.handleClose}
            >
              Cancel
            </button>
          </>
        ) : (
          <ActivityLoader />
        )}
      </Modal.Footer>
    </Modal>
  );
}
