import { TextField } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import CommonCard from '../../../components/CommonCard/CommonCard';
import Layout from '../../../components/layout/SideBar/Layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import { CREATE_USER_PATH, USER_PATH } from '../../../router/PathConst';
import { get, post, put } from '../../../utils/apiMethods';
import ApiConstant from '../../../utils/apiConstant';
import { checkProps, createMessage } from '../../../utils/common';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import ActivityLoader from '../../../components/Loader/ActivityLoader';
import { toast } from 'react-toastify';

import './User.scss';

export default function CreateUserNew(props) {
  const [type, setType] = useState('add');
  const [data, setData] = useState({});
  const [jsonData, setJsonData] = useState([]);
  const [isFile, setIsFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionDone, setActionDone] = useState(true);
  const [assignedRoles, setAssignedRoles] = useState([]);
  const [hiddenProps] = useState([
    'bookmarks',
    'sorting',
    'theme',
    'ENABLE_API_ACCESS',
    'ENCRYPTED_AES_KEY',
    'API_Key',
    'ACCESS_TOKEN',
  ]);

  const [propsArray, setPropsArray] = useState([
    {
      id: '',
      name: '',
    },
  ]);

  const crumbs = [
    {
      title: 'Users',
      path: USER_PATH,
      active: false,
    },
    {
      title: type === 'edit' ? 'Update User' : 'Create User',
      path: CREATE_USER_PATH,
      active: true,
    },
  ];

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email Id is Required'),
    password: Yup.string()
      .min(8, 'Password should more than 8 characters')
      .max(12, 'Password should less than 12 characters'),
    userName: Yup.string().required('Please enter user name'),
  });

  const formik = useFormik({
    initialValues: {
      fName:
        type === 'edit' ? data.firstName : isFile ? jsonData.firstName : '',
      lName: type === 'edit' ? data.lastName : isFile ? jsonData.lastName : '',
      email: type === 'edit' ? data.email : isFile ? jsonData.email : '',
      password: '',
      userName:
        type === 'edit' ? data.username : isFile ? jsonData.username : '',
      phoneNumber: type === 'edit' ? data.phone : isFile ? jsonData.phone : '',
      tenantId:
        type === 'edit' ? data.tenantId : isFile ? jsonData.tenantId : '',
      userStatus:
        type === 'edit' ? data.userStatus : isFile ? jsonData.userStatus : 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmitCall(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (props.match.params.type === 'create') {
      setType(props.match.params.type);
    } else {
      setType('edit');
      setLoading(true);
    }
  }, [props.match.params.type]);

  useEffect(() => {
    const getUserData = async () => {
      const id = props.match.params.type;

      const URL = ApiConstant.GET_USER(id);
      const response = await get({ url: URL });
      if (response && response !== undefined) {
        setData(response);

        if (response.props && response.props !== undefined) {
          objToArray(response.props);
        }
        setLoading(false);
      }
    };

    if (props.match.params.type !== 'create') {
      getUserData();
    }
  }, [props.match.params.type]);

  const addProps = () => {
    const array = [...propsArray];
    const obj = {
      id: '',
      name: '',
    };
    array.push(obj);
    setPropsArray(array);
  };

  const deleteProps = (i) => {
    const array = [...propsArray];
    array.splice(i, 1);
    setPropsArray(array);
  };

  const hadleChangeProps = (e, index, type) => {
    const array = [...propsArray];
    if (type === 'id') {
      array[index].id = e.target.value;
    } else {
      array[index].name = e.target.value;
    }
    setPropsArray(array);
  };

  // new
  const getUserData = async (ID) => {
    const id = props.match.params.type;

    const URL = ApiConstant.GET_USER(id);

    const response = await get({ url: URL });

    if (response && response !== undefined) {
      setData(response);
      setActionDone(true);
      if (response.props && response.props !== undefined) {
        objToArray(response.props);
      }
    }
  };

  const onSubmitCall = async (values) => {
    setActionDone(false);

    var newProps = [];

    for (const key in data.props) {
      if (hiddenProps.includes(key) && key !== '') {
        const obj = {
          id: key,
          name: data.props[key],
        };

        newProps.push(obj);
      }
    }

    if (type === 'edit') {
      const id = props.match.params.type;

      const allProperties = arrayToObj([...propsArray, ...newProps]);

      const payload = {
        id: id,
        tenantId: values.tenantId,
        username: values.userName,
        firstName: values.fName,
        lastName: values.lName,
        email: values.email,
        phone: values.phoneNumber,
        userStatus: values.userStatus,
        props: allProperties,
      };

      const url = ApiConstant.PUT_USER(id);

      const response = await put({
        url: url,
        payload: payload,
        type: 'v2',
      });

      if (response.status === 200) {
        createMessage(200, 'User updated');

        if (assignedRoles.length > 0) {
          assignRoles(response.id);
        } else {
          setTimeout(() => {
            getUserData();
          }, 1000);
        }
      } else {
        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }

      setActionDone(true);
    } else {
      const payload = {
        username: values.userName,
        firstName: values.fName,
        lastName: values.lName,
        email: values.email,
        phone: values.phoneNumber,
        props: arrayToObj(checkProps(propsArray)),
      };

      const response = await post({
        url: ApiConstant.POST_USER,
        payload: payload,
        type: 'v2',
      });

      if (response.status === 200) {
        if (assignedRoles?.length > 0) {
          createMessage(200, 'User created');

          assignRoles(response[0].id);
        } else {
          createMessage(200, 'User created');

          props.history.push(USER_PATH);
        }
      } else {
        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }

      setActionDone(true);
    }
  };

  const assignRoles = async (id) => {
    const response = await put({
      url: ApiConstant.PUT_USER_ROLE(id),
      payload: assignedRoles,
      type: 'User',
    });
    if (response === 201) {
      createMessage(200, 'Role assigned to user');
    } else {
      createMessage(response);
    }

    setTimeout(() => {
      if (type === 'edit') {
        getUserData(id);
      } else {
        props.history.push(USER_PATH);
      }
    }, 1000);
  };

  const arrayToObj = (array) => {
    let obj = {};
    if (array && array.length > 0) {
      obj = array.reduce(
        (obj, item) => ({
          ...obj,
          [item.id]: item.name,
        }),
        {}
      );
    }

    return obj;
  };

  const objToArray = (properties) => {
    if (properties !== undefined) {
      let array = [];
      let propArray = Object.keys(properties);

      for (let i = 0; i < propArray.length; i++) {
        const obj = {
          id: propArray[i],
          name: properties[propArray[i]],
        };
        if (!hiddenProps?.includes(propArray[i])) {
          array.push(obj);
        }
      }
      setPropsArray(array);
    }
  };

  const onJsonUpload = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      const response = JSON.parse(e.target.result);

      setJsonData(response);
      setIsFile(true);
      if (response.props && response.props !== undefined) {
        objToArray(response.props);
      }
    };
  };

  const onRoleAssign = (data) => {
    setAssignedRoles(data);
  };

  // get user Roles

  // useEffect(() => {

  //   if (type ==='edit' &&data.id) {
  //     getUserRoleId(data.id);
  //   }
  // }, [data]);

  // useEffect(()=>{
  //   if (type ==='edit' &&roleUUID && roleUUID.length > 0) {
  //     getUserRoleById();
  //   }
  // },[roleUUID])
  // // user roles
  // const getUserRoleId = async (id) => {
  //   const response = await get({ url: `${GET_USER_ROLES}${id}/roles` });

  //   setRoleUUID(response);
  // };

  // const getUserRoleById = () => {
  //   if (roleUUID && roleUUID.length) {
  //     for (let i = 0; i < roleUUID.length; i++) {
  //       getAPICALl(roleUUID[i]);
  //     }
  //   }
  // };
  // const getAPICALl = async (id) => {
  //   let array = [...assignedRoles];

  //   const URL = `${GET_ROLES_UUID}/${id}`;
  //   const response = await get({ url: URL });
  //   if (response && response !== undefined) {

  //     if (!response.name.startsWith("SHADOW")) {

  //       array.push(response);
  //       setAssignedRoles(array);
  //     }
  //   }

  //   // return array[0]
  // };
  //

  return (
    <Layout crumbs={crumbs} {...props}>
      <div className="create-user">
        {/* {assignRoleModal && (
          <AssignRole
            onAssign={onRoleAssign}
            show={assignRoleModal}
            data={assignedRoles}
            handleClose={handleAssignRoleModal}
          />
        )} */}

        <CommonCard className="card-wrapper" isForm>
          <div className="form-wrapper">
            <h4 className="text_primary text-left">
              {type === 'edit' ? 'Update User' : 'Create User'}
            </h4>
          </div>

          {type === 'edit' && loading ? (
            <SkeletonLoader />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className="form-wrapper">
                <div>
                  <TextField
                    autoComplete="off"
                    inputProps={{ 'data-testid': 'username-Input' }}
                    style={{ width: '100%' }}
                    variant="outlined"
                    id="userName"
                    name="userName"
                    label="User Name"
                    value={formik.values.userName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.userName && Boolean(formik.errors.userName)
                    }
                    helperText={
                      formik.touched.userName && formik.errors.userName
                    }
                  />
                </div>
                <div className="mt-4">
                  <TextField
                    autoComplete="off"
                    inputProps={{ 'data-testid': 'email-Input' }}
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    variant="outlined"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                {/* <div className="mt-4">
                  <TextField
                    className={classes.root}
                    style={{ width: "100%" }}
                    id="outlined-basic"
                    variant="outlined"
                    name="tenantId"
                    label="Tenant Id"
                    value={formik.values.tenantId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.tenantId && Boolean(formik.errors.tenantId)
                    }
                    helperText={formik.touched.tenantId && formik.errors.tenantId}
                  />
                </div> */}
                {/* <div className="mt-4  d-flex justify-content-between">
                  <TextField
                    className={classes.root}
                    autoComplete="off"
                    inputProps={{ "data-testid": "password-Input" }}
                    type="password"
                    style={{ width: "100%" }}
                    id="outlined-basic"
                    variant="outlined"
                    name="password"
                    label="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </div> */}
                <div className="props-wrapper">
                  <p className="prop-label mt-2">
                    Properties
                    <span>
                      <i
                        className={
                          propsArray.length === 0
                            ? 'bi bi-plus-circle add-icon ms-2 text-primary cursor-pointer h4'
                            : 'bi bi-plus-circle vis-hidden'
                        }
                        onClick={
                          propsArray.length === 0 ? () => addProps() : null
                        }
                      />
                    </span>
                  </p>

                  <div className="props-box">
                    {propsArray &&
                      propsArray.map((item, i) => {
                        return (
                          <div
                            className="d-flex justify-content-between align-items-center mt-2"
                            key={i}
                          >
                            <div className="inputs">
                              <input
                                data-testid={`id-prop ${i}`}
                                className="form-control id-input"
                                placeholder="name"
                                onChange={(e) => hadleChangeProps(e, i, 'id')}
                                value={item.id}
                              />
                            </div>
                            <div className="inputs">
                              <input
                                data-testid={`name-prop ${i}`}
                                className="form-control id-input"
                                placeholder="value"
                                value={item.name}
                                onChange={(e) => hadleChangeProps(e, i, 'name')}
                              />
                            </div>
                            <div style={{ width: '80px', display: 'flex' }}>
                              <i
                                data-testid={`delete-prop ${i}`}
                                onClick={() => deleteProps(i)}
                                className="bi bi-dash-circle close-icon"
                              />
                              <i
                                data-testid={`add-prop ${i}`}
                                onClick={
                                  i === propsArray.length
                                    ? null
                                    : () => addProps()
                                }
                                className={
                                  i === propsArray.length - 1
                                    ? 'bi bi-plus-circle add-icon'
                                    : 'bi bi-plus-circle vis-hidden'
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="mt-5 d-flex justify-content-end gap-2">
                {actionDone ? (
                  <>
                    <button className="buttonX" type="submit">
                      {type === 'edit' ? 'Update User' : 'Create User'}
                    </button>
                    <button
                      className="buttonX white"
                      type="button"
                      onClick={() =>
                        type === 'edit'
                          ? props.history.goBack()
                          : props.history.push(USER_PATH)
                      }
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <ActivityLoader />
                )}
              </div>
            </form>
          )}
        </CommonCard>
      </div>
    </Layout>
  );
}
