import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CommonCard from '../../../components/CommonCard/CommonCard';
import Layout from '../../../components/layout/SideBar/Layout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import routeConstant from '../../../router/PathConst';
import { get, post, put } from '../../../utils/apiMethods';
import ApiConstant from '../../../utils/apiConstant';
import { createMessage } from '../../../utils/common';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import ActivityLoader from '../../../components/Loader/ActivityLoader';

import './user_group.scss';

export default function UserGroupAddEdit(props) {
  const [type, setType] = useState('add');
  const [data, setData] = useState({});
  const [jsonData, setJsonData] = useState([]);
  const [isFile, setIsFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionDone, setActionDone] = useState(true);

  const crumbs = [
    {
      title: 'User Groups',
      path: routeConstant.USER_GROUPS,
      active: false,
    },
    {
      title: type === 'edit' ? 'Update User Group' : 'Create User Group',
      path: routeConstant.USER_GROUP_ADD_EDIT('create'),
      active: true,
    },
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter user name'),
  });

  const formik = useFormik({
    initialValues: {
      name: type === 'edit' ? data.name : isFile ? jsonData.name : '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmitCall(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (props.match.params.type === 'create') {
      setType(props.match.params.type);
    } else {
      setType('edit');
      setLoading(true);
    }
  }, [props.match.params.type]);

  useEffect(() => {
    const getUserGroupData = async () => {
      const id = props.match.params.type;

      const URL = `${ApiConstant.GET_USER_GROUP_DETAILS(id)}`;

      const response = await get({ url: URL });
      if (response && response !== undefined) {
        setData(response);
        setLoading(false);
      }
    };

    if (props.match.params.type !== 'create') {
      getUserGroupData();
    }
  }, [props.match.params.type]);

  /// get Users
  const getUserGroupData = async (ID) => {
    const id = props.match.params.type;

    const URL = `${ApiConstant.GET_USER_GROUP_DETAILS(id)}`;

    const response = await get({ url: URL });

    if (response && response !== undefined) {
      setData(response);
      setActionDone(true);
    }
  };

  const onSubmitCall = async (values) => {
    setActionDone(false);

    if (type === 'edit') {
      const id = props.match.params.type;

      const payload = {
        name: values.name,
        description: '',
      };

      const url = `${ApiConstant.PUT_USER_GROUP(id)}`;

      const response = await put({
        url: url,
        payload: payload,
        type: 'User',
      });

      if (response && response.id) {
        createMessage(200, 'User updated');

        setTimeout(() => {
          getUserGroupData();
        }, 1000);
      } else if (response && response.message) {
        createMessage(400, response.message);
      } else {
        createMessage(response);
      }
      setActionDone(true);
    } else {
      const payload = {
        name: values.name,
        description: '',
      };

      const response = await post({
        url: ApiConstant.POST_USER_GROUPS,
        payload: payload,
        type: 'User',
      });

      if (response && response.id) {
        createMessage(200, 'User group created');
        setTimeout(() => {
          props.history.push(routeConstant.USER_GROUPS);
        }, 1000);
      } else if (response && response.message) {
        createMessage(400, response.message);
      } else {
        createMessage(response);
      }
      setActionDone(true);
    }
  };

  const onJsonUpload = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      const response = JSON.parse(e.target.result);

      setJsonData(response);
      setIsFile(true);
    };
  };

  return (
    <Layout crumbs={crumbs} {...props}>
      <div className="create-user">
        <CommonCard className="card-wrapper" isForm>
          <div className="form-wrapper">
            <h4 className="text_primary text-left">
              {type === 'edit' ? 'Update User Group' : 'Create User Group'}
            </h4>
          </div>

          {type === 'edit' && loading ? (
            <SkeletonLoader />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className="form-wrapper">
                <div>
                  <TextField
                    autoComplete="off"
                    inputProps={{ 'data-testid': 'name' }}
                    style={{ width: '100%' }}
                    variant="outlined"
                    id="name"
                    name="name"
                    label="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <p className="mt-3">
                    Make sure that the Group Name matches the Group Name in your
                    Identity Provider. The names are case sensitive.
                  </p>
                </div>
              </div>

              <div className="mt-5 d-flex justify-content-end gap-2">
                {actionDone ? (
                  <>
                    <button className="buttonX" type="submit">
                      {type === 'edit'
                        ? 'Update User Groups'
                        : 'Create User Groups'}
                    </button>
                    <button
                      className="buttonX white"
                      type="button"
                      onClick={() =>
                        type === 'edit'
                          ? props.history.goBack()
                          : props.history.push(routeConstant.USER_GROUPS)
                      }
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <ActivityLoader />
                )}
              </div>
            </form>
          )}
        </CommonCard>
      </div>
    </Layout>
  );
}
