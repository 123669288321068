import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { EnhancedTableHead } from '../../../components/TableComponent/TableWithSelection';
import {
  getComparator,
  stableSort,
} from '../../../components/TableComponent/TableComponent';
import Layout from '../../../components/layout/SideBar/Layout';
import CommonCard from '../../../components/CommonCard/CommonCard';

import {
  DATA_SOURCES_PATH,
  DATA_SOURCES_DETAILS_PATH,
} from '../../../router/PathConst';
import ApiConstant from '../../../utils/apiConstant';
import { deleteAPI, get, put } from '../../../utils/apiMethods';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import DataSourceMenu from './DataSourceMenu';
import DeleteModal from '../../../modals/Common/DeleteModal';
import MultiSelect from '../../../components/MultiSelect/MultiSelect';
import { deleteMessage, getReadableDate } from '../../../utils/common';
import UserContext from '../../../context/user/UserContext';

const crumbs = [
  {
    title: 'Data Sources',
    path: DATA_SOURCES_PATH,
    active: true,
  },
];

const options = [
  { value: 's3', label: 's3' },
  { value: 'awsglue', label: 'awsglue' },
];

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'aws_region',
    numeric: false,
    disablePadding: false,
    label: 'Region',
  },
  {
    id: 'created_timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Created',
  },
  {
    id: 'modified_timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Updated',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

export default class DataSources extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      page: 0,
      rowsPerPage: 10,
      dataSourceList: [],
      dataSourceRefList: [],
      UUID: [],
      loading: true,
      isDone: true,
      selectedDataSources: [],
      isDelete: false,
    };
  }

  componentDidMount() {
    this.getDataSourceId();

    // getDataSourceId
    if (this.context) {
      const { currentUser } = this.context;
      const { props } = currentUser;

      if (props && 'sorting' in props) {
        const sort = JSON.parse(props.sorting);
        if ('DataSource' in sort && sort.DataSource.length > 0) {
          this.setState({
            orderBy: sort.DataSource[0],
            order: sort.DataSource[1],
          });
        } else {
          this.setState({ orderBy: 'name' });
        }
      } else {
        this.setState({ orderBy: 'name' });
      }
    }
  }

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property }, () => {
      this.onSaveSort(property, isAsc ? 'desc' : 'asc');
    });
  };

  onSaveSort = (value, order) => {
    const { currentUser } = this.context;
    const { props } = currentUser;

    let array = { ...props };

    if (props && props.sorting) {
      let { sorting } = props;

      const obj = JSON.parse(sorting);
      if (sorting !== undefined) {
        obj.DataSource = [value, order];
      } else {
        obj.DataSource = [value, order];
      }
      array.sorting = JSON.stringify(obj);

      this.callAPi(array);
    } else {
      const obj = {
        DataSource: value,
      };

      currentUser.props = { sorting: JSON.stringify(obj) };
      this.callAPi(currentUser.props);
    }
  };

  callAPi = async (properties) => {
    const { currentUser, storeCurrentUser } = this.context;
    const { username, firstName, lastName, email, password, phone, id } =
      currentUser;

    const payload = {
      id: id,
      username: username,
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      phone: phone,
      props: properties,
    };

    const url = ApiConstant.PUT_USER(id);

    const response = await put({
      url: url,
      payload: payload,
    });

    if (response && response.id) {
      if ('sorting' in response.props) {
        storeCurrentUser(response);
      }
    }
  };

  // GET API CALL
  getDataSourceId = async () => {
    const response = await get({ url: ApiConstant.GET_DATA_SOURCES });

    const updatedDataSources = response?.map((dataSource) => {
      return {
        ...dataSource,
        aws_region: dataSource?.aws_glue_config?.aws_region,
        created_timestamp: dataSource?.metadata?.created_timestamp,
        modified_timestamp: dataSource?.metadata?.modified_timestamp,
      };
    });

    this.setState({
      dataSourceList: updatedDataSources,
      dataSourceRefList: updatedDataSources,
      isDone: true,
      loading: false,
    });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedData = this.state.dataSourceList.map((n) => n.id);

      this.setState({
        selected: newSelectedData,
        selectedDataSources: newSelectedData,
      });

      return;
    }
    this.setState({ selected: [], selectedDataSources: [] });
  };

  handleClick = (event, name) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    this.DataSourcesToDelete(name);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  onSearchData = (value) => {
    const { dataSourceRefList } = this.state;
    if (value !== '' && value !== undefined) {
      const result = dataSourceRefList.filter(
        (data) =>
          data.name.toLowerCase().includes(value.toLowerCase()) ||
          data.id.includes(value.toLowerCase())
      );
      this.setState({ dataSourceList: result, page: 0 });
    } else {
      this.setState({ dataSourceList: dataSourceRefList });
    }
  };

  // delete
  DataSourcesToDelete = (id) => {
    const { selectedDataSources } = this.state;
    let selectedDataSource = [...selectedDataSources];
    const isPresent = selectedDataSources.includes(id);
    const index = selectedDataSources.indexOf(id);

    if (!isPresent) {
      selectedDataSource.push(id);
      this.setState({ selectedDataSources: selectedDataSource });
    } else {
      selectedDataSource.splice(index, 1);
      this.setState({ selectedDataSources: selectedDataSource });
    }
  };

  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  onDeleteDataSources = async () => {
    const { selectedDataSources } = this.state;
    let success = 0;
    let failure = 0;
    for (let z = 0; z < selectedDataSources.length; z++) {
      const response = await deleteAPI({
        url: `${ApiConstant.DELETE_DATA_SOURCES}/${selectedDataSources[z]}`,
        type: 'Data Source',
      });

      if (response === 204) {
        success++;
      } else {
        failure++;
      }
    }
    if (success > 0) {
      deleteMessage(200, `${success} Data sources deleted`);
    }
    if (failure > 0) {
      deleteMessage(200, `${failure} Data sources failed to delete`, true);
    }
    this.deleteDone();
  };

  deleteDone = () => {
    this.setState(
      {
        dataSourceList: [],
        selectedDataSources: [],
        selected: [],
        page: 0,
        isDelete: false,
        loading: false,
      },
      () => {
        this.getDataSourceId();
      }
    );
  };

  // filter
  onFilterData = (value) => {
    let resArray = [];
    const { dataSourceRefList } = this.state;
    if (value && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        const result = this.onFilter(value[i].value);
        if (result !== undefined) {
          if (result && result.length > 1) {
            for (let k = 0; k < result.length; k++) {
              resArray.push(result[k]);
            }
          } else {
            resArray.push(result[0]);
          }
        }
      }
      this.setState({ dataSourceList: resArray, page: 0 });
    } else {
      this.setState({ dataSourceList: dataSourceRefList });
    }
  };

  onFilter = (status) => {
    const { dataSourceRefList } = this.state;

    const filterList = dataSourceRefList.filter(
      (data) => data.type.toLowerCase() === status.toLowerCase()
    );

    if (filterList && filterList.length > 0) {
      return filterList;
    }
  };

  render() {
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      dataSourceList,
      loading,
      isDone,
      selectedDataSources,
      isDelete,
    } = this.state;

    return (
      <Layout crumbs={crumbs} {...this.props}>
        <div className="d-flex justify-content-end gap-3 mb-4">
          <button
            className="buttonX"
            onClick={() =>
              this.props.history.push(`${DATA_SOURCES_PATH}/create`)
            }
          >
            <i className="bi bi-plus-lg " />
            Create Data Source
          </button>
          <button
            className="buttonX white"
            onClick={this.handleDeleteModal}
            disabled={selectedDataSources.length === 0}
          >
            <i className="bi bi-trash3" />
            Delete Data Sources
          </button>
        </div>
        <CommonCard className="">
          <div className="row">
            <div className="col-md-8">
              <div className="text-left mb-3">
                <input
                  type="search"
                  placeholder="Search By Name"
                  className="input-boxes"
                  onChange={(e) => this.onSearchData(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-right ">
                <MultiSelect
                  closeMenuOnSelect={false}
                  isMulti
                  options={options}
                  handleChange={(value) => this.onFilterData(value)}
                  placeholder="Filter By Type"
                />
              </div>
            </div>
          </div>

          <div className="desktop-table">
            <TableContainer>
              <Table
                className="tableX"
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={dataSourceList?.length}
                  headCells={headCells}
                />
                {isDone && dataSourceList && dataSourceList?.length > 0 ? (
                  <TableBody className="table Table">
                    {stableSort(dataSourceList, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((dataSourceData, index) => {
                        const isItemSelected = this.isSelected(
                          dataSourceData.id
                        );
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) =>
                                  this.handleClick(event, dataSourceData.id)
                                }
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>

                            <TableCell
                              align="left"
                              onClick={() =>
                                this.props.history.push(
                                  `${DATA_SOURCES_DETAILS_PATH}/${dataSourceData.id}`,
                                  dataSourceData
                                )
                              }
                            >
                              <span
                                data-testid={`data-id ${index}`}
                                className="id"
                              >
                                {dataSourceData.name}
                              </span>
                            </TableCell>

                            <TableCell align="left">
                              {dataSourceData.type ? dataSourceData.type : '-'}
                            </TableCell>

                            <TableCell align="left">
                              {dataSourceData?.datasource_config?.aws_region ??
                                '-'}
                            </TableCell>

                            <TableCell align="left">
                              {dataSourceData?.metadata?.created_timestamp
                                ? getReadableDate(
                                    dataSourceData?.metadata?.created_timestamp
                                  )
                                : '-'}
                            </TableCell>

                            <TableCell align="left">
                              {dataSourceData?.metadata?.modified_timestamp
                                ? getReadableDate(
                                    dataSourceData?.metadata?.modified_timestamp
                                  )
                                : '-'}
                            </TableCell>

                            <TableCell align="left">
                              <DataSourceMenu
                                onComplete={this.deleteDone}
                                id={dataSourceData.id}
                                title={dataSourceData.name}
                                history={this.props.history}
                                data={dataSourceData}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
            {loading ? (
              <SkeletonLoader />
            ) : isDone && dataSourceList && dataSourceList.length > 0 ? (
              <TablePagination
                className="table-pagination"
                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                component="div"
                count={dataSourceList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            ) : !loading && dataSourceList && dataSourceList.length === 0 ? (
              <p className="text_color text-center no-data-msg mt-2">
                No Data Found
              </p>
            ) : null}
          </div>
          <div className="mobile-table">
            {loading ? (
              <SkeletonLoader />
            ) : isDone && dataSourceList ? (
              dataSourceList.map((dataSourceData, i) => {
                return (
                  <div key={i} className="mob-main">
                    <div className="mob-rows">
                      <div className="keys">Name</div>
                      <div
                        onClick={() =>
                          this.props.history.push(
                            `${DATA_SOURCES_DETAILS_PATH}/${dataSourceData.id}`,
                            dataSourceData
                          )
                        }
                        className="values id"
                      >
                        {dataSourceData.name}
                      </div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">Type</div>
                      <div className="values">{dataSourceData.type}</div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">Region</div>
                      <div className="values ">
                        {dataSourceData?.datasource_config?.aws_region ?? '-'}
                      </div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">Created</div>
                      <div className="values">
                        {dataSourceData?.metadata?.created_timestamp
                          ? getReadableDate(
                              dataSourceData?.metadata?.created_timestamp
                            )
                          : '-'}
                      </div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">Updated</div>
                      <div className="values">
                        {dataSourceData?.metadata?.modified_timestamp
                          ? getReadableDate(
                              dataSourceData?.metadata?.modified_timestamp
                            )
                          : '-'}
                      </div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">Actions</div>
                      <div>
                        <DataSourceMenu
                          onComplete={this.deleteDone}
                          id={dataSourceData.id}
                          title={dataSourceData.name}
                          history={this.props.history}
                          data={dataSourceData}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : !loading && dataSourceList && dataSourceList.length === 0 ? (
              <p className="text_color text-center no-data-msg mt-2">
                No Data Found
              </p>
            ) : null}
          </div>
        </CommonCard>

        {isDelete && (
          <DeleteModal
            deleteCount={selectedDataSources.length}
            title="DataSources"
            show={isDelete}
            handleClose={this.handleDeleteModal}
            onDelete={this.onDeleteDataSources}
          />
        )}
      </Layout>
    );
  }
}
