// To get a value from local storage
export const getFromLocalStorage = (key) => {
  return JSON.parse(localStorage?.getItem(key));
};

// To set a value in local storage
export const setToLocalStorage = async (key, value) => {
  await localStorage?.setItem(key, JSON.stringify(value));
};

// To Remove Particular Local Storage Data
export const deleteFromLocalStorage = (key) => {
  localStorage?.removeItem(key);
};

export const maskAllCharacters = (inputString) => {
  if (typeof inputString === 'string') {
    return '*'.repeat(inputString.length);
  }
  return '';
};

export const areAllCharactersAsterisks = (inputString) => {
  for (let i = 0; i < inputString.length; i++) {
    if (inputString[i] !== '*') {
      return false;
    }
  }
  return true;
};

export const copyToClipBoard = (copyString) => {
  navigator.clipboard.writeText(copyString);
};

export function getDomainFromUrl(url) {
  try {
    const domain = new URL(url).hostname;
    return domain.startsWith('www.') ? domain.slice(4) : domain;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
}

export function getClusterStatusTextClassName(status) {
  return status
    ? status?.toLowerCase() === 'pending'
      ? 'pending'
      : status?.toLowerCase() === 'available'
        ? 'available'
        : 'disable'
    : '';
}
