import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const TimeSeriesChart = ({ TIMESERIES_DATA }) => {
  const [chartData, setChartData] = useState([]);
  const [layout, setLayout] = useState({});

  useEffect(() => {
    const tickVals = [];
    const tickText = [];

    let timeseriesData = Object.entries(TIMESERIES_DATA).map(([key, value]) => [
      parseInt(key),
      ...value,
    ]);
    timeseriesData.forEach((item, index) => {
      tickVals.push(index + 1);
      tickText.push(`${item[2]} : ${item[0]}`);
    });

    const minTime = Math.min(...timeseriesData.map((item) => item[1][0]));
    const maxTime = Math.max(...timeseriesData.map((item) => item[1][1]));
    const fullRange = maxTime - minTime;
    const minDt = new Date((minTime - fullRange * 0.01) / 1000.0);
    const maxDt = new Date((maxTime + fullRange * 0.01) / 1000.0);

    setChartData(processStepData(timeseriesData, fullRange));

    setLayout({
      height: Math.max(timeseriesData.length * 20 + 100, 700),
      margin: { t: 0 },
      xaxis: { range: [minDt, maxDt], type: 'date' },
      yaxis: {
        showgrid: false,
        tickmode: 'array',
        tickvals: tickVals,
        ticktext: tickText,
        fixedrange: true,
        automargin: true,
        range: [0, timeseriesData.length + 1],
      },
      showlegend: false,
      hovermode: 'closest',
    });
  }, [TIMESERIES_DATA]);

  const segment = (name, x1, x2, y, thickness, color, hover) => ({
    name: `Step: ${name}`,
    x: [new Date(x1 / 1000.0), new Date(x2 / 1000.0)],
    y: [y, y],
    mode: 'lines+markers',
    marker: { symbol: 142, size: thickness, color },
    line: { width: thickness },
    hovertemplate: `${hover}<extra></extra>`,
    type: 'scatter',
  });

  const processStepData = (input, fullRange) => {
    const result = [];
    const frac =
      (3.0 * (input.length + 1)) / Math.max(input.length * 20 + 100, 700);
    input.forEach((i, idx) => {
      let thickness = 3;
      let parallelism = '';
      let color = 'blue';
      let lcolor = 'lightblue';
      const start = i[1][0];
      const end = i[1][1];
      const consumed = i[1][2];
      let mid = start;
      const range = end - start;
      if (range > 0.02 * fullRange) {
        mid = end - consumed;
        if (consumed < 0.02 * range) mid = end;
      }

      if (i[4] > 1) {
        parallelism = `<br>Paralellism: ${i[4]}`;
        thickness = 1.2;
        const y = idx + 1 + frac;
        if (mid > start)
          result.push(segment(i[0], start, mid, y, thickness, lcolor, ''));
        if (mid < end)
          result.push(segment(i[0], mid, end, y, thickness, color, ''));
      }

      const hover = `%{x}<br>${i[2]} (Step: ${i[0]})<br>${i[3]}${parallelism}`;
      if (mid > start)
        result.push(
          segment(i[0], start, mid, idx + 1, thickness, lcolor, hover)
        );
      if (mid < end)
        result.push(segment(i[0], mid, end, idx + 1, thickness, color, hover));
    });
    return result;
  };

  return (
    <Plot
      data={chartData}
      layout={layout}
      useResizeHandler
      style={{
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    />
  );
};

export default TimeSeriesChart;
