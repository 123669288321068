import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  ConnectionLineType,
  Panel,
  useNodesState,
  useEdgesState,
  MiniMap,
  Controls,
  MarkerType,
  applyNodeChanges,
  useReactFlow,
  getRectOfNodes,
  getTransformForBounds,
} from 'reactflow';
import dagre from 'dagre';
import graphlibDot from 'graphlib-dot';
import 'reactflow/dist/style.css';
import { nodeTypes, edgeTypes } from './nodes-edges';
import NodeDetails from './NodeDetails';
import { toSvg } from 'html-to-image';
import DownloadIcon from '@mui/icons-material/Download';
import RefreshIcon from '@mui/icons-material/Refresh';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

const findTargetsGloble = (edges, source, targets = []) => {
  edges.forEach((edge) => {
    if (edge.source === source) {
      targets.push(edge.target);
      findTargetsGloble(edges, edge.target, targets);
    }
  });
  return targets;
};

const getLayoutedElements = (nodes, edges, refresh = null) => {
  const direction = 'TB'; // Fixed direction to Top-Bottom
  dagreGraph.setGraph({ rankdir: direction });
  nodes.forEach((node) => {
    const width = node.width + 40 || nodeWidth; // Default width if not specified
    const height = node.height + 40 || nodeHeight; // Default height if not specified
    dagreGraph.setNode(node.id, { width, height });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const targets = findTargetsGloble(edges, node.id);
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = 'top';
    node.sourcePosition = 'bottom';
    if (refresh === 'refresh') {
      node.hidden = false;
    }

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };
    node.data = {
      ...node.data,
      isSubNode: targets.length > 0 ? true : false,
      ...(refresh === 'refresh' ? { expanded: false } : ''),
    };
    return node;
  });
  return { nodes, edges };
};

const convertDotToReactFlow = (dotData) => {
  const graph = graphlibDot.read(dotData);
  const nodes = [];
  const edges = [];

  graph.nodes().forEach((nodeId) => {
    const node = graph.node(nodeId);

    nodes.push({
      id: nodeId,
      type: node.type || 'customNode',
      data: {
        label:
          node.label
            ?.replace('𝘚𝘵𝘦𝘱 𝘐𝘋: ', '')
            .replace(nodeId + '\\n', '')
            .replace(/^{{|}}$/g, '')
            .replace(/\/\*/g, '<b>')
            .replace(/\*\//g, '</b>')
            .replace(/\\n/g, '<br>') || nodeId,
        ...(node.fillcolor
          ? { backgroundColor: node.fillcolor, color: node.color || 'black' }
          : {}),
      },
      position: { x: 0, y: 0 },
      style: {
        border: '1px solid #c1c1c1',
        borderRadius: '5px',
        boxShadow: '4px 4px 10px 0px var(--clr-shadow)',
      },
    });
  });

  graph.edges().forEach((edge) => {
    const edgeData = graph.edge(edge);

    let markerStart = {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
      color: '#88a9ff',
    };

    let style = {
      strokeWidth: 1,
      stroke: '#88a9ff',
    };

    edges.push({
      id: `${edge.v}-${edge.w}`,
      type: 'custom-edge',
      // type: "smoothstep",
      source: edge.w,
      target: edge.v,
      label: edgeData.label?.replace(/\\n/g, ' ') || edgeData.label,
      arrowHeadType: 'arrow',
      markerStart: markerStart,
      style: style,
    });
  });
  return getLayoutedElements(nodes, edges);
};

const DFGsSummary = ({ GRAPH_PLAN, pdfName }) => {
  const { nodes: initialNodes, edges: initialEdges } =
    convertDotToReactFlow(GRAPH_PLAN);
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);
  const defaultViewport = { x: 50, y: 10, zoom: 0.7 };
  const [currentNode, setCurrentNode] = useState(null);
  const [expandNodeID, setExpandNodeID] = useState([]);
  const [onload, setOnLoad] = useState(true);
  const { getNodes, fitView } = useReactFlow();

  const onLayout = useCallback(
    (onload, parameter) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, parameter);
      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges]
  );

  const findTargets = (edges, sources, targets = []) => {
    edges.forEach((edge) => {
      if (edge.source === sources) {
        targets.push(edge.target);
        findTargetsGloble(edges, edge.target, targets);
      }
    });
    return targets;
  };

  const onNodeClick = (event, node) => {
    setCurrentNode(node);
  };

  const closeNodeDetails = () => {
    setCurrentNode(null);
  };

  const handleNodeClick = (node) => {
    setExpandNodeID((prevExpandNodeID) => {
      let updatedExpandNodeID;
      if (prevExpandNodeID.includes(node)) {
        updatedExpandNodeID = prevExpandNodeID.filter((item) => item !== node);
      } else {
        updatedExpandNodeID = [...prevExpandNodeID, node];
      }
      // Find targets for updated nodes
      let targetArr = updatedExpandNodeID.map((nodeId) => {
        const targets = findTargets(edges, nodeId);
        return { nodeId, targets };
      });

      // Collect all target node IDs
      let targetSet = new Set();
      targetArr.forEach((item) => {
        item.targets.forEach((target) => targetSet.add(target));
      });

      nodes.forEach((val) => {
        val.hidden = targetSet.has(val.id);

        if (updatedExpandNodeID.includes(val.id)) {
          val.data.expanded = true;
        } else if (node === val.id) {
          val.data.expanded = false;
        }
      });
      onLayout();
      return updatedExpandNodeID;
    });
  };

  const onNodesChange = (changes) => {
    setNodes((nds) =>
      applyNodeChanges(changes, nds).map((node) => ({
        ...node,
        data: {
          ...node.data,
          onClick: handleNodeClick,
          // expanded: false,
        },
      }))
    );
  };

  function downloadImage(dataUrl) {
    const a = document.createElement('a');
    a.setAttribute('download', pdfName + '-query-plan.svg');
    a.setAttribute('href', dataUrl);
    a.click();
  }

  const onClickDownload = () => {
    const firstNode = nodes.find(
      (node) => !edges.some((edge) => edge.target === node.id)
    );
    const lastNode = nodes.find(
      (node) => !edges.some((edge) => edge.source === node.id)
    );

    const reactFlowContainer = document.querySelector('.react-flow__viewport');
    let imageWidth = reactFlowContainer.clientWidth;
    let imageHeight = reactFlowContainer.clientHeight;

    if (firstNode && lastNode && firstNode.id !== lastNode.id) {
      imageHeight = Math.abs(lastNode.position.y - firstNode.position.y);
      imageWidth = imageHeight;
    } else {
      imageHeight = 350;
      imageWidth = 350;
    }

    // Find the bounding box of all nodes

    const nodesBounds = getRectOfNodes(getNodes());
    const transform = getTransformForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0.5,
      2
    );
    toSvg(document.querySelector('.react-flow__viewport'), {
      backgroundColor: '#fff',
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform:
          firstNode && lastNode && firstNode.id !== lastNode.id
            ? `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`
            : `translate(${transform[2]}px, ${transform[1]}px) scale(${transform[3]})`,
      },
    }).then(downloadImage);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (onload) {
        onLayout();
        setOnLoad(false);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [nodes, edges, onload]);

  const onFitView = () => {
    const fitOptions = {
      padding: 0.1, // Adjust as needed
      minZoom: 0.1, // Adjust as needed
      animate: true, // Whether to animate the transition
    };
    fitView(fitOptions);
  };

  return (
    <ReactFlow
      className="graph"
      nodes={nodes}
      edges={edges}
      onNodeClick={onNodeClick}
      connectionLineType={ConnectionLineType.SmoothStep}
      defaultViewport={defaultViewport}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onNodesChange={onNodesChange}
    >
      <MiniMap pannable zoomable zoomStep={3} onNodeClick={onNodeClick} />
      <Controls onFitView={onFitView} />
      <Panel position="top-right">
        {currentNode && (
          <NodeDetails node={currentNode} onClose={closeNodeDetails} />
        )}
        <div style={{ display: 'flex', gap: '10px' }}>
          <button className="buttonX" onClick={() => onClickDownload()}>
            <DownloadIcon /> Download
          </button>
          <button className="buttonX" onClick={() => onLayout(null, 'refresh')}>
            <RefreshIcon /> Refresh
          </button>
        </div>
      </Panel>
    </ReactFlow>
  );
};

export default DFGsSummary;
