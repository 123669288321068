import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { MenuItem, TextField } from '@mui/material';
import Layout from '../../components/layout/SideBar/Layout';
import CommonCard from '../../components/CommonCard/CommonCard';
import { CLUSTER_PATH, CREATE_CLUSTER_PATH } from '../../router/PathConst';
import ApiConstant from '../../utils/apiConstant';
import { get, post, put } from '../../utils/apiMethods';
import {
  createMessage,
  getPropArray,
  getPropsStructure,
} from '../../utils/common';
import config from '../../templates/ClusterTemplate.json';
import SkeletonLoader from '../../components/Loader/SkeletonLoader';
import ActivityLoader from '../../components/Loader/ActivityLoader';
import FloatingLabelDropdown from '../../components/FloatingLabelDropdown/FloatingLabelDropdown';
import { toast } from 'react-toastify';
import UpdateClusterWarningModal from '../../modals/cluster/UpdateClusterWarningModal';

export default function ClusterAddEdit(props) {
  const formRef = useRef(null);

  const [type, setType] = useState('create');
  const crumbs = [
    {
      title: 'Cluster',
      path: CLUSTER_PATH,
      active: false,
    },
    {
      title: type === 'edit' ? 'Update Cluster' : 'Create Cluster',
      path: CREATE_CLUSTER_PATH,
      active: true,
    },
  ];

  const [propsArray, setPropsArray] = useState([]);
  const [status, setStatus] = useState('available');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const [isFile, setIsFile] = useState(false);
  const [clusterConfig, setClusterConfig] = useState({});
  const [actionDone, setActionDone] = useState(true);
  const [isUpdatable, setIsUpdatable] = useState(
    type === 'edit' ? false : true
  );
  const [isUpdateClusterModal, setIsUpdateClusterModal] = useState(false);

  let isData = clusterConfig && clusterConfig.cluster ? true : false;

  const nameCheck = /^[A-Za-z][A-Za-z0-9-]+$/;

  const validationSchema = yup.object({
    clusterName: yup
      .string('Enter your cluster Name')
      .required('Cluster Name is required')
      .matches(
        nameCheck,
        'Cluster names can only start with a-z or A-Z, and can only contain A-Z, a-Z, 0-9 and dashes(-).'
      ),
  });

  const formik = useFormik({
    initialValues: {
      clusterName: type === 'edit' ? data?.name : isFile ? jsonData?.name : '',
      cloudConfigName:
        type === 'edit'
          ? data?.cloud_config_name
          : isFile
            ? jsonData?.cloud_config_name
            : '',
      cloudConfig:
        type === 'edit'
          ? (data?.cloud_config_id ?? '')
          : clusterConfig?.cloudConfig?.name?.Value,
      scale:
        type === 'edit'
          ? (data?.scale ?? 'Software')
          : isFile
            ? jsonData?.scale
            : clusterConfig?.scale?.name?.Value,
      ppInstanceType:
        type === 'edit'
          ? data?.parsing_and_planning_nodegroup?.instance_type
          : isFile
            ? jsonData?.parsing_and_planning_nodegroup?.instance_type
            : '',
      ppMin:
        type === 'edit'
          ? data?.parsing_and_planning_nodegroup?.desired_node_count
          : isFile
            ? jsonData?.parsing_and_planning_nodegroup?.desired_node_count
            : '',
      ppMax:
        type === 'edit'
          ? data?.parsing_and_planning_nodegroup?.desired_node_count
          : isFile
            ? jsonData?.parsing_and_planning_nodegroup?.desired_node_count
            : '',
      exeInstanceType:
        type === 'edit'
          ? data?.execution_nodegroup?.instance_type
          : isFile
            ? jsonData?.execution_nodegroup?.instance_type
            : '',
      exeMin:
        type === 'edit'
          ? data?.execution_nodegroup?.desired_node_count
          : isFile
            ? jsonData?.execution_nodegroup?.desired_node_count
            : '',
      exeMax:
        type === 'edit'
          ? data?.execution_nodegroup?.desired_node_count
          : isFile
            ? jsonData?.execution_nodegroup?.desired_node_count
            : '',
      created_timestamp: type === 'edit' ? data?.created_timestamp : '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmitHandler(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (data?.status === 'Update Failed') {
      setIsUpdatable(true);
    }
  }, [loading]);

  useEffect(() => {
    async function setData() {
      setLoading(true);

      let array = [];

      await config?.Restrictions?.props?.forEach((element) => {
        array?.push(element);
      });

      setPropsArray((prevState) => [...prevState, ...array]);

      let scaleValuesData = {};

      scaleValuesData = await get({ url: '/v2/cluster-scale' });

      let configValuesData = [];

      configValuesData = await get({
        url: '/v2/cloud-config?offset=0&limit=999',
      });

      configValuesData = configValuesData?.filter(
        (data) => data.state === 'Active'
      );

      const ClusterConfigData = {
        ...config?.Restrictions,
        scale: {
          ...config?.Restrictions?.scale,
          name: {
            ...config?.Restrictions?.scale?.name,
            Restrict: {
              ...config?.Restrictions?.scale?.name?.Restrict,
              selectValues: scaleValuesData,
            },
          },
        },
        cloudConfig: {
          ...config?.Restrictions?.cloudConfig,
          name: {
            ...config?.Restrictions?.cloudConfig?.name,
            Restrict: {
              ...config?.Restrictions?.cloudConfig?.name?.Restrict,
              selectValues: configValuesData ?? [],
            },
          },
        },
      };

      setClusterConfig(ClusterConfigData);
      setLoading(false);
    }

    setData();

    return () => {
      if (type !== 'edit') {
        const restore = [];

        config.Restrictions.props.forEach((element) => {
          element.name.Value = '';
          element.id.Value = '';

          restore.push(element);
        });

        setClusterConfig({});
        setPropsArray(restore);
      }
    };
  }, [type]);

  useEffect(() => {
    if (props.match.params.type === 'create') {
      setType(props.match.params.type);
    } else {
      setType('edit');
      setIsUpdatable(false);
      setLoading(true);
    }
  }, [props.match.params.type]);

  useEffect(() => {
    if (props.match.params.type !== 'create') {
      getClusterData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.type]);

  const onCreateJSONUpload = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = async (e) => {
      const response = JSON.parse(e.target.result);
      setJsonData(JSON.parse(e.target.result));
      setIsFile(true);
      if (response.props) {
        const idsToRemove = [
          'AWS Access Key ID',
          'AWS Secret Access Key',
          'AWS EC2 Key Pair Name',
          'AWS Region',
          'CLOUD_TYPE',
          'Compute',
          'Parsing And Planning',
          'Execution',
        ];

        const updatedProps = await removeItemsByIds(
          response?.props,
          idsToRemove
        );

        const result = getPropsStructure(
          updatedProps,
          config?.Restrictions?.props
        );
        setPropsArray(result);
      }
    };
  };

  async function updateField(e) {
    await formik.setFieldValue(e.target.name, e.target.value);
    setIsUpdatable(true);
  }

  async function removeItemsByIds(props, idsToRemove) {
    const updatedProps = await props?.filter(
      (item) => !idsToRemove?.includes(item.id)
    );
    return updatedProps;
  }

  const getClusterData = async () => {
    setLoading(true);

    try {
      const id = props.match.params.type;
      const URL = `${ApiConstant.GET_CLUSTER}/${id}`;

      const response = await get({ url: URL });

      if (response) {
        setData(response);
        setStatus(response.status);

        if (response.props) {
          const result = await getPropsStructure(
            response.props,
            config?.Restrictions?.props
          );

          setPropsArray(result);
        }
      }
    } catch (error) {
      console.error('Error fetching cluster data:', error);
    } finally {
      setLoading(false);
    }
  };

  const addProps = () => {
    const array = [...propsArray];
    const obj = {
      id: {
        Value: '',
        Restrict: {
          displayprompt: 'Name',
          editable: true,
          sensitive: false,
          default: '',
          required: false,
          valuehelp: '',
          valuevalidation: '',
        },
      },
      name: {
        Value: '',
        Restrict: {
          displayprompt: 'Value',
          editable: true,
          sensitive: true,
          default: '',
          required: false,
          valuehelp: '',
          valuevalidation: '',
        },
      },
      Restrict: {
        displayprompt: '',
        editable: true,
        sensitive: false,
        default: '""',
        required: false,
        valuehelp: '',
        valuevalidation: '',
      },
    };
    array.push(obj);
    setPropsArray(array);
  };

  const handleChangeProps = (e, index, type) => {
    const array = [...propsArray];
    if (type === 'id') {
      array[index].id.Value = e.target.value;
    } else {
      array[index].name.Value = e.target.value;
    }
    setPropsArray(array);
    setIsUpdatable(true);
  };

  const deleteProps = (i) => {
    const array = [...propsArray];
    array.splice(i, 1);
    setPropsArray(array);
  };

  function setScaleFieldCurrentValue(event) {
    const data =
      clusterConfig?.scale?.name?.Restrict?.selectValues?.[
        event.target.value ??
          Object.keys(clusterConfig?.scale?.name?.Restrict?.selectValues)[0]
      ];

    const fields = [
      {
        key: 'ppInstanceType',
        value: data?.parsing_and_planning_nodegroup?.instance_type,
      },
      {
        key: 'ppMin',
        value:
          data?.parsing_and_planning_nodegroup?.desired_node_count.toString(),
      },
      {
        key: 'ppMax',
        value:
          data?.parsing_and_planning_nodegroup?.desired_node_count.toString(),
      },
      {
        key: 'exeInstanceType',
        value: data?.execution_nodegroup?.instance_type,
      },
      {
        key: 'exeMin',
        value: data?.execution_nodegroup?.desired_node_count.toString(),
      },
      {
        key: 'exeMax',
        value: data?.execution_nodegroup?.desired_node_count.toString(),
      },
    ];

    fields.forEach((field) => {
      formik.setFieldValue(
        field.key,
        event.target.value === '' ? '' : field.value
      );
    });
  }

  function setCloudConfigFieldCurrentValue(event) {
    const data =
      clusterConfig?.cloudConfig?.name?.Restrict?.selectValues?.filter(
        (val) => val.name === event.target.value
      );

    const fields = [
      {
        key: 'ppInstanceType',
        value: data?.parsing_and_planning_nodegroup?.instance_type,
      },
      {
        key: 'ppMin',
        value:
          data?.parsing_and_planning_nodegroup?.desired_node_count.toString(),
      },
      {
        key: 'ppMax',
        value:
          data?.parsing_and_planning_nodegroup?.desired_node_count.toString(),
      },
      {
        key: 'exeInstanceType',
        value: data?.execution_nodegroup?.instance_type,
      },
      {
        key: 'exeMin',
        value: data?.execution_nodegroup?.desired_node_count.toString(),
      },
      {
        key: 'exeMax',
        value: data?.execution_nodegroup?.desired_node_count.toString(),
      },
    ];

    fields.forEach((field) => {
      formik.setFieldValue(
        field.key,
        event.target.value === '' ? '' : field.value
      );
    });
  }

  const onSubmitHandler = async (values) => {
    // let cloudConfigData =
    //   values.cloudConfig &&
    //   clusterConfig?.cloudConfig?.name?.Restrict?.selectValues?.filter(
    //     (val) => val.id === values.cloudConfig
    //   );
    // let configObj = values.cloudConfig;
    // if (cloudConfigData) {
    //   configObj = { id: cloudConfigData[0].id, name: cloudConfigData[0].name };
    // }

    setActionDone(false);

    if (type === 'edit') {
      const id = props.match.params.type;

      const payload = {
        id: id,
        name: values.clusterName,
        cloud_config_id: values.cloudConfig,
        scale: values.scale,
        parsing_and_planning_nodegroup: {
          instance_type: values.ppInstanceType,
          desired_node_count: values.ppMin,
          min_node_count: values.ppMin,
          max_node_count: values.ppMin,
        },
        execution_nodegroup: {
          instance_type: values.exeInstanceType,
          desired_node_count: values.exeMin,
          min_node_count: values.exeMin,
          max_node_count: values.exeMin,
        },
        props: getPropArray(propsArray),
        status: status,
        created_timestamp: data.created_timestamp,
        tenantId: data.tenant_id,
      };

      const response = await put({
        url: `${ApiConstant.UPDATE_CLUSTER}/${id}`,
        payload: payload,
        type: 'v2',
      });

      if (response && response?.data?.id) {
        isData = false;
        createMessage(200, 'Cluster updated');
        getClusterData();
      } else {
        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }
    } else {
      const payload = {
        name: values.clusterName,
        cloud_config_id: values.cloudConfig,
        scale: values.scale,
        parsing_and_planning_nodegroup: {
          instance_type: values.ppInstanceType,
          desired_node_count: values.ppMin,
          min_node_count: values.ppMin,
          max_node_count: values.ppMin,
        },
        execution_nodegroup: {
          instance_type: values.exeInstanceType,
          desired_node_count: values.exeMin,
          min_node_count: values.exeMin,
          max_node_count: values.exeMin,
        },
        props: getPropArray(propsArray),
        status: status,
      };

      const response = await post({
        url: ApiConstant.CREATE_CLUSTER,
        payload: payload,
        type: 'v2',
      });

      if (response && response?.data?.id) {
        props.history.push(CLUSTER_PATH);

        createMessage(200, 'Cluster Creation Started');
      } else {
        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }
    }

    setActionDone(true);
  };

  function toggleUpdateClusterHandler() {
    setIsUpdateClusterModal((prevState) => !prevState);
  }

  const triggerSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  return (
    <Layout crumbs={crumbs} {...props}>
      <div className="cluster-add">
        <CommonCard className="card-wrapper" isForm>
          <div className="form-wrapper">
            <h4
              className={
                type === 'edit'
                  ? 'text_primary text-left mb-4'
                  : 'text_primary text-left mb-4'
              }
            >
              {type === 'edit' ? 'Update Cluster' : 'Create Cluster'}
            </h4>

            {type === 'edit' && loading ? (
              <SkeletonLoader />
            ) : (
              <form
                ref={formRef}
                onSubmit={(event) => {
                  event.preventDefault();

                  if (type === 'edit') {
                    if (isUpdateClusterModal) {
                      formik.handleSubmit(event);
                      toggleUpdateClusterHandler();
                    } else {
                      toggleUpdateClusterHandler();
                    }
                  } else {
                    formik.handleSubmit(event);
                  }
                }}
              >
                <div>
                  <TextField
                    autoComplete="off"
                    inputProps={{ 'data-testid': 'name-Input' }}
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    variant="outlined"
                    name="clusterName"
                    label={
                      isData && clusterConfig.cluster.Restrict.displayprompt
                    }
                    InputLabelProps={{
                      required: false,
                    }}
                    value={`${formik.values.clusterName}`}
                    onChange={updateField}
                    error={Boolean(formik.errors.clusterName)}
                    helperText={formik.errors.clusterName}
                    required={
                      isData &&
                      clusterConfig &&
                      clusterConfig.cluster &&
                      clusterConfig.cluster.Restrict.required
                    }
                    disabled={
                      type === 'edit'
                        ? true
                        : isData &&
                          clusterConfig &&
                          clusterConfig.cluster &&
                          !clusterConfig.cluster.Restrict.editable
                    }
                  />
                </div>
                <div className="props-wrapper">
                  <p className="prop-label mt-2 ">Properties</p>
                  <div className="props-box">
                    <div
                      className={`d-flex justify-content-between align-items-center mt-3`}
                    >
                      <div className="inputs">
                        <input
                          className="form-control id-input"
                          value={clusterConfig?.cloudConfig?.id?.Value}
                          placeholder={
                            clusterConfig?.cloudConfig?.id?.Restrict
                              ?.displayprompt
                          }
                          required={
                            clusterConfig?.cloudConfig?.id?.Restrict?.required
                          }
                          disabled={
                            !clusterConfig?.cloudConfig?.id?.Restrict?.editable
                          }
                        />
                      </div>

                      {type === 'edit' ? (
                        <TextField
                          className="inputs"
                          autoComplete="off"
                          id="outlined-basic"
                          variant="outlined"
                          name="cloudConfig"
                          label={
                            clusterConfig?.cloudConfig?.name?.Restrict
                              ?.displayprompt
                          }
                          value={formik.values.cloudConfigName}
                          disabled="true"
                        />
                      ) : (
                        <div className="inputs">
                          <FloatingLabelDropdown
                            style={{ width: '100%' }}
                            labelStyle={{ fontSize: '14px' }}
                            name="cloudConfig"
                            label={
                              clusterConfig?.cloudConfig?.name?.Restrict
                                ?.displayprompt
                            }
                            value={formik.values.cloudConfig}
                            required={
                              clusterConfig?.cloudConfig?.name?.Restrict
                                ?.required
                            }
                            disabled={
                              type === 'edit'
                                ? true
                                : !clusterConfig?.cloudConfig?.name?.Restrict
                                    ?.editable
                            }
                            handleChange={(e) => {
                              updateField(e);
                              setCloudConfigFieldCurrentValue(e);
                            }}
                          >
                            {clusterConfig?.cloudConfig?.name?.Restrict?.selectValues?.map(
                              (data) => (
                                <MenuItem value={data.id} key={data.id}>
                                  {data.name}
                                </MenuItem>
                              )
                            )}
                          </FloatingLabelDropdown>
                        </div>
                      )}

                      <div
                        style={{
                          width: '80px',
                          display: 'flex',
                        }}
                      />
                    </div>

                    <div
                      className={`d-flex justify-content-between align-items-center mt-3`}
                    >
                      <div className="inputs">
                        <input
                          className="form-control id-input"
                          value={clusterConfig?.scale?.id?.Value}
                          placeholder={
                            clusterConfig?.scale?.id?.Restrict?.displayprompt
                          }
                          required={
                            clusterConfig?.scale?.id?.Restrict?.required
                          }
                          disabled={
                            !clusterConfig?.scale?.id?.Restrict?.editable
                          }
                        />
                      </div>

                      <div className="inputs">
                        <FloatingLabelDropdown
                          style={{ width: '100%' }}
                          labelStyle={{ fontSize: '14px' }}
                          name="scale"
                          label={
                            clusterConfig?.scale?.name?.Restrict?.displayprompt
                          }
                          value={formik.values.scale || ''}
                          required={
                            clusterConfig?.scale?.name?.Restrict?.required
                          }
                          disabled={
                            type === 'edit'
                              ? true
                              : !clusterConfig?.scale?.name?.Restrict?.editable
                          }
                          handleChange={(e) => {
                            updateField(e);
                            setScaleFieldCurrentValue(e);
                          }}
                        >
                          {Object.entries(
                            clusterConfig?.scale?.name?.Restrict
                              ?.selectValues || {}
                          ).map(([key, value]) => (
                            <MenuItem value={key} key={key}>
                              {value?.name}
                            </MenuItem>
                          ))}
                        </FloatingLabelDropdown>
                      </div>

                      <div
                        style={{
                          width: '80px',
                          display: 'flex',
                        }}
                      />
                    </div>

                    <div
                      className={`d-flex justify-content-between align-items-center mt-3`}
                    >
                      <div className="inputs">
                        <input
                          className="form-control id-input"
                          placeholder={
                            clusterConfig?.parsingAndPlanningNodeGroup?.id
                              ?.Restrict?.required.displayprompt
                          }
                          value={
                            clusterConfig?.parsingAndPlanningNodeGroup?.id
                              ?.Value
                          }
                          disabled={
                            !clusterConfig?.parsingAndPlanningNodeGroup?.id
                              ?.Restrict?.editable
                          }
                          required={
                            clusterConfig?.parsingAndPlanningNodeGroup?.id
                              ?.Restrict?.required
                          }
                        />
                      </div>

                      <div className="inputs gap-2">
                        {formik?.values?.scale === 'Software' ? (
                          <FloatingLabelDropdown
                            style={{ width: '60%' }}
                            labelStyle={{
                              fontSize: '14px',
                              color: 'var(--clr-input-text)',
                            }}
                            name="ppInstanceType"
                            label={
                              clusterConfig?.parsingAndPlanningNodeGroup?.name
                                ?.Restrict?.displayprompt
                            }
                            value={formik.values.ppInstanceType || ''}
                            handleChange={updateField}
                            disabled={
                              formik.values.scale === ''
                                ? true
                                : type === 'edit'
                                  ? true
                                  : !clusterConfig?.scale?.name?.Restrict
                                      ?.selectValues?.[
                                      formik.values.scale !== '' &&
                                      formik.values.scale !== undefined
                                        ? formik.values.scale
                                        : Object.keys(
                                            clusterConfig?.scale?.name?.Restrict
                                              ?.selectValues
                                          )[0]
                                    ]?.editable
                            }
                          >
                            {clusterConfig?.parsingAndPlanningNodeGroup?.name?.Restrict?.ParsingAndPlanning?.map(
                              (data, i) => (
                                <MenuItem key={'minX' + i} value={data}>
                                  {data}
                                </MenuItem>
                              )
                            )}
                          </FloatingLabelDropdown>
                        ) : (
                          <TextField
                            autoComplete="off"
                            id="outlined-basic"
                            variant="outlined"
                            name="ppInstanceType"
                            label={
                              clusterConfig?.parsingAndPlanningNodeGroup?.name
                                ?.Restrict?.displayprompt
                            }
                            style={{ width: '60%' }}
                            InputLabelProps={{ style: { fontSize: '14px' } }}
                            value={formik.values.ppInstanceType || ''}
                            onChange={updateField}
                            disabled={
                              formik.values.scale === ''
                                ? true
                                : type === 'edit'
                                  ? true
                                  : !clusterConfig?.scale?.name?.Restrict
                                      ?.selectValues?.[
                                      formik.values.scale !== '' &&
                                      formik.values.scale !== undefined
                                        ? formik.values.scale
                                        : Object.keys(
                                            clusterConfig?.scale?.name?.Restrict
                                              ?.selectValues
                                          )[0]
                                    ]?.editable
                            }
                          />
                        )}

                        <TextField
                          autoComplete="off"
                          id="outlined-basic"
                          variant="outlined"
                          name="ppMin"
                          label="Count"
                          style={{ width: '40%' }}
                          InputLabelProps={{ style: { fontSize: '14px' } }}
                          value={formik.values.ppMin}
                          onChange={updateField}
                          disabled={
                            formik.values.scale === ''
                              ? true
                              : type === 'edit'
                                ? true
                                : !clusterConfig?.scale?.name?.Restrict
                                    ?.selectValues?.[
                                    formik.values?.scale !== '' &&
                                    formik.values?.scale !== undefined
                                      ? formik.values.scale
                                      : Object.keys(
                                          clusterConfig?.scale?.name?.Restrict
                                            ?.selectValues
                                        )[0]
                                  ]?.editable
                          }
                        />
                        {/* <TextField
                          className={classes.root}
                          autoComplete="off"
                          id="outlined-basic"
                          variant="outlined"
                          name="ppMax"
                          label="max"
                          type="select"
                          style={{ width: "20%" }}
                          value={formik.values.ppMax}
                          onChange={updateField}
                          disabled={
                            !clusterConfig?.scale?.name?.Restrict
                              ?.selectValues?.[
                              formik.values.scale !== "" &&
                              formik.values.scale !== undefined
                                ? formik.values.scale
                                : Object.keys(
                                    clusterConfig?.scale?.name?.Restrict
                                      ?.selectValues
                                  )[0]
                            ].editable
                          }
                        /> */}
                      </div>

                      <div
                        style={{
                          width: '80px',
                          display: 'flex',
                        }}
                      ></div>
                    </div>

                    <div
                      className={`d-flex justify-content-between align-items-center mt-3`}
                    >
                      <div className="inputs">
                        <input
                          className="form-control id-input"
                          placeholder={
                            clusterConfig?.executionNodeGroup?.id?.Restrict
                              ?.required.displayprompt
                          }
                          value={clusterConfig?.executionNodeGroup?.id?.Value}
                          disabled={
                            !clusterConfig?.executionNodeGroup?.id?.Restrict
                              ?.editable
                          }
                          required={
                            clusterConfig?.executionNodeGroup?.id?.Restrict
                              ?.required
                          }
                        />
                      </div>

                      <div className="inputs gap-2">
                        {formik?.values?.scale === 'Software' ? (
                          <FloatingLabelDropdown
                            style={{ width: '60%' }}
                            labelStyle={{ fontSize: '14px' }}
                            name="exeInstanceType"
                            label={
                              clusterConfig?.executionNodeGroup?.name?.Restrict
                                ?.displayprompt
                            }
                            value={formik.values.exeInstanceType || ''}
                            handleChange={updateField}
                            disabled={
                              formik.values.scale === ''
                                ? true
                                : type === 'edit'
                                  ? true
                                  : !clusterConfig?.scale?.name?.Restrict
                                      ?.selectValues?.[
                                      formik.values.scale !== '' &&
                                      formik.values.scale !== undefined
                                        ? formik.values.scale
                                        : Object.keys(
                                            clusterConfig?.scale?.name?.Restrict
                                              ?.selectValues
                                          )[0]
                                    ]?.editable
                            }
                          >
                            {(formik?.values?.scale === 'Software'
                              ? clusterConfig?.executionNodeGroup?.name
                                  ?.Restrict?.softwareExecution
                              : clusterConfig?.executionNodeGroup?.name
                                  ?.Restrict?.execution
                            )?.map((data, i) => (
                              <MenuItem
                                key={'minX' + i}
                                value={data.toString()}
                              >
                                {data}
                              </MenuItem>
                            ))}
                          </FloatingLabelDropdown>
                        ) : (
                          <TextField
                            autoComplete="off"
                            id="outlined-basic"
                            variant="outlined"
                            name="exeInstanceType"
                            label={
                              clusterConfig?.executionNodeGroup?.name?.Restrict
                                ?.displayprompt
                            }
                            style={{ width: '60%' }}
                            InputLabelProps={{ style: { fontSize: '14px' } }}
                            value={formik.values.exeInstanceType || ''}
                            onChange={updateField}
                            disabled={
                              formik.values.scale === ''
                                ? true
                                : type === 'edit'
                                  ? true
                                  : !clusterConfig?.scale?.name?.Restrict
                                      ?.selectValues?.[
                                      formik.values.scale !== '' &&
                                      formik.values.scale !== undefined
                                        ? formik.values.scale
                                        : Object.keys(
                                            clusterConfig?.scale?.name?.Restrict
                                              ?.selectValues
                                          )[0]
                                    ]?.editable
                            }
                          />
                        )}

                        <TextField
                          autoComplete="off"
                          id="outlined-basic"
                          variant="outlined"
                          name="exeMin"
                          label="Count"
                          style={{ width: '40%' }}
                          InputLabelProps={{ style: { fontSize: '14px' } }}
                          value={formik.values.exeMin}
                          onChange={updateField}
                          disabled={
                            formik.values.scale === ''
                              ? true
                              : type === 'edit'
                                ? true
                                : !clusterConfig?.scale?.name?.Restrict
                                    ?.selectValues?.[
                                    formik.values.scale !== '' &&
                                    formik.values.scale !== undefined
                                      ? formik.values.scale
                                      : Object.keys(
                                          clusterConfig?.scale?.name?.Restrict
                                            ?.selectValues
                                        )[0]
                                  ]?.editable
                          }
                        />
                        {/* <TextField
                          className={classes.root}
                          autoComplete="off"
                          id="outlined-basic"
                          variant="outlined"
                          name="exeMax"
                          label="max"
                          type="select"
                          style={{ width: "20%" }}
                          value={formik.values.exeMax}
                          onChange={updateField}
                          disabled={
                            !clusterConfig?.scale?.name?.Restrict
                              ?.selectValues?.[
                              formik.values.scale !== "" &&
                              formik.values.scale !== undefined
                                ? formik.values.scale
                                : Object.keys(
                                    clusterConfig?.scale?.name?.Restrict
                                      ?.selectValues
                                  )[0]
                            ].editable
                          }
                        /> */}
                      </div>

                      <div
                        style={{
                          width: '80px',
                          display: 'flex',
                        }}
                      >
                        <i
                          onClick={
                            0 !== propsArray.length ? null : () => addProps()
                          }
                          className={
                            0 !== propsArray.length
                              ? 'bi bi-plus-circle vis-hidden '
                              : 'bi bi-plus-circle add-icon'
                          }
                        />
                      </div>
                    </div>

                    {isData &&
                      propsArray &&
                      propsArray?.map((item, i) => {
                        return (
                          <div
                            key={i}
                            className="d-flex justify-content-between align-items-center mt-2"
                          >
                            <div className="inputs">
                              <input
                                data-testid={`prop2 ${i}`}
                                className="form-control id-input"
                                placeholder={item?.id?.Restrict?.displayprompt}
                                value={item?.id?.Value}
                                onChange={(e) => handleChangeProps(e, i, 'id')}
                                disabled={!item.id?.Restrict?.editable}
                                required={item?.id?.Restrict?.required}
                              />
                            </div>

                            <div className="inputs">
                              <input
                                data-testid={`prop ${i}`}
                                className="form-control id-input"
                                placeholder={
                                  item?.name?.Restrict?.displayprompt
                                }
                                value={item?.name?.Value}
                                onChange={(e) =>
                                  handleChangeProps(e, i, 'name')
                                }
                                disabled={!item?.name?.Restrict?.editable}
                                required={item?.name?.Restrict?.required}
                              />
                            </div>

                            <div style={{ width: '80px', display: 'flex' }}>
                              <i
                                data-testid={`delete-prop ${i}`}
                                onClick={
                                  item?.Restrict?.required
                                    ? null
                                    : () => deleteProps(i)
                                }
                                className={
                                  item?.Restrict?.required
                                    ? 'bi bi-dash-circle disable d-none'
                                    : 'bi bi-dash-circle close-icon'
                                }
                              />
                              <i
                                data-testid={`add-prop ${i}`}
                                onClick={
                                  i < propsArray?.length - 1
                                    ? null
                                    : () => addProps()
                                }
                                className={
                                  i < propsArray?.length - 1
                                    ? 'bi bi-plus-circle vis-hidden '
                                    : 'bi bi-plus-circle add-icon'
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="mt-5 d-flex justify-content-end gap-2">
                  {actionDone ? (
                    <>
                      <button
                        className="buttonX"
                        type="submit"
                        disabled={!isUpdatable}
                      >
                        {type === 'edit' ? 'Update Cluster' : 'Create Cluster'}
                      </button>
                      <button
                        className="buttonX white"
                        type="button"
                        onClick={() =>
                          type === 'edit'
                            ? props.history.goBack()
                            : props.history.push(CLUSTER_PATH)
                        }
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <ActivityLoader />
                  )}
                </div>
              </form>
            )}
          </div>
        </CommonCard>
      </div>

      {isUpdateClusterModal && (
        <UpdateClusterWarningModal
          show={isUpdateClusterModal}
          handleClose={toggleUpdateClusterHandler}
          onConfirm={triggerSubmit}
        />
      )}
    </Layout>
  );
}
