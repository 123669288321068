import axiosInstance from './axiosInstance';
import Cookies from 'js-cookie';
import { getFromLocalStorage } from './helperFunctions';

function redirectToApiUrl(response, url) {
  if (
    response?.status === undefined ||
    (response.status >= 300 && response.status > 400)
  ) {
    if (!window.location.toString().includes('localhost')) {
      window.location = url;
    }
  }
}

/// Get API Method
export async function get({ url, type, isRedirectAllowed }) {
  const { REACT_APP_BASE_API_URL } = getFromLocalStorage('envConfig') || {};

  try {
    const response = await axiosInstance.get(url);

    if (type === 'v2') {
      return response;
    } else if (response.status === 200) {
      return response.data;
    }

    isRedirectAllowed &&
      redirectToApiUrl(response, REACT_APP_BASE_API_URL + url);
  } catch (err) {
    isRedirectAllowed &&
      redirectToApiUrl(err?.response, REACT_APP_BASE_API_URL + url);

    console.error(err);
  }
}

/// Post API Method
export const post = async ({ url, payload, type }) => {
  try {
    const response = await axiosInstance.post(url, payload);

    if (type === 'v2') {
      if (
        window.location.toString().includes('localhost') &&
        response?.data?.token
      ) {
        Cookies.set('idToken', response?.data?.token, { domain: 'localhost' });
      }

      return response;
    } else if (
      response.status === 200 ||
      response.status === 201 ||
      response.status === 400
    ) {
      return response.data;
    } else {
      return response.status;
    }
  } catch (err) {
    console.error(err);
    return err;
  }
};

/// Put API Method
export const put = async ({ url, payload, type }) => {
  try {
    const response = await axiosInstance.put(url, payload);

    if (type === 'v2') {
      return response;
    } else if (response.status === 200 || response.status === 400) {
      if (type === 'Job') {
        return response.status;
      } else {
        return response.data;
      }
    } else {
      return response.status;
    }
  } catch (err) {
    console.error(err);
    return err;
  }
};

/// Delete API Method
export const deleteAPI = async ({ url, payload, type }) => {
  try {
    const response = await axiosInstance.delete(url, { data: payload });

    if (type === 'v2') {
      return response;
    } else {
      return response.status;
    }
  } catch (err) {
    console.error(err);
    return err;
  }
};
