import React, { useEffect, useState } from 'react';
import ApiConstant from '../../../utils/apiConstant';
import { put } from '../../../utils/apiMethods';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import QueryProfileSections from './QueryProfileSections';

import '../../../assets/css/query_profile.scss';

export default function QueryProfileDetails(props) {
  const [queryDoc, setQueryDoc] = useState({});
  const [queryDocApiLoading, setQueryDocApiLoading] = useState(true);

  useEffect(() => {
    getQueryProfileDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clusterId, props.queryProfileId]);

  async function getQueryProfileDetails() {
    const response = await put({
      url: ApiConstant.GET_QUERY_PROFILE_DETAILS_SUMMARY,
      payload: {
        cluster_id: props.clusterId,
        key: atob(props.queryProfileId),
      },
      type: 'v2',
    });

    if (response.status === 200) {
      setQueryDoc(response.data);
      // setQueryDoc(responseData);
    }
    setQueryDocApiLoading(false);
  }

  return (
    <div className="query_profile">
      {queryDocApiLoading ? (
        <SkeletonLoader />
      ) : queryDoc ? (
        <QueryProfileSections queryDoc={queryDoc} />
      ) : null}
    </div>
  );
}
