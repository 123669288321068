import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

export default function SuspendResumeClusterModal(props) {
  const [isAPILoading, setIsAPILoading] = useState(false);

  const onConfirmHandle = async () => {
    setIsAPILoading(true);

    await props.onConfirm();

    setIsAPILoading(false);
  };

  return (
    <Modal
      dialog
      centered
      show={props.show}
      onHide={props.handleClose}
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Suspend Cluster</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {`Are you sure you want to suspend the cluster? Any queries being processed will be terminated.`}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="buttonX"
          data-testid="modal-action-button"
          onClick={!isAPILoading && onConfirmHandle}
        >
          {isAPILoading ? 'Loading...' : 'Suspend Cluster'}
        </button>
        <button className="buttonX white" onClick={props.handleClose}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}
