import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import {
  EnhancedTableHead,
  getComparator,
  stableSort,
} from '../../../components/TableComponent/TableComponent';
import ViewMore from '../../../modals/Common/ViewMore';
import routeConstant from '../../../router/PathConst';

export default class Properties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'desc',
      orderBy: '',
      rowsPerPage: 10,
      page: 0,
      data: [],
      viewMore: false,
      viewMoreData: '',
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  openedViewMore = (data) => {
    this.setState({ viewMoreData: data, viewMore: true });
  };

  closedViewMore = () => {
    this.setState({ viewMoreData: '', viewMore: false });
  };

  render() {
    const { order, orderBy, rowsPerPage, page, viewMoreData, viewMore } =
      this.state;

    const { data, cloudProfileId } = this.props;

    return (
      <>
        {data && data.length > 0 ? (
          <>
            {viewMore && (
              <ViewMore
                show={viewMore}
                handleClose={this.closedViewMore}
                data={viewMoreData}
                fetchKey="name"
                headKey="id"
              />
            )}

            <TableContainer id="tableDiv" className="mt-4">
              <Table
                className="tableX"
                sx={{
                  minWidth: 750,
                  borderCollapse: 'separate',
                }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                  headCells={headCells}
                />

                <TableBody className="table Table">
                  {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell align="left">
                            <span
                              className={`p-0 text-white ${
                                row.id === 'Parsing And Planning' ||
                                row.id === 'Execution'
                                  ? 'ps-3'
                                  : ''
                              }`}
                              data-testid={`cluster-prop-id ${index}`}
                            >
                              {row.id}
                            </span>
                          </TableCell>

                          <TableCell
                            style={{ overflowWrap: 'anywhere' }}
                            align="left"
                            onClick={() =>
                              cloudProfileId && row.id === 'Cloud Profile'
                                ? this.props.history.push({
                                    pathname: `${routeConstant.CLOUD_PROFILE_DETAILS(
                                      cloudProfileId
                                    )}`,
                                    state: {
                                      id: cloudProfileId,
                                    },
                                  })
                                : null
                            }
                          >
                            {row.name && row.name.length > 50 ? (
                              <span
                                className={`p-0 ${row.id === 'Cloud Profile' ? 'id' : ''}`}
                              >
                                {row.name.slice(0, 50)}{' '}
                                <span
                                  className="view-more"
                                  data-testid={`viewMore ${index}`}
                                  onClick={() => this.openedViewMore(row)}
                                >
                                  ...View More
                                </span>
                              </span>
                            ) : row.name ? (
                              <span
                                className={`p-0 text-white ${row.id === 'Cloud Profile' ? 'id' : ''}`}
                              >
                                {row.name}
                              </span>
                            ) : (
                              '--'
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="table-pagination"
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              showLastButton={true}
              showFirstButton={true}
            />
          </>
        ) : (
          <p className="text_color text-center no-data-msg mt-2">
            No Data Found
          </p>
        )}
      </>
    );
  }
}

const headCells = [
  {
    id: 'id',
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'name',
    disablePadding: false,
    label: 'Value',
  },
];
