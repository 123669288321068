import { Checkbox, MenuItem, TextField } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import CommonCard from '../../../components/CommonCard/CommonCard';
import Layout from '../../../components/layout/SideBar/Layout';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'yup-phone';
import './CloudProfile.scss';
import routeConstant from '../../../router/PathConst';
import { get, post, put } from '../../../utils/apiMethods';
import ApiConstant from '../../../utils/apiConstant';
import { createMessage } from '../../../utils/common';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import ActivityLoader from '../../../components/Loader/ActivityLoader';
import FloatingLabelDropdown from '../../../components/FloatingLabelDropdown/FloatingLabelDropdown';
import { toast } from 'react-toastify';

export default function CreateCloudProfileNew(props) {
  const [type, setType] = useState('add');
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [actionDone, setActionDone] = useState(true);
  const [isUpdatable, setIsUpdatable] = useState(
    type === 'edit' ? false : true
  );
  // const [loggingData, setLoggingData] = useState([]);
  // const [storageBucketData, setStorageBucketData] = useState([]);

  const crumbs = [
    {
      title: 'Cloud Profile',
      path: routeConstant.CLOUD_PROFILES,
      active: false,
    },
    {
      title: type === 'edit' ? 'Update Cloud Profile' : 'Create Cloud Profile',
      path: routeConstant.CLOUD_PROFILE_ADD_EDIT(props.match.params.type),
      active: true,
    },
  ];

  const [cloudProfile] = useState([
    {
      title: 'AWS',
      value: 'aws',
    },
  ]);

  const [regions, setRegions] = useState([]);

  const nameCheck = /^[A-Za-z-]+$/;

  const validationSchema = yup.object({
    name: yup
      .string('Enter your Cloud Profile Name')
      .required('Cloud Profile Name is required')
      .matches(
        nameCheck,
        'Cloud Profile names can only contain letters (A-Z, a-z) and dashes (-).'
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: type === 'edit' ? data.name : '',
      cloud_provider: type === 'edit' ? data.cloud_provider : '',
      account_id: type === 'edit' ? data?.details?.account_id : '',
      region: type === 'edit' ? data?.details?.region : '',
      opensearch_choice:
        type === 'edit'
          ? data?.resource_selection?.opensearch_choice === 'Create'
            ? true
            : false
          : false,
      queryprofile_choice:
        type === 'edit'
          ? data?.resource_selection?.queryprofile_choice === 'Create'
            ? true
            : false
          : false,
      opensearch_domain_name:
        type === 'edit' ? data?.resource_selection?.opensearch_domain_name : '',
      s3_bucket_name:
        type === 'edit' ? data?.resource_selection?.s3_bucket_name : '',
      btnType: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmitCall(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (props.match.params.type === 'create') {
      setType(props.match.params.type);
      setIsUpdatable(true);
    } else {
      setType('edit');
      setIsUpdatable(false);
      setLoading(true);
    }
  }, [props.match.params.type]);

  useEffect(() => {
    const getRegions = async () => {
      const URL = `${ApiConstant.GET_REGIONS}`;

      const response = await get({ url: URL });

      if (response && response !== undefined) {
        setRegions(response?.Providers?.AWS?.regions);
      }
    };

    getRegions();
  }, []);

  useEffect(() => {
    const getCloudProfileData = async () => {
      const URL = `${ApiConstant.GET_CLOUD_PROFILE_DETAILS(
        props.match.params.type
      )}`;

      const response = await get({ url: URL });

      if (response && response !== undefined) {
        setData(response);
        setLoading(false);
      }
    };

    if (props.match.params.type !== 'create') {
      getCloudProfileData();
    }
  }, [props.match.params.type]);

  const onSubmitCall = async (values) => {
    if (!isUpdatable) {
      props.history.push(
        routeConstant.CLOUD_PROFILE_ACTIVATE(props.match.params.type)
      );

      return;
    }

    setActionDone(false);

    if (type === 'edit') {
      const id = props.match.params.type;

      const updatePayload = {
        name: values.name,
        cloud_provider: values.cloud_provider,
        details: {
          account_id: values.account_id,
          region: values.region,
        },
        resource_selection: {
          opensearch_choice:
            values.opensearch_choice === true ? 'Create' : 'Disable',
          queryprofile_choice:
            values.queryprofile_choice === true ? 'Create' : 'Disable',
          ...(values.opensearch_choice === true
            ? { opensearch_domain_name: values.opensearch_domain_name }
            : {}),
          ...(values.queryprofile_choice === true
            ? { s3_bucket_name: values.s3_bucket_name }
            : {}),
        },
      };

      const url = `${ApiConstant.PUT_CLOUD_PROFILES(id)}`;

      const response = await put({
        url: url,
        payload: updatePayload,
        type: 'v2',
      });

      if (response.status === 200) {
        createMessage(200, 'Cloud Profile updated');

        if (values.btnType === 'updateAndActivate') {
          props.history.push(routeConstant.CLOUD_PROFILE_ACTIVATE(id));
        } else {
          props.history.push(routeConstant.CLOUD_PROFILE_DETAILS(id));
        }
      } else {
        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }
      setActionDone(true);
    } else {
      const createProfilePayload = {
        name: values.name,
        cloud_provider: values.cloud_provider,
        details: {
          use_role: true,
          account_id: values.account_id,
          region: values.region,
        },
      };

      const url = `${ApiConstant.POST_CLOUD_PROFILE}`;

      const response = await post({
        url: url,
        payload: createProfilePayload,
        type: 'v2',
      });

      if (response.status === 200) {
        createMessage(200, 'Cloud Profile created');

        props.history.push(
          `${routeConstant.CLOUD_PROFILE_ADD_EDIT(response?.data?.id)}`
        );
      } else {
        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }

      setActionDone(true);
    }
  };

  return (
    <Layout crumbs={crumbs} {...props}>
      <div className="create-user">
        <CommonCard className="card-wrapper" isForm>
          <div className="form-wrapper">
            <h4 className="text_primary text-left">
              {type === 'edit'
                ? 'Prepare Cloud Profile'
                : 'Create Cloud Profile'}
            </h4>
          </div>

          {loading ? (
            <SkeletonLoader />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className="form-wrapper">
                <div className="">
                  <TextField
                    autoComplete="off"
                    inputProps={{ 'data-testid': 'username-Input' }}
                    style={{ width: '100%' }}
                    variant="outlined"
                    id="outlined-basic"
                    name="name"
                    label="Profile Name"
                    value={formik.values.name}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setIsUpdatable(true);
                    }}
                    error={Boolean(formik.errors.name)}
                    helperText={formik.errors.name}
                    required
                  />
                </div>

                <div className="mt-4">
                  <FloatingLabelDropdown
                    handleChange={(e) => {
                      formik.handleChange(e);
                      setIsUpdatable(true);
                    }}
                    value={formik.values.cloud_provider}
                    name="cloud_provider"
                    error={
                      formik.touched.cloud_provider &&
                      formik.errors.cloud_provider
                    }
                    label="Cloud Provider"
                    inputProps={{ 'data-testid': 'type-select' }}
                    required
                  >
                    {cloudProfile &&
                      cloudProfile.map((item, i) => {
                        return (
                          <MenuItem value={item?.value} key={item?.value}>
                            {item?.title}
                          </MenuItem>
                        );
                      })}
                  </FloatingLabelDropdown>
                </div>

                <div className="mt-4">
                  <TextField
                    autoComplete="off"
                    inputProps={{ 'data-testid': 'username-Input' }}
                    style={{ width: '100%' }}
                    variant="outlined"
                    id="outlined-basic"
                    name="account_id"
                    label="Account Id"
                    value={formik.values.account_id}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setIsUpdatable(true);
                    }}
                    error={
                      formik.touched.account_id &&
                      Boolean(formik.errors.account_id)
                    }
                    helperText={
                      formik.touched.account_id && formik.errors.account_id
                    }
                    required
                  />
                </div>

                <div>
                  <FloatingLabelDropdown
                    className="mt-4"
                    label="Region"
                    name="region"
                    inputProps={{ 'data-testid': 'region' }}
                    value={formik.values.region}
                    handleChange={(e) => {
                      formik.handleChange(e);
                      setIsUpdatable(true);
                    }}
                    error={formik.touched.region && formik.errors.region}
                    required
                  >
                    {regions?.map((region) => (
                      <MenuItem value={region?.id} key={region?.id}>
                        {region?.display_name}
                      </MenuItem>
                    ))}
                  </FloatingLabelDropdown>
                </div>

                {type === 'edit' ? (
                  <>
                    <div className="mt-4">
                      <h4 className="text_primary text-left">
                        {type === 'edit' ? 'Resources' : null}
                      </h4>
                    </div>

                    <h5 className="mt-4">Logging</h5>
                    <div className="d-flex gap-5">
                      <div>
                        <input
                          className="form-check-input"
                          id="createNew"
                          name="opensearch_choice"
                          type="radio"
                          checked={formik.values.opensearch_choice}
                          onChange={(e) => {
                            formik.setFieldValue('opensearch_choice', true);
                            setIsUpdatable(true);
                          }}
                        />
                        <label className="ms-2" htmlFor="createNew">
                          Create new
                        </label>
                      </div>
                      <div>
                        <input
                          className="form-check-input"
                          id="disable"
                          name="opensearch_choice"
                          type="radio"
                          checked={
                            formik.values.opensearch_choice === false
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            formik.setFieldValue('opensearch_choice', false);
                            setIsUpdatable(true);
                          }}
                        />
                        <label className="ms-2" htmlFor="disable">
                          Disable
                        </label>
                      </div>
                    </div>
                    {formik.values.opensearch_choice ? (
                      <div className="mt-3">
                        <TextField
                          autoComplete="off"
                          inputProps={{ 'data-testid': 'username-Input' }}
                          style={{ width: '100%' }}
                          variant="outlined"
                          id="outlined-basic"
                          name="opensearch_domain_name"
                          label="Enter Open Search Domain"
                          value={formik.values.opensearch_domain_name}
                          onChange={(e) => {
                            formik.handleChange(e);
                            setIsUpdatable(true);
                          }}
                          error={
                            formik.touched.opensearch_domain_name &&
                            Boolean(formik.errors.opensearch_domain_name)
                          }
                          helperText={
                            formik.touched.opensearch_domain_name &&
                            formik.errors.opensearch_domain_name
                          }
                        />
                      </div>
                    ) : null}

                    <h5 className="mt-4">Storage</h5>

                    <div className="d-flex gap-5">
                      <div>
                        <input
                          id="queryprofile_createNew"
                          className="form-check-input"
                          name="queryprofile_choice"
                          type="radio"
                          checked={formik.values.queryprofile_choice}
                          onChange={(e) => {
                            formik.setFieldValue('queryprofile_choice', true);
                            setIsUpdatable(true);
                          }}
                        />
                        <label
                          className="ms-2"
                          htmlFor="queryprofile_createNew"
                        >
                          Create new
                        </label>
                      </div>
                      <div>
                        <input
                          id="queryprofile_disable"
                          className="form-check-input"
                          name="queryprofile_choice"
                          type="radio"
                          checked={
                            formik.values.queryprofile_choice === false
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            formik.setFieldValue('queryprofile_choice', false);
                            setIsUpdatable(true);
                          }}
                        />
                        <label className="ms-2" htmlFor="queryprofile_disable">
                          Disable
                        </label>
                      </div>
                    </div>
                    {formik.values.queryprofile_choice ? (
                      <div className="mt-3">
                        <TextField
                          autoComplete="off"
                          inputProps={{ 'data-testid': 'username-Input' }}
                          style={{ width: '100%' }}
                          variant="outlined"
                          id="outlined-basic"
                          name="s3_bucket_name"
                          label="S3 bucket"
                          value={formik.values.s3_bucket_name}
                          onChange={(e) => {
                            formik.handleChange(e);
                            setIsUpdatable(true);
                          }}
                          error={
                            formik.touched.s3_bucket_name &&
                            Boolean(formik.errors.s3_bucket_name)
                          }
                          helperText={
                            formik.touched.s3_bucket_name &&
                            formik.errors.s3_bucket_name
                          }
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>

              <div className="mt-5 d-flex justify-content-end gap-2">
                {actionDone ? (
                  <>
                    {type === 'edit' ? (
                      <button
                        className="buttonX"
                        type="submit"
                        onClick={() =>
                          formik.setFieldValue('btnType', 'updateAndActivate')
                        }
                      >
                        Prepare
                      </button>
                    ) : null}
                    {type !== 'edit' && (
                      <button
                        className="buttonX"
                        type="submit"
                        onClick={() =>
                          formik.setFieldValue('btnType', 'submit')
                        }
                      >
                        {type === 'edit' ? 'Save and Return' : 'Create'}
                      </button>
                    )}
                    <button
                      className="buttonX white"
                      type="button"
                      onClick={() =>
                        type === 'edit'
                          ? props.history.push(
                              routeConstant.CLOUD_PROFILE_DETAILS(
                                props?.match?.params?.type
                              )
                            )
                          : props.history.push(routeConstant.CLOUD_PROFILES)
                      }
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <ActivityLoader />
                )}
              </div>
            </form>
          )}
        </CommonCard>
      </div>
    </Layout>
  );
}
