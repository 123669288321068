// NodeDetails.js
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const NodeDetails = ({ node, onClose }) => {
  if (!node) return null;

  const extractText = (label, type = null) => {
    const regex = /<b>(.*?)<\/b><br>/;
    const match = label.match(regex);

    if (type === 'head') {
      return match ? match[1].trim() : '';
    }
    return label.replace(/<b>(.*?)<\/b><br>/, '');
  };

  console.log(node);

  return (
    <div className="node-details">
      <div className="node-details-head" style={{ padding: '0.4rem' }}>
        <h5
          className="fw-bold"
          dangerouslySetInnerHTML={{
            __html: node?.data?.label
              ? `${extractText(node?.data?.label, 'head')} [${node.id}]`
              : '',
          }}
        />

        <IconButton onClick={onClose} sx={{ padding: 0, color: 'inherit' }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div
        className="node-details-body"
        dangerouslySetInnerHTML={{
          __html: node?.data?.label
            ? extractText(node?.data?.label)
            : node?.data?.label,
        }}
      />
    </div>
  );
};

export default NodeDetails;
