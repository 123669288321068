import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import React, { Component } from 'react';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import {
  EnhancedTableHead,
  getComparator,
  stableSort,
} from '../../../components/TableComponent/TableComponent';
import { GET_JOB } from '../../../utils/apiConstant';
import { get } from '../../../utils/apiMethods';
import { getReadableDate } from '../../../utils/common';
import routeConstant from '../../../router/PathConst';

export default class SubJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDone: false,
      jobsList: [],
      loading: true,
      order: 'asc',
      orderBy: '',
      selected: [],
      page: 0,
      rowsPerPage: 20,
    };
  }

  componentDidMount() {
    this.getJobsById();
  }

  getJobsById = () => {
    const { UUID } = this.props;
    if (UUID && UUID.length > 0) {
      for (let i = 0; i < UUID.length; i++) {
        this.getAPICALl(UUID[i]);
      }
    } else {
      this.setState({ loading: false });
    }
  };

  getAPICALl = async (id) => {
    let array = this.state.jobsList;

    const URL = `${GET_JOB}/${id}`;
    const response = await get({ url: URL });
    if (response && response !== undefined) {
      array.push(response);
      this.setState({
        jobsList: array,
        jobRefList: array,
        isDone: true,
        loading: false,
      });
    }

    // return array[0]
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };
  render() {
    const { order, orderBy, rowsPerPage, page, loading, isDone, jobsList } =
      this.state;

    return (
      <div className="Jobs-submain">
        <div className="desktop-table">
          <TableContainer>
            <Table
              className="tableX"
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={jobsList.length}
                headCells={headCells}
              />
              {isDone && jobsList && jobsList.length > 0 ? (
                <TableBody className="table Table">
                  {stableSort(jobsList, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      //   const isItemSelected = this.isSelected(row.id);
                      //   const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          //   role="checkbox"
                          //   aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          //   selected={isItemSelected}
                        >
                          <TableCell align="left" className="id">
                            <span
                              onClick={() =>
                                this.props.history.push(
                                  routeConstant.CLUSTER_JOB_DETAILS(
                                    this.props.match.params.clusterId,
                                    row.id
                                  ),
                                  row
                                )
                              }
                              data-testid={`subjob-id ${index}`}
                              className="id"
                            >
                              {row.id}
                            </span>
                          </TableCell>
                          <TableCell align="left">{row.atype}</TableCell>
                          <TableCell align="left">{row.tenant_id}</TableCell>
                          <TableCell align="left">{row.user_id}</TableCell>
                          <TableCell align="left">{row.description}</TableCell>
                          <TableCell align="left">
                            <p
                              style={{ paddingBottom: '0px' }}
                              className={
                                row.status === 'In Progress'
                                  ? 'pending'
                                  : 'available'
                              }
                            >
                              {row.state}
                            </p>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>

          {loading ? (
            <SkeletonLoader />
          ) : isDone && jobsList && jobsList.length > 0 ? (
            <TablePagination
              className="table-pagination"
              rowsPerPageOptions={[20, 40, 60, 80, 100]}
              component="div"
              count={jobsList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              showLastButton={true}
              showFirstButton={true}
            />
          ) : !loading && jobsList && jobsList.length === 0 ? (
            <p className="text_color text-center no-data-msg mt-2">
              No Data Found
            </p>
          ) : null}
        </div>

        {/* Mobile */}
        <div className="mobile-table">
          {loading ? (
            <SkeletonLoader />
          ) : isDone && jobsList && jobsList.length ? (
            jobsList &&
            jobsList.map((data, i) => {
              return (
                <div key={i} className="mob-main">
                  <div className="mob-rows">
                    <div className="keys">Job ID</div>
                    <div
                      className="values id"
                      onClick={() =>
                        this.props.history.push(
                          routeConstant.CLUSTER_JOB_DETAILS(
                            this.props.match.params.clusterId,
                            data.id
                          ),
                          data
                        )
                      }
                    >
                      {data.id}
                    </div>
                  </div>
                  <div className="mob-rows">
                    <div className="keys">Job Type</div>
                    <div className="values">{data.atype}</div>
                  </div>
                  <div className="mob-rows">
                    <div className="keys">Updated</div>
                    <div className="values">
                      {getReadableDate(data.modified_timestamp)}
                    </div>
                  </div>
                  <div className="mob-rows">
                    <div className="keys">Description</div>
                    <div className="values">
                      {/* {data.description && data.description.length > 15 ? (
                          <span className="p-0">
                            {data.description.slice(0, 10)}{" "}
                            <span
                              onClick={() => this.openedViewMore(data)}
                              className="view-more"
                            >
                              ...View More
                            </span>
                          </span>
                        ) : data.description ? (
                          data.description
                        ) : (
                          "--"
                        )} */}
                      {data.description}
                    </div>
                  </div>

                  <div className="mob-rows">
                    <div className="keys">Status</div>
                    <div
                      className={
                        data.state === 'Completed'
                          ? ' available values'
                          : 'values disabled'
                      }
                    >
                      {data.state}
                    </div>
                  </div>
                </div>
              );
            })
          ) : !loading && jobsList && jobsList.length === 0 ? (
            <p className="text_color text-center no-data-msg mt-2">
              No Data Found
            </p>
          ) : null}
        </div>
      </div>
    );
  }
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Job ID',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Job Type',
  },
  {
    id: 'tenant_id',
    numeric: false,
    disablePadding: false,
    label: 'Tenant Id',
  },
  {
    id: 'user_id',
    numeric: false,
    disablePadding: false,
    label: 'User Id',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
];
