import React from 'react';
import { Link } from 'react-router-dom';

import routeConstant from '../../router/PathConst';

import './not-found.scss';

export default function NotFound() {
  return (
    <div className="not-found">
      <h1>404 - Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>

      <Link to={routeConstant.INDEX} className="buttonX">
        Go to Home
      </Link>
    </div>
  );
}
