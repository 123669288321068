import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from 'reactflow';

export default function CustomEdge({ id, label, markerStart, ...props }) {
  const [edgePath, labelX, labelY] = getSmoothStepPath(props);

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerStart={markerStart}
        interactionWidth={2}
      />
      <EdgeLabelRenderer>
        <div
          dangerouslySetInnerHTML={{ __html: label }}
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            background: 'white',
            padding: 2,
            borderRadius: 5,
            fontSize: 10,
          }}
        />
      </EdgeLabelRenderer>
    </>
  );
}
