import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import routeConstant from '../../router/PathConst';
import ApiConstant from '../../utils/apiConstant';
import { get, post } from '../../utils/apiMethods';
import { toast } from 'react-toastify';
import SkeletonLoader from '../../components/Loader/SkeletonLoader';
import { getFromLocalStorage } from '../../utils/helperFunctions';

export default function LoginPage(props) {
  const [loginType, setLoginType] = useState({});
  const [isIDPLoading, setIsIDPLoading] = useState(false);
  const [isAPILoading, setIsAPILoading] = useState({});

  const initialValues = { username: '', password: '' };

  const validationSchema = Yup.object({
    username: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
  });

  useEffect(() => {
    getUserData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLoginType();
  }, []);

  async function getUserData() {
    try {
      const URL = ApiConstant.GET_USER_DETAILS;

      const response = await get({ url: URL, type: 'v2' });

      if (response?.status === 200) {
        props.history.push(routeConstant.DASHBOARD);
      }
    } catch (error) {
      console.log('The user is not logged in.');
    }
  }

  async function getLoginType() {
    try {
      setIsAPILoading(true);

      const URL = ApiConstant.GET_LOGIN_DETAILS;

      const response = await get({ url: URL });

      if (response) {
        setLoginType((prevData) => ({
          ...prevData,
          ...response,
        }));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsAPILoading(false);
    }
  }

  async function loginViaIDP() {
    try {
      setIsIDPLoading(true);

      const { REACT_APP_BASE_API_URL } = getFromLocalStorage('envConfig') || {};

      const URL = REACT_APP_BASE_API_URL + ApiConstant.GET_SIGN_IN_VIA_IDP;

      window.location = URL;
    } catch (error) {
      console.error('Error while sign in via IDP:', error);
    } finally {
      setIsIDPLoading(false);
    }
  }

  async function loginHandler(values, actions) {
    try {
      actions.setSubmitting(true);

      const URL = ApiConstant.POST_LOGIN;

      const response = await post({ url: URL, payload: values, type: 'v2' });

      if (response?.status === 200) {
        props.history.push(routeConstant.DASHBOARD);
      } else {
        toast.error('Please enter valid credentials!');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      actions.setSubmitting(false);
    }
  }

  return (
    <div className="auth-page">
      <div className="auth-card">
        <div className="auth-header">
          <div className="auth-logo" />

          <h6 className="auth-subtitle">Login to continue to DataPelago</h6>
        </div>

        {isAPILoading ? (
          <SkeletonLoader />
        ) : (
          <>
            {loginType?.is_idp_enabled && (
              <>
                {loginType?.idp_name && (
                  <button
                    className="buttonX primary outline mt-4 w-100"
                    type="button"
                    onClick={loginViaIDP}
                  >
                    {isIDPLoading
                      ? 'Logging in...'
                      : `Login through Single Sign-on (${loginType?.idp_name})`}
                  </button>
                )}
                <div
                  className="mt-4 d-flex justify-content-center align-items-center gap-3"
                  style={{ color: 'var(--clr-text-100)' }}
                >
                  <hr className="w-100" style={{ borderWidth: '2px' }} />
                  <p className="m-0">OR</p>
                  <hr className="w-100" style={{ borderWidth: '2px' }} />
                </div>
              </>
            )}

            <div className="auth-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={loginHandler}
              >
                {({ values, touched, errors, isSubmitting, handleChange }) => (
                  <Form className="d-flex flex-column">
                    <TextField
                      className={`mt-3 auth-input`}
                      label={
                        loginType?.is_idp_enabled
                          ? 'Enter root username'
                          : 'Enter username'
                      }
                      name="username"
                      type="username"
                      id="username"
                      variant="outlined"
                      inputProps={{ 'data-testid': 'username' }}
                      autoComplete="off"
                      InputLabelProps={{
                        required: false,
                      }}
                      value={values.username}
                      onChange={handleChange}
                      error={Boolean(errors.username) && touched.username}
                      helperText={errors.username}
                    />
                    <TextField
                      className={`mt-3 auth-input`}
                      label={
                        loginType?.is_idp_enabled
                          ? 'Enter root password'
                          : 'Enter password'
                      }
                      name="password"
                      type="password"
                      id="password"
                      variant="outlined"
                      inputProps={{ 'data-testid': 'password' }}
                      autoComplete="off"
                      InputLabelProps={{
                        required: false,
                      }}
                      value={values.password}
                      onChange={handleChange}
                      error={Boolean(errors.password) && touched.password}
                      helperText={errors.password}
                    />

                    <div className="auth-buttons mt-4 w-100">
                      <button className="buttonX" type="submit">
                        {isSubmitting
                          ? 'Logging in...'
                          : loginType?.is_idp_enabled
                            ? 'Login as Root'
                            : 'Login'}
                      </button>

                      <button className="buttonX outline" type="reset">
                        Cancel
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}

        <div className="auth-footer">
          <h6 className="mt-5">
            <Link
              to={routeConstant.FORGOT_PASSWORD}
              className="fw-bold"
              style={{ color: 'var(--clr-primary-500)' }}
            >
              Forgot Password?
            </Link>
          </h6>
        </div>
      </div>
    </div>
  );
}
