import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import {
  getComparator,
  stableSort,
} from '../../../components/TableComponent/TableComponent';
import ViewMore from '../../../modals/Common/ViewMore';
import routeConstant from '../../../router/PathConst';

export default class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'desc',
      orderBy: '',
      rowsPerPage: 10,
      page: 0,
      data: [],
      viewMore: false,
      viewMoreData: '',
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  openViewMore = (data) => {
    this.setState({ viewMoreData: data, viewMore: true });
  };

  closedViewMore = () => {
    this.setState({ viewMoreData: '', viewMore: false });
  };

  render() {
    const { order, orderBy, rowsPerPage, page, viewMoreData, viewMore } =
      this.state;

    const { clusterData, clusterNotifications } = this.props;

    const notificationList = [
      ...(clusterData?.cluster_error
        ? [
            {
              id: 'CLUSTER_ERROR',
              name: clusterData?.cluster_error,
            },
          ]
        : []),

      ...clusterNotifications.map((notification) => ({
        ...notification,
        id: notification?.description,
        name: notification?.state,
        jobId: notification?.id,
      })),
    ];

    return (
      <>
        {notificationList && notificationList.length > 0 ? (
          <>
            {viewMore && (
              <ViewMore
                show={viewMore}
                handleClose={this.closedViewMore}
                data={viewMoreData}
                fetchKey="name"
                headKey="id"
              />
            )}

            <TableContainer id="tableDiv" className="mt-4">
              <Table
                className="tableX"
                sx={{
                  minWidth: 750,
                  borderCollapse: 'separate',
                }}
                aria-labelledby="tableTitle"
              >
                <TableBody className="table Table tableX">
                  {stableSort(notificationList, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell
                            align="left"
                            onClick={() =>
                              row.jobId
                                ? this.props.history.push({
                                    pathname: `${routeConstant.CLUSTER_JOB_DETAILS(
                                      clusterData.id,
                                      row.jobId
                                    )}`,
                                    state: {
                                      job: clusterNotifications?.find(
                                        (job) => job.id === row.jobId
                                      ),
                                    },
                                  })
                                : null
                            }
                          >
                            <span
                              className={`${row.jobId ? 'id' : ''}`}
                              data-testid={`cluster-prop-id ${index}`}
                            >
                              {row.id}
                            </span>
                          </TableCell>

                          <TableCell
                            style={{ overflowWrap: 'anywhere' }}
                            align="left"
                          >
                            {row.name && row.name.length > 50 ? (
                              <span className="p-0">
                                {row.name.slice(0, 50)}{' '}
                                <span
                                  onClick={() => this.openViewMore(row)}
                                  className="view-more"
                                  data-testid={`viewMore ${index}`}
                                >
                                  ...View More
                                </span>
                              </span>
                            ) : row.name ? (
                              row.name
                            ) : (
                              '--'
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="table-pagination"
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              component="div"
              count={notificationList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              showLastButton={true}
              showFirstButton={true}
            />
          </>
        ) : (
          <p className="text_color text-center no-data-msg">No Data Found</p>
        )}
      </>
    );
  }
}
