import React, { useContext } from 'react';
import ConfigContext from '../../../context/config/ConfigContext';

import './Footer.scss';

export default function Footer() {
  const { currentConfig } = useContext(ConfigContext);

  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-main">
      <p>
        <span className="ms-2">
          versionsData Version:{' '}
          {currentConfig?.versionsData
            ? currentConfig?.versionsData['serviceplane-version']?.serviceplane
            : '-'}
          ,
        </span>
        <span className="ms-2">
          DataPlane Version:{' '}
          {currentConfig?.versionsData
            ? currentConfig?.versionsData['dataplane-version']?.wright_version
            : '_'}
          ,
        </span>
        <span className="ms-2">© 2022-{currentYear},</span>
        <a
          className="link-a ms-1"
          target="_blank"
          href="https://datapelago.io/"
          rel="noreferrer"
        >
          DataPelago, Inc.
        </a>
      </p>
    </div>
  );
}
