import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import { EnhancedTableHead } from '../../../components/TableComponent/TableWithSelection';
import {
  getComparator,
  stableSort,
} from '../../../components/TableComponent/TableComponent';
import Layout from '../../../components/layout/SideBar/Layout';
import CommonCard from '../../../components/CommonCard/CommonCard';
import routeConstant from '../../../router/PathConst';
import { get, put } from '../../../utils/apiMethods';
import ApiConstant from '../../../utils/apiConstant';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import {
  deleteMessage,
  getReadableDate,
  deleteCloudProfileById,
} from '../../../utils/common';
import DeleteModal from '../../../modals/Common/DeleteModal';
import MultiSelect from '../../../components/MultiSelect/MultiSelect';
import CloudProfileMenu from './CloudProfileMenu';
import UserContext from '../../../context/user/UserContext';

const crumbs = [
  {
    title: 'Cloud Profiles',
    path: routeConstant.CLOUD_PROFILES,
    active: true,
  },
];

const options = [
  { value: 'Active', label: 'Active' },
  { value: 'Creating Resources', label: 'Creating Resources' },
  { value: 'Pending Completion', label: 'Pending Completion' },
  { value: 'Deleting Resources', label: 'Deleting Resources' },
  { value: 'Resource Creation Failed', label: 'Resource Creation Failed' },
  { value: 'Delete Failed', label: 'Delete Failed' },
  { value: 'Failed', label: 'Failed' },
];

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'account_id',
    numeric: false,
    disablePadding: false,
    label: 'AWS Account ID',
  },
  {
    id: 'region',
    numeric: false,
    disablePadding: false,
    label: 'Region',
  },
  {
    id: 'created_timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Created',
  },
  {
    id: 'modified_timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Updated',
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

export default class CloudProfiles extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      page: 0,
      rowsPerPage: 10,
      UUID: [],
      cloudProfilesList: [],
      loading: true,
      isDone: true,
      cloudProfilesRefList: [],
      selectedCloudProfiles: [],
      isDelete: false,
      searchValue: '',
      filterValue: [],
    };
  }

  componentDidMount() {
    if (this.context) {
      const { currentUser } = this.context;
      const { props } = currentUser;

      if (props && 'sorting' in props) {
        const sort = JSON.parse(props.sorting);
        if ('cloudProfile' in sort && sort.cloudProfile.length > 0) {
          this.setState({
            orderBy: sort.cloudProfile[0],
            order: sort.cloudProfile[1],
          });
        } else {
          this.setState({ orderBy: 'name' });
        }
      } else {
        this.setState({ orderBy: 'name' });
      }
    }

    this.getCloudProfiles();
  }

  // GET API CALL
  getCloudProfiles = async () => {
    const response = await get({ url: ApiConstant.GET_CLOUD_PROFILES });

    const updatedCloudProfiles = await response?.map((cloudProfiles) => {
      return {
        ...cloudProfiles,
        account_id: cloudProfiles?.details?.account_id,
        region: cloudProfiles?.details?.region,
        created_timestamp: cloudProfiles?.metadata?.created_timestamp,
        modified_timestamp: cloudProfiles?.metadata?.modified_timestamp,
      };
    });

    this.setState({
      cloudProfilesList: updatedCloudProfiles,
      cloudProfilesRefList: updatedCloudProfiles,
      isDone: true,
      loading: false,
    });
  };

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
    this.onSaveSort(property, isAsc ? 'desc' : 'asc');
  };

  // save sort
  onSaveSort = (value, order) => {
    const { currentUser } = this.context;
    const { props } = currentUser;

    let array = { ...props };
    if (props && props.sorting) {
      let { sorting } = props;
      const obj = JSON.parse(sorting);
      if (sorting !== undefined) {
        obj.cloudProfile = [value, order];
      } else {
        obj.cloudProfile = [value, order];
      }
      array.sorting = JSON.stringify(obj);

      this.updateUserHandler(array);
    } else {
      const obj = {
        cloudProfile: value,
      };

      currentUser.props = { sorting: JSON.stringify(obj) };
      this.updateUserHandler(currentUser.props);
    }
  };

  updateUserHandler = async (properties) => {
    const { currentUser, storeCurrentUser } = this.context;
    const { id, username, email } = currentUser;

    const payload = {
      id,
      username,
      email,
      props: properties,
    };

    const URL = ApiConstant.PUT_USER(id);

    const response = await put({
      url: URL,
      payload: payload,
    });

    if (response && response.id) {
      if ('sorting' in response.props) {
        storeCurrentUser(response);
      }
    }
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = this.state.cloudProfilesList.map((n) => n.id);
      this.setState({
        selected: newSelected,
        selectedCloudProfiles: newSelected,
      });
      return;
    }
    this.setState({ selected: [], selectedCloudProfiles: [] });
  };

  handleClick = (event, name) => {
    const { selected } = this.state;
    let newSelected = [];
    const selectedIndex = selected.indexOf(name);
    this.userToDelete(name);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected });
  };

  userToDelete = (id) => {
    const { selectedCloudProfiles } = this.state;
    let selectedUser = [...selectedCloudProfiles];
    const isPresent = selectedCloudProfiles.includes(id);
    const index = selectedCloudProfiles.indexOf(id);

    if (!isPresent) {
      selectedUser.push(id);
      this.setState({ selectedCloudProfiles: selectedUser });
    } else {
      selectedUser.splice(index, 1);
      this.setState({ selectedCloudProfiles: selectedUser });
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  // delete
  onDeleteCloudProfile = async () => {
    const { selectedCloudProfiles } = this.state;
    let success = [];
    let failure = [];
    for (let z = 0; z < selectedCloudProfiles.length; z++) {
      const response = await deleteCloudProfileById(selectedCloudProfiles[z]);

      if (response === 200) {
        success.push(response);
      } else {
        failure.push(response);
      }
    }
    if (success.length > 0) {
      deleteMessage(200, `${success.length} cloud profiles deleted`);
    }
    if (failure.length > 0) {
      deleteMessage(
        200,
        `${failure.length} cloud profiles failed to delete`,
        true
      );
    }

    this.deleteDone();
  };

  deleteDone = () => {
    this.setState(
      {
        cloudProfilesList: [],
        selectedCloudProfiles: [],
        selected: [],
        page: 0,
        isDelete: false,
        loading: true,
      },
      () => {
        this.getCloudProfiles();
      }
    );
  };

  //   handleDeleteModal = () => {
  //     this.setState({ isDelete: !this.state.isDelete });
  //   };

  onSearchAndFilter = (searchValue, filterValue) => {
    const { cloudProfilesRefList } = this.state;

    let filteredList = cloudProfilesRefList;

    // Apply search filter if searchValue is provided
    if (searchValue && searchValue !== '') {
      filteredList = filteredList.filter(
        (data) =>
          data.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          data.id.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    // Apply status filter if filterValue is provided
    if (filterValue && filterValue.length > 0) {
      let resArray = [];
      for (let i = 0; i < filterValue.length; i++) {
        const result = filteredList.filter(
          (data) => data.state === filterValue[i].value
        );
        resArray = [...resArray, ...result];
      }
      filteredList = resArray;
    }

    // Update the state with the filtered list
    this.setState({ cloudProfilesList: filteredList, page: 0 });
  };

  // handle search input change
  handleSearchChange = (event) => {
    const searchValue = event.target.value;
    this.setState({ searchValue }, () => {
      this.onSearchAndFilter(this.state.searchValue, this.state.filterValue);
    });
  };

  // handle filter value change
  handleFilterChange = (filterValue) => {
    this.setState({ filterValue }, () => {
      this.onSearchAndFilter(this.state.searchValue, this.state.filterValue);
    });
  };

  render() {
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      cloudProfilesList,
      loading,
      isDone,
      selectedCloudProfiles,
      isDelete,
    } = this.state;

    return (
      <Layout crumbs={crumbs} {...this.props}>
        <div className="d-flex justify-content-end gap-3 mb-4">
          <button
            className="buttonX"
            onClick={() =>
              this.props.history.push(
                `${routeConstant.CLOUD_PROFILE_ADD_EDIT('create')}`
              )
            }
          >
            <i className="bi bi-plus-lg" />
            Create Profiles
          </button>
          {/* <button
            className="buttonX white"
            onClick={this.handleDeleteModal}
            disabled={selectedCloudProfiles.length === 0}
          >
            <i className="bi bi-trash3" />
            Delete Users
          </button> */}
        </div>

        <CommonCard className="">
          <div className="row">
            <div className="col-md-8">
              <div className="text-left mb-3">
                <input
                  type="search"
                  placeholder="Search By Name"
                  className="input-boxes"
                  value={this.state.searchValue}
                  onChange={this.handleSearchChange}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-right ">
                <MultiSelect
                  closeMenuOnSelect={false}
                  isMulti
                  handleChange={this.handleFilterChange}
                  options={options}
                  placeholder="Filter By Status"
                />
              </div>
            </div>
          </div>
          <div className="desktop-table">
            <TableContainer>
              <Table
                className="tableX"
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={cloudProfilesList?.length}
                  headCells={headCells}
                  hideCheckBox={true}
                />
                {isDone && cloudProfilesList && cloudProfilesList.length > 0 ? (
                  <TableBody className="table Table">
                    {stableSort(
                      cloudProfilesList,
                      getComparator(order, orderBy)
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((cloudProfilesData, index) => {
                        const isItemSelected = this.isSelected(
                          cloudProfilesData.id
                        );

                        return (
                          <TableRow
                            hover
                            key={index}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          >
                            {/* <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) =>
                                  this.handleClick(
                                    event,
                                    cloudProfilesData.id,
                                    cloudProfilesData
                                  )
                                }
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell> */}

                            <TableCell
                              onClick={() =>
                                this.props.history.push(
                                  `${routeConstant.CLOUD_PROFILE_DETAILS(
                                    cloudProfilesData.id
                                  )}`,
                                  cloudProfilesData
                                )
                              }
                              className="id"
                              align="left"
                            >
                              <span
                                className="id"
                                style={{ maxWidth: '260px', display: 'block' }}
                              >
                                {cloudProfilesData.name || '--'}
                              </span>
                            </TableCell>

                            <TableCell align="left">
                              {cloudProfilesData.details.account_id}
                            </TableCell>

                            <TableCell align="left">
                              {cloudProfilesData.details.region}
                            </TableCell>

                            <TableCell align="left">
                              {getReadableDate(
                                cloudProfilesData.metadata.created_timestamp
                              )}
                            </TableCell>

                            <TableCell align="left">
                              {getReadableDate(
                                cloudProfilesData.metadata.modified_timestamp
                              )}
                            </TableCell>

                            <TableCell align="left">
                              <span
                                className={`text-capitalize ${
                                  cloudProfilesData.state ===
                                    'Pending Completion' ||
                                  cloudProfilesData.state ===
                                    'pending completion'
                                    ? 'pending'
                                    : cloudProfilesData.state === 'Active' ||
                                        cloudProfilesData.state === 'active'
                                      ? 'available'
                                      : cloudProfilesData.state === 'Deleted' ||
                                          cloudProfilesData.state === 'deleted'
                                        ? 'deleted'
                                        : 'disable'
                                }`}
                              >
                                {cloudProfilesData.state
                                  ? cloudProfilesData.state
                                  : 'Inactive'}
                              </span>
                            </TableCell>

                            <TableCell align="left">
                              <CloudProfileMenu
                                onComplete={this.deleteDone}
                                id={cloudProfilesData.id}
                                title={cloudProfilesData.name}
                                history={this.props.history}
                                status={cloudProfilesData.state}
                                data={cloudProfilesData}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
            {loading ? (
              <SkeletonLoader />
            ) : isDone && cloudProfilesList && cloudProfilesList.length > 0 ? (
              <TablePagination
                className="table-pagination"
                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                component="div"
                count={cloudProfilesList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                showLastButton={true}
                showFirstButton={true}
              />
            ) : !loading &&
              cloudProfilesList &&
              cloudProfilesList.length === 0 ? (
              <p className="text_color text-center no-data-msg mt-2">
                No Data Found
              </p>
            ) : null}
          </div>
          <div className="mobile-table">
            {loading ? (
              <SkeletonLoader />
            ) : isDone && cloudProfilesList ? (
              cloudProfilesList.map((cloudProfilesData, index) => {
                return (
                  <div key={index} className="mob-main">
                    <div className="mob-rows">
                      <div className="keys">{headCells[0].label}</div>
                      <div
                        className="values id"
                        onClick={() =>
                          this.props.history.push(
                            `${routeConstant.CLOUD_PROFILE_DETAILS(
                              cloudProfilesData.id
                            )}`,
                            cloudProfilesData
                          )
                        }
                      >
                        {cloudProfilesData.name}
                      </div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">{headCells[1].label}</div>
                      <div className="values">
                        {cloudProfilesData?.details.account_id}
                      </div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">{headCells[2].label}</div>
                      <div className="values">
                        {cloudProfilesData?.details.region}
                      </div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">{headCells[3].label}</div>
                      <div className="values">
                        {getReadableDate(
                          cloudProfilesData?.metadata.created_timestamp
                        )}
                      </div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">{headCells[4].label}</div>
                      <div className="values">
                        {getReadableDate(
                          cloudProfilesData?.metadata.modified_timestamp
                        )}
                      </div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">{headCells[5].label}</div>
                      <div className="values">
                        <span
                          className={`text-capitalize ${
                            cloudProfilesData.state === 'Pending Completion' ||
                            cloudProfilesData.state === 'pending completion'
                              ? 'pending'
                              : cloudProfilesData.state === 'Active' ||
                                  cloudProfilesData.state === 'active'
                                ? 'available'
                                : cloudProfilesData.state === 'Deleted' ||
                                    cloudProfilesData.state === 'deleted'
                                  ? 'deleted'
                                  : 'disable'
                          }`}
                        >
                          {cloudProfilesData.state
                            ? cloudProfilesData.state
                            : 'Inactive'}
                        </span>
                      </div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">{headCells[6].label}</div>
                      <div className="text_color_main">
                        <CloudProfileMenu
                          onComplete={this.deleteDone}
                          title={cloudProfilesData.name}
                          id={cloudProfilesData.id}
                          history={this.props.history}
                          status={cloudProfilesData.state}
                          data={cloudProfilesData}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : !loading &&
              cloudProfilesList &&
              cloudProfilesList.length === 0 ? (
              <p className="text_color text-center no-data-msg mt-2">
                No Data Found
              </p>
            ) : null}
          </div>
        </CommonCard>

        {isDelete && (
          <DeleteModal
            deleteCount={selectedCloudProfiles.length}
            title="Cloud Profiles"
            show={isDelete}
            handleClose={this.handleDeleteModal}
            onDelete={this.onDeleteCloudProfile}
          />
        )}
      </Layout>
    );
  }
}
