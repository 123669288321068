import React from 'react';

export default function LoadingScreen() {
  return (
    <div
      className="p-4 d-flex flex-column justify-content-center align-items-center gap-3"
      style={{ height: '100vh', backgroundColor: 'var(--clr-card-100)' }}
    >
      <div className="d-flex justify-content-center align-items-center gap-3">
        {Array.from({ length: 3 }, (_, index) => (
          <div
            className="spinner-grow"
            role="status"
            style={{ color: 'var(--clr-primary-500)' }}
            key={'loading' + index}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        ))}
      </div>

      <h5>Loading...</h5>
    </div>
  );
}
