import React, { useContext } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import './Pie.scss';
import ThemeContext from '../../themes/ThemeContext';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PieNew({ clusterData }) {
  const { theme } = useContext(ThemeContext);

  let active = [];
  let pending = [];
  let inActive = [];
  let other = [];

  // debugger;
  if (clusterData && clusterData.length) {
    for (let i = 0; i < clusterData.length; i++) {
      if (clusterData[i].status.toLowerCase() === 'available') {
        active.push(clusterData[i]);
      } else if (clusterData[i].status.toLowerCase() === 'suspended') {
        inActive.push(clusterData[i]);
      } else if (
        clusterData[i].status.toLowerCase() === 'pending' ||
        clusterData[i].status.toLowerCase() === 'suspending cluster' ||
        clusterData[i].status.toLowerCase() === 'resuming cluster' ||
        clusterData[i].status.toLowerCase() === 'updating cluster'
      ) {
        pending.push(clusterData[i]);
      } else {
        other.push(clusterData[i]);
      }
    }
  }

  const data = {
    labels: [
      `Available (${active.length})`,
      `Pending (${pending.length}) `,
      `Suspended (${inActive.length})`,
      `Other (${other.length})`,
    ],

    datasets: [
      {
        data: [active.length, pending.length, inActive.length, other.length],
        backgroundColor: ['#0052CC', '#3385FF', '#9D9D9D', '#6b6a6a'],
        borderColor: ['#0052CC', '#3385FF', '#9D9D9D', '#6b6a6a'],
        borderWidth: 2,
        hoverOffset: 5,
        radius: '80%',
      },
    ],
  };

  const options = {
    cutout: '60%',
    aspectRatio: 0.5,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        width: 10,
        labels: {
          color: theme === 'dark' ? 'white' : 'black',
        },
      },
    },
  };

  let total = pending.length + active.length + inActive.length + other.length;

  return (
    // <div className="graph-wrapper">
    <div>
      {clusterData && clusterData.length ? (
        <div className="relative">
          <Doughnut data={data} options={options} height="250px" />
          <div className="pieContainer">
            <p data-testid="cluster-total" className="text_color_main total">
              Total
            </p>
            <p className="text_color_main tvalue">{total}</p>
          </div>
          <div id="legend" />
        </div>
      ) : (
        <p className="text_color_main">No Clusters Found</p>
      )}
    </div>
  );
}
