import React from 'react';
import ReactDOM from 'react-dom';
import UserProvider from './context/user/UserProvider';
import ConfigProvider from './context/config/ConfigProvider';
import ThemeProvider from './themes/ThemeProvider';
import reportWebVitals from './reportWebVitals';

import App from './App';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './themes/themes.css';
import './assets/fonts/fonts.css';
import './index.css';
import './assets/css/main.css';
import './assets/css/table.scss';
import './assets/css/style.css';
import './assets/css/details.css';
import './assets/css/details.scss';
import './app.css';

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <ConfigProvider>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </ConfigProvider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
