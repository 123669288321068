import { MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CommonCard from '../../../components/CommonCard/CommonCard';
import Layout from '../../../components/layout/SideBar/Layout';
import { useFormik } from 'formik';
import 'yup-phone';
import './CloudProfile.scss';
import routeConstant from '../../../router/PathConst';
import { get, put } from '../../../utils/apiMethods';
import ApiConstant from '../../../utils/apiConstant';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import ActivityLoader from '../../../components/Loader/ActivityLoader';
import FloatingLabelDropdown from '../../../components/FloatingLabelDropdown/FloatingLabelDropdown';
import { toast } from 'react-toastify';

export default function CloudProfileActivate(props) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [actionDone, setActionDone] = useState(true);
  const [downloadCloudFormationLink, setDownloadCloudFormationLink] =
    useState();
  const [isGenerateCftBtmDisable, setIsGenerateCftBtmDisable] = useState(false);

  const crumbs = [
    {
      title: 'Cloud Profile',
      path: routeConstant.CLOUD_PROFILES,
      active: false,
    },
    {
      title: 'Activation',
      path: routeConstant.CLOUD_PROFILE_ACTIVATE('1'),
      active: true,
    },
  ];

  const [cloudProfile] = useState([
    {
      title: 'AWS',
      value: 'aws',
    },
  ]);
  const [regions, setRegions] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: data.name,
      cloud_provider: data.cloud_provider,
      account_id: data?.details?.account_id,
      region: data?.details?.region,
      opensearch_choice:
        data?.resource_selection?.opensearch_choice === 'Create' ? true : false,
      queryprofile_choice:
        data?.resource_selection?.queryprofile_choice === 'Create'
          ? true
          : false,
      opensearch_domain_name: data?.resource_selection?.opensearch_domain_name,
      s3_bucket_name: data?.resource_selection?.s3_bucket_name,
      hostName: '',
      // logging: data?.logging_configuration?.cloud_config_id,
      // isNoLogging: false,
      // os_domain_host: data.os_domain_host,
      // storageBucket: data?.query_profile_storage_configuration?.cloud_config_id,
      // isStorageBucket: false,
      // s3_bucket_name: data.s3_bucket_name,
    },
    onSubmit: (values) => {
      onSubmitCall(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    const getRegions = async () => {
      const URL = `${ApiConstant.GET_REGIONS}`;

      const response = await get({ url: URL });

      if (response && response !== undefined) {
        setRegions(response?.Providers?.AWS?.regions);
      }
    };

    getRegions();
  }, []);

  useEffect(() => {
    const downloadCloudFormationLinkHandler = async (cloud_profile_id) => {
      const URL = `${ApiConstant.GENERATE_TENANT_SETUP_CFT(cloud_profile_id)}`;

      const response = await put({
        url: URL,
        type: 'v2',
      });

      if (response.status === 200) {
        setDownloadCloudFormationLink(response.data);
      } else {
        setIsGenerateCftBtmDisable(true);

        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }

      setLoading(false);
    };

    const getCloudProfileData = async () => {
      const URL = `${ApiConstant.GET_CLOUD_PROFILE_DETAILS(
        props.match.params.id
      )}`;

      const response = await get({ url: URL });

      if (response && response !== undefined) {
        setData(response);

        downloadCloudFormationLinkHandler(response.id);
      }
    };

    if (props.match.params.id) {
      setLoading(true);

      getCloudProfileData();
    }
  }, [props.match.params.id]);

  const onSubmitCall = async (values) => {
    setActionDone(false);

    const id = props.match.params.id;

    // const loggingPayload = {
    //   name: values.name,
    //   details: {
    //     os_domain_host: values.opensearch_domain_name,
    //   },
    // };

    // const storageBucketPayload = {
    //   name: values.name,
    //   details: {
    //     s3_bucket_name: values.s3_bucket_name,
    //   },
    // };

    const activationPayload = {
      ...(data?.resource_selection?.opensearch_choice === 'Create'
        ? { os_domain_host: values.hostName }
        : {}),
    };

    // const urlLogging = `${ApiConstant.POST_LOGGING}/${id}/logging`;

    // if (data?.resource_selection?.opensearch_choice === "Create") {
    //   const responseLogging = await post({
    //     url: urlLogging,
    //     payload: loggingPayload,
    //     type: "v2",
    //   });
    // }

    // const urlStorageBucket = `${ApiConstant.POST_STORAGE_BUCKET}/${id}/queryprofile-storage-config`;

    // if (data?.resource_selection?.queryprofile_choice === "Create") {
    //   const responseStorage = await post({
    //     url: urlStorageBucket,
    //     payload: storageBucketPayload,
    //     type: "v2",
    //   });
    // }

    const urlProfileActivate = `${ApiConstant.ACTIVATE_CLOUD_PROFILE(id)}`;

    const response = await put({
      url: urlProfileActivate,
      payload: activationPayload,
      type: 'v2',
    });

    if (response.status === 200) {
      toast.success('Cloud Profile activation started.');

      props.history.push(`${routeConstant.CLOUD_PROFILE_DETAILS(id)}`);
    } else {
      toast.warning(
        response?.response?.data?.message || 'Something went wrong.'
      );
    }

    setActionDone(true);
  };

  return (
    <Layout crumbs={crumbs} {...props}>
      <div className="create-user">
        <CommonCard className="card-wrapper" isForm>
          {loading ? (
            <SkeletonLoader />
          ) : (
            <>
              <div className="form-wrapper">
                <h4 className="text_primary text-left">
                  Activate Cloud Profile
                </h4>

                <p className="mt-3">
                  To activate a profile, (1) you must create permissions and
                  resources in your cloud account that Datapelago clusters will
                  use to provide the data processing functionality, and (2)
                  confirm it below
                </p>

                <p className="mt-3">
                  Datapelago provides an AWS CloudFormation template to create
                  permissions and resources in your AWS account. You can
                  directly execute the AWS CloudFormation template from here if
                  you have the correct permissions in your AWS account or you
                  can download the template now and execute it later.
                </p>
              </div>

              <form onSubmit={formik.handleSubmit}>
                <div className="form-wrapper">
                  <div className="">
                    <h4 className="text_primary text-left">Profile details</h4>
                  </div>

                  <div className="mt-2">
                    <TextField
                      autoComplete="off"
                      inputProps={{ 'data-testid': 'username-Input' }}
                      style={{ width: '100%' }}
                      variant="outlined"
                      id="outlined-basic"
                      name="name"
                      label="Profile Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      disabled
                      required
                    />
                  </div>

                  <div className="mt-4">
                    <FloatingLabelDropdown
                      handleChange={formik.handleChange}
                      value={formik.values.cloud_provider}
                      name="cloud_provider"
                      error={
                        formik.touched.cloud_provider &&
                        formik.errors.cloud_provider
                      }
                      label="Cloud Provider"
                      inputProps={{ 'data-testid': 'type-select' }}
                      disabled
                      required
                    >
                      {cloudProfile &&
                        cloudProfile.map((item, i) => {
                          return (
                            <MenuItem value={item?.value} key={item?.value}>
                              {item?.title}
                            </MenuItem>
                          );
                        })}
                    </FloatingLabelDropdown>
                  </div>

                  <div className="mt-4">
                    <TextField
                      autoComplete="off"
                      inputProps={{ 'data-testid': 'username-Input' }}
                      style={{ width: '100%' }}
                      variant="outlined"
                      id="outlined-basic"
                      name="account_id"
                      label="Account Id"
                      value={formik.values.account_id}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.account_id &&
                        Boolean(formik.errors.account_id)
                      }
                      helperText={
                        formik.touched.account_id && formik.errors.account_id
                      }
                      disabled
                    />
                  </div>

                  <div>
                    <FloatingLabelDropdown
                      className="mt-4"
                      label="Region"
                      name="region"
                      inputProps={{ 'data-testid': 'region' }}
                      value={formik.values.region}
                      handleChange={formik.handleChange}
                      error={formik.touched.region && formik.errors.region}
                      disabled
                      required
                    >
                      {regions?.map((region) => (
                        <MenuItem value={region?.id} key={region?.id}>
                          {region?.display_name}
                        </MenuItem>
                      ))}
                    </FloatingLabelDropdown>
                  </div>

                  <p className="mt-3">
                    If you don't have the permission to execute AWS
                    CloudFormation template in your AWS account. download the
                    template and ask your system Administrator to execute it.
                  </p>

                  <div
                    className={`mt-2 d-flex justify-content-start align-items-center gap-3`}
                  >
                    {/* <button
                      className="buttonX mt-3"
                      type="button"
                      onClick={() =>
                        props.history.push({
                          pathname: `${routeConstant.CLOUD_PROFILE_ADD_EDIT(
                            props.match.params.id
                          )}`,
                          state: {
                            id: props.match.params.id,
                          },
                        })
                      }
                    >
                      Edit Profile
                    </button> */}
                    <button
                      className="buttonX mt-3"
                      type="button"
                      onClick={
                        isGenerateCftBtmDisable
                          ? () => {}
                          : () => {
                              window.open(
                                downloadCloudFormationLink.s3_presigned_url,
                                '_blank',
                                'noreferrer'
                              );
                            }
                      }
                      disabled={isGenerateCftBtmDisable}
                    >
                      Download CloudFormation Template
                    </button>
                    <button
                      className="buttonX mt-3"
                      type="button"
                      onClick={
                        isGenerateCftBtmDisable
                          ? () => {}
                          : () => {
                              window.open(
                                downloadCloudFormationLink.cft_url,
                                '_blank',
                                'noreferrer'
                              );
                            }
                      }
                      disabled={isGenerateCftBtmDisable}
                    >
                      Execute CloudFormation Template
                    </button>
                  </div>

                  <>
                    <div className="mt-4">
                      <h4 className="text_primary text-left">
                        Confirm cloud resources
                      </h4>
                    </div>

                    <p className="mt-2">
                      {formik.values.opensearch_choice
                        ? 'After you have executed the AWS CloudFormation template enter the OpenSearch Host Name information below and click Activate.'
                        : 'After you have executed the AWS CloudFormation template click Activate button below.'}
                    </p>

                    <div className="mt-4 mb-4">
                      <h4 className="text_primary text-left">Logging</h4>
                    </div>
                    {formik.values.opensearch_choice ? (
                      <>
                        <div
                          className={`d-flex justify-content-between align-items-center mt-2`}
                        >
                          <div className="inputs1">
                            <input
                              className="form-control id-input"
                              placeholder="OpenSearch Domain Name"
                              value="OpenSearch Domain Name"
                              disabled
                            />
                          </div>

                          <div className="inputs2">
                            <input
                              className="form-control id-input"
                              name="opensearch_domain_name"
                              placeholder=""
                              value={formik.values.opensearch_domain_name}
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          className={`d-flex justify-content-between align-items-center mt-2`}
                        >
                          <div className="inputs1">
                            <input
                              className="form-control id-input"
                              value="OpenSearch Host Name"
                              placeholder="OpenSearch Host Name"
                              disabled
                            />
                          </div>

                          <div className="inputs2">
                            <input
                              className="form-control id-input"
                              name="hostName"
                              placeholder=""
                              value={formik.values.hostName}
                              onChange={formik.handleChange}
                              required
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <p>Logging is disabled.</p>
                    )}

                    <div className="mt-4 mb-4">
                      <h4 className="text_primary text-left">Storage</h4>
                    </div>
                    {formik.values.queryprofile_choice ? (
                      <div
                        className={`d-flex justify-content-between align-items-center mt-2`}
                      >
                        <div className="inputs1">
                          <input
                            className="form-control id-input"
                            value="Bucket Name"
                            placeholder="Bucket Name"
                            disabled
                          />
                        </div>

                        <div className="inputs2">
                          <input
                            className="form-control id-input"
                            name="s3_bucket_name"
                            placeholder=""
                            value={formik.values.s3_bucket_name}
                            disabled
                          />
                        </div>
                      </div>
                    ) : (
                      <p>Storage is disabled.</p>
                    )}
                  </>
                </div>

                <div className="mt-5 d-flex justify-content-end gap-2">
                  {actionDone ? (
                    <>
                      <button
                        className="buttonX"
                        type="submit"
                        disabled={isGenerateCftBtmDisable}
                      >
                        Activate
                      </button>
                      <button
                        className="buttonX white"
                        type="button"
                        onClick={() => props.history.goBack()}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <ActivityLoader />
                  )}
                </div>
              </form>
            </>
          )}
        </CommonCard>
      </div>
    </Layout>
  );
}
