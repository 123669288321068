import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './Roles.scss';
import ModalLoader from '../Common/ModalLoader';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import {
  EnhancedTableHead,
  getComparator,
  stableSort,
} from '../../components/TableComponent/TableComponent';
import SkeletonLoader from '../../components/Loader/SkeletonLoader';
import { get, post } from '../../utils/apiMethods';
import { toast } from 'react-toastify';
import ApiConstant from '../../utils/apiConstant';

import config from '../../templates/PermissionTemplate.json';

export default function AddNewPermissionModal(props) {
  const [isAllNewPermission, setIsAllNewPermission] = useState(false);
  const [isDeleteNewPermission, setIsDeleteNewPermission] = useState(true);

  const headCells = [
    {
      id: 'checkbox',
      numeric: false,
      disablePadding: false,
      label: 'Checkbox',
      isChecked: isAllNewPermission,
      onIsCheckedClick: toggleAllNewPermissions,
    },
    {
      id: 'resource_type',
      numeric: false,
      disablePadding: false,
      label: 'Resource type',
    },

    {
      id: 'resource_name',
      numeric: false,
      disablePadding: false,
      label: 'Resource Name',
    },
    {
      id: 'resource_id',
      numeric: false,
      disablePadding: false,
      label: 'Resource ID',
    },
    {
      id: 'operation',
      numeric: false,
      disablePadding: false,
      label: 'Operation',
    },
  ];

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [isAPILoading, setIsAPILoading] = useState(false);
  const [isDone] = useState(true);
  const [clusterResources, setClusterResources] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [users, setUsers] = useState([]);
  const [cloudConfigs, setCloudConfigs] = useState([]);
  const [newPermissionList, setNewPermissionList] = useState([]);
  const [checkedResourcesCount, setCheckedResourcesCount] = useState(0);
  const [isAllOperation, setIsAllOperation] = useState(false);
  const [isAllResources, setIsAllResources] = useState(false);
  const [isCreateResourcesAPILoading, setIsCreateResourcesAPILoading] =
    useState(false);
  const [resourcesName, setResourcesName] = useState('');
  const [resourcesNameError, setSearchFieldError] = useState('');
  const [searchField, setSearchField] = useState('');
  const [initialResource] = useState({
    id: '',
    name: '',
    description: '',
    resource_id: '',
    resource_type: '',
    operation: '',
    decision: 'allow',
    status: 'active',
    created_timestamp: null,
    modified_timestamp: null,
  });

  const [operations, setOperations] = useState([]);
  const [isCreateOperationVisible, setIsCreateOperationVisible] =
    useState(true);
  const [resourcesTypes, setResourcesTypes] = useState([]);
  const [selectedResourcesType, setSelectedResourcesTypes] = useState();

  useEffect(() => {
    if (resourcesName !== '*') {
      setOperations((currentList) =>
        currentList?.map((data) => {
          return {
            ...data,
            isChecked: data.title === 'Create' ? false : data.isChecked,
          };
        })
      );
    }
  }, [resourcesName]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  async function getResourcesAPIHandler(type) {
    try {
      const URL = `${
        type === resourcesTypes[0]?.type
          ? ApiConstant.GET_CLUSTERS
          : type === resourcesTypes[1]?.type
            ? ApiConstant.GET_DATA_SOURCES
            : type === resourcesTypes[2]?.type
              ? ApiConstant.GET_USERS
              : ApiConstant.GET_CLOUD_PROFILES
      }`;

      const stateSetterMap = {
        clusters: setClusterResources,
        datasources: setDataSources,
        users: setUsers,
        cloudconfigs: setCloudConfigs,
      };

      const response = await get({
        url: URL,
      });

      if (response) {
        stateSetterMap[type](response);
      }
    } catch (error) {
      return;
    }
  }

  async function getResourcesTypes() {
    await setResourcesTypes(() => config?.resourceTypes);
    await setSelectedResourcesTypes(() => config?.resourceTypes[0]?.value);
  }

  async function getOperations() {
    await setOperations(() => config?.operations);
  }

  useEffect(() => {
    getResourcesTypes();
    getOperations();
  }, []);

  useEffect(() => {
    setIsAPILoading(true);

    getResourcesAPIHandler(resourcesTypes[0]?.type);
    getResourcesAPIHandler(resourcesTypes[1]?.type);
    getResourcesAPIHandler(resourcesTypes[2]?.type);
    getResourcesAPIHandler(resourcesTypes[3]?.type);

    setIsAPILoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourcesTypes]);

  useEffect(() => {
    const isAllChecked = operations?.every((data) => data.isChecked);

    const isAllUnchecked = operations?.some((data) => !data.isChecked);

    if (isAllChecked) {
      setIsAllOperation(() => true);
    }

    if (isAllUnchecked) {
      setIsAllOperation(() => false);
    }
  }, [operations]);

  useEffect(() => {
    const listSetterMap = {
      CLUSTER: clusterResources,
      DATASOURCE: dataSources,
      USER: users,
      CLOUDCONFIG: cloudConfigs,
    };

    let listToUpdate = listSetterMap[selectedResourcesType];

    const isAllChecked = listToUpdate?.every((data) =>
      searchField !== ''
        ? selectedResourcesType === resourcesTypes[0]?.value ||
          selectedResourcesType === resourcesTypes[1]?.value
          ? data?.name?.toLowerCase().includes(searchField?.toLowerCase()) &&
            data.isChecked
          : data.username?.toLowerCase().includes(searchField?.toLowerCase()) &&
            data.isChecked
        : data.isChecked
    );

    const isAllUnchecked = listToUpdate?.some((data) =>
      searchField !== ''
        ? selectedResourcesType === resourcesTypes[0]?.value ||
          selectedResourcesType === resourcesTypes[1]?.value
          ? data?.name?.toLowerCase().includes(searchField?.toLowerCase()) &&
            !data.isChecked
          : data.username?.toLowerCase().includes(searchField?.toLowerCase()) &&
            !data.isChecked
        : !data.isChecked
    );

    const isAllValueUnchecked = listToUpdate?.every((data) => !data.isChecked);

    if (isAllChecked) {
      setIsAllResources(() => true);
    }

    if (isAllUnchecked) {
      setIsAllResources(() => false);
    }

    if (isAllValueUnchecked) {
      setIsCreateOperationVisible(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedResourcesType,
    clusterResources,
    dataSources,
    users,
    cloudConfigs,
    searchField,
  ]);

  useEffect(() => {
    let count = 0;

    const listSetterMap = {
      CLUSTER: clusterResources,
      DATASOURCE: dataSources,
      USER: users,
      CLOUDCONFIG: cloudConfigs,
    };

    let listToUpdate = listSetterMap[selectedResourcesType];

    listToUpdate?.forEach((data) => {
      if (data.isChecked) {
        count++;
      }
    });

    setCheckedResourcesCount(count);
  }, [
    selectedResourcesType,
    clusterResources,
    dataSources,
    users,
    cloudConfigs,
  ]);

  function toggleAllOperation(event) {
    setIsAllOperation(event.target?.checked);

    setOperations((currentList) =>
      currentList?.map((data) => {
        return {
          ...data,
          isChecked: event.target?.checked ? true : false,
        };
      })
    );
  }

  function updateOperations(title) {
    setOperations((currentList) =>
      currentList?.map((data) => {
        return {
          ...data,
          isChecked: title === data.title ? !data.isChecked : data.isChecked,
        };
      })
    );
  }

  function updateResources(id, isAllChecked) {
    setIsCreateOperationVisible(false);

    if (id === -1) {
      setIsAllResources(() => true);
    }

    const stateSetterMap = {
      CLUSTER: setClusterResources,
      DATASOURCE: setDataSources,
      USER: setUsers,
      CLOUDCONFIG: setCloudConfigs,
    };

    const listSetterMap = {
      CLUSTER: clusterResources,
      DATASOURCE: dataSources,
      USER: users,
      CLOUDCONFIG: cloudConfigs,
    };

    let listToUpdate = listSetterMap[selectedResourcesType];

    const updatedList = listToUpdate?.map((data) => {
      if (id === -1 || id === data.id) {
        return {
          ...data,
          isChecked:
            id === -1
              ? selectedResourcesType === resourcesTypes[0]?.value ||
                selectedResourcesType === resourcesTypes[1]?.value ||
                selectedResourcesType === resourcesTypes[3]?.value
                ? data?.name?.toLowerCase().includes(searchField?.toLowerCase())
                  ? isAllChecked
                  : data.isChecked
                : data.username
                      ?.toLowerCase()
                      .includes(searchField?.toLowerCase())
                  ? isAllChecked
                  : data.isChecked
              : !data.isChecked,
        };
      }
      return data;
    });

    stateSetterMap[selectedResourcesType](updatedList);
  }

  function isAllUnCheckedInList(list) {
    const isAllUnchecked = list?.every((data) => !data.isChecked);
    return isAllUnchecked;
  }

  async function updateNewPermissionList() {
    setSearchFieldError('');

    let newObject;

    const listSetterMap = {
      CLUSTER: clusterResources,
      DATASOURCE: dataSources,
      USER: users,
      CLOUDCONFIG: cloudConfigs,
    };

    let listToUpdate = listSetterMap[selectedResourcesType];

    if (isAllUnCheckedInList(operations)) {
      toast.warning('Please include at least one corresponding operation.');
      return;
    }
    if (resourcesName === '' && isAllUnCheckedInList(listToUpdate)) {
      toast.warning('Please include at least one corresponding resource.');
      return;
    }

    if (resourcesName !== '') {
      if (resourcesName === '*') {
      } else if (resourcesName !== '*') {
        newObject = listToUpdate.find(
          (resource) => resource.name === resourcesName
        );

        if (newObject === null || newObject === undefined) {
          setSearchFieldError('Resource not present in resource list');
          return;
        }
      }
    }

    await setNewPermissionList(() => {
      let newList = [...newPermissionList];

      if (!isAllOperation) {
        operations?.forEach((data) => {
          if (resourcesName !== '' && data.isChecked) {
            if (resourcesName === '*') {
              const newPermission = {
                ...initialResource,
                resource_type: selectedResourcesType,
                name: resourcesName,
                resource_id: resourcesName === '*' ? '*' : newObject?.id,
                operation: data.value,
              };

              const foundObject = checkIsInList(newList, newPermission);

              if (!foundObject) {
                newList.push(newPermission);
              }
            } else if (
              resourcesName !== '*' &&
              data.title !== operations[0].title
            ) {
              const newPermission = {
                ...initialResource,
                resource_type: selectedResourcesType,
                name: resourcesName,
                resource_id: newObject?.id,
                operation: data.value,
              };

              const foundObject = checkIsInList(newList, newPermission);

              if (!foundObject) {
                newList.push(newPermission);
              }
            }
          } else {
            listToUpdate?.forEach((item) => {
              if (
                data.isChecked &&
                item.isChecked &&
                data.title !== operations[0].title
              ) {
                const newPermission = {
                  ...initialResource,
                  resource_type: selectedResourcesType,
                  name:
                    selectedResourcesType === resourcesTypes[2].value
                      ? item.username
                      : item.name,
                  resource_id: item.id,
                  operation: data.value,
                };

                const foundObject = checkIsInList(newList, newPermission);

                if (!foundObject) {
                  newList.push(newPermission);
                }
              }
            });
          }
        });
      } else if (isAllOperation) {
        if (resourcesName !== '') {
          const newPermission = {
            ...initialResource,
            resource_type: selectedResourcesType,
            name: resourcesName,
            resource_id: resourcesName === '*' ? '*' : newObject?.id,
            operation: 'ALL',
          };

          const foundObject = checkIsInList(newList, newPermission);

          if (!foundObject) {
            newList.push(newPermission);
          }
        } else {
          listToUpdate?.forEach((item) => {
            if (item.isChecked) {
              const newPermission = {
                ...initialResource,
                resource_type: selectedResourcesType,
                name:
                  selectedResourcesType === resourcesTypes[2].value
                    ? item.username
                    : item.name,
                resource_id: item.id,
                operation: 'ALL',
              };

              const foundObject = checkIsInList(newList, newPermission);

              if (!foundObject) {
                newList.push(newPermission);
              }
            }
          });
        }
      }

      return [...newList];
    });

    setResourcesName(() => '');

    resetAllModalData({ setNewPermissionListNull: false });
  }

  function checkIsInList(newList, newPermission) {
    const ifPresentReason = `${newPermission.resource_type}, ${newPermission.name} - ALL or ${newPermission.operation} permission is already present`;

    const isInCurrentPermissions = props.currentPermissions.find(
      (data) =>
        data.resource_id === newPermission.resource_id &&
        (data.operation === 'ALL' ||
          data.operation === newPermission.operation) &&
        data.resource_type === newPermission.resource_type
    );

    if (isInCurrentPermissions) {
      toast.warning(ifPresentReason + '.');

      return isInCurrentPermissions;
    }

    const foundObject = newList.find(
      (data) =>
        data.resource_id === newPermission.resource_id &&
        (data.operation === 'ALL' ||
          data.operation === newPermission.operation) &&
        data.resource_type === newPermission.resource_type
    );

    if (foundObject) {
      toast.warning(ifPresentReason + ' in below list.');
    }

    return foundObject;
  }

  async function createPermissionsAPIHandler() {
    try {
      setIsCreateResourcesAPILoading(true);

      if (!Array.isArray(newPermissionList) || newPermissionList.length === 0) {
        toast.warning(
          'Please include at least one resource and a corresponding operation.'
        );
        return;
      }

      const URL = `${ApiConstant.CREATE_ROLE_PERMISSION}/${props.roleId}/permissions`;

      const response = await post({
        url: URL,
        payload: newPermissionList,
        type: 'v2',
      });

      const data = await response.data;
      if (response.status === 201) {
        setNewPermissionList([]);
        toast.success('Permission created successfully!');
        props.addNewPermissionsInList(data);
        props.handleClose();
      } else {
        toast.error(
          data.message ?? 'Permission creation failed. Please try again later.'
        );
      }
    } catch (e) {
      toast.error('Permission creation failed. Please try again later.');
      console.log('Something went wrong');
      console.error(e);
    } finally {
      setIsCreateResourcesAPILoading(false);
    }
  }

  function resetAllModalData({ setNewPermissionListNull = false }) {
    if (setNewPermissionListNull) {
      setNewPermissionList([]);
    }

    setIsCreateOperationVisible(true);
    setResourcesName('');
    setSearchField('');
    setSearchFieldError('');
    setIsAllOperation(false);
    setIsAllResources(false);

    setOperations((currentList) =>
      currentList?.map((data) => {
        return { ...data, isChecked: false };
      })
    );

    setClusterResources((currentList) =>
      currentList?.map((data) => {
        return { ...data, isChecked: false };
      })
    );

    setDataSources((currentList) =>
      currentList?.map((data) => {
        return { ...data, isChecked: false };
      })
    );

    setUsers((currentList) =>
      currentList?.map((data) => {
        return { ...data, isChecked: false };
      })
    );

    setCloudConfigs((currentList) =>
      currentList?.map((data) => {
        return { ...data, isChecked: false };
      })
    );
  }

  useEffect(() => {
    resetAllModalData({ setNewPermissionListNull: true });
  }, [props.show]);

  useEffect(() => {
    const isAllChecked = newPermissionList?.every((data) => data.isChecked);

    const isAllUnchecked = newPermissionList?.some((data) => !data.isChecked);

    const isSomeChecked = newPermissionList?.some((data) => data.isChecked);

    if (isAllChecked) {
      setIsAllNewPermission(() => true);
    }

    if (isAllUnchecked) {
      setIsAllNewPermission(() => false);
    }

    setIsDeleteNewPermission(() => !isSomeChecked);
  }, [newPermissionList]);

  async function toggleAllNewPermissions(event) {
    const isChecked = event?.target?.checked;

    setIsAllNewPermission(isChecked);

    var newPermission = [];

    newPermission = await newPermissionList.map((data) => {
      return {
        ...data,
        isChecked: isChecked,
      };
    });

    setNewPermissionList(() => newPermission);
  }

  async function toggleNewPermissionIsChecked(isChecked, id, operation) {
    var newPermission = [];

    newPermission = await newPermissionList.map((data) => {
      if (data.resource_id === id && data.operation === operation) {
        return {
          ...data,
          isChecked: isChecked,
        };
      }
      return data;
    });

    setNewPermissionList(() => [...newPermission]);
  }

  async function deleteSelectedNewPermissions() {
    var newPermission = [];

    newPermission = await newPermissionList.filter(
      (data) => data.isChecked !== true
    );

    setNewPermissionList(() => [...newPermission]);
  }

  return (
    <>
      {true ? (
        <Modal
          dialog
          centered
          show={props.show}
          onHide={props.handleClose}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Resource</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {' '}
            <div
              className="p-3 rounded"
              style={{ border: '1px solid #5a5a5a' }}
            >
              <div className="d-flex align-items-center mb-2">
                <h5 className="w-50 mb-0">
                  Resource ({checkedResourcesCount} selected)
                </h5>
                <div className="w-50">
                  <input
                    className="input-boxes add-permission-search"
                    type="search"
                    placeholder="Resource Name (* or specific name)"
                    value={resourcesName}
                    onChange={(e) => {
                      setIsCreateOperationVisible(false);
                      setResourcesName(e.target.value);
                    }}
                  />
                  <p className="text-danger">{resourcesNameError}</p>
                </div>
              </div>
              <div className="d-flex add-permission-section">
                <div style={{ flex: 1 }}>
                  {resourcesTypes?.map((resourcesType) => (
                    <div
                      className={`add-permission-tile ${
                        selectedResourcesType === resourcesType.value
                          ? 'active'
                          : ''
                      }`}
                      key={resourcesType.name}
                      onClick={() => {
                        setSelectedResourcesTypes(resourcesType.value);
                      }}
                    >
                      {`${resourcesType.name} (${
                        resourcesType.value === resourcesTypes[0]?.value
                          ? clusterResources.length
                          : resourcesType.value === resourcesTypes[1]?.value
                            ? dataSources.length
                            : resourcesType.value === resourcesTypes[2]?.value
                              ? users.length
                              : cloudConfigs.length
                      })`}
                      <i className="bi bi-chevron-right float-end" />
                    </div>
                  ))}
                </div>
                <div
                  className={`overflow-auto ${
                    resourcesName !== '' && 'opacity-25'
                  }`}
                  style={{ flex: 1 }}
                >
                  <div className="px-3 d-flex align-items-center">
                    <i className="bi bi-search m-1" />
                    <input
                      type="search"
                      placeholder="Search resource name"
                      className="input-boxes add-permission-search"
                      value={searchField}
                      onChange={(e) => {
                        setSearchField(e.target.value);
                      }}
                      disabled={resourcesName !== ''}
                    />
                  </div>

                  <div className="add-permission-tile">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="allResourceCheck"
                        checked={isAllResources}
                        onChange={(e) =>
                          resourcesName !== ''
                            ? null
                            : updateResources(-1, e.target.checked)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="allResourceCheck"
                      >
                        All
                      </label>
                    </div>
                  </div>
                  {(selectedResourcesType === resourcesTypes[0]?.value
                    ? clusterResources
                    : selectedResourcesType === resourcesTypes[1]?.value
                      ? dataSources
                      : selectedResourcesType === resourcesTypes[2]?.value
                        ? users
                        : cloudConfigs
                  )
                    ?.filter((data) =>
                      selectedResourcesType === resourcesTypes[0]?.value ||
                      selectedResourcesType === resourcesTypes[1]?.value ||
                      selectedResourcesType === resourcesTypes[3]?.value
                        ? data.name
                            ?.toLowerCase()
                            .includes(searchField?.toLowerCase())
                        : data.username
                            ?.toLowerCase()
                            .includes(searchField?.toLowerCase())
                    )
                    ?.map((data) => (
                      <div className="add-permission-tile" key={'rs' + data.id}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={data.id}
                            checked={data.isChecked}
                            onChange={() =>
                              resourcesName !== ''
                                ? null
                                : updateResources(data.id)
                            }
                          />
                          <label
                            className="form-check-label w-full"
                            htmlFor={data.id}
                          >
                            {selectedResourcesType ===
                              resourcesTypes[0]?.value ||
                            selectedResourcesType ===
                              resourcesTypes[1]?.value ||
                            selectedResourcesType === resourcesTypes[3]?.value
                              ? data.name
                              : data.username}
                          </label>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="mt-3 d-flex">
                <div style={{ flex: 1 }}>
                  <h5>
                    {`Operation
                (${
                  isAllOperation
                    ? '5'
                    : operations.filter((data) => data.isChecked).length
                }
                selected)`}
                  </h5>
                  <div className="add-permission-radio-group">
                    <div className="form-check w-100" style={{ flex: 'auto' }}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="allOperationCheck"
                        checked={isAllOperation}
                        onChange={toggleAllOperation}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="allOperationCheck"
                      >
                        All
                      </label>
                    </div>
                    {operations?.map((data) => (
                      <div className="form-check" key={data.title}>
                        <input
                          className={`form-check-input ${
                            data.title === 'Create' && resourcesName !== '*'
                              ? 'opacity-25'
                              : ''
                          } ${
                            data.title === 'Create' && isCreateOperationVisible
                              ? 'opacity-100'
                              : ''
                          }`}
                          type="checkbox"
                          id={data.title}
                          checked={data.isChecked}
                          onChange={(e) =>
                            data.title === 'Create' && resourcesName !== '*'
                              ? isCreateOperationVisible
                                ? updateOperations(
                                    data.title,
                                    e.target?.checked
                                  )
                                : null
                              : updateOperations(data.title, e.target?.checked)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={data.title}
                        >
                          {data.title}
                        </label>
                      </div>
                    ))}
                  </div>
                  <p
                    className="mb-0"
                    style={{ color: 'var(--clr-text-300)', fontSize: '0.9rem' }}
                  >
                    Create permission cannot be defined for existing resources.
                    <br />
                    It can only be defined for a{' '}
                    <q>
                      <sub style={{ fontSize: '1.5rem' }}>*</sub>
                    </q>{' '}
                    resource (ANY).
                  </p>
                </div>
                <div
                  className="d-flex justify-content-end align-items-end gap-2"
                  style={{ flex: 1 }}
                >
                  <button className="buttonX" onClick={updateNewPermissionList}>
                    + Add
                  </button>
                  <button
                    className="buttonX"
                    type="button"
                    disabled={isDeleteNewPermission}
                    onClick={deleteSelectedNewPermissions}
                  >
                    - Remove
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-4 overflow-scroll" style={{ height: '300px' }}>
              <div className="desktop-table">
                <TableContainer>
                  <Table
                    className="tableX"
                    sx={{
                      minWidth: 750,
                      borderCollapse: 'separate',
                    }}
                    aria-labelledby="tableTitle"
                  >
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={newPermissionList.length}
                      headCells={headCells}
                      disablePadding={false}
                    />
                    {isDone &&
                    newPermissionList &&
                    newPermissionList.length > 0 ? (
                      <TableBody className="table Table">
                        {stableSort(
                          newPermissionList,
                          getComparator(order, orderBy)
                        )?.map((data, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              onSelectAllClick={() => {}}
                              aria-checked={data.isChecked}
                              key={'pc' + data.resource_id + data.operation}
                            >
                              <TableCell padding="checkbox form-check">
                                <Checkbox
                                  color="primary"
                                  checked={data.isChecked}
                                  onChange={(event) =>
                                    toggleNewPermissionIsChecked(
                                      event.target?.checked,
                                      data.resource_id,
                                      data.operation
                                    )
                                  }
                                />
                                {/* <input
                                  className="form-check-input m-0 float-none border-grey"
                                  type="checkbox"
                                  checked={data.isChecked}
                                  onChange={(event) =>
                                    toggleNewPermissionIsChecked(
                                      event.target?.checked,
                                      data.resource_id,
                                      data.operation
                                    )
                                  }
                                /> */}
                              </TableCell>
                              <TableCell align="left">
                                {data.resource_type}
                              </TableCell>
                              <TableCell align="left">{data.name}</TableCell>
                              <TableCell align="left">
                                {data.resource_id}
                              </TableCell>
                              <TableCell
                                className="text-capitalize"
                                align="left"
                              >
                                {data.operation}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : null}
                  </Table>
                </TableContainer>
                {isAPILoading ? (
                  <SkeletonLoader />
                ) : !isAPILoading &&
                  newPermissionList &&
                  newPermissionList.length === 0 ? (
                  <p className="text_color text-center no-data-msg mt-2">
                    No Data Found
                  </p>
                ) : null}
              </div>
              <div className="mobile-table">
                {isAPILoading ? (
                  <SkeletonLoader />
                ) : isDone && newPermissionList ? (
                  newPermissionList?.map((data, i) => {
                    return (
                      <div
                        className="mob-main"
                        key={'mb' + data.resource_id + data.operation}
                      >
                        <div className="mob-rows">
                          <div className="keys">{headCells[0].label}</div>
                          <div className="values">{data.resource_type}</div>
                        </div>

                        <div className="mob-rows">
                          <div className="keys">{headCells[1].label}</div>
                          <div className="values">{data.name}</div>
                        </div>

                        <div className="mob-rows">
                          <div className="keys">{headCells[2].label}</div>
                          <div className="values">{data.resource_id}</div>
                        </div>
                        <div className="mob-rows">
                          <div className="keys">{headCells[3].label}</div>
                          <div className="values">{data.operation}</div>
                        </div>
                      </div>
                    );
                  })
                ) : !isAPILoading &&
                  newPermissionList &&
                  newPermissionList.length === 0 ? (
                  <p className="text_color text-center no-data-msg mt-2">
                    No Data Found
                  </p>
                ) : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="buttonX"
              disabled={
                newPermissionList.length === 0 || isCreateResourcesAPILoading
              }
              onClick={
                !isCreateResourcesAPILoading && createPermissionsAPIHandler
              }
            >
              {isCreateResourcesAPILoading ? 'Loading...' : 'Save'}
            </button>
            <button className="buttonX white" onClick={props.handleClose}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      ) : (
        <ModalLoader centered show={props.show} />
      )}
    </>
  );
}
