import { TextField } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

export default function CreateTokenModal(props) {
  const [accessTokenName, setAccessTokenName] = useState('');
  const [accessTokenDetails, setAccessTokenDetails] = useState({});

  const [isCreateTokenAPILoading, setIsCreateTokenAPILoading] = useState(false);
  const [isAPIDone, setIsAPIDone] = useState(false);

  const [isDownloadTokenModal, setIsDownloadTokenModal] = useState(false);

  useEffect(() => {
    setIsAPIDone(false);
    setAccessTokenName('');
  }, [props.show]);

  async function createToken() {
    setIsCreateTokenAPILoading(true);

    const response = await props.createAccessesHandler(accessTokenName);

    if (response.id) {
      setIsAPIDone(true);
      setAccessTokenDetails(response);
    }

    setIsCreateTokenAPILoading(false);
  }

  async function downloadCreatedToken() {
    const file = new Blob([accessTokenDetails?.accessToken], {
      type: 'text/plain;charset=utf-8',
    });
    const desiredFileName = accessTokenDetails?.name + '_token.txt';

    downloadFile(file, desiredFileName);
  }

  const downloadFile = (file, fileName) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function downloadToken() {
    if (isDownloadTokenModal) {
      toggleDownloadTokenModal();
    }

    if (isDownloadTokenModal === false) {
      props.handleClose();
    }

    downloadCreatedToken();
    toast.success('Token created successfully!!');

    setAccessTokenDetails({});
  }

  function toggleDownloadTokenModal() {
    setIsDownloadTokenModal((currentState) => !currentState);
  }

  function toggleCloseWarningModal() {
    props.handleClose();

    if (isAPIDone) {
      toggleDownloadTokenModal();
    }
  }

  function DownloadTokenWarningModal() {
    return (
      <Modal
        size="md"
        centered
        show={isDownloadTokenModal}
        onHide={() => {
          toggleDownloadTokenModal();
          !isDownloadTokenModal ?? props.handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>This is your final opportunity to download the token.</h5>
        </Modal.Body>
        <Modal.Footer>
          <button className="buttonX" onClick={() => downloadToken()}>
            Download
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <Modal
        size="md"
        centered
        show={props.show}
        onHide={toggleCloseWarningModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isAPIDone ? 'Token Created!' : 'Create Token'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isAPIDone ? (
            <>
              <h5>
                We recommend you download this token now!
                <br />
                This is the only time you can download the token.
              </h5>
              <h5 className="mt-4">Name</h5>
              <p>{accessTokenDetails?.name}</p>
            </>
          ) : (
            <>
              <h6>Create a new token for authentication with SQL cluster.</h6>
              <TextField
                className={'mt-4 mb-3 w-100'}
                name="name"
                label="Name"
                autoComplete="off"
                id="outlined-basic"
                variant="outlined"
                value={accessTokenName}
                onChange={(e) => setAccessTokenName(e.target.value)}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!isAPIDone ? (
            <>
              <button className="buttonX" onClick={createToken}>
                {isCreateTokenAPILoading ? 'Loading...' : 'Create'}
              </button>
            </>
          ) : (
            <button
              className="buttonX"
              onClick={downloadToken}
              disabled={isCreateTokenAPILoading}
            >
              Download
            </button>
          )}
        </Modal.Footer>
      </Modal>

      <DownloadTokenWarningModal />
    </>
  );
}
