import React, { useState, useEffect, useContext } from 'react';
import { Router } from './router';
import { get } from './utils/apiMethods';
import ApiConstant from './utils/apiConstant';
import LoadingScreen from './components/LoadingScreen';
import { ToastContainer } from 'react-toastify';
import ThemeContext from './themes/ThemeContext';
import { setToLocalStorage } from './utils/helperFunctions';
import axios from 'axios';
import axiosInstance from './utils/axiosInstance';
import UserContext, {
  initialCurrentUserState,
} from './context/user/UserContext';
import ConfigContext from './context/config/ConfigContext';

export default function App() {
  const { theme } = useContext(ThemeContext);

  const { currentUser, storeCurrentUser } = useContext(UserContext);
  const { setCurrentConfig } = useContext(ConfigContext);

  const [isConfigDataLoading, setIsConfigDataLoading] = useState(true);

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser?.id || currentUser?.user_id) {
      getUIConfiguration();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id, currentUser?.user_id]);

  useEffect(() => {
    const interval = setInterval(getUserData, 1000 * 60);

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id]);

  async function fetchData() {
    try {
      setIsConfigDataLoading(true);

      const response = await axios.get('./config.txt');

      if (response.status === 200) {
        const res = await response.data;

        axiosInstance.defaults.baseURL = response?.data?.REACT_APP_BASE_API_URL;

        await setToLocalStorage('envConfig', res);

        await getUserData();
      } else {
        console.error('Config File error: ', response.status);
      }
    } catch (err) {
      console.error('Error fetching config file: ', err);
    } finally {
      setIsConfigDataLoading(false);
    }
  }

  async function getUserData() {
    try {
      const URL = currentUser?.id
        ? ApiConstant.GET_USER(currentUser?.id)
        : ApiConstant.GET_USER_DETAILS;

      const response = await get({ url: URL, type: 'v2' });

      if (response?.status === 200) {
        const updatedResponse = {
          ...(response?.data?.user_id ? { id: response?.data?.user_id } : {}),
          ...response?.data,
        };

        await storeCurrentUser(updatedResponse);
      } else {
        await storeCurrentUser(initialCurrentUserState.currentUser);
      }
    } catch (error) {}
  }

  function getUIConfiguration() {
    fetchUiTabsInfo();
    getVersions();
  }

  async function fetchUiTabsInfo() {
    const response = await get({ url: ApiConstant.GET_UI_TABS, type: 'v2' });

    if (response.status === 200) {
      setCurrentConfig((prevConfig) => ({
        ...prevConfig,
        uiTabs: response?.data ?? [],
      }));
    }
  }

  async function getVersions() {
    const url = `${ApiConstant.GET_UI_VERSIONS}`;

    const response = await get({
      url: url,
      type: 'v2',
    });

    if (response.status === 200) {
      setCurrentConfig((prevConfig) => ({
        ...prevConfig,
        versionsData: response?.data || {},
      }));
    }
  }

  return (
    <div className="App">
      {!isConfigDataLoading ? <Router /> : <LoadingScreen />}

      <ToastContainer theme={theme} autoClose={false} />
    </div>
  );
}
