import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

export default function AccessTokenMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [isChangeStatusAPILoading, setIsChangeStatusAPILoading] =
    useState(false);
  const [isDeleteAPILoading, setIsCDeleteAPILoading] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  async function activateDeactivateHandler() {
    setIsChangeStatusAPILoading(true);

    props?.data?.status === 'Activated'
      ? await props.deactivateAccessToken(props.data)
      : await props.activateAccessToken(props.data);

    setIsChangeStatusAPILoading(false);

    handleClose();
  }

  async function deleteTokenHandler() {
    setIsCDeleteAPILoading(true);

    await props.deleteAccessToken(props?.data?.id);

    setIsCDeleteAPILoading(false);

    handleClose();
  }

  return (
    <>
      <i
        className="bi bi-three-dots-vertical dots"
        style={{ cursor: 'pointer' }}
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={activateDeactivateHandler}>
          {isChangeStatusAPILoading
            ? 'Loading...'
            : props?.data?.status === 'Activated'
              ? 'Deactivate'
              : 'Activate'}
        </MenuItem>
        <MenuItem onClick={deleteTokenHandler}>
          {isDeleteAPILoading ? 'Deleting...' : 'Delete'}
        </MenuItem>
      </Menu>
    </>
  );
}
