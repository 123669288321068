import React, { Component } from 'react';
import Layout from '../../../components/layout/SideBar/Layout';
import {
  ALL_RESOURCES_PATH,
  DATA_SOURCES_DETAILS_PATH,
  DATA_SOURCES_PATH,
} from '../../../router/PathConst';
import ApiConstant from '../../../utils/apiConstant';
import { deleteAPI, get } from '../../../utils/apiMethods';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import {
  EnhancedTableHead,
  getComparator,
  stableSort,
} from '../../../components/TableComponent/TableComponent';
import CommonCard from '../../../components/CommonCard/CommonCard';
import DeleteModal from '../../../modals/Common/DeleteModal';
import { FaCopy } from 'react-icons/fa';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import { createMessage, getReadableDate } from '../../../utils/common';
import { maskAllCharacters } from '../../../utils/helperFunctions';
import s3Template from '../../../templates/data_source_templates/s3Template.json';
import awsTemplate from '../../../templates/data_source_templates/awsglueTemplate.json';
import { toast } from 'react-toastify';

const headCells = [
  {
    id: 'id',
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'name',
    disablePadding: false,
    label: 'Value',
  },
];

export default class DataSourceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      selectedTab: 0,
      data: {},
      order: 'desc',
      orderBy: '',
      rowsPerPage: 10,
      page: 0,
      isData: false,
      isDelete: false,
      isCopy: false,
      loading: true,
      config: {},
    };
  }

  componentDidMount() {
    this.getDataSourceDetails();
  }

  getDataSourceDetails = async () => {
    const id = this.props.match.params.id;
    const URL = `${ApiConstant.GET_DATA_SOURCE}/${id}`;
    const response = await get({ url: URL });

    const newResponse = await {
      ...response,
      properties: [
        ...(response?.type === 'AWS_GLUE'
          ? [
              { id: 'Type', name: response?.type },
              {
                id: 'AWS Region',
                name: response?.datasource_config?.aws_region,
              },
              {
                id: 'AWS Secret Key',
                name: maskAllCharacters(
                  response?.datasource_config?.aws_keypair?.secret_key
                ),
              },
              {
                id: 'AWS Access Key',
                name: response?.datasource_config?.aws_keypair?.access_key,
              },
            ]
          : []),

        ...(response?.properties ?? []),
      ],
    };

    if (newResponse && newResponse !== undefined) {
      this.setState({
        data: newResponse,
        loading: false,
        isData: true,
        config: newResponse.type === 's3' ? s3Template : awsTemplate,
      });
    } else {
      this.setState({ loading: false });
    }
  };

  // editDatasource() {
  //   let url = `${DATA_SOURCES_PATH}/` + this.state.id;
  //   this.props.history.push(url);
  // }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  // handleRequestSort = (event, property) => {
  //   const { order, orderBy } = this.state;
  //   const isAsc = orderBy === property && order === "asc";

  //   this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  // };

  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  onDeleteDataSources = async () => {
    const id = this.props.match.params.id;

    const response = await deleteAPI({
      url: `${ApiConstant.DELETE_DATA_SOURCES}/${id}`,
      type: 'v2',
    });

    if (response.status === 204) {
      createMessage(200, 'Data Source deleted');

      setTimeout(() => {
        this.props.history.push(DATA_SOURCES_PATH);
      }, 1000);
    } else {
      toast.warning(
        response?.response?.data?.message || 'Something went wrong.'
      );
    }
  };

  onCopy = (id) => {
    navigator.clipboard.writeText(id);
    this.setState({ isCopy: true }, () => {
      setTimeout(() => {
        this.setState({ isCopy: false });
      }, 1000);
    });
  };

  maskPropertyValues = (key, value) => {
    var isSensitive = this.state.config.Restrictions.detailsProperties.find(
      (obj) => {
        if (obj.name === key && obj.sensitive) {
          return true;
        }
        return false;
      }
    );

    if (isSensitive) {
      return maskAllCharacters(value);
    }

    return value;
  };

  render() {
    const {
      data,
      isDelete,
      isCopy,
      isData,
      order,
      orderBy,
      rowsPerPage,
      page,
      loading,
    } = this.state;

    const crumbs = [
      {
        title: 'Data Source',
        path: DATA_SOURCES_PATH,
        active: false,
      },
      {
        title: 'Data Source Details',
        path: DATA_SOURCES_DETAILS_PATH,
        active: true,
      },
    ];

    return (
      <Layout crumbs={crumbs} {...this.props}>
        {loading && !isData ? (
          <div className="mt-3">
            <SkeletonLoader />
          </div>
        ) : !loading && isData ? (
          <div className="cluster-details">
            <div className="details-section">
              <div className="details-section-1">
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Name:</h6>
                    <h6 data-testid="name" className="details-data-value">
                      {data.name ? data.name : '-'}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">ID:</h6>
                    <h6 className="details-data-value copy-id">
                      <span
                        title="Copy to Clipboard"
                        onClick={() => this.onCopy(data.id)}
                      >
                        {data.id ? data.id : '-'}
                      </span>
                      {isCopy ? (
                        <span
                          style={{
                            marginLeft: '10px',
                            color: 'green',
                            fontSize: '12px',
                          }}
                        >
                          Copied
                        </span>
                      ) : (
                        <span
                          data-testid="copyIcon"
                          title="Copy to Clipboard"
                          className="copy-icon"
                          onClick={() => this.onCopy(data.id)}
                        >
                          {' '}
                          <FaCopy />
                        </span>
                      )}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Created:</h6>
                    <h6 className="details-data-value">
                      {data?.metadata?.created_timestamp
                        ? getReadableDate(data?.metadata?.created_timestamp)
                        : '-'}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Updated:</h6>
                    <h6 className="details-data-value">
                      {data?.metadata?.modified_timestamp
                        ? getReadableDate(data?.metadata?.modified_timestamp)
                        : '-'}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="details-section-2">
                <div className="details-button-group">
                  <button
                    className="buttonX details-button"
                    onClick={() =>
                      this.props.history.push({
                        pathname: `${DATA_SOURCES_PATH}/${data.id}`,
                        state: {
                          id: data.id,
                        },
                      })
                    }
                  >
                    <i className="bi bi-pencil-square mr-1" />
                    Edit Data source
                  </button>

                  <button
                    className="buttonX white details-button"
                    onClick={this.handleDeleteModal}
                  >
                    <i className="bi bi-trash3" />
                    Delete Data source
                  </button>
                </div>
              </div>
            </div>
            <CommonCard className="mt-4">
              <div className="text-left">
                <h4 className="text_color_main mb-4">Properties</h4>
                <hr className="text_color_main"></hr>
              </div>
              {data && data.properties && data.properties.length > 0 ? (
                <TableContainer id="tableDiv" className="mt-3">
                  <Table
                    className="tableX"
                    sx={{
                      minWidth: 750,
                      borderCollapse: 'separate',
                    }}
                    aria-labelledby="tableTitle"
                  >
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      rowCount={data.properties && data.properties.length}
                      headCells={headCells}
                    />

                    <TableBody className="table Table">
                      {stableSort(
                        data.properties,
                        getComparator(order, orderBy)
                      )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell align="left">
                                <span data-testid={`data-prop-id ${index}`}>
                                  {row.id}
                                </span>
                              </TableCell>

                              <TableCell
                                style={{ overflowWrap: 'anywhere' }}
                                align="left"
                              >
                                {this.maskPropertyValues(row.id, row.name)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
              {data && data.properties && data.properties.length > 0 ? (
                <TablePagination
                  className="table-pagination"
                  rowsPerPageOptions={[10, 20, 30, 50, 100]}
                  component="div"
                  count={data.properties && data.properties.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  showLastButton={true}
                  showFirstButton={true}
                />
              ) : (
                <p className="text_color text-center no-data-msg mt-2">
                  No properties found
                </p>
              )}
            </CommonCard>
          </div>
        ) : (
          <h4 className="text_color_main text-center no-data-msg mt-2">
            No Data Found
          </h4>
        )}

        {isDelete && (
          <DeleteModal
            title={data.name}
            modalType="data source"
            show={isDelete}
            handleClose={this.handleDeleteModal}
            onDelete={this.onDeleteDataSources}
          />
        )}
      </Layout>
    );
  }
}
