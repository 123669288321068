import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function FloatingLabelDropdown({
  style = {},
  className = '',
  label,
  labelStyle = {},
  name,
  value,
  handleChange,
  error,
  children,
  inputProps = {},
  multiple = false,
  required = false,
  disabled = false,
  formControlProps = {},
}) {
  const labelId = `${name}-label`;

  return (
    <FormControl
      className={className}
      sx={{ minWidth: 120, width: '100%', ...style }}
      {...formControlProps}
    >
      <InputLabel id={labelId} style={labelStyle}>
        {label}
      </InputLabel>
      <Select
        id={`${name}-select`}
        labelId={labelId}
        name={name}
        label={label}
        inputProps={inputProps}
        multiple={multiple}
        value={value}
        onChange={handleChange}
        required={required}
        disabled={disabled}
      >
        {children}
      </Select>
      {error && (
        <FormHelperText sx={{ color: 'error.main' }}>{error}</FormHelperText>
      )}
    </FormControl>
  );
}

FloatingLabelDropdown.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelStyle: PropTypes.object,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]).isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  children: PropTypes.node.isRequired,
  inputProps: PropTypes.object,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  formControlProps: PropTypes.object,
};

FloatingLabelDropdown.defaultProps = {
  style: {},
  className: '',
  labelStyle: {},
  inputProps: {},
  multiple: false,
  required: false,
  disabled: false,
  formControlProps: {},
};
