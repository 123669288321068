import React from 'react';

export const initialConfigState = {
  currentConfig: {
    uiTabs: [],
    versionsData: {},
  },
  setCurrentConfig: () => null,
};

const ConfigContext = React.createContext(initialConfigState);
export default ConfigContext;
