import React, { Component } from 'react';
import Layout from '../../../components/layout/SideBar/Layout';

import routeConstant from '../../../router/PathConst';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../../components/TabPanel';
import UserGroupRoles from './UserDetailTabs/UserGroupRoles';
import { get } from '../../../utils/apiMethods';
import ApiConstant from '../../../utils/apiConstant';
import {
  deleteMessage,
  getReadableDate,
  deleteUserGroupById,
} from '../../../utils/common';
import DeleteModal from '../../../modals/Common/DeleteModal';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import { FaCopy } from 'react-icons/fa';
import ActiveInactiveUserModal from '../../../modals/Users/ActiveInactiveUserModal';
import CommonCard from '../../../components/CommonCard/CommonCard';

export default class UserGroupDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRoleModal: false,
      selectedTab: 0,
      value: 0,
      data: {},
      isData: false,
      roleUUID: [],
      userGroupRoleList: [],
      properties: {},
      isDelete: false,
      loading: true,
      isActiveInactiveModalVisible: false,
    };
  }

  componentDidMount() {
    this.getUserGroupDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.state !== prevProps.location.state) {
      this.setState({ loading: true, isData: false }, () => {
        this.getUserGroupDetails();
      });
    }
  }

  getUserGroupDetails = async () => {
    const id = this.props.match.params.id;

    const URL = `${ApiConstant.GET_USER_GROUP_DETAILS(id)}`;

    const response = await get({ url: URL });

    if (response && response !== undefined) {
      if (response.status === undefined) {
        response.status = 0;
      }
      this.setState({ data: response, isData: true, loading: false });
    } else {
      this.setState({ loading: false });
    }
  };

  handleTabs = (index) => {
    this.setState({ selectedTab: index });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  // Delete
  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  deleteUser = async () => {
    const id = this.props.match.params.id;

    const response = await deleteUserGroupById(id);

    deleteMessage(response, 'User Group deleted');

    setTimeout(() => {
      this.props.history.push(routeConstant.USER_GROUPS);
    }, 1000);
  };

  onCopy = (id) => {
    navigator.clipboard.writeText(id);
    this.setState({ isCopy: true }, () => {
      setTimeout(() => {
        this.setState({ isCopy: false });
      }, 1000);
    });
  };

  toggleActiveInactiveModal = () => {
    this.setState({
      isActiveInactiveModalVisible: !this.state.isActiveInactiveModalVisible,
    });
  };

  updateUserStatus = () => {
    this.setState({
      data: {
        ...this.state?.data,
        status: this.state?.data?.status === -1 ? 0 : -1,
      },
    });
  };

  render() {
    const {
      isDelete,
      value,
      data,
      isData,
      isCopy,
      loading,
      isActiveInactiveModalVisible,
    } = this.state;

    let crumbs = [
      {
        title: 'User Groups',
        path: routeConstant.USER_GROUPS,
        active: false,
      },
      {
        title: 'User Group Details',
        path: routeConstant.USER_GROUP_DETAILS(data?.id),
        active: true,
      },
    ];

    return (
      <Layout crumbs={crumbs} {...this.props}>
        {loading && !isData ? (
          <div className="mt-3">
            <SkeletonLoader />
          </div>
        ) : !loading && isData ? (
          <div className="user-details">
            <div className="details-section">
              <div className="details-section-1">
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Name:</h6>
                    <h6 data-testid="user-name" className="details-data-value">
                      {data ? data.name : '-'}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">User Group ID:</h6>
                    <h6 className="details-data-value copy-id">
                      <span
                        title="Copy to Clipboard"
                        onClick={() => this.onCopy(data.id)}
                      >
                        {data.id ? data.id : '-'}
                      </span>
                      {isCopy ? (
                        <span
                          style={{
                            marginLeft: '10px',
                            color: 'green',
                            fontSize: '12px',
                          }}
                        >
                          Copied
                        </span>
                      ) : (
                        <span
                          data-testid="copyIcon"
                          title="Copy to Clipboard"
                          className="copy-icon"
                          onClick={() => this.onCopy(data.id)}
                        >
                          {' '}
                          <FaCopy />
                        </span>
                      )}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Created:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(data && data.created_timestamp)}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Updated:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(data && data.modified_timestamp)}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Status:</h6>
                    <h6
                      className={
                        data.status !== 0
                          ? 'disable details-data-value'
                          : 'available details-data-value'
                      }
                    >
                      {data.status !== 0 ? 'Inactive' : 'Active'}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="details-section-2">
                <div className="details-button-group">
                  <button
                    className="buttonX details-button"
                    onClick={() =>
                      this.props.history.push({
                        pathname: `${routeConstant.USER_GROUPS}/${data.id}`,
                        state: {
                          id: data.id,
                        },
                      })
                    }
                  >
                    <i className="bi bi-pencil-square me-2" />
                    Edit User Group
                  </button>

                  <button
                    className="buttonX white details-button"
                    onClick={this.handleDeleteModal}
                  >
                    <i className="bi bi-trash3 me-2" />
                    Delete User Group
                  </button>
                  {/* <button
                    className="buttonX white details-button"
                    onClick={this.toggleActiveInactiveModal}
                  >
                    <i
                      className={`bi ${
                        data.status !== 0
                          ? "bi-person-check"
                          : "bi-person-dash"
                      } me-2`}
                    />
                    {data.status !== 0 ? "Activate User Group" : "Disable User Group"}
                  </button> */}
                </div>
              </div>
            </div>

            <CommonCard className="mt-4">
              <Tabs
                variant="fullWidth"
                scrollButtons="auto"
                allowScrollButtonsMobile={true}
                value={value}
                onChange={this.handleChange}
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  className={`tabX-tab-head ${0 === value ? 'active' : ''}`}
                  label="Roles"
                />
              </Tabs>

              <SwipeableViews
                axis={useTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={this.handleChangeIndex}
              >
                <TabPanel
                  className="tabX-tab-body"
                  value={value}
                  index={0}
                  dir={useTheme.direction}
                >
                  <UserGroupRoles
                    onAssign={this.onRoleAssign}
                    id={data.id}
                    history={this.props.history}
                  />
                </TabPanel>
              </SwipeableViews>
            </CommonCard>
          </div>
        ) : (
          <h4 className="text_color_main text-center no-data-msg mt-2">
            No Data Found
          </h4>
        )}

        {isDelete && (
          <DeleteModal
            title={data.name}
            modalType="user group"
            show={isDelete}
            handleClose={this.handleDeleteModal}
            onDelete={this.deleteUser}
          />
        )}

        <ActiveInactiveUserModal
          show={isActiveInactiveModalVisible}
          handleClose={this.toggleActiveInactiveModal}
          updateUserStatus={this.updateUserStatus}
          userData={data}
        />
      </Layout>
    );
  }
}
