import React, { Component } from 'react';
import { Base64 } from 'js-base64';
import InputProps from './InputProps';
import OutputProps from './OutputProps';

export default class Operations extends Component {
  render() {
    const { Operations } = this.props;
    const retriev =
      Operations && Operations.length && JSON.parse(Operations[0]);

    return (
      <div>
        <div className="details-data-row mb-2">
          <div className="details-data-label"> Command:</div>
          <div className="details-data-value">
            {retriev && retriev.Command && Base64.decode(retriev.Command)}
          </div>
        </div>
        <div className="operation-properties">
          <div className="input-prop">
            <div className="input-props-label">Input Properties</div>
            <InputProps data={retriev && retriev.InputProperties} />
          </div>

          <div className="out-props">
            <div className="input-props-label">Output Properties</div>
            <OutputProps data={retriev && retriev.OutputProperties} />
          </div>
        </div>
      </div>
    );
  }
}
