import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

NormalModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default function NormalModal(props) {
  return (
    <Modal centered show={props?.show} onHide={props?.handleClose}>
      <Modal.Header>
        <h5 className="text_color_main m-0">{props?.title}</h5>
      </Modal.Header>

      <Modal.Body>{props?.description}</Modal.Body>

      <Modal.Footer>
        <button
          className="buttonX white"
          type="reset"
          onClick={props?.handleClose}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}
