import React, { useEffect, useState } from 'react';
import QueryProfileSections from './QueryProfileSections';
import ApiConstant from '../../../utils/apiConstant';
import CommonCard from '../../../components/CommonCard/CommonCard';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import { put } from '../../../utils/apiMethods';

import '../../../assets/css/query_profile.scss';

export default function QueryProfileDetailsNewTabs(props) {
  const [queryDoc, setQueryDoc] = useState({});
  const [queryDocApiLoading, setQueryDocApiLoading] = useState(true);

  useEffect(() => {
    getQueryProfileDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getQueryProfileDetails() {
    const response = await put({
      url: ApiConstant.GET_QUERY_PROFILE_DETAILS_SUMMARY,
      payload: {
        cluster_id: props.match.params.clusterId,
        key: atob(props.match.params.queryProfileId),
      },
      type: 'v2',
    });

    if (response.status === 200) {
      setQueryDoc(response.data);
    }
    setQueryDocApiLoading(false);
  }

  return (
    <section
      className="p-4 px-5 overflow-scroll query_profile"
      style={{ height: '100vh', backgroundColor: 'var(--clr-background-200)' }}
    >
      {queryDocApiLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          {queryDoc ? (
            <QueryProfileSections queryDoc={queryDoc} />
          ) : (
            <CommonCard>
              <h5 className="mb-0">No data found</h5>
            </CommonCard>
          )}
        </>
      )}
    </section>
  );
}
