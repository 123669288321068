import React, { useState, useEffect } from 'react';
import { FaCopy } from 'react-icons/fa';
import CommonCard from '../../components/CommonCard/CommonCard';
import Layout from '../../components/layout/SideBar/Layout';
import { getReadableDate } from '../../utils/common';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import TabPanel from '../../components/TabPanel';
import Properties from './JobsTabs/Properties';
import SubJobs from './JobsTabs/SubJobs';
import Operations from './JobsTabs/Operations';
import SkeletonLoader from '../../components/Loader/SkeletonLoader';
import routeConstant from '../../router/PathConst';
import { useParams, useHistory, useLocation } from 'react-router-dom';

const menu = ['Properties', 'Sub Jobs', 'Operations'];

function JobsDetails(props) {
  const { clusterId, id } = useParams();
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();

  const jobsDetails = location?.state?.job;

  const [isCopy, setIsCopy] = useState(false);
  const [isCopyForUserId, setIsCopyForUserId] = useState(false);
  const [data, setData] = useState({});
  const [isDone, setIsDone] = useState(false);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);

  const crumbs = [
    {
      title: 'Cluster',
      path: routeConstant.CLUSTER_DETAILS(clusterId),
      active: false,
    },
    {
      title: 'Job Details',
      path: routeConstant.CLUSTER_JOB_DETAILS(clusterId, id),
      active: true,
    },
  ];

  useEffect(() => {
    getAPICall();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAPICall = async () => {
    if (jobsDetails) {
      setData(jobsDetails);
    }

    setLoading(false);
    setIsDone(true);

    return;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const onCopy = (id) => {
    navigator.clipboard.writeText(id);
    setIsCopy(true);
    setTimeout(() => {
      setIsCopy(false);
    }, 1000);
  };

  const onCopyForId = (id, setState) => {
    navigator.clipboard.writeText(id);
    setState(true);
    setTimeout(() => {
      setState(false);
    }, 1000);
  };

  return (
    <Layout crumbs={crumbs} {...props}>
      {loading && !isDone ? (
        <SkeletonLoader />
      ) : !loading && isDone && data?.id ? (
        <div className="cluster-details">
          <div className="details-section">
            <div className="details-section-1">
              <div>
                <div className="details-data-row">
                  <h6 className="details-data-label"> Job ID:</h6>
                  <h6 className="details-data-value copy-id">
                    <span
                      title="Copy to Clipboard"
                      onClick={() => onCopy(data.id)}
                    >
                      {data.id ? data.id : '-'}
                    </span>
                    {isCopy ? (
                      <span
                        style={{
                          marginLeft: '5px',
                          color: 'green',
                          fontSize: '12px',
                        }}
                      >
                        Copied
                      </span>
                    ) : (
                      <span
                        data-testid="copyIcon"
                        title="Copy to Clipboard"
                        className="copy-icon"
                        onClick={() => onCopy(data.id)}
                      >
                        <FaCopy />
                      </span>
                    )}
                  </h6>
                </div>
                <div className="details-data-row">
                  <h6 className="details-data-label">Job Type :</h6>
                  <h6 className="details-data-value">
                    {data ? data.atype : '-'}
                  </h6>
                </div>
                <div className="details-data-row">
                  <h6 className="details-data-label">Job Description :</h6>
                  <h6 className="details-data-value">
                    {data ? data.description : '-'}
                  </h6>
                </div>
              </div>
              <div>
                <div className="details-data-row">
                  <h6 className="details-data-label">User ID:</h6>
                  <h6 className="details-data-value copy-id">
                    <span
                      title="Copy to Clipboard"
                      onClick={() =>
                        onCopyForId(data.user_id, setIsCopyForUserId)
                      }
                    >
                      {data.user_id ? data.user_id : '-'}
                    </span>
                    {isCopyForUserId ? (
                      <span
                        style={{
                          marginLeft: '5px',
                          color: 'green',
                          fontSize: '12px',
                        }}
                      >
                        Copied
                      </span>
                    ) : (
                      <span
                        data-testid="copy2Icon"
                        title="Copy to Clipboard"
                        className="copy-icon"
                        onClick={() =>
                          onCopyForId(data.user_id, setIsCopyForUserId)
                        }
                      >
                        <FaCopy />
                      </span>
                    )}
                  </h6>
                </div>
                <div className="details-data-row">
                  <h6 className="details-data-label"> Updated:</h6>
                  <h6 className="details-data-value">
                    {getReadableDate(data.modified_timestamp)}
                  </h6>
                </div>
              </div>
              <div>
                <div className="details-data-row">
                  <h6 className="details-data-label">Parent Job:</h6>
                  <h6
                    className="details-data-value parent-id"
                    onClick={() => {
                      if (data?.ParentJob) {
                        history.push(
                          routeConstant.CLUSTER_JOB_DETAILS(
                            clusterId,
                            data.ParentJob
                          )
                        );
                      }
                    }}
                  >
                    {data?.ParentJob || '-'}
                  </h6>
                </div>
                <div className="details-data-row">
                  <h6 className="details-data-label">State:</h6>
                  <h6
                    className={
                      data.state === 'Completed'
                        ? 'available details-data-value'
                        : 'disabled details-data-value'
                    }
                  >
                    {data.state}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <CommonCard className="mt-4 job-tabs">
            <Tabs
              variant="fullWidth"
              scrollButtons="auto"
              allowScrollButtonsMobile={true}
              value={value}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
            >
              {menu.map((item, i) => {
                return (
                  <Tab
                    className={`tabX-tab-head ${i === value ? 'active' : ''}`}
                    key={i}
                    label={item}
                  />
                );
              })}
            </Tabs>

            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel
                className="tabX-tab-body"
                value={value}
                index={0}
                dir={theme.direction}
              >
                <Properties data={data && data.Properties} />
              </TabPanel>
              <TabPanel
                className="tabX-tab-body"
                value={value}
                index={1}
                dir={theme.direction}
              >
                <SubJobs
                  UUID={data && data.subjobsid}
                  history={history}
                  match={props.match}
                />
              </TabPanel>
              <TabPanel
                className="tabX-tab-body"
                value={value}
                index={2}
                dir={theme.direction}
              >
                <Operations Operations={data && data.operations} />
              </TabPanel>
            </SwipeableViews>
          </CommonCard>
        </div>
      ) : (
        <h4 className="text_color_main text-center no-data-msg mt-2">
          No Data Found
        </h4>
      )}
    </Layout>
  );
}

export default JobsDetails;
