import React, { useContext, useEffect, useState } from 'react';
import Aside from './Aside';
import Main from './Main';
import ApiConstant from '../../../utils/apiConstant';
import UserContext, {
  initialCurrentUserState,
} from '../../../context/user/UserContext';
import { get } from '../../../utils/apiMethods';

export default function Layout(props) {
  const { currentUser, storeCurrentUser } = useContext(UserContext);

  const [rtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image] = useState(true);
  const [toggled, setToggled] = useState(false);
  const [active, setActive] = useState(true);

  useEffect(() => {
    getUserData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const navigate = (item) => {
    setActive(!active);
    props.history.push(item);
  };

  async function getUserData() {
    try {
      if (currentUser?.id || currentUser?.user_id) {
        return;
      }

      const URL = ApiConstant.GET_USER_DETAILS;

      const response = await get({ url: URL, type: 'v2' });

      if (response?.status === 200) {
        const updatedResponse = {
          ...(response?.data?.user_id ? { id: response?.data?.user_id } : {}),
          ...response?.data,
        };

        await storeCurrentUser(updatedResponse);
      } else {
        await storeCurrentUser(initialCurrentUserState.currentUser);
      }
    } catch (error) {}
  }

  return (
    <div className={`app ${rtl ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`}>
      <Aside
        image={image}
        collapsed={collapsed}
        rtl={rtl}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        navigate={navigate}
        active={active}
        heading={props.title}
        crumbs={props.crumbs}
        history={props.history}
      />

      <Main
        handleToggleSidebar={handleToggleSidebar}
        collapsed={collapsed}
        rtl={rtl}
        toggled={toggled}
        handleCollapsedChange={handleCollapsedChange}
        crumbs={props.crumbs}
        history={props.history}
      >
        {props.children}
      </Main>
    </div>
  );
}

// export default withRouter(Layout);
