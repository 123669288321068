import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { EnhancedTableHead } from '../../../components/TableComponent/TableWithSelection';
import {
  getComparator,
  stableSort,
} from '../../../components/TableComponent/TableComponent';
import Layout from '../../../components/layout/SideBar/Layout';
import CommonCard from '../../../components/CommonCard/CommonCard';
import routeConstant from '../../../router/PathConst';
import UserGroupMenu from './UserGroupMenu';
import { get, put } from '../../../utils/apiMethods';
import ApiConstant from '../../../utils/apiConstant';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import {
  deleteMessage,
  getReadableDate,
  deleteUserGroupById,
} from '../../../utils/common';
import DeleteModal from '../../../modals/Common/DeleteModal';
import MultiSelect from '../../../components/MultiSelect/MultiSelect';
import ViewMore from '../../../modals/Common/ViewMore';
import UserContext from '../../../context/user/UserContext';

const crumbs = [
  {
    title: 'User groups',
    path: routeConstant.USER_GROUPS,
    active: true,
  },
];

const options = [
  { value: 0, label: 'Active' },
  { value: -1, label: 'Inactive' },
];

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'created_timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Created',
  },
  {
    id: 'modified_timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Updated',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

export default class UserGroups extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      page: 0,
      rowsPerPage: 10,
      UUID: [],
      userGroupList: [],
      loading: true,
      isDone: true,
      userGroupRefList: [],
      selectedUserGroups: [],
      isDelete: false,
      viewMore: false,
      viewMoreData: '',
    };
  }

  componentDidMount() {
    if (this.context) {
      const { currentUser } = this.context;
      const { props } = currentUser;

      if (props && 'sorting' in props) {
        const sort = JSON.parse(props.sorting);
        if ('userGroup' in sort && sort.userGroup.length > 0) {
          this.setState({
            orderBy: sort.userGroup[0],
            order: sort.userGroup[1],
          });
        } else {
          this.setState({ orderBy: 'name' });
        }
      } else {
        this.setState({ orderBy: 'name' });
      }
    }
    this.getUserGroups();
  }

  // GET API CALL
  getUserGroups = async () => {
    const response = await get({
      url: ApiConstant.GET_USER_GROUPS,
    });

    this.setState({
      userGroupList: response?.data,
      userGroupRefList: response?.data,
      isDone: true,
      loading: false,
    });
  };

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
    this.onSaveSort(property, isAsc ? 'desc' : 'asc');
  };

  // save sort
  onSaveSort = (value, order) => {
    const { currentUser } = this.context;
    const { props } = currentUser;

    let array = { ...props };
    if (props && props.sorting) {
      let { sorting } = props;
      const obj = JSON.parse(sorting);
      if (sorting !== undefined) {
        obj.userGroup = [value, order];
      } else {
        obj.userGroup = [value, order];
      }
      array.sorting = JSON.stringify(obj);

      this.callAPi(array);
    } else {
      const obj = {
        userGroup: value,
      };

      currentUser.props = { sorting: JSON.stringify(obj) };
      this.callAPi(currentUser.props);
    }
  };

  callAPi = async (properties) => {
    const { currentUser, storeCurrentUser } = this.context;
    const { username, firstName, lastName, email, password, phone, id } =
      currentUser;

    const payload = {
      id: id,
      username: username,
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      phone: phone,
      props: properties,
    };

    const url = ApiConstant.PUT_USER(id);

    const response = await put({
      url: url,
      payload: payload,
    });

    if (response && response.id) {
      if ('sorting' in response.props) {
        storeCurrentUser(response);
      }
    }
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = this.state.userGroupList.map((n) => n.id);
      this.setState({ selected: newSelected, selectedUserGroups: newSelected });
      return;
    }
    this.setState({ selected: [], selectedUserGroups: [] });
  };

  handleClick = (event, name) => {
    const { selected } = this.state;
    let newSelected = [];
    const selectedIndex = selected.indexOf(name);
    this.userToDelete(name);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected });
  };

  userToDelete = (id) => {
    const { selectedUserGroups } = this.state;
    let selectedUser = [...selectedUserGroups];
    const isPresent = selectedUserGroups.includes(id);
    const index = selectedUserGroups.indexOf(id);

    if (!isPresent) {
      selectedUser.push(id);
      this.setState({ selectedUserGroups: selectedUser });
    } else {
      selectedUser.splice(index, 1);
      this.setState({ selectedUserGroups: selectedUser });
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  onSearchData = (value) => {
    const { userGroupRefList } = this.state;
    if (value !== '' && value !== undefined) {
      const result = userGroupRefList.filter(
        (data) =>
          data.name.toLowerCase().includes(value.toLowerCase()) ||
          data.id.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({ userGroupList: result, page: 0 });
    } else {
      this.setState({ userGroupList: userGroupRefList });
    }
  };

  // filter
  onFilter = (status) => {
    const { userGroupRefList } = this.state;

    const filterList = userGroupRefList.filter(
      (data) => data.status === status
    );

    if (filterList && filterList.length > 0) {
      return filterList;
    }
  };

  onFilterData = (value) => {
    let resArray = [];
    const { userGroupRefList } = this.state;

    if (value && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        const result = this.onFilter(value[i].value);
        if (result !== undefined) {
          if (result && result.length > 1) {
            for (let k = 0; k < result.length; k++) {
              resArray.push(result[k]);
            }
          } else {
            resArray.push(result[0]);
          }
        }
      }
      this.setState({ userGroupList: resArray, page: 0 });
    } else {
      this.setState({ userGroupList: userGroupRefList });
    }

    // const filter = clusterList.filter((data)=>data.status  )
  };

  // delete
  onDeleteUserGroup = async () => {
    const { selectedUserGroups } = this.state;
    let success = [];
    let failure = [];
    for (let z = 0; z < selectedUserGroups.length; z++) {
      const response = await deleteUserGroupById(selectedUserGroups[z]);

      if (response === 200) {
        success.push(response);
      } else {
        failure.push(response);
      }
    }
    if (success.length > 0) {
      deleteMessage(200, `${success.length} users deleted`);
    }
    if (failure.length > 0) {
      deleteMessage(200, `${failure.length} users failed to delete`, true);
    }

    this.deleteDone();
  };

  deleteDone = () => {
    this.setState(
      {
        userGroupList: [],
        selectedUserGroups: [],
        selected: [],
        page: 0,
        isDelete: false,
        loading: true,
      },
      () => {
        this.getUserGroups();
      }
    );
  };

  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  openedViewMore = (data) => {
    this.setState({ viewMoreData: data, viewMore: true });
  };

  closedViewMore = () => {
    this.setState({ viewMoreData: '', viewMore: false });
  };

  render() {
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      userGroupList,
      loading,
      isDone,
      selectedUserGroups,
      isDelete,
      viewMoreData,
      viewMore,
    } = this.state;

    return (
      <Layout crumbs={crumbs} {...this.props}>
        <div className="d-flex justify-content-end gap-3 mb-4">
          <button
            className="buttonX"
            onClick={() =>
              this.props.history.push(
                routeConstant.USER_GROUP_ADD_EDIT('create')
              )
            }
          >
            <i className="bi bi-plus-lg" />
            Create User Group
          </button>
          <button
            className="buttonX white"
            onClick={this.handleDeleteModal}
            disabled={selectedUserGroups.length === 0}
          >
            <i className="bi bi-trash3" />
            Delete User Groups
          </button>
        </div>

        <CommonCard className="">
          <div className="row">
            <div className="col-md-8">
              <div className="text-left mb-3">
                <input
                  type="search"
                  placeholder="Search By Name"
                  className="input-boxes"
                  onChange={(e) => this.onSearchData(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-right ">
                <MultiSelect
                  closeMenuOnSelect={false}
                  isMulti
                  handleChange={(value) => this.onFilterData(value)}
                  options={options}
                  placeholder="Filter By Status"
                />
              </div>
            </div>
          </div>
          <div className="desktop-table">
            <TableContainer>
              <Table
                className="tableX"
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={userGroupList?.length}
                  headCells={headCells}
                />
                {isDone && userGroupList && userGroupList.length > 0 ? (
                  <TableBody className="table Table">
                    {stableSort(userGroupList, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((userData, index) => {
                        const isItemSelected = this.isSelected(userData.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const status =
                          userData.status === -1 ? 'Inactive' : 'Active';
                        return (
                          <TableRow
                            hover
                            key={index}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) =>
                                  this.handleClick(event, userData.id, userData)
                                }
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>

                            <TableCell
                              onClick={() =>
                                this.props.history.push(
                                  `${routeConstant.USER_GROUP_DETAILS(
                                    userData.id
                                  )}`,
                                  userData
                                )
                              }
                              className="id"
                              align="left"
                            >
                              <span
                                data-testid={`user-id ${index}`}
                                className="id"
                              >
                                {userData?.name.length > 20 ? (
                                  <>
                                    {userData?.name.slice(0, 20)}
                                    <span
                                      className="view-more"
                                      data-testid={`viewMore ${index}`}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        this.openedViewMore(userData);
                                      }}
                                    >
                                      ...View More
                                    </span>
                                  </>
                                ) : (
                                  (userData?.name ?? '--')
                                )}
                              </span>
                            </TableCell>

                            <TableCell align="left">
                              {getReadableDate(userData.created_timestamp)}
                            </TableCell>

                            <TableCell align="left">
                              {getReadableDate(userData.modified_timestamp)}
                            </TableCell>

                            <TableCell align="left">
                              <span
                                className={
                                  status === 'Inactive'
                                    ? 'disable'
                                    : 'available'
                                }
                              >
                                {status}
                              </span>
                            </TableCell>

                            <TableCell align="left">
                              <UserGroupMenu
                                onComplete={this.deleteDone}
                                id={userData.id}
                                title={userData.name}
                                history={this.props.history}
                                status={status}
                                data={userData}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
            {loading ? (
              <SkeletonLoader />
            ) : isDone && userGroupList && userGroupList.length > 0 ? (
              <TablePagination
                className="table-pagination"
                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                component="div"
                count={userGroupList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                showLastButton={true}
                showFirstButton={true}
              />
            ) : !loading && userGroupList && userGroupList.length === 0 ? (
              <p className="text_color text-center no-data-msg mt-2">
                No Data Found
              </p>
            ) : null}
          </div>
          <div className="mobile-table">
            {loading ? (
              <SkeletonLoader />
            ) : isDone && userGroupList ? (
              userGroupList.map((userData, index) => {
                const status = userData.status !== 0 ? 'Inactive' : 'Active';

                return (
                  <div key={index} className="mob-main">
                    <div className="mob-rows">
                      <div className="keys">Name</div>
                      <div
                        onClick={() =>
                          this.props.history.push(
                            `${routeConstant.USER_GROUP_DETAILS(userData.id)}`,
                            userData
                          )
                        }
                        className="values id"
                      >
                        {userData.name}
                      </div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">Created</div>
                      <div className="values">
                        {getReadableDate(userData.created_timestamp)}
                      </div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">Updated</div>
                      <div className="values">
                        {getReadableDate(userData.modified_timestamp)}
                      </div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">Actions</div>
                      <div className="text_color_main">
                        <UserGroupMenu
                          title={userData.name}
                          id={userData.id}
                          history={this.props.history}
                          status={status}
                          data={userData}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : !loading && userGroupList && userGroupList.length === 0 ? (
              <p className="text_color text-center no-data-msg mt-2">
                No Data Found
              </p>
            ) : null}
          </div>
        </CommonCard>

        {isDelete && (
          <DeleteModal
            deleteCount={selectedUserGroups.length}
            title="User Groups"
            show={isDelete}
            handleClose={this.handleDeleteModal}
            onDelete={this.onDeleteUserGroup}
          />
        )}

        {viewMore && (
          <ViewMore
            show={viewMore}
            handleClose={this.closedViewMore}
            data={viewMoreData}
            title="User Group Name"
            fetchKey="name"
          />
        )}
      </Layout>
    );
  }
}
