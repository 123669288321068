import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import {
  DATA_SOURCES_DETAILS_PATH,
  DATA_SOURCES_PATH,
} from '../../../router/PathConst';
import DeleteModal from '../../../modals/Common/DeleteModal';
import { deleteAPI } from '../../../utils/apiMethods';
import ApiConstant from '../../../utils/apiConstant';
import { createMessage } from '../../../utils/common';
import { toast } from 'react-toastify';

export default function DataSourceMenu({
  id,
  history,
  title,
  onComplete,
  data,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [isDelete, setIsDelete] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDelete = () => {
    setAnchorEl(null);
    setIsDelete(true);
  };

  const closeDelete = () => setIsDelete(false);

  const onDeleteRole = async () => {
    setIsDelete(false);

    const response = await deleteAPI({
      url: `${ApiConstant.DELETE_DATA_SOURCES}/${id}`,
      type: 'v2',
    });

    if (response.status === 204) {
      createMessage(200, 'Data Source deleted');

      setTimeout(() => {
        onComplete();
      }, 1000);
    } else {
      toast.warning(
        response?.response?.data?.message || 'Something went wrong.'
      );
    }
  };

  return (
    <>
      <i
        className="bi bi-three-dots-vertical dots"
        data-testid="data-dots"
        style={{ cursor: 'pointer' }}
        onClick={handleClick}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() =>
            history.push({
              pathname: `${DATA_SOURCES_DETAILS_PATH}/${id}`,
              state: {
                id: id,
              },
            })
          }
        >
          View Details
        </MenuItem>

        <MenuItem
          onClick={() =>
            history.push({
              pathname: `${DATA_SOURCES_PATH}/${id}`,
              state: {
                id: id,
              },
            })
          }
        >
          Edit Data Source
        </MenuItem>

        <MenuItem onClick={openDelete}>Delete Data Source</MenuItem>
      </Menu>

      {isDelete && (
        <DeleteModal
          title={title}
          modalType="data source"
          show={isDelete}
          handleClose={closeDelete}
          onDelete={onDeleteRole}
        />
      )}
    </>
  );
}
