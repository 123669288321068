import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import CommonCard from '../../../components/CommonCard/CommonCard';
import FloatingLabelDropdown from '../../../components/FloatingLabelDropdown/FloatingLabelDropdown';
import Layout from '../../../components/layout/SideBar/Layout';
import { DATA_SOURCES_PATH } from '../../../router/PathConst';
import './DataSources.scss';
import * as Yup from 'yup';
import { get, post, put } from '../../../utils/apiMethods';
import ApiConstant from '../../../utils/apiConstant';
import { checkProps, createMessage } from '../../../utils/common';
import s3Template from '../../../templates/data_source_templates/s3Template.json';
import awsTemplate from '../../../templates/data_source_templates/awsglueTemplate.json';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import ActivityLoader from '../../../components/Loader/ActivityLoader';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { toast } from 'react-toastify';

export default function DataSourceAddEdit(props) {
  const nameCheck = /^[A-Za-z0-9]+$/;

  const [jsonData, setJsonData] = useState([]);
  const [type, setType] = useState('add');
  const [sourceType, setSourceType] = useState('AWS_GLUE');

  const [data, setData] = useState({});
  const [isFile, setIsFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionDone, setActionDone] = useState(true);
  const [propsArray, setPropsArray] = useState([]);
  const [allow, setAllow] = useState(true);
  const [config, setConfig] = useState({});
  const [configForProperties, setConfigForProperties] = useState({});

  const [isCredentialKeyFieldVisible, setShowPassword] = useState(false);
  const [isCredentialValueFieldVisible, setIsShowPassword] = useState(false);
  const [isSecretKeyVisible, setIsSecretKeyVisible] = useState(false);
  const [isSensitiveEdit, setIsSensitiveEdit] = useState(false);

  const crumbs = [
    {
      title: 'Data Source',
      path: DATA_SOURCES_PATH,
      active: false,
    },
    {
      title: type === 'edit' ? 'Update Data Source' : 'Create Data Source',
      path: DATA_SOURCES_PATH,
      active: true,
    },
  ];

  function toggleCredentialKeyField() {
    setShowPassword(!isCredentialKeyFieldVisible);
  }

  function toggleCredentialValueField() {
    setIsShowPassword(!isCredentialValueFieldVisible);
  }

  function toggleSecretKeyValueField() {
    setIsSecretKeyVisible(!isSecretKeyVisible);
  }

  let validation;

  const isConfig = config && config.name ? true : false;

  if (config && config.name) {
    validation = Yup.object().shape({
      valueRequired: Yup.boolean(),
      credKeyRequired: Yup.boolean(),
      credentialValueRequired: Yup.boolean(),
      name: Yup.string()
        .required('name' in config && config?.name?.Restrict?.valuehelp)
        .matches(
          nameCheck,
          ' Data Source Name should be combination of a-z A-Z 0-9'
        ),
      type: Yup.string().required('Please select the type'),
      value: Yup.string().when('valueRequired', {
        is: true,
        then: Yup.string().required(config?.value?.Restrict?.valuehelp),
      }),
      credentialKey: Yup.string().when('credKeyRequired', {
        is: true,
        then: Yup.string().required(config?.credentialKey?.Restrict?.valuehelp),
      }),
      credentialValue: Yup.string().when('credentialValueRequired', {
        is: true,
        then: Yup.string().required(
          config?.credentialValue?.Restrict?.valuehelp
        ),
      }),
    });
  }

  const formik = useFormik({
    initialValues: {
      name: data?.name ? data?.name : isFile ? jsonData?.name : '',
      type: sourceType,
      value: data?.value ? data?.value : isFile ? jsonData?.value : '',
      valueRequired:
        config && 'value' in config && config?.value?.Restrict?.required,
      credentialKey: data?.credentialKey
        ? data?.credentialKey
        : isFile
          ? jsonData?.credentialKey
          : '',
      credKeyRequired:
        config &&
        'credentialKey' in config &&
        config?.credentialKey?.Restrict?.required,
      credentialValue: data?.credentialValue
        ? data?.credentialValue
        : isFile
          ? jsonData?.credentialValue
          : '',
      credentialValueRequired:
        config &&
        'credentialValue' in config &&
        config?.credentialValue?.Restrict?.required,
      created_timestamp: data?.created_timestamp ? data?.created_timestamp : '',
      awsRegion: data?.datasource_config
        ? data?.datasource_config?.aws_region
        : isFile
          ? jsonData?.datasource_config?.aws_region
          : '',
      accessKey: data?.datasource_config
        ? data?.datasource_config?.aws_keypair?.access_key
        : isFile
          ? jsonData?.datasource_config?.aws_keypair?.access_key
          : '',
      secretKey: data?.datasource_config
        ? data?.datasource_config?.aws_keypair?.secret_key
        : isFile
          ? jsonData?.datasource_config?.aws_keypair?.secret_key
          : '',
    },

    validationSchema: validation,
    onSubmit: (values) => {
      onSubmitCall(values);
    },
    enableReinitialize: true,
  });

  // to set templates
  useEffect(() => {
    if (sourceType === 's3') {
      setConfig(s3Template.Restrictions.datasource);
      setConfigForProperties(s3Template);
    } else {
      setConfig(awsTemplate.Restrictions.datasource);
      setConfigForProperties(s3Template);
    }
  }, [sourceType]);

  useEffect(() => {
    if (props.match.params.type === 'create') {
      setType(props.match.params.type);
    } else {
      setType('edit');
      setLoading(true);
    }
  }, [props.match.params.type]);

  useEffect(() => {
    const getDataSourceData = async () => {
      const id = props.match.params.type;

      const URL = `${ApiConstant.GET_DATA_SOURCE}/${id}`;

      const response = await get({ url: URL });

      if (response && response !== undefined) {
        setData(response);
        setSourceType(response.type);
        setPropsArray(response.properties ? response.properties : []);
        setAllow(false);
        setLoading(false);
      }
    };
    if (props.match.params.type !== 'create') {
      getDataSourceData();
    }
  }, [props.match.params.type]);

  const onChangeType = (value) => {
    setSourceType(value);
    setAllow(true);
    setData(formik.values);
  };

  const onJsonUpload = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      const response = JSON.parse(e.target.result);

      setJsonData(response);
      setPropsArray(response.properties ? response.properties : []);
      setSourceType(response.type);
      setIsFile(true);
    };
  };

  // props
  const addProps = () => {
    const array = [...propsArray];
    const obj = {
      id: '',
      name: '',
    };
    array.push(obj);
    setPropsArray(array);
  };

  const deleteProps = (i) => {
    const array = [...propsArray];
    array.splice(i, 1);
    setPropsArray(array);
  };

  const hadleChangeProps = (e, index, type) => {
    const array = [...propsArray];
    if (type === 'id') {
      array[index].id = e.target.value;
    } else {
      array[index].name = e.target.value;
    }
    setPropsArray(array);
  };

  // new
  const getDataSourceData = async () => {
    const id = props.match.params.type;
    const URL = `${ApiConstant.GET_DATA_SOURCE}/${id}`;
    const response = await get({ url: URL });
    if (response && response !== undefined) {
      setData(response);
      setSourceType(response.type);
      setPropsArray(response.properties ? response.properties : []);
      setAllow(false);
      setLoading(false);
    }
  };

  const onSubmitCall = async (values) => {
    setActionDone(false);

    if (type === 'edit') {
      const id = props.match.params.type;

      const payload = {
        ...data,
        id: id,
        name: values.name,
        type: values.type,
        ...(values.type === 'AWS_GLUE' && {
          datasource_config: {
            aws_region: values.awsRegion,
            is_role_access: false,
            aws_keypair: {
              secret_key: values.secretKey,
              access_key: values.accessKey,
            },
          },
        }),
        ...(values.type === 's3' && {
          s3_config: {
            value: values.value,
            credentialKey: values.credentialKey,
            credentialValue: values.credentialValue,
          },
        }),
        // properties: checkProps(propsArray),
      };

      const URL = `${ApiConstant.UPDATE_DATA_SOURCES}/${id}`;

      const response = await put({
        url: URL,
        payload: payload,
        type: 'v2',
      });

      if (response.status === 200) {
        createMessage(200, 'Data Source Updated');

        setLoading(true);

        setTimeout(() => {
          getDataSourceData();
        }, 1000);

        setActionDone(true);
      } else {
        setActionDone(true);

        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }
    } else {
      const payload = {
        name: values.name,
        type: values.type,
        ...(values.type === 'AWS_GLUE' && {
          datasource_config: {
            aws_region: values.awsRegion,
            is_role_access: false,
            aws_keypair: {
              secret_key: values.secretKey,
              access_key: values.accessKey,
            },
          },
        }),
        ...(values.type === 's3' && {
          s3_config: {
            value: values.value,
            credentialKey: values.credentialKey,
            credentialValue: values.credentialValue,
          },
        }),
        // properties: checkProps(propsArray),
      };

      const response = await post({
        url: ApiConstant.ADD_DATA_SOURCES,
        payload: payload,
        type: 'v2',
      });

      if (response.status === 201 || response.status === 200) {
        props.history.push(DATA_SOURCES_PATH);

        createMessage(200, 'Data Source created');

        setTimeout(() => {
          props.history.push(DATA_SOURCES_PATH);
        }, 1000);
        setActionDone(true);
      } else {
        setActionDone(true);
        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }
    }
  };

  return (
    <Layout crumbs={crumbs} {...props}>
      <div className="data-source-add">
        {type === 'edit' && loading ? (
          <SkeletonLoader />
        ) : (
          <CommonCard isForm>
            <div className="card-wrapper">
              <div className="form-wrapper">
                <h4 className="text_primary text-left">
                  {type === 'edit'
                    ? 'Update Data Source'
                    : 'Create Data Source'}
                </h4>
              </div>

              <form onSubmit={formik.handleSubmit}>
                <div className="form-wrapper">
                  <div className="mt-4">
                    <TextField
                      autoComplete="off"
                      inputProps={{ 'data-testid': 'name-input' }}
                      style={{ width: '100%' }}
                      id="outlined-basic"
                      variant="outlined"
                      name="name"
                      label={
                        isConfig ? config.name.Restrict.displayprompt : 'Name'
                      }
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      disabled={
                        isConfig && config && !config.name.Restrict.editable
                      }
                    />
                  </div>
                  <div className="mt-4">
                    <FloatingLabelDropdown
                      handleChange={(e) => onChangeType(e.target.value)}
                      value={formik.values.type}
                      name="type"
                      error={formik.touched.type && formik.errors.type}
                      label="Type"
                      disabled={
                        isConfig && config && !config.type.Restrict.editable
                      }
                      inputProps={{ 'data-testid': 'type-select' }}
                    >
                      {Types &&
                        Types.map((item, i) => {
                          return (
                            <MenuItem key={i} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                    </FloatingLabelDropdown>
                  </div>
                  {formik.values.type === 's32' && (
                    <>
                      <div className="mt-4">
                        <TextField
                          autoComplete="off"
                          inputProps={{ 'data-testid': 'vaue-input' }}
                          style={{ width: '100%' }}
                          id="outlined-basic"
                          variant="outlined"
                          name="value"
                          label="Value"
                          value={formik.values.value}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.value && Boolean(formik.errors.value)
                          }
                          helperText={
                            formik.touched.value && formik.errors.value
                          }
                          disabled={
                            isConfig &&
                            config &&
                            !config?.value?.Restrict?.editable
                          }
                        />
                      </div>
                      <div className="mt-4">
                        <TextField
                          autoComplete="off"
                          style={{ width: '100%' }}
                          variant="outlined"
                          name="credentialKey"
                          inputProps={{ 'data-testid': 'credentialKey-input' }}
                          label={
                            isConfig
                              ? config?.credentialKey?.Restrict?.displayprompt
                              : 'Credential Key'
                          }
                          value={formik.values.credentialKey}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.credentialKey &&
                            Boolean(formik.errors.credentialKey)
                          }
                          helperText={
                            formik.touched.credentialKey &&
                            formik.errors.credentialKey
                          }
                          disabled={
                            isConfig &&
                            config &&
                            !config?.credentialKey?.Restrict?.editable
                          }
                          type={
                            isCredentialKeyFieldVisible ? 'text' : 'password'
                          }
                          InputProps={
                            type !== 'edit' && {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleCredentialKeyField}
                                    edge="end"
                                  >
                                    {isCredentialKeyFieldVisible ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }
                          }
                        />
                      </div>
                      <div className="mt-4">
                        <TextField
                          autoComplete="off"
                          inputProps={{
                            'data-testid': 'credentialValue-input',
                          }}
                          style={{ width: '100%' }}
                          id="outlined-basic"
                          variant="outlined"
                          name="credentialValue"
                          label={
                            isConfig
                              ? config?.credentialValue?.Restrict?.displayprompt
                              : 'Credential Value'
                          }
                          value={formik.values.credentialValue}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.credentialValue &&
                            Boolean(formik.errors.credentialValue)
                          }
                          helperText={
                            formik.touched.credentialValue &&
                            formik.errors.credentialValue
                          }
                          type={
                            isCredentialValueFieldVisible ? 'text' : 'password'
                          }
                          InputProps={
                            type !== 'edit' && {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleCredentialValueField}
                                    edge="end"
                                  >
                                    {isCredentialValueFieldVisible ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }
                          }
                        />
                      </div>
                    </>
                  )}
                  {formik.values.type === 'AWS_GLUE' && (
                    <>
                      <div className="mt-3">
                        <TextField
                          autoComplete="off"
                          style={{ width: '100%' }}
                          variant="outlined"
                          name="awsRegion"
                          label={config?.awsRegion?.Restrict?.displayprompt}
                          value={formik.values.awsRegion}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.awsRegion &&
                            Boolean(formik.errors.awsRegion)
                          }
                          helperText={
                            formik.touched.awsRegion && formik.errors.awsRegion
                          }
                          disabled={
                            isConfig &&
                            config &&
                            !config?.awsRegion?.Restrict?.editable
                          }
                        />
                      </div>
                      <div
                        className={`py-3 position-relative ${
                          type === 'edit'
                            ? isSensitiveEdit
                              ? 'edit-sensitive-border my-4'
                              : 'edit-sensitive-border edit-sensitive-disable my-4'
                            : ''
                        }`}
                      >
                        {type === 'edit' && (
                          <div className="p-2 edit-sensitive-field">
                            <input
                              className="me-2"
                              id="unlock-check"
                              type="checkbox"
                              checked={isSensitiveEdit}
                              onChange={() => {
                                setIsSensitiveEdit(
                                  (currentState) => !currentState
                                );

                                if (isSensitiveEdit) {
                                  formik.setFieldValue(
                                    'accessKey',
                                    data?.datasource_config?.aws_keypair?.access_key?.toString()
                                  );
                                  formik.setFieldValue(
                                    'secretKey',
                                    data?.datasource_config?.aws_keypair?.secret_key?.toString()
                                  );
                                } else {
                                  formik.setFieldValue('accessKey', '');
                                  formik.setFieldValue('secretKey', '');
                                }
                              }}
                            />

                            <label htmlFor="unlock-check">
                              {isSensitiveEdit
                                ? 'Lock to cancel edit'
                                : 'Unlock to edit'}
                            </label>
                          </div>
                        )}
                        <div>
                          <TextField
                            autoComplete="off"
                            style={{ width: '100%' }}
                            id="outlined-basic"
                            variant="outlined"
                            name="accessKey"
                            label={config?.accessKey?.Restrict?.displayprompt}
                            value={formik.values.accessKey}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.accessKey &&
                              Boolean(formik.errors.accessKey)
                            }
                            helperText={
                              formik.touched.accessKey &&
                              formik.errors.accessKey
                            }
                            disabled={
                              type === 'edit'
                                ? isSensitiveEdit
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </div>
                        <div className="mt-3">
                          <TextField
                            autoComplete="off"
                            style={{ width: '100%' }}
                            id="outlined-basic"
                            variant="outlined"
                            name="secretKey"
                            label={
                              isConfig
                                ? config?.secretKey?.Restrict?.displayprompt
                                : 'Credential Value'
                            }
                            value={formik.values.secretKey}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.secretKey &&
                              Boolean(formik.errors.secretKey)
                            }
                            helperText={
                              formik.touched.secretKey &&
                              formik.errors.secretKey
                            }
                            type={isSecretKeyVisible ? 'text' : 'password'}
                            InputProps={
                              type !== 'edit' && {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={toggleSecretKeyValueField}
                                      edge="end"
                                    >
                                      {isSecretKeyVisible ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }
                            }
                            disabled={
                              type === 'edit'
                                ? isSensitiveEdit
                                  ? false
                                  : true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {/* <div className="props-wrapper">
                    <p className="prop-label mt-2">
                      <span className="me-3">Properties</span>
                      {propsArray.length === 0 && (
                        <i
                          onClick={() => addProps()}
                          className="bi bi-plus-circle add-icon"
                        ></i>
                      )}
                    </p>
                    <div className="props-box">
                      {propsArray &&
                        propsArray.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className="d-flex justify-content-between align-items-center mt-2"
                            >
                              <div className="inputs">
                                <input
                                  data-testid={`id-prop ${i}`}
                                  className="form-control id-input"
                                  placeholder="name"
                                  onChange={(e) => hadleChangeProps(e, i, "id")}
                                  value={item.id}
                                  disabled={
                                    type === "edit" &&
                                    maskPropertyValues(item.id, item.name)
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                              <div className="inputs">
                                <input
                                  data-testid={`name-prop ${i}`}
                                  className="form-control id-input"
                                  placeholder="value"
                                  value={item.name}
                                  onChange={(e) => {
                                    hadleChangeProps(e, i, "name");
                                  }}
                                  type={
                                    type === "edit" &&
                                    maskPropertyValues(item.id, item.name)
                                      ? "password"
                                      : "text"
                                  }
                                />
                              </div>

                              <div style={{ width: "80px", display: "flex" }}>
                                <i
                                  data-testid={`delete-prop ${i}`}
                                  // onClick={propsArray.length===1 ? null : () => deleteProps(i)}
                                  onClick={() => deleteProps(i)}
                                  // className={
                                  //   propsArray.length===1 ||(sourceType==='s3' && i<3)
                                  //     ? "bi bi-dash-circle disable"
                                  //     : "bi bi-dash-circle close-icon"
                                  // }
                                  className={"bi bi-dash-circle close-icon"}
                                ></i>
                                <i
                                  data-testid={`add-prop ${i}`}
                                  // onClick={() =>(sourceType==='s3' && i<2) ||i=== propsArray.length ?null: addProps()}

                                  // className={(sourceType==='s3' && i<3)|| i=== propsArray.length-1 ? "bi bi-plus-circle add-icon":"bi bi-plus-circle vis-hidden"}

                                  onClick={
                                    i === propsArray.length
                                      ? null
                                      : () => addProps()
                                  }
                                  className={
                                    i === propsArray.length - 1
                                      ? "bi bi-plus-circle add-icon"
                                      : "bi bi-plus-circle vis-hidden"
                                  }
                                ></i>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div> */}
                  <div className="mt-3 d-flex justify-content-end gap-2">
                    {actionDone ? (
                      <>
                        <button
                          className="buttonX"
                          data-testid={`data-submit`}
                          type="submit"
                        >
                          {type === 'edit'
                            ? 'Update Data Source'
                            : 'Create Data Source'}
                        </button>
                        <button
                          className="buttonX white"
                          type="button"
                          onClick={() =>
                            type === 'edit'
                              ? props.history.goBack()
                              : props.history.push(DATA_SOURCES_PATH)
                          }
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <ActivityLoader />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </CommonCard>
        )}
      </div>
    </Layout>
  );
}

const Types = ['AWS_GLUE'];
