import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import DeleteModal from '../../modals/Common/DeleteModal';
import routeConstant, {
  CLUSTER_DETAILS_PATH,
  CLUSTER_PATH,
} from '../../router/PathConst';
import ApiConstant from '../../utils/apiConstant';
import { deleteAPI, get } from '../../utils/apiMethods';

export default function ClusterMenu({ clusterData, onComplete, history }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [isDelete, setIsDelete] = useState(false);
  const [isConnectToClusterAPILoading, setIsConnectToClusterAPILoading] =
    useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteModal = () => {
    setAnchorEl(null);

    setIsDelete(!isDelete);
  };

  const deleteCluster = async () => {
    const response = await deleteAPI({
      url: ApiConstant.DELETE_CLUSTER(clusterData?.id),
      type: 'v2',
    });

    if (response.status === 200) {
      toast.success('Cluster deletion triggered.');
    } else {
      toast.warning(
        response?.response?.data?.message || 'Something went wrong.'
      );
    }

    onComplete();
  };

  const connectToClusterHandler = async () => {
    try {
      setIsConnectToClusterAPILoading(true);

      const URL = ApiConstant.GET_CONNECT_TO_CLUSTER(clusterData?.id);

      const response = await get({
        url: URL,
        type: 'v2',
      });

      if (response.status === 200 && response?.data?.jupyterhub_url) {
        window.open(response.data.jupyterhub_url, '_blank');

        setAnchorEl(null);
      }
    } catch (error) {
      console.log(error ?? 'Something went wrong');

      toast.warning(error?.response?.data?.message || 'Something went wrong.');
    } finally {
      setIsConnectToClusterAPILoading(false);
    }
  };

  return (
    <>
      <i
        className="bi bi-three-dots-vertical dots"
        data-testid="cluster-dots"
        style={{ cursor: 'pointer' }}
        onClick={handleClick}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={connectToClusterHandler}
          disabled={isConnectToClusterAPILoading}
        >
          {!isConnectToClusterAPILoading
            ? 'Connect to Cluster'
            : 'Connecting...'}
        </MenuItem>

        <MenuItem
          onClick={() =>
            history.push(`${CLUSTER_DETAILS_PATH}/${clusterData?.id}`)
          }
        >
          View Details
        </MenuItem>

        <MenuItem
          onClick={() =>
            history.push(`${routeConstant.QUERY_PROFILE(clusterData?.id)}`)
          }
        >
          View Query History
        </MenuItem>

        <MenuItem
          onClick={() =>
            history.push({
              pathname: `${CLUSTER_PATH}/${clusterData?.id}`,
              state: {
                id: clusterData?.id,
              },
            })
          }
        >
          Edit Cluster
        </MenuItem>

        <MenuItem onClick={handleDeleteModal}>Delete Cluster</MenuItem>
      </Menu>

      {isDelete && (
        <DeleteModal
          title={clusterData?.name}
          modalType="cluster"
          show={isDelete}
          handleClose={handleDeleteModal}
          onDelete={deleteCluster}
        />
      )}
    </>
  );
}
