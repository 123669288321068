import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
  materialLight,
  materialDark,
} from 'react-syntax-highlighter/dist/esm/styles/prism';
import ThemeContext from '../../themes/ThemeContext';

export default function AuditLogsModal({ dataObj, ...restProps }) {
  const { theme } = useContext(ThemeContext);

  const codeTheme = theme === 'dark' ? materialDark : materialLight;
  return (
    <Modal
      centered
      show={restProps.show}
      onHide={restProps.handleClose}
      size="xl"
    >
      <Modal.Header>
        <Modal.Title>{restProps.modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ maxHeight: '60vh', overflow: 'scroll' }}>
          <SyntaxHighlighter language="json" style={codeTheme}>
            {JSON.stringify(dataObj, null, 2)}
          </SyntaxHighlighter>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="buttonX white" onClick={restProps.handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
