import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
  oneLight,
  oneDark,
} from 'react-syntax-highlighter/dist/esm/styles/prism';
import { format } from 'sql-formatter';
import { useContext, useEffect } from 'react';
import ThemeContext from '../../../../themes/ThemeContext';

const SqlStatement = ({ onFormat, SQL_QUERY }) => {
  const { theme } = useContext(ThemeContext);

  const codeTheme = theme === 'dark' ? oneDark : oneLight;

  const formattedQuery = format(SQL_QUERY);

  useEffect(() => {
    onFormat(formattedQuery);
  }, [formattedQuery]);

  return (
    <SyntaxHighlighter language="sql" style={codeTheme}>
      {formattedQuery || ''}
    </SyntaxHighlighter>
  );
};

export default SqlStatement;
