import React, { memo } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoader = memo(
  ({
    height = 30,
    time = 1.3,
    count = 3,
    uniqueKeyPrefix = 'SkeletonLoader',
  }) => {
    const skeletonThemeProps = {
      height,
      baseColor: 'var(--clr-card-200)',
      highlightColor: 'var(--clr-card-300)',
    };

    return (
      <section>
        <SkeletonTheme {...skeletonThemeProps}>
          {Array.from({ length: count }, (_, index) => (
            <Skeleton
              key={`${uniqueKeyPrefix}-${index}`}
              duration={time + index * 0.005}
            />
          ))}
        </SkeletonTheme>
      </section>
    );
  }
);

export default SkeletonLoader;
