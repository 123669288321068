import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import routeConstant from '../../../../router/PathConst';

const tableCellStyle = {
  width: '50%',
  borderBottom: 'none',
};

export default class CloudResources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resourcesDetails: this.props.resourcesDetails,
    };
  }

  render() {
    const { resourcesDetails } = this.state;
    return (
      <div>
        <>
          <div className="mt-3">
            <h4 className="text_color_main">Cloud Formation</h4>

            <TableContainer>
              <Table
                className="tableX"
                sx={{
                  minWidth: 750,
                  borderCollapse: 'none',
                }}
                aria-labelledby="tableTitle"
              >
                <TableBody>
                  <TableRow>
                    <TableCell style={tableCellStyle}>Stack</TableCell>
                    <TableCell style={tableCellStyle}>
                      {resourcesDetails?.resource_selection.setup_stack_name
                        ? resourcesDetails?.resource_selection.setup_stack_name
                        : '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="mt-3">
            <h4 className="text_color_main">Role</h4>

            <TableContainer>
              <Table
                className="tableX"
                sx={{
                  minWidth: 750,
                  borderCollapse: 'none',
                }}
                aria-labelledby="tableTitle"
              >
                <TableBody>
                  <TableRow>
                    <TableCell style={tableCellStyle}>
                      EKS Cluster Admin Role
                    </TableCell>
                    <TableCell style={tableCellStyle}>
                      {resourcesDetails?.role_details?.eks_cluster_admin_role
                        ? resourcesDetails?.role_details?.eks_cluster_admin_role
                        : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={tableCellStyle}>
                      EKS Cluster Role
                    </TableCell>
                    <TableCell style={tableCellStyle}>
                      {resourcesDetails?.role_details?.eks_cluster_role
                        ? resourcesDetails?.role_details?.eks_cluster_role
                        : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={tableCellStyle}>EKS Node Role</TableCell>
                    <TableCell style={tableCellStyle}>
                      {resourcesDetails?.role_details?.eks_node_role
                        ? resourcesDetails?.role_details?.eks_node_role
                        : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={tableCellStyle}>
                      Datapelago Deployment Role
                    </TableCell>
                    <TableCell style={tableCellStyle}>
                      {resourcesDetails?.role_details?.deployment_access_role
                        ? resourcesDetails?.role_details?.deployment_access_role
                        : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={tableCellStyle}>
                      RDS Monitoring Role
                    </TableCell>
                    <TableCell style={tableCellStyle}>
                      {resourcesDetails?.role_details?.rds_node_monitoring_role
                        ? resourcesDetails?.role_details
                            ?.rds_node_monitoring_role
                        : '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="mt-3">
            <h4 className="text_color_main">Resource</h4>

            <TableContainer>
              <Table
                className="tableX"
                sx={{
                  minWidth: 750,
                  borderCollapse: 'separate',
                }}
                aria-labelledby="tableTitle"
              >
                <TableBody>
                  <TableRow>
                    <TableCell style={tableCellStyle}>EC2 Key Pair</TableCell>
                    <TableCell style={tableCellStyle}>
                      {resourcesDetails?.resource_details?.ssh_key_pair
                        ? resourcesDetails.resource_details.ssh_key_pair
                        : '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="mt-3">
            <h4 className="text_color_main">Logging</h4>

            {resourcesDetails?.resource_selection?.opensearch_choice ===
            'Create' ? (
              <TableContainer>
                <Table
                  className="tableX"
                  sx={{
                    minWidth: 750,
                    borderCollapse: 'none',
                  }}
                  aria-labelledby="tableTitle"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell style={tableCellStyle}>
                        OpenSearch Domain Name
                      </TableCell>
                      <TableCell style={tableCellStyle}>
                        {resourcesDetails?.resource_selection
                          ?.opensearch_domain_name
                          ? resourcesDetails.resource_selection
                              .opensearch_domain_name
                          : '-'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={tableCellStyle}>
                        OpenSearch Host Name
                      </TableCell>
                      <TableCell>
                        {resourcesDetails?.logging_configuration?.details
                          ?.os_domain_host
                          ? resourcesDetails.logging_configuration?.details
                              .os_domain_host
                          : '-'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <p className="mb-4">Logging is disabled.</p>
            )}
          </div>

          <div className="mt-3">
            <h4 className="text_color_main">Storage</h4>

            {resourcesDetails?.resource_selection?.queryprofile_choice ===
            'Create' ? (
              <TableContainer>
                <Table
                  className="tableX"
                  sx={{
                    minWidth: 750,
                    borderCollapse: 'separate',
                  }}
                  aria-labelledby="tableTitle"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell style={tableCellStyle}>Bucket</TableCell>
                      <TableCell style={tableCellStyle}>
                        {resourcesDetails?.resource_selection?.s3_bucket_name
                          ? resourcesDetails.resource_selection.s3_bucket_name
                          : '-'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <p className="mb-4">Storage is disabled.</p>
            )}
          </div>

          <div className="mt-3">
            <h4 className="text_color_main">Hive Metastore Service</h4>

            <TableContainer>
              <Table
                className="tableX"
                sx={{
                  minWidth: 750,
                  borderCollapse: 'separate',
                }}
                aria-labelledby="tableTitle"
              >
                <TableBody>
                  <TableRow>
                    <TableCell style={tableCellStyle}>Profile Used</TableCell>
                    <TableCell
                      style={tableCellStyle}
                      onClick={
                        resourcesDetails?.metastore_configuration
                          ?.cloud_config_id
                          ? () =>
                              this.props.history.push({
                                pathname: `${routeConstant.CLOUD_PROFILE_DETAILS(
                                  resourcesDetails?.metastore_configuration
                                    ?.cloud_config_id
                                )}`,
                                state: {
                                  id: routeConstant.CLOUD_PROFILE_DETAILS(
                                    resourcesDetails?.metastore_configuration
                                      ?.cloud_config_id
                                  ),
                                },
                              })
                          : () => {}
                      }
                    >
                      <span className="id">
                        {resourcesDetails?.metastore_configuration
                          ?.cloud_config_name
                          ? resourcesDetails.metastore_configuration
                              .cloud_config_name
                          : '-'}
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={tableCellStyle}>
                      Health Endpoint
                    </TableCell>
                    <TableCell
                      style={tableCellStyle}
                      onClick={() => {
                        if (
                          resourcesDetails?.metastore_configuration?.details
                            .health_endpoint
                        ) {
                          window.open(
                            resourcesDetails?.metastore_configuration?.details
                              .health_endpoint,
                            '_blank'
                          );
                        }
                      }}
                    >
                      <span className="id">
                        {resourcesDetails?.metastore_configuration?.details
                          .health_endpoint
                          ? resourcesDetails?.metastore_configuration?.details
                              .health_endpoint
                          : '-'}
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={tableCellStyle}>Logs Endpoint</TableCell>
                    <TableCell
                      style={tableCellStyle}
                      onClick={() => {
                        if (
                          resourcesDetails?.metastore_configuration?.details
                            .logging_endpoint
                        ) {
                          window.open(
                            resourcesDetails?.metastore_configuration?.details
                              .logging_endpoint,
                            '_blank'
                          );
                        }
                      }}
                    >
                      <span className="id">
                        {resourcesDetails?.metastore_configuration?.details
                          .logging_endpoint
                          ? resourcesDetails?.metastore_configuration?.details
                              .logging_endpoint
                          : '-'}
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={tableCellStyle}>
                      Service Endpoint
                    </TableCell>
                    <TableCell style={tableCellStyle}>
                      {resourcesDetails?.metastore_configuration?.details
                        ?.vpc_endpoint_service
                        ? resourcesDetails?.metastore_configuration?.details
                            ?.vpc_endpoint_service
                        : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={tableCellStyle}>Status</TableCell>
                    <TableCell style={tableCellStyle}>
                      {resourcesDetails?.metastore_configuration?.state
                        ? resourcesDetails?.metastore_configuration?.state
                        : '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="mt-3">
            <h4 className="text_color_main">Delta Lake Concurrency</h4>

            <TableContainer>
              <Table
                className="tableX"
                sx={{
                  minWidth: 750,
                  borderCollapse: 'separate',
                }}
                aria-labelledby="tableTitle"
              >
                <TableBody>
                  <TableRow>
                    <TableCell style={tableCellStyle}>Profile Used</TableCell>
                    <TableCell
                      style={tableCellStyle}
                      onClick={
                        resourcesDetails?.deltalake_configuration
                          ?.cloud_config_id
                          ? () =>
                              this.props.history.push({
                                pathname: `${routeConstant.CLOUD_PROFILE_DETAILS(
                                  resourcesDetails?.deltalake_configuration
                                    ?.cloud_config_id
                                )}`,
                                state: {
                                  id: routeConstant.CLOUD_PROFILE_DETAILS(
                                    resourcesDetails?.deltalake_configuration
                                      ?.cloud_config_id
                                  ),
                                },
                              })
                          : () => {}
                      }
                    >
                      <span className="id">
                        {resourcesDetails?.deltalake_configuration
                          ?.cloud_config_name
                          ? resourcesDetails.deltalake_configuration
                              .cloud_config_name
                          : '-'}
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={tableCellStyle}>
                      DynamoDB Endpoint
                    </TableCell>
                    <TableCell style={tableCellStyle}>
                      {resourcesDetails?.deltalake_configuration?.name
                        ? resourcesDetails?.deltalake_configuration?.name
                        : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={tableCellStyle}>Status</TableCell>
                    <TableCell style={tableCellStyle}>
                      {resourcesDetails?.deltalake_configuration?.state
                        ? resourcesDetails?.deltalake_configuration?.state
                        : '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      </div>
    );
  }
}
