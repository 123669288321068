import axios from 'axios';
import routeConstant from '../router/PathConst';
import { getFromLocalStorage } from './helperFunctions';

const axiosInstance = axios.create({
  baseURL:
    getFromLocalStorage('envConfig')?.REACT_APP_BASE_API_URL ||
    'http://localhost:8080',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  crossDomain: true,
  maxRedirects: 0,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    if (error.response) {
      const status = error?.response?.status;

      if (status === 401) {
        !getFromLocalStorage('envConfig')?.REACT_APP_BYPASS_LOGIN &&
          (window.location = '#' + routeConstant.LOGIN);
      }

      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
