import React, { useState } from 'react';
import ConfigContext, { initialConfigState } from './ConfigContext';

export default function ConfigProvider(props) {
  const [currentConfig, setCurrentConfig] = useState(
    initialConfigState.currentConfig
  );

  return (
    <ConfigContext.Provider value={{ currentConfig, setCurrentConfig }}>
      {props.children}
    </ConfigContext.Provider>
  );
}
