import React, { Component } from 'react';
import Layout from '../../../components/layout/SideBar/Layout';
import routeConstant from '../../../router/PathConst';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../../components/TabPanel';
import { get, post } from '../../../utils/apiMethods';
import ApiConstant from '../../../utils/apiConstant';
import {
  getReadableDate,
  deleteCloudProfileById,
  forceDeleteCloudProfileById,
} from '../../../utils/common';
import DeleteModal from '../../../modals/Common/DeleteModal';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import { FaCopy } from 'react-icons/fa';
import CommonCard from '../../../components/CommonCard/CommonCard';
import CloudResources from './CloudProfilesDetailTabs/CloudResources';
import { toast } from 'react-toastify';
import NormalModal from '../../../modals/Common/NormalModal';
import ViewMore from '../../../modals/Common/ViewMore';
import DependentCloudProfiles from './CloudProfilesDetailTabs/DependentCloudProfiles';
import DependentClusters from './CloudProfilesDetailTabs/DependentClusters';

export default class CloudProfilesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      value: 0,
      data: {},
      isData: false,
      isDelete: false,
      loading: true,
      isTenantCopy: false,
      isProfile: false,
      isAfterDeleteModalVisible: false,
      normalModalData: '',
      viewMore: false,
      viewMoreData: '',
      isCopy: false,
      dependentClusters: [],
      isDeletePermission: false,
      isForceDeleteAllowed: false,
    };
  }

  componentDidMount() {
    this.getProfilesDetails();
    this.updateBreadCrumb();
    this.getDependentClusters();
  }

  updateBreadCrumb = () => {
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.isProfile
    ) {
      this.setState({ isProfile: true });
    } else {
      this.setState({ isProfile: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.state !== prevProps.location.state) {
      this.setState({ loading: true, isData: false }, () => {
        this.getProfilesDetails();
      });
      this.updateBreadCrumb();
    }
  }

  getProfilesDetails = async () => {
    const id = this.props.match.params.id;

    const URL = `${ApiConstant.GET_CLOUD_PROFILE_DETAILS(id)}`;

    const response = await get({ url: URL });
    if (response && response !== undefined) {
      this.setState({ data: response, isData: true, loading: false }, () => {});
      this.checkDeletePermission(response?.id);
    } else {
      this.setState({ loading: false });
    }
  };

  handleTabs = (index) => {
    this.setState({ selectedTab: index });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  // Delete
  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  handleNormalModal = () => {
    this.setState({
      isAfterDeleteModalVisible: !this.state.isAfterDeleteModalVisible,
    });
  };

  deleteCloudProfile = async () => {
    this.setState({ normalModalData: '' });

    const id = this.props.match.params.id;

    const response = await deleteCloudProfileById(id);

    if (response.status === 200) {
      toast.success('Delete cloud profile triggered.');

      this.props.history.push(routeConstant.CLOUD_PROFILES);
    } else {
      if (response?.response?.status !== 409) {
        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }

      if (
        response?.response?.status === 409 &&
        response?.response?.data?.message
      ) {
        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }

      if (response?.response?.status === 409) {
        if (response?.response?.data?.clusters?.length > 0) {
          let clusterNames = '';

          await response?.response?.data?.clusters?.forEach(
            (cluster) =>
              (clusterNames = (
                <>
                  {clusterNames}
                  <li className="mb-0">{cluster.name}</li>
                </>
              ))
          );

          this.setState({
            normalModalData: (
              <>
                <h5 className="fw-bold">Clusters:</h5>
                <div
                  className="overflow-y-scroll"
                  style={{ maxHeight: '150px' }}
                >
                  <ul>{clusterNames}</ul>
                </div>
              </>
            ),
          });

          clusterNames = '';
        }

        if (response?.response?.data?.dependent_clusters) {
          let dependentCloudProfileNames = '';

          await Object.entries(
            response?.response?.data?.dependent_clusters
          ).forEach(
            ([cloudProfileId, clusterList]) =>
              (dependentCloudProfileNames = (
                <>
                  {dependentCloudProfileNames}
                  <li className="mb-0">
                    {
                      response?.response?.data?.cloud_profiles?.find(
                        (data) => data?.id === cloudProfileId
                      )?.name
                    }
                  </li>
                  <ul>
                    {clusterList?.map((cluster) => (
                      <li className="mb-0">{cluster.name}</li>
                    ))}
                  </ul>
                </>
              ))
          );

          this.setState({
            normalModalData: (
              <>
                {this.state.normalModalData}
                <h5 className="fw-bold">
                  Dependent Cloud Profiles and Clusters:
                </h5>
                <div
                  className="overflow-y-scroll"
                  style={{ maxHeight: '150px' }}
                >
                  <ul>{dependentCloudProfileNames}</ul>
                </div>
              </>
            ),
          });

          dependentCloudProfileNames = '';
        }

        if (
          response?.response?.data?.clusters?.length > 0 ||
          response?.response?.data?.dependent_clusters
        ) {
          this.handleNormalModal();
        }
      }

      this.handleDeleteModal();
    }
  };

  onForceDeleteProfile = async () => {
    this.setState({ normalModalData: '' });

    const id = this.props.match.params.id;

    const response = await forceDeleteCloudProfileById(id);

    if (response.status === 200) {
      toast.success('Delete cloud profile triggered.');

      this.props.history.push(routeConstant.CLOUD_PROFILES);
    } else {
      this.handleDeleteModal();

      if (response?.response?.status !== 409) {
        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }
    }
  };

  onCopy = (id) => {
    navigator.clipboard.writeText(id);
    this.setState({ isCopy: true }, () => {
      setTimeout(() => {
        this.setState({ isCopy: false });
      }, 1000);
    });
  };

  onTenantCopy = (id) => {
    navigator.clipboard.writeText(id);
    this.setState({ isTenantCopy: true }, () => {
      setTimeout(() => {
        this.setState({ isTenantCopy: false });
      }, 1000);
    });
  };

  openViewMore = (data) => {
    this.setState({ viewMoreData: data, viewMore: true });
  };

  closedViewMore = () => {
    this.setState({ viewMoreData: '', viewMore: false });
  };

  getDependentClusters = async () => {
    const id = this.props.match.params.id;

    const URL = `${ApiConstant.GET_DEPENDENT_CLUSTERS(id)}`;

    const response = await get({ url: URL, type: 'v2' });

    if (response?.status === 200) {
      this.setState({ dependentClusters: response?.data || [] });
    } else {
      this.setState({ dependentClusters: [] });
    }
  };

  checkDeletePermission = async (id) => {
    const response = await post({
      url: ApiConstant.POST_AUTH_CHECK,
      payload: {
        resource_id: id,
        resource_type: 'CLOUDCONFIG',
        OPERATION: 'delete',
      },
      type: 'v2',
    });

    if (response.status === 200) {
      if (response.data.is_allowed) {
        this.setState({ isDeletePermission: true });
      } else {
        this.setState({ isDeletePermission: false });
      }

      if (response.data.force_delete_allowed) {
        this.setState({ isForceDeleteAllowed: true });
      } else {
        this.setState({ isForceDeleteAllowed: false });
      }
    } else {
      this.setState({ isDeletePermission: false });
    }
  };

  render() {
    const {
      isTenantCopy,
      isDelete,
      value,
      data,
      isData,
      loading,
      isProfile,
      isAfterDeleteModalVisible,
      normalModalData,
      viewMore,
      viewMoreData,
      isCopy,
      dependentClusters,
      isDeletePermission,
      isForceDeleteAllowed,
    } = this.state;

    let crumbs = [];

    if (isProfile) {
      crumbs = [
        {
          title: 'My profile',
          path: routeConstant.CLOUD_PROFILE_DETAILS(''),
          active: true,
        },
      ];
    } else {
      crumbs = [
        {
          title: 'Cloud Profile',
          path: routeConstant.CLOUD_PROFILES,
          active: false,
        },
        {
          title: 'Cloud Profile Details',
          path: routeConstant.CLOUD_PROFILE_DETAILS(''),
          active: true,
        },
      ];
    }

    return (
      <Layout crumbs={crumbs} {...this.props}>
        {loading && !isData ? (
          <div className="mt-3">
            <SkeletonLoader />
          </div>
        ) : !loading && isData ? (
          <div className="user-details">
            <div className="details-section">
              <div className="details-section-1">
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Name:</h6>
                    <h6 data-testid="user-name" className="details-data-value">
                      {data ? data.name : '-'}
                    </h6>
                  </div>

                  <div className="details-data-row">
                    <h6 className="details-data-label">Cloud Profile ID:</h6>
                    <h6 className="details-data-value copy-id">
                      <span
                        title="Copy to Clipboard"
                        onClick={() => this.onCopy(data.id)}
                      >
                        {data && data.id}
                      </span>

                      {isCopy ? (
                        <span
                          style={{
                            marginLeft: '10px',
                            color: 'green',
                            fontSize: '12px',
                          }}
                        >
                          Copied
                        </span>
                      ) : (
                        <span
                          data-testid="copyIcon"
                          title="Copy to Clipboard"
                          className="copy-icon"
                          onClick={() => this.onCopy(data.id)}
                        >
                          {' '}
                          <FaCopy />
                        </span>
                      )}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Status:</h6>
                    <h6>
                      <span
                        className={`text-capitalize ${
                          data.state === 'Pending Completion' ||
                          data.state === 'pending completion'
                            ? 'pending'
                            : data.state === 'Active' || data.state === 'active'
                              ? 'available'
                              : data.state === 'Deleted' ||
                                  data.state === 'deleted'
                                ? 'deleted'
                                : 'disable'
                        }`}
                      >
                        {data.state ? data.state : 'Inactive'}
                      </span>
                      <br />
                      {data.error !== '' && data.error !== undefined && (
                        <span
                          className="view-more text-decoration-underline"
                          onClick={() => this.openViewMore(data)}
                        >
                          Show error message
                        </span>
                      )}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Created:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(
                        data && data?.metadata?.created_timestamp
                      )}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Updated:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(
                        data && data?.metadata?.modified_timestamp
                      )}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Cloud Provider:</h6>
                    <h6 className="details-data-value text-wrap">
                      {data ? data.cloud_provider : '-'}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Account ID:</h6>
                    <h6 className="details-data-value copy-id">
                      <span
                        title="Copy to Clipboard"
                        onClick={() =>
                          this.onTenantCopy(data.details.account_id)
                        }
                      >
                        {data.details && data.details.account_id
                          ? data.details.account_id
                          : '-'}
                      </span>

                      {isTenantCopy ? (
                        <span
                          style={{
                            marginLeft: '10px',
                            color: 'green',
                            fontSize: '12px',
                          }}
                        >
                          Copied
                        </span>
                      ) : (
                        <span
                          data-testid="tenantIcon"
                          title="Copy to Clipboard"
                          className="copy-icon"
                          onClick={() =>
                            this.onTenantCopy(data.details.account_id)
                          }
                        >
                          <FaCopy />
                        </span>
                      )}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Region:</h6>
                    <h6 className="details-data-value text-wrap">
                      {data && data.details ? data.details.region : '-'}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="details-section-2">
                <div className="details-button-group">
                  {data.state === 'Pending Completion' ? (
                    <button
                      className="buttonX details-button"
                      onClick={() =>
                        this.props.history.push({
                          pathname: `${routeConstant.CLOUD_PROFILE_ADD_EDIT(
                            data?.id
                          )}`,
                          state: {
                            id: data.id,
                          },
                        })
                      }
                    >
                      <i className="bi bi-pencil-square me-2" />
                      Prepare Profile
                    </button>
                  ) : null}

                  {data.state === 'Pending Completion' ? (
                    <button
                      className="buttonX details-button"
                      onClick={() =>
                        this.props.history.push({
                          pathname: routeConstant.CLOUD_PROFILE_ACTIVATE(
                            data?.id
                          ),
                          state: {
                            id: data.id,
                          },
                        })
                      }
                    >
                      <i className="bi bi-check-circle me-2" />
                      Activate Profile
                    </button>
                  ) : null}

                  <button
                    className="buttonX white details-button"
                    onClick={this.handleDeleteModal}
                    disabled={
                      data?.state === 'Deleting Resources' ||
                      data?.state === 'Creating Resources' ||
                      !isDeletePermission
                    }
                  >
                    <i className="bi bi-trash3 me-2" />
                    Delete Profile
                  </button>
                  {/* <button
                    className="buttonX white details-button"
                    onClick={this.toggleActiveInactiveModal}
                  >
                    <i
                      className={`bi ${
                        data.userStatus !== 0
                          ? "bi-person-check"
                          : "bi-person-dash"
                      } me-2`}
                    />
                    {data.userStatus !== 0 ? "Activate User" : "Disable User"}
                  </button> */}
                </div>
              </div>
            </div>

            <CommonCard className="mt-4">
              <Tabs
                variant="fullWidth"
                scrollButtons="auto"
                allowScrollButtonsMobile={true}
                value={value}
                onChange={this.handleChange}
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  className={`tabX-tab-head ${0 === value ? 'active' : ''}`}
                  label="Cloud Resources"
                />
                <Tab
                  className={`tabX-tab-head ${1 === value ? 'active' : ''}`}
                  label="Cluster"
                />
                <Tab
                  className={`tabX-tab-head ${2 === value ? 'active' : ''}`}
                  label="Dependent Cloud Profile"
                />
              </Tabs>

              <SwipeableViews
                axis={useTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={this.handleChangeIndex}
              >
                <TabPanel
                  className="tabX-tab-body"
                  value={value}
                  index={0}
                  dir={useTheme.direction}
                >
                  <CloudResources
                    resourcesDetails={data}
                    history={this.props.history}
                  />
                </TabPanel>

                <TabPanel
                  className="tabX-tab-body"
                  value={value}
                  index={1}
                  dir={useTheme.direction}
                >
                  <DependentClusters
                    dependentClusters={dependentClusters}
                    history={this.props.history}
                  />
                </TabPanel>

                <TabPanel
                  className="tabX-tab-body"
                  value={value}
                  index={2}
                  dir={useTheme.direction}
                >
                  <DependentCloudProfiles
                    dependentCloudProfiles={data?.dependent_cloud_profiles}
                    history={this.props.history}
                  />
                </TabPanel>
              </SwipeableViews>
            </CommonCard>
          </div>
        ) : (
          <h4 className="text_color_main text-center no-data-msg mt-2">
            No Data Found
          </h4>
        )}

        {isDelete && (
          <DeleteModal
            title={data.name}
            modalType="cloud profile"
            show={isDelete}
            handleClose={this.handleDeleteModal}
            onDelete={this.deleteCloudProfile}
            onForceDelete={this.onForceDeleteProfile}
            isForceDeleteAllowed={isForceDeleteAllowed}
            failedToDeleteResource={[
              ...(data?.failed_to_delete || []),
              ...(data?.owner_metastore_configuration?.details
                ?.failed_to_delete || []),
              ...(data?.owner_deltalake_configuration?.details
                ?.failed_to_delete || []),
            ]}
          />
        )}

        {isAfterDeleteModalVisible && (
          <NormalModal
            show={isAfterDeleteModalVisible}
            handleClose={this.handleNormalModal}
            title="Delete failed, Resources using cloud profile."
            description={normalModalData}
          />
        )}

        {viewMore && (
          <ViewMore
            show={viewMore}
            handleClose={this.closedViewMore}
            data={viewMoreData}
            fetchKey="error"
            title="Failure reason"
          />
        )}
      </Layout>
    );
  }
}
