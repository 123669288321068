import CustomEdge from './CustomEdge';
import CustomNodeComponent from './CustomNodeComponent';

export const nodeTypes = {
  customNode: CustomNodeComponent,
};

export const edgeTypes = {
  'custom-edge': CustomEdge,
};
