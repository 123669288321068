import React, { Component } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import routeConstant, {
  CLUSTER_DETAILS_PATH,
  CLUSTER_PATH,
  CREATE_ROLES_PATH,
  CREATE_TENANT_PATH,
  CREATE_USER_PATH,
  DATA_SOURCES_PATH,
  ROLES_PATH,
  ROLE_DETAILS_PATH,
  TENANT_PATH,
  USER_DETAILS_PATH,
  USER_PATH,
  DATA_SOURCES_DETAILS_PATH,
  TENANT_DETAILS_PATH,
} from './PathConst';

import Dashboard from '../pages/dashboard/Dashboard';
import Cluster from '../pages/cluster/Cluster';
import ClusterDetails from '../pages/cluster/ClusterDetails';
import ClusterAddEdit from '../pages/cluster/ClustersAddEdit';
import DataSources from '../pages/administration/DataSources/DataSources';
import DataSourceDetails from '../pages/administration/DataSources/DataSourceDetails';
import DataSourceAddEdit from '../pages/administration/DataSources/DataSourceAddEdit';
import JobsDetails from '../pages/Jobs/JobsDetails';
import LoginPage from '../pages/authentication/Login';
import ForgotPassword from '../pages/authentication/ForgotPassword';
import ResetPassword from '../pages/authentication/ResetPassword';
import NotFound from '../pages/not_found/NotFound';
import QueryProfile from '../pages/cluster/query_profile/QueryProfile';
import QueryProfileDetailsNewTabs from '../pages/cluster/query_profile/QueryProfileDetailsNewTabs';
import Roles from '../pages/administration/roles/Roles';
import RoleDetails from '../pages/administration/roles/RoleDetails';
import CreateRole from '../pages/administration/roles/CreateRole';
import Tenant from '../pages/administration/tenant/Tenant';
import TenantDetails from '../pages/administration/tenant/TenantDetails';
import AddEditTenant from '../pages/administration/tenant/AddEditTenant';
import Users from '../pages/administration/users/Users';
import UserDetails from '../pages/administration/users/UserDetails';
import CreateUserNew from '../pages/administration/users/CreateUserNew';
import IdentityProvider from '../pages/administration/identity_provider/IdentityProvider';
import UserGroups from '../pages/administration/user_groups/UserGroups';
import UserGroupAddEdit from '../pages/administration/user_groups/UserGroupAddEdit';
import UserGroupDetails from '../pages/administration/user_groups/UserGroupDetails';
import CloudProfiles from '../pages/administration/cloud_profiles/CloudProfiles';
import CloudProfilesDetails from '../pages/administration/cloud_profiles/CloudProfilesDetails';
import CreateCloudProfileNew from '../pages/administration/cloud_profiles/CreateCloudProfileNew';
import CloudProfileActivate from '../pages/administration/cloud_profiles/CloudProfileActivate';
import AuditLogs from '../pages/administration/audit-logs/AuditLogs';
import { getFromLocalStorage } from '../utils/helperFunctions';

const PrivateRoute = ({ component: Component, isBypassLogin, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isBypassLogin ? (
        <Component {...props} />
      ) : (
        <Redirect to={routeConstant.DASHBOARD} />
      )
    }
  />
);

export default class RouterMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBypassLogin: getFromLocalStorage('envConfig')?.REACT_APP_BYPASS_LOGIN,
    };
  }

  render() {
    const { isBypassLogin } = this.state;

    return (
      <HashRouter>
        <Switch>
          <PrivateRoute
            exact
            path={routeConstant.INDEX}
            component={LoginPage}
            isBypassLogin={isBypassLogin}
          />
          <PrivateRoute
            exact
            path={routeConstant.LOGIN}
            component={LoginPage}
            isBypassLogin={isBypassLogin}
          />
          <Route
            exact
            path={routeConstant.FORGOT_PASSWORD}
            component={ForgotPassword}
          />
          <Route
            exact
            path={routeConstant.RESET_PASSWORD}
            component={ResetPassword}
          />
          <Route exact path={routeConstant.DASHBOARD} component={Dashboard} />
          <Route exact path={CLUSTER_PATH} component={Cluster} />
          <Route
            exact
            path={`${CLUSTER_PATH}/:type`}
            component={ClusterAddEdit}
          />
          <Route
            exact
            path={`${CLUSTER_DETAILS_PATH}/:id`}
            component={ClusterDetails}
          />

          <Route
            exact
            path={`${routeConstant.CLUSTER_JOB_DETAILS(':clusterId', ':id')}`}
            component={JobsDetails}
          />

          <Route
            exact
            path={`${routeConstant.QUERY_PROFILE(':clusterId')}`}
            component={QueryProfile}
          />

          <Route
            exact
            path={`${routeConstant.QUERY_PROFILE_DETAILS(
              ':clusterId',
              ':queryProfileId'
            )}`}
            component={QueryProfileDetailsNewTabs}
          />

          <Route exact path={TENANT_PATH} component={Tenant} />
          <Route
            exact
            path={`${TENANT_DETAILS_PATH}/:id`}
            component={TenantDetails}
          />
          <Route
            exact
            path={`${CREATE_TENANT_PATH}/:type`}
            component={AddEditTenant}
          />
          <Route exact path={ROLES_PATH} component={Roles} />
          <Route
            exact
            path={`${CREATE_ROLES_PATH}/:type`}
            component={CreateRole}
          />
          <Route
            exact
            path={`${ROLE_DETAILS_PATH}/:id`}
            component={RoleDetails}
          />
          <Route exact path={USER_PATH} component={Users} />
          <Route
            exact
            path={`${CREATE_USER_PATH}/:type`}
            component={CreateUserNew}
          />
          <Route
            exact
            path={`${USER_DETAILS_PATH}/:id`}
            component={UserDetails}
          />
          <Route exact path={DATA_SOURCES_PATH} component={DataSources} />
          <Route
            exact
            path={`${DATA_SOURCES_PATH}/:type`}
            component={DataSourceAddEdit}
          />
          {/* <Route
              exact
              path={`${JOBS_PATH}/:type`}
              component={EditJob}
            /> */}
          <Route
            exact
            path={DATA_SOURCES_DETAILS_PATH}
            component={DataSourceDetails}
          />
          <Route
            exact
            path={`${DATA_SOURCES_DETAILS_PATH}/:id`}
            component={DataSourceDetails}
          />
          <Route
            exact
            path={routeConstant.IDENTITY_PROVIDER}
            component={IdentityProvider}
          />

          <Route
            exact
            path={routeConstant.USER_GROUPS}
            component={UserGroups}
          />
          <Route
            exact
            path={`${routeConstant.USER_GROUP_ADD_EDIT(':type')}`}
            component={UserGroupAddEdit}
          />
          <Route
            exact
            path={`${routeConstant.USER_GROUP_DETAILS(':id')}`}
            component={UserGroupDetails}
          />

          <Route
            exact
            path={routeConstant.CLOUD_PROFILES}
            component={CloudProfiles}
          />
          <Route
            exact
            path={`${routeConstant.CLOUD_PROFILE_DETAILS(':id')}`}
            component={CloudProfilesDetails}
          />

          <Route
            exact
            path={`${routeConstant.CLOUD_PROFILE_ADD_EDIT(':type')}`}
            component={CreateCloudProfileNew}
          />

          <Route
            exact
            path={`${routeConstant.CLOUD_PROFILE_ACTIVATE(':id')}`}
            component={CloudProfileActivate}
          />

          <Route exact path={routeConstant.AUDIT_LOGS} component={AuditLogs} />

          <Route exact path="*" component={NotFound} />
        </Switch>
      </HashRouter>
    );
  }
}
