import React, { Component } from 'react';
import Layout from '../../../components/layout/SideBar/Layout';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
  getComparator,
  stableSort,
} from '../../../components/TableComponent/TableComponent';
import { EnhancedTableHead } from '../../../components/TableComponent/TableWithSelection';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import {
  CREATE_TENANT_PATH,
  TENANT_DETAILS_PATH,
  TENANT_PATH,
} from '../../../router/PathConst';
import { GET_TENANT } from '../../../utils/apiConstant';
import { getReadableDate } from '../../../utils/common';
import { get } from '../../../utils/apiMethods';
import { FaCopy } from 'react-icons/fa';
import CommonCard from '../../../components/CommonCard/CommonCard';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'value',
    numeric: false,
    disablePadding: false,
    label: 'Value',
  },
];

export default class TenantDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      page: 0,
      rowsPerPage: 5,
      properties: [],
      loading: true,
      isDone: false,
      isData: false,
      data: {},
      isCopy: false,
      isProfile: false,
    };
  }
  componentDidMount() {
    this.getTenantDetails();

    if (
      this.props.location.state !== undefined &&
      this.props.location.state.isProfile
    ) {
      this.setState({ isProfile: true });
    } else {
      this.setState({ isProfile: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.state !== prevProps.location.state) {
      this.setState({ loading: true, isData: false }, () => {
        this.getTenantDetails();
      });
    }
  }

  getTenantDetails = async () => {
    const id = this.props.match.params.id;

    const URL = `${GET_TENANT}/${id}`;
    const response = await get({ url: URL });

    if (response && response !== undefined) {
      this.setState({ data: response, loading: false, isData: true });
    } else {
      this.setState({ loading: false });
    }
  };

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  handleSelectAllClick = (event) => {
    const { properties } = this.state;
    if (event.target.checked) {
      const newSelecteds = properties.map((n) => n.name);

      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, name) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  onCopy = (id) => {
    navigator.clipboard.writeText(id);
    this.setState({ isCopy: true }, () => {
      setTimeout(() => {
        this.setState({ isCopy: false });
      }, 1000);
    });
  };

  render() {
    // const data = {
    //   id: "33313102-969a-49e1-94b0-44731eef9cf1",
    //   name: "dev-test",
    //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
    //   created: "Feb 1, 2022, 4:30 PM",
    //   updated: "June 1, 2022, 4:30 PM",
    //   status: "Pending",
    // };

    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      properties,
      page,
      isDone,
      loading,
      data,
      isProfile,
      isData,
      isCopy,
    } = this.state;

    let crumbs = [];

    if (isProfile) {
      crumbs = [
        {
          title: 'Account Settings',
          path: TENANT_DETAILS_PATH,
          active: true,
        },
      ];
    } else {
      crumbs = [
        {
          title: 'Tenant',
          path: TENANT_PATH,
          active: false,
        },
        {
          title: 'Tenant Details',
          path: TENANT_DETAILS_PATH,
          active: true,
        },
      ];
    }

    return (
      <Layout crumbs={crumbs} {...this.props}>
        {loading && !isData ? (
          <div>
            <SkeletonLoader />
          </div>
        ) : !loading && isData ? (
          <div className="tenant-details">
            <div className="details-section">
              <div className="details-section-1">
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Name:</h6>
                    <h6 className="details-data-value">
                      {data.name ? data.name : '-'}
                    </h6>
                  </div>
                  <div className="details-data-row copy-id">
                    <h6 className="details-data-label">
                      {isProfile ? 'Account ID' : 'Tenant ID'}:
                    </h6>
                    <h6 className="details-data-value">
                      <span
                        title="Copy to Clipboard"
                        onClick={() => this.onCopy(data.id)}
                      >
                        {data.id ? data.id : '-'}
                      </span>
                      {isCopy ? (
                        <span
                          style={{
                            marginLeft: '10px',
                            color: 'green',
                            fontSize: '12px',
                          }}
                        >
                          Copied
                        </span>
                      ) : (
                        <span
                          data-testid="copyIcon"
                          title="Copy to Clipboard"
                          className="copy-icon"
                          onClick={() => this.onCopy(data.id)}
                        >
                          {' '}
                          <FaCopy />
                        </span>
                      )}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Created:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(data && data.created_timestamp)}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Updated:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(data && data.modified_timestamp)}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Status:</h6>
                    <h6
                      className={
                        data.status === 'Pending' || data.status === 'pending'
                          ? 'pending details-data-value'
                          : data.status === 'Available' ||
                              data.status === 'available'
                            ? 'available details-data-value'
                            : 'disable'
                      }
                    >
                      {data && data.status}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="details-section-2">
                <div className="details-button-group">
                  {!isProfile ? (
                    <button
                      className="buttonX details-button"
                      onClick={() =>
                        this.props.history.push({
                          pathname: `${CREATE_TENANT_PATH}/${data.id}`,
                          state: {
                            id: data.id,
                          },
                        })
                      }
                    >
                      <i className="bi bi-pencil-square mr-1" />
                      Edit Tenant
                    </button>
                  ) : null}

                  {!isProfile ? (
                    <button className="buttonX white details-button" disabled>
                      <i className="bi bi-trash3" />
                      Delete Tenant
                    </button>
                  ) : null}
                </div>
              </div>
            </div>

            <CommonCard className="mt-4">
              <div className="text-left">
                <h4 className="text_color_main">Properties</h4>
                <hr className="text_color_main" />
              </div>

              {/* <div className="text-right">
                <button className="delete-btn">
                  <i className="bi bi-trash3"/>Delete Properties
                </button>
              </div> */}

              <div className="desktop-table mt-2">
                <TableContainer>
                  <Table
                    className="tableX"
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={this.handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={properties.length}
                      headCells={headCells}
                    />
                    {isDone && properties && properties.length > 0 ? (
                      <TableBody className="table Table">
                        {stableSort(properties, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const isItemSelected = this.isSelected(row.name);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={index}
                                selected={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  {/*<Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                    onClick={(event) =>
                                      this.handleClick(event, row.name)
                                    }
                                  /> */}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  className="id"
                                  // onClick={() =>
                                  //   this.props.history.push(
                                  //     `${JOBS_DETAIL_PATH}/${row.id}`,
                                  //     row
                                  //   )
                                  // }
                                >
                                  {row.name}
                                </TableCell>
                                <TableCell align="center">
                                  {row.value}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    ) : null}
                  </Table>
                </TableContainer>
                {loading ? (
                  <div className="mt-3">
                    <SkeletonLoader />
                  </div>
                ) : isDone && properties && properties.length > 0 ? (
                  <TablePagination
                    className="table-pagination"
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={properties.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />
                ) : !loading && properties && properties.length === 0 ? (
                  <p className="text_color text-center no-data-msg mt-2">
                    No Data Found
                  </p>
                ) : null}
              </div>
            </CommonCard>

            <div className="mobile-table">
              {properties && properties.length > 0 ? (
                properties.map((data, index) => {
                  return (
                    <div className="mob-main">
                      <div className="mob-rows">
                        <div className="keys">Name</div>
                        <div className="values">{data.id}</div>
                      </div>
                      <div className="mob-rows">
                        <div className="keys">Value</div>
                        <div className="values">
                          {data.name}
                          {/* {data.name.length > 10 ? (
                              <span className="p-0">
                                {data.name.slice(0, 10)}{" "}
                                <span
                                  onClick={() => this.openedViewMore(data)}
                                  className="view-more"
                                  // data-testid={`viewMore ${index}`}
                                >
                                  ...View More
                                </span>
                              </span>
                            ) : (
                              data.name
                            )} */}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h4 className="text_color_main text-center no-data-msg mt-2">
                  No Properties found
                </h4>
              )}
            </div>
          </div>
        ) : (
          <h4 className="text_color_main text-center no-data-msg mt-2">
            No Data Found
          </h4>
        )}
      </Layout>
    );
  }
}
