import React from 'react';
import { Modal } from 'react-bootstrap';
import SkeletonLoader from '../../components/Loader/SkeletonLoader';

export default function ModalLoader({ show, handleClose }) {
  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body className="text-center">
        <SkeletonLoader />
      </Modal.Body>
    </Modal>
  );
}
