import React, { Component } from 'react';

import CommonCard from '../../components/CommonCard/CommonCard';
import PieNew from '../../components/PieChart/PieNew';
import CloudProfilesPie from '../../components/PieChart/CloudProfilesPie';
import Layout from '../../components/layout/SideBar/Layout';
import { DASHBOARD_PATH } from '../../router/PathConst';
import { get } from '../../utils/apiMethods';
import ApiConstant from '../../utils/apiConstant';
import SkeletonLoader from '../../components/Loader/SkeletonLoader';
import { getStatusCounts } from '../../utils/common';

import './dashboard.css';

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      isUserDone: false,
      roleList: [],
      isRoleDone: false,
      dataSourceList: [],
      isDataDone: false,
      userGroupList: [],
      isUserGroupDone: false,
      clusterList: [],
      isClusterDone: false,
      cloudProfilesList: [],
      isCloudProfilesDone: false,
      loading: true,
      notifications: [],
      notificationsRef: [],
      selectedNotifications: [],
      isNotificationDone: false,
      notificationDetails: {},
    };
    this._isMounted = false;
  }
  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      await this.getRoles();
      await this.getUsers();
      await this.getDataSources();
      await this.getUserGroups();
      await this.getClusters();
      await this.getCloudProfiles();
    }
  }

  /// Clusters
  getClusters = async () => {
    const response = await get({ url: ApiConstant.GET_CLUSTERS });

    this.setState({
      clusterList: response ?? [],
      isClusterDone: true,
    });
  };

  /// Cloud Profiles
  getCloudProfiles = async () => {
    const response = await get({ url: ApiConstant.GET_CLOUD_PROFILES });

    this.setState({
      cloudProfilesList: response ?? [],
      isCloudProfilesDone: true,
    });
  };

  /// Users
  getUsers = async () => {
    const response = await get({ url: ApiConstant.GET_USERS });

    const newResponse = await response?.map((user) => {
      return {
        ...user,
        status: user.userStatus === -1 ? 'Inactive' : 'Active',
      };
    });

    this.setState({
      userList: newResponse ?? [],
      isUserDone: true,
    });
  };

  /// Get Roles
  getRoles = async () => {
    const response = await get({ url: ApiConstant.GET_ROLES });

    if (response) {
      const newArray = response
        .filter((data) => !data.name.startsWith('SHADOW'))
        .sort((a, b) => {
          if (a.is_system_role && !b.is_system_role) {
            return -1;
          }
          if (!a.is_system_role && b.is_system_role) {
            return 1;
          }
          return 0;
        });

      this.setState({
        roleList: newArray,
        isRoleDone: true,
      });
    } else {
      this.setState({
        roleList: [],
        isRoleDone: true,
      });
    }
  };

  /// Data source
  getDataSources = async () => {
    const response = await get({ url: ApiConstant.GET_DATA_SOURCES });

    this.setState({
      dataSourceList: response ?? [],
      isDataDone: true,
    });
  };

  /// User Groups
  getUserGroups = async () => {
    const response = await get({
      url: ApiConstant.GET_USER_GROUPS,
    });

    this.setState({
      userGroupList: response?.data ?? [],
      isUserGroupDone: true,
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      userList,
      isUserDone,
      roleList,
      isRoleDone,
      dataSourceList,
      isDataDone,
      userGroupList,
      isUserGroupDone,
      clusterList,
      isClusterDone,
      cloudProfilesList,
      isCloudProfilesDone,
    } = this.state;

    return (
      <Layout crumbs={crumbs} {...this.props}>
        <div className="dashboard-layout">
          <CommonCard className="dp-cluster-card1">
            <h5 className="card-heading">Clusters</h5>

            {isClusterDone && clusterList && clusterList.length > 0 ? (
              <PieNew clusterData={clusterList} />
            ) : !isClusterDone ? (
              <div className="mt-5">
                <SkeletonLoader />
              </div>
            ) : (
              <p className="text_color_main">No Clusters Found</p>
            )}
          </CommonCard>

          <CommonCard className="dp-jobs-card1">
            <h5 className="card-heading">Cloud Profiles</h5>

            {isCloudProfilesDone ? (
              <CloudProfilesPie cloudProfilesData={cloudProfilesList} />
            ) : (
              <div className="mt-5">
                <SkeletonLoader />
              </div>
            )}
          </CommonCard>

          <CommonCard>
            <h5 className="card-heading">Administration</h5>
            <div className="dp-admin-prop-cont">
              <div className="dp-table-cont">
                {!isUserDone ? (
                  <SkeletonLoader />
                ) : (
                  <div className="dp-prop-table">
                    <table>
                      <tbody>
                        <tr>
                          <th className="admin-threads" colSpan="2">
                            Users
                          </th>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Total</td>
                          <td
                            data-testid="user-counts"
                            className="text-right admin-td"
                          >
                            {userList && userList.length
                              ? userList.length
                              : '0'}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Active</td>
                          <td className="text-right admin-td">
                            {userList && userList.length
                              ? getStatusCounts(userList, 'status', 'Active')
                              : '0'}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Inactive</td>
                          <td className="text-right admin-td">
                            {userList && userList.length
                              ? getStatusCounts(userList, 'status', 'Inactive')
                              : '0'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className="dp-table-cont">
                {!isRoleDone ? (
                  <SkeletonLoader />
                ) : (
                  <div className="dp-prop-table">
                    <table>
                      <tbody>
                        <tr>
                          <th className="admin-threads" colSpan="2">
                            Roles
                          </th>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Roles</td>
                          <td
                            data-testid="roles-counts"
                            className="text-right admin-td"
                          >
                            {roleList && roleList.length
                              ? roleList.length
                              : '0'}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Active</td>
                          <td className="text-right admin-td">
                            {roleList && roleList.length
                              ? getStatusCounts(roleList, 'status', 'active')
                              : '0'}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Inactive</td>
                          <td className="text-right admin-td">
                            {' '}
                            {roleList && roleList.length
                              ? getStatusCounts(roleList, 'status', 'inactive')
                              : '0'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className="dp-table-cont">
                {!isDataDone ? (
                  <SkeletonLoader />
                ) : (
                  <div className="dp-prop-table">
                    <table>
                      <tbody>
                        <tr>
                          <th className="admin-threads" colSpan="2">
                            Data Sources
                          </th>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Total</td>
                          <td
                            data-testid="data-source-counts"
                            className="text-right admin-td"
                          >
                            {dataSourceList && dataSourceList.length
                              ? dataSourceList.length
                              : '0'}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Active</td>
                          <td className="text-right admin-td">
                            {dataSourceList && dataSourceList.length
                              ? dataSourceList.length
                              : '0'}
                          </td>
                        </tr>
                        {/* <tr>
                                <td className="text-left">Inactive</td>
                                <td className="text-right">0</td>
                              </tr> */}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className="dp-table-cont">
                {!isUserGroupDone ? (
                  <SkeletonLoader />
                ) : (
                  <div className="dp-prop-table">
                    <table>
                      <tbody>
                        <tr>
                          <th className="admin-threads" colSpan="2">
                            User Groups
                          </th>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Total</td>
                          <td
                            data-testid="data-source-counts"
                            className="text-right admin-td"
                          >
                            {userGroupList && userGroupList.length
                              ? userGroupList.length
                              : '0'}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left admin-td">Active</td>
                          <td className="text-right admin-td">
                            {userGroupList && userGroupList.length
                              ? userGroupList.length
                              : '0'}
                          </td>
                        </tr>
                        {/* <tr>
                                <td className="text-left">Inactive</td>
                                <td className="text-right">0</td>
                              </tr> */}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </CommonCard>
        </div>
      </Layout>
    );
  }
}

const crumbs = [
  {
    title: 'Dashboard',
    path: DASHBOARD_PATH,
    active: true,
  },
];
