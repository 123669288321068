import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CommonCard from '../../../components/CommonCard/CommonCard';
import Layout from '../../../components/layout/SideBar/Layout';
import * as Yup from 'yup';
import 'yup-phone';
import {
  CREATE_ROLES_PATH,
  ROLES_PATH,
  ROLE_DETAILS_PATH,
} from '../../../router/PathConst';
import { get, post, put } from '../../../utils/apiMethods';
import ApiConstant, {
  GET_ROLES_UUID,
  UPDATE_ROLE,
} from '../../../utils/apiConstant';
import { useFormik } from 'formik';
import { createMessage } from '../../../utils/common';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import ActivityLoader from '../../../components/Loader/ActivityLoader';

import './Roles.scss';

export default function CreateRole(props) {
  const [type, setType] = useState('add');
  const [data, setData] = useState({});
  const [jsonData, setJsonData] = useState([]);
  const [isFile, setIsFile] = useState(false);
  const [status, setStatus] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionDone, setActionDone] = useState(true);

  const validation = Yup.object().shape({
    name: Yup.string().required('Please enter Role Name'),
    // status: Yup.string().required("Please select status"),
  });

  useEffect(() => {
    if (props.match.params.type === 'create') {
      setType(props.match.params.type);
    } else {
      setType('edit');
      setLoading(true);
    }
  }, [props.match.params.type]);

  useEffect(() => {
    const getRoleData = async () => {
      const id = props.match.params.type;
      const URL = `${GET_ROLES_UUID}/${id}`;
      const response = await get({ url: URL });
      if (response && response !== undefined) {
        setData(response);
        setStatus(response.status);
        setLoading(false);
      }
    };
    if (props.match.params.type !== 'create') {
      getRoleData();
    }
  }, [props.match.params.type]);

  const formik = useFormik({
    initialValues: {
      name: data.name ? data.name : isFile ? jsonData.name : '',
      description: data.description
        ? data.description
        : isFile
          ? jsonData.description
          : '',
      // status: data.status
      //   ? data.status
      //   : isFile
      //   ? jsonData.status.toLowerCase()
      //   : "",
      tenentId: data.tenentId ? data.tenentId : isFile ? jsonData.tenentId : '',
      id: data.id ? data.id : isFile ? jsonData.id : '',
    },
    validationSchema: validation,
    onSubmit: (values) => {
      onSubmitCall(values);
    },
    enableReinitialize: true,
  });

  // new
  const getRoleData = async (ID) => {
    const id = ID === undefined ? props.match.params.type : ID;
    const URL = `${GET_ROLES_UUID}/${id}`;
    const response = await get({ url: URL });
    if (response && response !== undefined) {
      setData(response);
      setStatus(response.status);
      setLoading(false);
      setActionDone(true);
    }
  };

  const onSubmitCall = async (data) => {
    setActionDone(false);
    if (type === 'edit') {
      // const id = props.location.state.id;
      const payload = {
        id: data.id,
        name: data.name,
        description: data.description,
      };

      const url = `${UPDATE_ROLE}/${data.id}`;

      const response = await put({
        url: url,
        payload: payload,
        type: 'Role',
      });

      if (response && response !== undefined && response.id) {
        createMessage(200, 'Role updated');

        if (permissions.length > 0) {
          assignPermission(response.id);
        } else {
          setTimeout(() => {
            getRoleData();
            setLoading(true);
          }, 1000);
        }
      } else if (response && response.message) {
        createMessage(400, response.message);
      } else {
        createMessage(response);
      }
    } else {
      const payload = {
        name: data.name,
        description: data.description,
      };

      const response = await post({
        url: ApiConstant.POST_ROLE,
        payload: payload,
        type: 'Role',
      });

      if (response && response.id) {
        createMessage(200, 'Role created');
        if (permissions.length > 0) {
          assignPermission(response.id);
        } else {
          setTimeout(() => {
            props.history.push(ROLE_DETAILS_PATH + '/' + response.id);
          }, 1000);
          setActionDone(true);
        }
      } else if (response && response.message) {
        createMessage(400, response.message);
        setActionDone(true);
      } else {
        createMessage(response);
        setActionDone(true);
      }
    }
  };

  const assignPermission = async (id) => {
    const response = await put({
      url: `${GET_ROLES_UUID}/${id}/permissions`,
      payload: permissions,
      type: 'Role',
    });

    if (response === 201) {
      createMessage(200, 'Permission assigned to role');
    } else {
      createMessage(response);
    }
    setLoading(true);
    setTimeout(() => {
      if (type === 'edit') {
        setPermissions([]);
        getRoleData(id);
      } else {
        props.history.push(ROLES_PATH);
      }
    }, 1000);
  };

  const onJsonUpload = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      console.log('e.target.result', e.target.result, jsonData, isFile, status);
      const response = JSON.parse(e.target.result);

      setJsonData(response);
      setIsFile(true);
    };
  };

  const crumbs = [
    {
      title: 'Roles',
      path: ROLES_PATH,
      active: false,
    },
    {
      title: type === 'edit' ? 'Update Role' : 'Create Role',
      path: CREATE_ROLES_PATH,
      active: true,
    },
  ];

  return (
    <Layout crumbs={crumbs} {...props}>
      <div className="create-role">
        {type === 'edit' && loading ? (
          <SkeletonLoader />
        ) : (
          <CommonCard isForm>
            <div className="card-wrapper">
              <div className="form-wrapper">
                <h4 className="text_primary text-left">
                  {type === 'edit' ? 'Update Role' : 'Create Role'}
                </h4>
              </div>

              <form onSubmit={formik.handleSubmit}>
                <div className="form-wrapper">
                  <div>
                    <TextField
                      autoComplete="off"
                      inputProps={{ 'data-testid': 'role-name' }}
                      style={{ width: '100%' }}
                      id="outlined-basic"
                      variant="outlined"
                      name="name"
                      label="Role Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </div>

                  <div className="mt-4">
                    <TextField
                      autoComplete="off"
                      inputProps={{ 'data-testid': 'description' }}
                      style={{ width: '100%' }}
                      variant="outlined"
                      id="description"
                      multiline
                      rows={1}
                      name="description"
                      label="Description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </div>
                </div>

                <div className="assign-perm-buttons mt-3">
                  {actionDone ? (
                    <>
                      <button
                        className="buttonX"
                        type="submit"
                        data-testid="role-submit"
                      >
                        {type === 'edit' ? 'Update Role' : 'Create Role'}
                      </button>
                      <button
                        className="buttonX white"
                        type="button"
                        onClick={() =>
                          type === 'edit'
                            ? props.history.goBack()
                            : props.history.push(ROLES_PATH)
                        }
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <ActivityLoader />
                  )}
                </div>
              </form>
            </div>
          </CommonCard>
        )}
      </div>
    </Layout>
  );
}
