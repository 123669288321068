import NotFound from '../pages/not_found/NotFound';

import { BsBroadcast, BsFillPeopleFill, BsSpeedometer } from 'react-icons/bs';
import routeConstant from './PathConst';

export const routes = [
  {
    path: '/dashboard',
    hashPath: '#/dashboard',
    sideBarDetails: {
      title: 'Dashboard',
      icon: <BsSpeedometer className="sideBar-icons" />,
      isSubMenu: false,
      isVisible: true,
    },
  },
  {
    path: '/cluster',
    hashPath: '#/cluster',
    sideBarDetails: {
      title: 'Clusters',
      icon: <BsBroadcast className="sideBar-icons" />,
      isSubMenu: false,
      isVisible: true,
    },
  },
  {
    path: '/orders-hub',
    sideBarDetails: {
      title: 'Administration',
      icon: <BsFillPeopleFill className="sideBar-icons" />,
      isSubMenuOpen: true,
      isSubMenu: true,
      subMenu: [
        {
          title: 'Cloud Profiles',
          path: routeConstant.CLOUD_PROFILES,
          hashPath: `#${routeConstant.CLOUD_PROFILES}`,
          Component: '',
          isVisible: true,
        },
        {
          title: 'Data Sources',
          path: '/datasources',
          hashPath: '#/datasources',
          Component: '',
          isVisible: true,
        },
        {
          title: 'Users',
          path: '/users',
          hashPath: '#/users',
          Component: '',
          isVisible: true,
        },
        {
          title: 'User Groups',
          path: routeConstant.USER_GROUPS,
          hashPath: `#${routeConstant.USER_GROUPS}`,
          Component: '',
          isVisible: true,
        },
        {
          title: 'Roles',
          path: '/roles',
          hashPath: '#/role',
          Component: '',
          isVisible: true,
        },
        {
          title: 'Identity Provider Settings',
          path: routeConstant.IDENTITY_PROVIDER,
          hashPath: `#${routeConstant.IDENTITY_PROVIDER}`,
          Component: '',
          isVisible: false,
          tabId: 'IdpConfiguration',
        },
        {
          title: 'Audit Logs',
          path: routeConstant.AUDIT_LOGS,
          hashPath: `#${routeConstant.AUDIT_LOGS}`,
          Component: '',
          isVisible: true,
        },
      ],
    },
  },
  {
    path: '*',
    component: NotFound,
  },
];
