import { get, deleteAPI } from './apiMethods';
import ApiConstant, { ADD_ROLES, GET_ROLES_UUID } from './apiConstant';
import { toast } from 'react-toastify';
import moment from 'moment';

export const isLocalHost = () => {
  return window.location.hostname === 'localhost';
};

export const getReadableDate = (date) => {
  return moment(date * 1000).format('MM/DD/YYYY, hh:mm:ss A');
};

export const getDataId = (data) => {
  let array = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      array.push(data[i].id);
    }
  }
  return array;
};

export const getPropArray = (data) => {
  let array = [];

  if (data && data.length > 0) {
    data.forEach((element) => {
      const obj = {
        id: element.id.Value,
        name: element.name.Value,
      };
      array.push(obj);
    });
  }
  return array;
};

export const getPropsStructure = (items, config) => {
  let array = [];

  if (items && items.length > 0) {
    items.forEach((item) => {
      if (item.id !== 'CLUSTER_CREATION_ERROR') {
        const index = config.findIndex((x) => x.id.Value === item.id);
        if (index !== -1 && config[index].id.Value === item.id) {
          config[index].name.Value = item.name;
          array.push(config[index]);
        } else {
          const obj = {
            id: {
              Value: item.id,
              Restrict: {
                displayprompt: 'Name',
                editable: true,
                sensitive: false,
                default: '',
                required: false,
                valuehelp: '',
                valuevalidation: '',
              },
            },
            name: {
              Value: item.name,
              Restrict: {
                displayprompt: 'Value',
                editable: true,
                sensitive: true,
                default: '',
                required: false,
                valuehelp: '',
                valuevalidation: '',
              },
            },
            Restrict: {
              displayprompt: '',
              editable: true,
              sensitive: false,
              default: '""',
              required: false,
              valuehelp: '',
              valuevalidation: '',
            },
          };
          array.push(obj);
        }
      }
    });
  }

  return array.sort((a, b) => {
    if (a.id?.Restrict?.required && !b.id?.Restrict?.required) return -1;
    if (!a.id?.Restrict?.required && b.id?.Restrict?.required) return 1;
    if (a.id?.Value === 'Compute' && b.id?.Value !== 'Compute') return 1;
    if (a.id?.Value !== 'Compute' && b.id?.Value === 'Compute') return -1;
    return 0;
  });
};

// users
let count = 0;

export const deleteUserById = async (id) => {
  const response = await deleteAPI({
    url: ApiConstant.DELETE_USER(id),
    type: 'User',
  });

  return response;
};

/// Delete Cloud Profile
export const deleteCloudProfileById = async (id) => {
  const response = await deleteAPI({
    url: `${ApiConstant.DELETE_CLOUD_PROFILE(id)}`,
    type: 'v2',
  });

  return response;
};

export const forceDeleteCloudProfileById = async (id) => {
  const response = await deleteAPI({
    url: `${ApiConstant.DELETE_FORCE_CLOUD_PROFILE(id)}`,
    type: 'v2',
  });

  return response;
};

// roles
let rolesPermission = [];

export const deleteRoleById = async (id) => {
  rolesPermission = await get({ url: `${GET_ROLES_UUID}/${id}/permissions` });

  if (rolesPermission && rolesPermission.length > 0) {
    for (let i = 0; i < rolesPermission.length; i++) {
      onUnassignPermission(rolesPermission[i], id);
      if (i === rolesPermission.length - 1) {
        const response = await deleteAPI({
          url: ApiConstant.DELETE_ROLE(id),
          type: 'Role',
        });
        return response;
      }
    }
  } else {
    const response = await deleteAPI({
      url: ApiConstant.DELETE_ROLE(id),
      type: 'Role',
    });

    return response;
  }
};

const onUnassignPermission = async (deleteId, id) => {
  await deleteAPI({
    url: `${ADD_ROLES}/${id}/permissions/${deleteId}`,
    type: 'Role',
  });
  count = count + 1;
  console.log('PERMISSION COUNT', count);
};

export const checkProps = (props) => {
  let finalArray = [];
  if (props && props.length > 0) {
    props.forEach((element) => {
      if (element.id !== '' || element.name) {
        finalArray.push(element);
      }
    });
  }
  return finalArray;
};

// save sorting
export const createMessage = (status, msg, custom) => {
  const successMessage = `${msg} successfully`;

  const messages = {
    200: custom ? msg : successMessage,
    400: `Invalid input, ${msg}`,
    401: 'Authentication needed before performing this operation',
    403: 'Insufficient permissions to perform this operation',
    409: 'Object could not be created due to conflict',
    404: 'The requested resource was not found',
  };

  const message = messages[status] || 'Something went wrong';

  if (status === 200 && !custom) {
    toast.success(message);
  } else {
    toast.error(message);
  }
};

export const deleteMessage = (status, msg, custom) => {
  const statusMessages = {
    200: custom ? msg : `${msg} successfully`,
    201: custom ? msg : `${msg} successfully`,
    204: custom ? msg : `${msg} successfully`,
    400: 'Invalid input',
    401: 'Authentication needed before performing this operation',
    403: 'Insufficient permissions to perform this operation',
    409: 'Object could not be deleted due to conflict',
    404: 'The requested resource was not found',
  };

  const defaultErrorMessage = 'Something went wrong';
  const message = statusMessages[status] || defaultErrorMessage;

  custom ? toast.error(message) : toast.success(message);
};

//  get status counts
export const getStatusCounts = (array, key, value) => {
  return array?.reduce((count, element) => {
    return element[key] === value ? count + 1 : count;
  }, 0);
};

export const deleteUserGroupById = async (id) => {
  const response = await deleteAPI({
    url: `${ApiConstant.DELETE_USER_GROUP(id)}`,
  });
  return response;
};
