import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { post } from '../../../utils/apiMethods';
import ApiConstant from '../../../utils/apiConstant';
import { toast } from 'react-toastify';
import routeConstant from '../../../router/PathConst';

export default function QueryProfileMenu({
  clusterId,
  queryProfileData,
  onRenderProfileQuery,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [isDownloadAPILoading, setIsDownloadAPILoading] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadQueryProfileSummery = async (key, clusterId) => {
    setIsDownloadAPILoading(true);
    const response = await post({
      url: `${ApiConstant.GET_QUERY_PROFILE_SUMMARY_DOWNLOAD}`,
      payload: { key: key, cluster_id: clusterId },
      type: 'v2',
    });

    if (response && response?.data?.cluster_id) {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(response?.data)
      )}`;

      const link = document.createElement('a');
      link.href = jsonString;
      link.download = `query_history_${response?.data?.connection_id}.summary`;

      link.click();

      setIsDownloadAPILoading(false);
    } else {
      toast.warning(
        response?.response?.data?.message || 'Something went wrong.'
      );
      setIsDownloadAPILoading(false);
    }
  };

  return (
    <>
      <i
        className="bi bi-three-dots-vertical dots"
        data-testid="cluster-dots"
        style={{ cursor: 'pointer' }}
        onClick={handleClick}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            const base54 = btoa(queryProfileData.key);
            onRenderProfileQuery(base54);
            handleClose();
          }}
        >
          Query Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            const base54 = btoa(queryProfileData.key);

            window.open(
              `/#${routeConstant.QUERY_PROFILE_DETAILS(clusterId, base54)}`
            );

            handleClose();
          }}
        >
          Query Profile (new tab)
        </MenuItem>
        <MenuItem
          onClick={async () => {
            await downloadQueryProfileSummery(queryProfileData.key, clusterId);
            handleClose();
          }}
        >
          {!isDownloadAPILoading ? 'Download Query History' : 'Downloading...'}
        </MenuItem>
      </Menu>
    </>
  );
}
