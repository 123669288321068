import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ApiConstant from '../../utils/apiConstant';
import { post } from '../../utils/apiMethods';
import { toast } from 'react-toastify';

export default function ForgotPassword(props) {
  const [successfulEmail, setSuccessfulEmail] = useState('');

  const initialValues = { email: '' };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, actions) => {
      forgotPasswordHandler(values, actions);
    },
    enableReinitialize: true,
  });

  async function forgotPasswordHandler(values, actions) {
    try {
      setSuccessfulEmail('');

      actions.setSubmitting(true);

      const URL = ApiConstant.POST_FORGOT_PASSWORD;

      const response = await post({ url: URL, payload: values, type: 'v2' });

      if (response?.status === 200) {
        setSuccessfulEmail(values.email);
      } else {
        toast.error('Please enter valid credentials!');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      actions.setSubmitting(false);
    }
  }

  return (
    <div className="auth-page">
      <div className="auth-card">
        <div className="auth-header">
          <div className="auth-logo" />

          <h6 className="auth-subtitle text-center">
            Enter your email address to receive a link to reset your password
          </h6>
        </div>

        <div className="auth-body">
          <form className="d-flex flex-column" onSubmit={formik.handleSubmit}>
            <TextField
              className={`mt-3 auth-input`}
              label="Enter email"
              name="email"
              type="email"
              id="email"
              variant="outlined"
              inputProps={{ 'data-testid': 'email' }}
              autoComplete="off"
              InputLabelProps={{
                required: false,
              }}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.email) && formik.touched.email}
              helperText={formik.errors.email}
            />

            <div className="auth-buttons mt-4 w-100">
              <button className="buttonX" type="submit">
                {formik.isSubmitting ? 'Loading...' : 'Reset Password'}
              </button>

              <button className="buttonX outline" type="reset">
                Cancel
              </button>
            </div>
          </form>
        </div>

        <div className="auth-footer mt-5">
          {successfulEmail && (
            <h5
              className="text-center"
              style={{
                color: 'var(--clr-primary-500)',
              }}
            >
              An email will be send to '{successfulEmail}' with further
              instaurations if you are a valid DataPelago user.
            </h5>
          )}
        </div>
      </div>
    </div>
  );
}
