import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import {
  EnhancedTableHead,
  getComparator,
  stableSort,
} from '../../../components/TableComponent/TableComponent';
import ApiConstant from '../../../utils/apiConstant';
import { get } from '../../../utils/apiMethods';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import { getReadableDate } from '../../../utils/common';

import '../Cluster.scss';
import routeConstant from '../../../router/PathConst';

const headCells = [
  {
    id: 'value',
    disablePadding: false,
    label: 'Value',
  },
  {
    id: 'name',
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'created',
    disablePadding: false,
    label: 'Created',
  },
  {
    id: 'updated',
    disablePadding: false,
    label: 'Updated',
  },
];

export default class EndPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'desc',
      orderBy: '',
      rowsPerPage: 20,
      page: 0,
      data: ['1'],
      uuId: [],
      endpointList: [],
      isDone: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.getEndpointId();
  }

  getEndpointId = async () => {
    const { clusterID } = this.props;

    const url = ApiConstant.GET_CLUSTER_ENDPOINTS_ID(clusterID);

    const response = await get({ url: url });

    console.log('API res CLUSTER', response);

    this.setState({ uuId: response, loading: false }, () => {
      this.getEndpointById();
    });
  };

  getEndpointById = async () => {
    const { uuId } = this.state;

    if (uuId && uuId.length) {
      for (let i = 0; i < uuId.length; i++) {
        await this.getAPICALl(uuId[i]);
      }
    }

    // this.setState({isDone:true })
  };

  getAPICALl = async (id) => {
    const { clusterID } = this.props;

    let array = this.state.endpointList;

    const URL = ApiConstant.GET_CLUSTER_ENDPOINTS_BY_ID(clusterID, id);
    const response = await get({ url: URL });

    if (response && response !== undefined) {
      array.push(response);
      this.setState({ endpointList: array, isDone: true });
    }

    return;
    // return array[0]
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  onValueClick = (url, name) => {
    if (name === 'query_history') {
      this.props.history.push(
        routeConstant.QUERY_PROFILE(this.props.clusterID)
      );
    } else {
      window.open(url, '_blank');
    }
  };

  render() {
    const {
      //data,
      order,
      orderBy,
      rowsPerPage,
      page,
      isDone,
      loading,
      endpointList,
    } = this.state;
    return (
      <div className="end-Points-main mt-3">
        <div className="desktop-table">
          <TableContainer id="tableDiv" className="mt-4">
            <Table
              className="tableX"
              sx={{
                minWidth: 750,
                borderCollapse: 'separate',
              }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={endpointList.length}
                headCells={headCells}
              />
              {isDone && endpointList && endpointList.length > 0 ? (
                <TableBody className="table Table">
                  {stableSort(endpointList, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          <TableCell align="left">
                            <span
                              className="id p-0"
                              data-testid={`cluster-end-id ${index}`}
                              onClick={() =>
                                this.onValueClick(row.value, row.name)
                              }
                            >
                              {row.value}
                            </span>
                          </TableCell>

                          <TableCell align="left">{row.name}</TableCell>

                          <TableCell align="left">
                            {getReadableDate(row.created_timestamp)}
                          </TableCell>

                          <TableCell align="left">
                            {getReadableDate(row.modified_timestamp)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
          {loading ? (
            <SkeletonLoader />
          ) : isDone && endpointList && endpointList.length > 0 ? (
            <TablePagination
              className="table-pagination"
              rowsPerPageOptions={[20, 40, 60, 80, 100]}
              component="div"
              count={endpointList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              showLastButton={true}
              showFirstButton={true}
            />
          ) : !loading && endpointList && endpointList.length === 0 ? (
            <p
              data-testid="no-endpoints"
              className="text_color text-center no-data-msg mt-2"
            >
              No Data Found
            </p>
          ) : null}
        </div>
        <div className="mobile-table">
          {endpointList &&
            endpointList.map((data) => {
              return (
                <div className="mob-main">
                  <div className="mob-rows">
                    <div className="keys">Name</div>
                    <div className="values">{data.name}</div>
                  </div>

                  <div className="mob-rows">
                    <div className="keys">Value</div>
                    <div
                      onClick={() => this.onValueClick(data.value)}
                      className="values"
                    >
                      {data.value}
                    </div>
                  </div>

                  <div className="mob-rows">
                    <div className="keys">Created Date</div>
                    <div className="values">
                      {getReadableDate(data.created_timestamp)}
                    </div>
                  </div>

                  <div className="mob-rows">
                    <div className="keys">Updated Date</div>
                    <div className="values">
                      {getReadableDate(data.modified_timestamp)}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
