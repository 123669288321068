import React, { Component } from 'react';
import Layout from '../../../components/layout/SideBar/Layout';
import {
  CREATE_USER_PATH,
  USER_DETAILS_PATH,
  USER_PATH,
} from '../../../router/PathConst';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../../components/TabPanel';
import RolesTab from './UserDetailTabs/RolesTab';
import Properties from './UserDetailTabs/Properties';
import { get } from '../../../utils/apiMethods';
import ApiConstant from '../../../utils/apiConstant';
import {
  deleteMessage,
  getReadableDate,
  deleteUserById,
} from '../../../utils/common';
import DeleteModal from '../../../modals/Common/DeleteModal';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import { FaCopy } from 'react-icons/fa';
import AccessToken from './UserDetailTabs/AccessToken';
import ActiveInactiveUserModal from '../../../modals/Users/ActiveInactiveUserModal';
import CommonCard from '../../../components/CommonCard/CommonCard';

export default class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRoleModal: false,
      selectedTab: 0,
      value: 0,
      data: {},
      isData: false,
      roleUUID: [],
      userRoleList: [],
      properties: {},
      isDelete: false,
      loading: true,
      isTenantCopy: false,
      isProfile: false,
      isActiveInactiveModalVisible: false,
      domainName: new URL(window.location.href).origin,
    };
  }

  componentDidMount() {
    this.getUserDetails();
    this.updateBreadCrumb();
  }

  updateBreadCrumb = () => {
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.isProfile
    ) {
      this.setState({ isProfile: true });
    } else {
      this.setState({ isProfile: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.state !== prevProps.location.state) {
      this.setState({ loading: true, isData: false }, () => {
        this.getUserDetails();
      });
      this.updateBreadCrumb();
    }
  }

  getUserDetails = async () => {
    const id = this.props.match.params.id;
    const URL = ApiConstant.GET_USER(id);
    const response = await get({ url: URL });
    if (response && response !== undefined) {
      if (response.userStatus === undefined) {
        response.userStatus = 0;
      }
      this.setState({ data: response, isData: true, loading: false }, () => {
        // setTimeout(() => {
        //   this.setState({ loading: false,isData: true });
        // }, 1000);
      });
    } else {
      this.setState({ loading: false });
    }
  };

  handleTabs = (index) => {
    this.setState({ selectedTab: index });
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  // Delete
  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  deleteUser = async () => {
    const id = this.props.match.params.id;

    const response = await deleteUserById(id);

    if (response === 200 || response === 204) {
      deleteMessage(200, `User deleted`);

      setTimeout(() => {
        this.props.history.push(USER_PATH);
      }, 1000);
    } else {
      deleteMessage(response);
    }
  };

  onCopy = (id) => {
    navigator.clipboard.writeText(id);
    this.setState({ isCopy: true }, () => {
      setTimeout(() => {
        this.setState({ isCopy: false });
      }, 1000);
    });
  };

  onTenantCopy = (id) => {
    navigator.clipboard.writeText(id);
    this.setState({ isTenantCopy: true }, () => {
      setTimeout(() => {
        this.setState({ isTenantCopy: false });
      }, 1000);
    });
  };

  toggleActiveInactiveModal = () => {
    this.setState({
      isActiveInactiveModalVisible: !this.state.isActiveInactiveModalVisible,
    });
  };

  updateUserStatus = () => {
    this.setState({
      data: {
        ...this.state?.data,
        userStatus: this.state?.data?.userStatus === -1 ? 0 : -1,
      },
    });
  };

  render() {
    const {
      domainName,
      isTenantCopy,
      isDelete,
      value,
      data,
      isData,
      isCopy,
      loading,
      isProfile,
      isActiveInactiveModalVisible,
    } = this.state;

    let crumbs = [];

    if (isProfile) {
      crumbs = [
        {
          title: 'My profile',
          path: USER_DETAILS_PATH,
          active: true,
        },
      ];
    } else {
      crumbs = [
        {
          title: 'Users',
          path: USER_PATH,
          active: false,
        },
        {
          title: 'User Details',
          path: USER_DETAILS_PATH,
          active: true,
        },
      ];
    }

    return (
      <Layout crumbs={crumbs} {...this.props}>
        {loading && !isData ? (
          <div className="mt-3">
            <SkeletonLoader />
          </div>
        ) : !loading && isData ? (
          <div className="user-details">
            <div className="details-section">
              <div className="details-section-1">
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Name:</h6>
                    <h6 data-testid="user-name" className="details-data-value">
                      {data ? data.username : '-'}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label"> User ID:</h6>
                    <h6 className="details-data-value copy-id">
                      <span
                        title="Copy to Clipboard"
                        onClick={() => this.onCopy(data.id)}
                      >
                        {data.id ? data.id : '-'}
                      </span>
                      {isCopy ? (
                        <span
                          style={{
                            marginLeft: '10px',
                            color: 'green',
                            fontSize: '12px',
                          }}
                        >
                          Copied
                        </span>
                      ) : (
                        <span
                          data-testid="copyIcon"
                          title="Copy to Clipboard"
                          className="copy-icon"
                          onClick={() => this.onCopy(data.id)}
                        >
                          {' '}
                          <FaCopy />
                        </span>
                      )}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Account ID:</h6>
                    <h6 className="details-data-value copy-id">
                      <span
                        title="Copy to Clipboard"
                        onClick={() => this.onTenantCopy(data.id)}
                      >
                        {data.tenantId ? data.tenantId : '-'}
                      </span>

                      {isTenantCopy ? (
                        <span
                          style={{
                            marginLeft: '10px',
                            color: 'green',
                            fontSize: '12px',
                          }}
                        >
                          Copied
                        </span>
                      ) : (
                        <span
                          data-testid="tenantIcon"
                          title="Copy to Clipboard"
                          className="copy-icon"
                          onClick={() => this.onTenantCopy(data.tenantId)}
                        >
                          <FaCopy />
                        </span>
                      )}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Account Name:</h6>
                    <h6 className="details-data-value">
                      {data?.tenant_name ? data.tenant_name : '-'}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Domain name:</h6>
                    <h6 className="details-data-value">
                      <a
                        className="link-a ms-1"
                        target="_blank"
                        href={domainName}
                        rel="noreferrer"
                      >
                        {domainName}
                      </a>
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Created:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(data && data.created_timestamp)}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Updated:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(data && data.modified_timestamp)}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Email:</h6>
                    <h6 className="details-data-value text-wrap">
                      {data ? data.email : '-'}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Status:</h6>
                    <h6
                      className={
                        data.userStatus !== 0
                          ? 'disable details-data-value'
                          : 'available details-data-value'
                      }
                    >
                      {data.userStatus !== 0 ? 'Inactive' : 'Active'}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="details-section-2">
                <div className="details-button-group">
                  <button
                    className="buttonX details-button"
                    onClick={() =>
                      this.props.history.push({
                        pathname: `${CREATE_USER_PATH}/${data.id}`,
                        state: {
                          id: data.id,
                        },
                      })
                    }
                  >
                    <i className="bi bi-pencil-square me-2" />
                    Edit User
                  </button>

                  <button
                    className="buttonX white details-button"
                    onClick={this.handleDeleteModal}
                  >
                    <i className="bi bi-trash3 me-2" />
                    Delete User
                  </button>
                  <button
                    className="buttonX white details-button"
                    onClick={this.toggleActiveInactiveModal}
                  >
                    <i
                      className={`bi ${
                        data.userStatus !== 0
                          ? 'bi-person-check'
                          : 'bi-person-dash'
                      } me-2`}
                    />
                    {data.userStatus !== 0 ? 'Activate User' : 'Disable User'}
                  </button>
                </div>
              </div>
            </div>

            <CommonCard className="mt-4">
              <Tabs
                variant="fullWidth"
                scrollButtons="auto"
                allowScrollButtonsMobile={true}
                value={value}
                onChange={this.handleChange}
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  className={`tabX-tab-head ${0 === value ? 'active' : ''}`}
                  label="Roles"
                />
                <Tab
                  className={`tabX-tab-head ${1 === value ? 'active' : ''}`}
                  label="Access Tokens"
                />
                <Tab
                  className={`tabX-tab-head ${2 === value ? 'active' : ''}`}
                  label="Properties"
                />
              </Tabs>

              <SwipeableViews
                axis={useTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={this.handleChangeIndex}
              >
                <TabPanel
                  className="tabX-tab-body"
                  value={value}
                  index={0}
                  dir={useTheme.direction}
                >
                  <RolesTab
                    onAssign={this.onRoleAssign}
                    id={data.id}
                    history={this.props.history}
                  />
                </TabPanel>
                <TabPanel
                  className="tabX-tab-body"
                  value={value}
                  index={1}
                  dir={useTheme.direction}
                >
                  <AccessToken id={data.id} />
                </TabPanel>
                <TabPanel
                  className="tabX-tab-body"
                  value={value}
                  index={2}
                  dir={useTheme.direction}
                >
                  <Properties properties={data.props} />
                </TabPanel>
              </SwipeableViews>
            </CommonCard>
          </div>
        ) : (
          <h4 className="text_color_main text-center no-data-msg mt-2">
            No Data Found
          </h4>
        )}

        {isDelete && (
          <DeleteModal
            title={data.username}
            modalType="user"
            show={isDelete}
            handleClose={this.handleDeleteModal}
            onDelete={this.deleteUser}
          />
        )}

        <ActiveInactiveUserModal
          show={isActiveInactiveModalVisible}
          handleClose={this.toggleActiveInactiveModal}
          updateUserStatus={this.updateUserStatus}
          userData={data}
        />
      </Layout>
    );
  }
}
