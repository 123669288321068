import React from 'react';
import { Modal } from 'react-bootstrap';

export default function ViewMore({
  show,
  handleClose,
  data,
  title,
  fetchKey,
  headKey,
}) {
  return (
    <div>
      <Modal centered show={show} onHide={handleClose}>
        {<Modal.Header>{headKey ? data[headKey] : title}</Modal.Header>}
        <Modal.Body>
          <div className="view-more-main" style={{ overflowWrap: 'anywhere' }}>
            {data[fetchKey]}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="buttonX white" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
