import React, { useContext, useState } from 'react';

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { routes } from '../../../router/routeConfig';
import HeaderNew from '../Header/HeaderNew';
import ThemeSetter from '../../../themes/ThemeSetter';

import './Aside.scss';
import './SideBar.scss';
import ConfigContext from '../../../context/config/ConfigContext';

function Aside({
  image,
  collapsed,
  rtl,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
  navigate,
  active,
  heading,
  crumbs,
  history,
}) {
  const sidebarDataFromRoute = routes.filter((item) => item.sideBarDetails);
  const [sidebarData] = useState(sidebarDataFromRoute);

  const { currentConfig } = useContext(ConfigContext);

  const onopenChange = (element) => {
    const data = sidebarData;

    data.forEach((item) => {
      if (item.sideBarDetails.title === element.title) {
        item.sideBarDetails.isSubMenuOpen = !item.sideBarDetails.isSubMenuOpen;
      } else {
        item.sideBarDetails.isSubMenuOpen = false;
      }
    });
  };

  return (
    <ProSidebar
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <div className="desktop">
        <HeaderNew
          crumbs={crumbs}
          history={history}
          title={heading}
          collapsed={collapsed}
          handleToggle={handleCollapsedChange}
        />
      </div>

      <SidebarContent>
        <Menu>
          {sidebarDataFromRoute.map((item, index) => {
            return !item.sideBarDetails.isSubMenu ? (
              <MenuItem
                key={index}
                icon={item.sideBarDetails.icon}
                onClick={() => navigate(item.path)}
                className={
                  window.location.href.includes(item.path)
                    ? 'active'
                    : 'inactive'
                }
              >
                {item.sideBarDetails.title}
              </MenuItem>
            ) : (
              <SubMenu
                key={index}
                icon={item.sideBarDetails.icon}
                title={item.sideBarDetails.title}
                defaultOpen={item.sideBarDetails.isSubMenuOpen}
                onOpenChange={() => onopenChange(item.sideBarDetails)}
                className={
                  item.sideBarDetails.subMenu.some((submenuItem) =>
                    window.location.href.includes(submenuItem.path)
                      ? true
                      : false
                  )
                    ? ''
                    : 'inactive'
                }
              >
                {item.sideBarDetails.subMenu.map((submenuItem, index) => {
                  return (
                    <>
                      {submenuItem?.isVisible ||
                      currentConfig?.uiTabs?.includes(submenuItem?.tabId) ? (
                        <MenuItem
                          key={index}
                          onClick={() => navigate(submenuItem.path)}
                          className={
                            window.location.href.includes(submenuItem.path)
                              ? 'active'
                              : 'inactive'
                          }
                        >
                          {submenuItem.title}
                        </MenuItem>
                      ) : null}
                    </>
                  );
                })}
              </SubMenu>
            );
          })}
        </Menu>
      </SidebarContent>

      <SidebarFooter className="d-flex justify-content-center align-items-center">
        <ThemeSetter isSideOpen={collapsed} />
      </SidebarFooter>
    </ProSidebar>
  );
}

export default Aside;
