import React from 'react';
import { Modal } from 'react-bootstrap';

export default function ViewMoreV2({ show, handleClose, title, body }) {
  return (
    <div>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header>
          <h5>{title}</h5>
        </Modal.Header>

        <Modal.Body>
          <div className="view-more-main" style={{ overflowWrap: 'anywhere' }}>
            {body}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="buttonX white" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
