import React, { useState } from 'react';
import UserContext, { initialCurrentUserState } from './UserContext';

export default function UserProvider(props) {
  const [currentUser, setCurrentUser] = useState(
    initialCurrentUserState.currentUser
  );

  async function storeCurrentUser(data) {
    await setCurrentUser((prevState) => ({
      ...prevState,
      ...(data ? data : {}),
    }));
  }

  async function resetCurrentUser(data) {
    await setCurrentUser(initialCurrentUserState.currentUser);
  }

  return (
    <UserContext.Provider
      value={{ currentUser, storeCurrentUser, resetCurrentUser }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
