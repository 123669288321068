import React, { Component } from 'react';
import ApiConstant, { GET_ROLES_UUID } from '../../../../utils/apiConstant';
import { deleteAPI, get, put } from '../../../../utils/apiMethods';
import { ROLE_DETAILS_PATH } from '../../../../router/PathConst';
import DeleteModal from '../../../../modals/Common/DeleteModal';
import AssignRoleModal from '../../../../modals/Users/AssignRoleModal';
import { createMessage, deleteMessage } from '../../../../utils/common';
import SkeletonLoader from '../../../../components/Loader/SkeletonLoader';
import ActivityLoader from '../../../../components/Loader/ActivityLoader';

export default class RolesTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleUUID: [],
      userRoleList: [],
      loading: true,
      isDone: false,
      selectedRoles: [],
      isDelete: false,
      deleteId: {},
      isSingleDelete: false,
      assignRoleModal: false,
      assignedRoles: [],
      actionDone: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.getUserRoleId();
    });
  }

  // user roles
  getUserRoleId = async () => {
    const { id } = this.props;
    const response = await get({ url: ApiConstant.GET_USER_ROLES(id) });
    this.setState({ roleUUID: response }, () => {
      this.getUserRoleById();
    });
  };

  getUserRoleById = async () => {
    const { roleUUID } = this.state;
    if (roleUUID && roleUUID.length > 0) {
      for (let i = 0; i < roleUUID.length; i++) {
        this.getAPICALl(roleUUID[i], i);
        //  if(i ===roleUUID.length-1){
        //   this.setState({loading:false})
        //  }
      }
    } else {
      this.setState({ loading: false });
    }
  };

  getAPICALl = async (id, index) => {
    let array = this.state.userRoleList;

    const URL = `${GET_ROLES_UUID}/${id}`;

    const response = await get({ url: URL });

    if (response && response !== undefined) {
      if (!response.name.startsWith('SHADOW')) {
        array.push(response);
        this.setState({
          userRoleList: array,
          isDone: true,
          loading: false,
          actionDone: true,
        });
      }
    }
    if (index === this.state.roleUUID.length - 1) {
      this.setState({ loading: false, actionDone: true });
    }
    // return array[0]
  };

  rolesToDelete = (id) => {
    const { selectedRoles } = this.state;
    let selectedRole = [...selectedRoles];
    const isPresent = selectedRoles.includes(id);
    const index = selectedRoles.indexOf(id);

    if (!isPresent) {
      selectedRole.push(id);
      this.setState({ selectedRoles: selectedRole });
    } else {
      selectedRole.splice(index, 1);
      this.setState({ selectedRoles: selectedRole });
    }
  };

  // DELETE
  onDeleteRole = async () => {
    const { selectedRoles } = this.state;
    const { id } = this.props;
    let success = [];
    let failure = [];

    for (let z = 0; z < selectedRoles.length; z++) {
      const response = await deleteAPI({
        url: ApiConstant.DELETE_USER_ROLE(id, selectedRoles[z]),
        type: 'Role',
      });

      if (response === 200) {
        success.push(response);
      } else {
        failure.push(response);
      }
      // await deleteAPI({ url: `${ADD_ROLES}/${selectedRoles[z]}` });
      // await   deleteRoleById(selectedRoles[z])
    }

    if (success.length > 0) {
      deleteMessage(200, `${success.length} Roles unassigned`);
    } else if (failure.length > 0) {
      deleteMessage(200, `${failure.length} roles failed to unassigned`, true);
    }

    this.onDeleteDone();
  };

  onDeleteDone = () => {
    this.setState(
      {
        userRoleList: [],
        selectedRoles: [],
        selected: [],
        isDelete: false,
        loading: true,
      },
      () => {
        this.getUserRoleId();
      }
    );
  };

  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  deleteRole = (value) => {
    this.setState({ deleteId: value, isSingleDelete: true });
  };

  onSingleDelete = async (data) => {
    const { id } = this.props;

    const response = await deleteAPI({
      url: ApiConstant.DELETE_USER_ROLE(id, data.id),
      type: 'Role',
    });

    deleteMessage(response, 'Role unassigned');

    this.setState({ deleteId: {}, isSingleDelete: false }, () => {
      this.onDeleteDone();
    });
  };

  // assign
  onRoleAssign = async (data, uuid) => {
    await this.setState({ actionDone: false }, () => {
      this.onAssign(uuid);
      this.setState({ assignRoleModal: false });
    });
  };

  onAssign = async (data) => {
    const { id } = this.props;

    if (data.length > 0) {
      const response = await put({
        url: ApiConstant.PUT_USER_ROLE(id),
        payload: data,
        type: 'User',
      });

      if (response === 201) {
        createMessage(200, 'Role[s] assigned');
      } else {
        createMessage(response);
      }
      this.onDeleteDone();
    }
    this.setState({ actionDone: true });
  };

  handleAssignRoleModal = () => {
    this.setState({ assignRoleModal: !this.state.assignRoleModal });
  };

  render() {
    const {
      loading,
      userRoleList,
      assignRoleModal,
      selectedRoles,
      isDelete,
      isSingleDelete,
      deleteId,
      actionDone,
    } = this.state;

    let currentCategory = null;

    return (
      <>
        <div className="mt-3">
          <div className="d-flex justify-content-start justify-content-md-end gap-3">
            {actionDone ? (
              <>
                <button
                  className="buttonX"
                  data-testid={`assign-role`}
                  onClick={this.handleAssignRoleModal}
                >
                  <i className="bi bi-person-plus"></i> Assign Role(s){' '}
                </button>
                <button
                  className="buttonX white"
                  data-testid={`unassign`}
                  disabled={selectedRoles.length === 0}
                  onClick={this.onDeleteRole}
                >
                  <i className="bi bi-trash3"></i> Unassign Role(s)
                </button>
              </>
            ) : (
              <ActivityLoader />
            )}
          </div>
        </div>
        <div className="mt-3">
          <h4 className="text_color_main">Assigned roles to this user</h4>

          <div>
            {!loading && userRoleList && userRoleList.length > 0 ? (
              userRoleList
                .sort((a, b) => {
                  if (a.is_system_role && !b.is_system_role) return -1;
                  if (!a.is_system_role && b.is_system_role) return 1;
                  return 0;
                })
                .map((data, i) => {
                  const title = data.is_system_role
                    ? 'System Role'
                    : 'User Defined Role';

                  if (title !== currentCategory) {
                    currentCategory = title;
                    return (
                      <React.Fragment key={`title-${title}`}>
                        <h5 className="mt-4">{title}</h5>
                        <div className="list">
                          <input
                            className="form-check-input"
                            data-testid={`role-check ${i}`}
                            type="checkbox"
                            onChange={() => this.rolesToDelete(data.id)}
                          />
                          <p
                            className="role actions"
                            onClick={() =>
                              this.props.history.push({
                                pathname: `${ROLE_DETAILS_PATH}/${data.id}`,
                                state: {
                                  id: data.id,
                                },
                              })
                            }
                          >
                            {data.name}
                          </p>
                        </div>
                      </React.Fragment>
                    );
                  }

                  return (
                    <div className="list">
                      <input
                        className="form-check-input"
                        data-testid={`role-check ${i}`}
                        type="checkbox"
                        onChange={() => this.rolesToDelete(data.id)}
                      />
                      <p
                        className="role actions"
                        onClick={() =>
                          this.props.history.push({
                            pathname: `${ROLE_DETAILS_PATH}/${data.id}`,
                            state: {
                              id: data.id,
                            },
                          })
                        }
                      >
                        {data.name}
                      </p>
                    </div>
                  );
                })
            ) : loading ? (
              <SkeletonLoader />
            ) : !loading && userRoleList && userRoleList.length === 0 ? (
              <p className="text-center text_color_main no-data-msg mt-2">
                No Roles Assigned{' '}
              </p>
            ) : null}
          </div>
        </div>

        {assignRoleModal && (
          <AssignRoleModal
            onAssign={this.onRoleAssign}
            show={assignRoleModal}
            data={userRoleList}
            handleClose={this.handleAssignRoleModal}
          />
        )}

        {isDelete && (
          <DeleteModal
            deleteCount={selectedRoles.length}
            title="Roles"
            show={isDelete}
            handleClose={this.handleDeleteModal}
            onDelete={this.onDeleteRole}
          />
        )}

        {isSingleDelete && (
          <DeleteModal
            title={deleteId.name}
            modalType="role"
            show={isSingleDelete}
            handleClose={() => this.setState({ isSingleDelete: false })}
            onDelete={this.onSingleDelete}
          />
        )}
      </>
    );
  }
}
