import React from 'react';
import { useLocation } from 'react-router-dom';
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ApiConstant from '../../utils/apiConstant';
import { post } from '../../utils/apiMethods';
import { toast } from 'react-toastify';
import routeConstant from '../../router/PathConst';

export default function ResetPassword(props) {
  const location = useLocation();

  const initialValues = { password: '', confirm_password: '' };

  const validationSchema = Yup.object({
    password: Yup.string().required('Required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, actions) => {
      resetPasswordHandler(values, actions);
    },
    enableReinitialize: true,
  });

  async function resetPasswordHandler(values, actions) {
    const searchParams = new URLSearchParams(location.search);

    const email = searchParams.get('email');
    const verification_key = searchParams.get('verification_key');

    const resetPasswordPayload = {
      ...values,
      email: email,
      verification_key: verification_key,
    };

    try {
      actions.setSubmitting(true);

      const URL = ApiConstant.POST_RESET_PASSWORD;

      const response = await post({
        url: URL,
        payload: resetPasswordPayload,
        type: 'v2',
      });

      if (response?.status === 200) {
        props.history.push(routeConstant.LOGIN);
        toast.success('Password reset successfully!');
      } else {
        toast.error('Something went wrong. Please enter valid credentials.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      actions.setSubmitting(false);
    }
  }

  return (
    <div className="auth-page">
      <div className="auth-card">
        <div className="auth-header">
          <div className="auth-logo" />

          <h6 className="auth-subtitle">Enter your new password</h6>
        </div>

        <div className="auth-body">
          <form className="d-flex flex-column" onSubmit={formik.handleSubmit}>
            <TextField
              className={`mt-3 auth-input`}
              label="Enter new password"
              name="password"
              type="password"
              id="password"
              variant="outlined"
              inputProps={{ 'data-testid': 'password' }}
              autoComplete="off"
              InputLabelProps={{
                required: false,
              }}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.password) && formik.touched.password}
              helperText={formik.errors.password}
            />
            <TextField
              className={`mt-3 auth-input`}
              label="Re-enter password to confirm"
              name="confirm_password"
              type="password"
              id="confirm_password"
              variant="outlined"
              inputProps={{ 'data-testid': 'confirm_password' }}
              autoComplete="off"
              InputLabelProps={{
                required: false,
              }}
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
              error={
                Boolean(formik.errors.confirm_password) &&
                formik.touched.confirm_password
              }
              helperText={formik.errors.confirm_password}
            />

            <div className="auth-buttons mt-4 w-100">
              <button className="buttonX" type="submit">
                {formik.isSubmitting ? 'Loading...' : 'Reset Password'}
              </button>

              <button className="buttonX outline" type="reset">
                Cancel
              </button>
            </div>
          </form>
        </div>

        {/* <div className="auth-footer mt-5"></div> */}
      </div>
    </div>
  );
}
