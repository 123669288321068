import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Input from '../../components/Input/Input';
import ApiConstant, { GET_ROLES_UUID } from '../../utils/apiConstant';
import { get } from '../../utils/apiMethods';
import ModalLoader from '../Common/ModalLoader';

import './userModal.scss';

export default class AssignRoleModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roleList: [],
      isDone: false,
      selectedRoles: [],
      roleRefList: [],
      loading: false,
      systemRoleList: [],
    };
  }

  componentDidMount() {
    this.getRolesId();
    this.getSystemRole();
  }

  /// Get System Roles
  getSystemRole = async () => {
    const URL = ApiConstant.GET_ROLES;

    const response = await get({ url: URL });

    if (response) {
      this.setState({
        systemRoleList: response.filter((item) => item.is_system_role === true),
      });
    }
  };

  // GET API CALL
  getRolesId = async () => {
    const response = await get({ url: GET_ROLES_UUID });

    this.setState({ UUID: response }, () => {
      this.getRoleById();
    });
  };

  getRoleById = async () => {
    const { UUID } = this.state;

    if (UUID && UUID.length) {
      for (let i = 0; i < UUID.length; i++) {
        this.getAPICALl(UUID[i], i);

        // if (i === UUID.length - 1) {
        //   this.setState({ isDone: true });
        // }
      }
    } else {
      this.setState({ isDone: true });
    }
  };

  getAPICALl = async (id, index) => {
    const { data } = this.props;
    let array = this.state.roleList;

    const URL = `${GET_ROLES_UUID}/${id}`;

    const response = await get({ url: URL });
    if (response && response !== undefined) {
      if (
        !response.name.startsWith('SHADOW') &&
        !response.name.startsWith('DEFAULT') &&
        !response.is_system_role
      ) {
        response.isVisible = true;
        array.push(response);
        this.setState(
          { roleList: array, roleRefList: array, isDone: true },
          () => {
            if (data !== undefined && data.length > 0) {
              this.checkData(data);
            }
          }
        );
      }
    }
    if (index === this.state.UUID.length - 1) {
      this.setState({ isDone: true });
    }
    // return array[0]
  };

  checkData = (data) => {
    const { roleList } = this.state;
    const array = [...roleList];
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (array[i].name === data[j].name) {
          array[i].isVisible = false;
        }
      }
    }
    this.onFilterData(array);
  };

  onFilterData = (roles) => {
    const data = roles.filter((item) => item.isVisible);
    this.setState({ roleList: data });
  };

  handleSelect = (i, data) => {
    const { roleList } = this.state;
    this.rolesToassign(data.id);
    const array = [...roleList];
    array[i].selected = !array[i].selected;
    this.setState({ roleList: array });
  };

  handleSystemRoleChange = (data) => {
    const newRoleList = this.state.systemRoleList.map((role) => {
      return {
        ...role,
        selected: data.id === role.id ? true : false,
      };
    });

    this.setState({
      systemRoleList: newRoleList,
    });

    this.rolesToassign(data.id);
  };

  // roles to assign
  rolesToassign = (id) => {
    const { selectedRoles } = this.state;
    let selectedRole = [...selectedRoles];
    const isPresent = selectedRoles.includes(id);
    const index = selectedRoles.indexOf(id);

    if (!isPresent) {
      selectedRole.push(id);
      this.setState({ selectedRoles: selectedRole });
    } else {
      selectedRole.splice(index, 1);
      this.setState({ selectedRoles: selectedRole });
    }
  };

  onSearchData = (value) => {
    const { roleRefList } = this.state;
    if (value !== '' && value !== undefined) {
      const result = roleRefList.filter((data) =>
        data.name.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({ roleList: result });
    } else {
      this.setState({ roleList: roleRefList });
    }
  };

  onSubmit = () => {
    const { roleList, systemRoleList, selectedRoles } = this.state;
    const result = roleList.filter((item) => item.selected === true);
    const result2 = systemRoleList.filter((item) => item.selected === true);

    this.props.onAssign([...result, ...result2], selectedRoles);
  };

  render() {
    const { show, handleClose } = this.props;
    const { roleList, isDone, systemRoleList } = this.state;

    return (
      <div>
        {isDone ? (
          <Modal size="lg" centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Select & Assign Roles</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Input
                type="search"
                placeholder="Search Role"
                handleChange={(e) => this.onSearchData(e.target.value)}
              />
              <h6 className="mb-3 fw-bold">System Roles</h6>
              <div className="assign-role" style={{ height: 'max-content' }}>
                {systemRoleList && systemRoleList.length > 0 ? (
                  systemRoleList.map((item, i) => {
                    return (
                      <div key={i} style={{ marginBottom: '0.5rem' }}>
                        <input
                          name="systemRole"
                          checked={item.selected}
                          className="form-check-input"
                          type="radio"
                          onChange={() => this.handleSystemRoleChange(item)}
                        />
                        <div className="role-div">{item.name}</div>
                        <hr />
                      </div>
                    );
                  })
                ) : (
                  <p>No system roles available.</p>
                )}
              </div>
              <h6 className="mt-4 mb-3 fw-bold">User Defined Roles</h6>
              <div className="assign-role">
                {roleList && roleList.length > 0 ? (
                  roleList.map((item, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          marginBottom:
                            roleList.length - 1 !== i ? '0.5rem' : '0',
                        }}
                      >
                        {/* {item && item.isVisible ?( */}
                        <>
                          <input
                            data-testid={`role ${i}`}
                            checked={item.selected}
                            className="form-check-input"
                            type="checkbox"
                            onChange={() => this.handleSelect(i, item)}
                          />
                          <div className="role-div">{item.name}</div>
                          {roleList.length - 1 !== i && <hr />}
                        </>
                        {/* ):null} */}
                      </div>
                    );
                  })
                ) : (
                  <p className="text_color text-center  mt-2">No roles found</p>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-content-end gap-2">
                <button
                  className="buttonX"
                  data-testid="assign-submit"
                  onClick={this.onSubmit}
                >
                  Assign
                </button>
                <button
                  className="buttonX white"
                  onClick={this.props.handleClose}
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        ) : (
          <ModalLoader centered show={show} />
        )}
      </div>
    );
  }
}
