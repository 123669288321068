import React, { useState } from 'react';
import CommonCard from '../../../components/CommonCard/CommonCard';
import moment from 'moment';
import SqlStatement from './summary/SqlStatement';
import TimeSeriesChart from './summary/TimeSeriesChart';
import { FaCopy } from 'react-icons/fa';
import FlowProviderDFGs from './summary/FlowProviderDFGs';

export default function QueryProfileSections(props) {
  const [isCopy, setIsCopy] = useState(false);

  const [isSQLStatementVisible, setIsSQLStatementVisible] = useState(true);
  const [isStatementPlantVisible, setIsStatementPlanVisible] = useState(true);
  const [isDFGsSummaryVisible, setIsDFGsSummaryVisible] = useState(true);
  const [isTimingChartVisible, setIsTimingChartVisible] = useState(true);
  const [formattedQuery, setFormattedQuery] = useState('');

  function calculateDifference(startDate, endDate) {
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const diffInMilliseconds = end.diff(start);
      const duration = moment.duration(diffInMilliseconds, 'milliseconds');
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      const milliseconds = duration.milliseconds();

      let result = '';
      if (minutes > 0) {
        result += `${minutes} minute${minutes !== 1 ? 's' : ''}`;
      }
      if (seconds > 0) {
        if (result) {
          result += ', ';
        }
        result += `${seconds} second${seconds !== 1 ? 's' : ''}`;
      }
      if (milliseconds > 0) {
        if (result) {
          result += ', ';
        }
        result += `${milliseconds} millisecond${milliseconds !== 1 ? 's' : ''}`;
      }

      return result;
    }

    return '';
  }

  const handleFormattedQuery = async (query) => {
    setFormattedQuery(query);
  };

  const onCopy = async (id, show) => {
    if (show === 1) {
      id = formattedQuery;
    }

    const preElement = document.createElement('pre');
    preElement.style.position = 'absolute';
    preElement.style.left = '-9999px';
    preElement.textContent = id;
    document.body.appendChild(preElement);

    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(preElement);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy');
    selection.removeAllRanges();
    document.body.removeChild(preElement);
    setIsCopy(show);

    setTimeout(() => {
      setIsCopy(false);
    }, 1000);
  };

  const summary = props?.queryDoc?.SUMMARY || {};

  const excludedKeys = [
    'connection_id',
    'statement_id',
    'build',
    'start_time',
    'end_time',
  ];

  // Function to format the keys
  const formatKey = (key) => {
    return key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const summaryElements = Object.entries(summary)
    .filter(
      ([key, value]) =>
        value !== null && value !== '' && !excludedKeys.includes(key)
    )
    .map(([key, value]) => (
      <div className="details-data-row mb-2" key={key}>
        <h5 className="details-data-label">{formatKey(key)}:</h5>
        <h5 className="details-data-value">{value}</h5>
      </div>
    ));

  return (
    <>
      <CommonCard className="mb-4">
        <h1 className="mb-5">
          {`Summary for statement ${
            props?.queryDoc?.SUMMARY?.statement_id || '-'
          } from connection ${props?.queryDoc?.SUMMARY?.connection_id || '-'}`}
        </h1>

        <div class="details-data-row mb-2">
          <h5 class="details-data-label">Connection-Stmt:</h5>
          <h5 class="details-data-value">
            {`${props?.queryDoc?.SUMMARY?.connection_id || '-'} - ${
              props?.queryDoc?.SUMMARY?.statement_id || '-'
            }`}
          </h5>
        </div>

        <div class="details-data-row mb-4">
          <h5 class="details-data-label">Build Version:</h5>
          <h5 class="details-data-value">
            {props?.queryDoc?.SUMMARY?.build || '-'}
          </h5>
        </div>

        {summaryElements}

        {props?.queryDoc?.SUMMARY?.start_time &&
          props?.queryDoc?.SUMMARY?.end_time && (
            <div class="details-data-row mb-2 mt-4">
              <h5 class="details-data-label">Query ran for a total of:</h5>
              <h5 class="details-data-value">
                {calculateDifference(
                  props?.queryDoc.SUMMARY.start_time,
                  props?.queryDoc.SUMMARY.end_time
                )}
              </h5>
            </div>
          )}

        {props?.queryDoc?.SUMMARY?.start_time &&
          props?.queryDoc?.SUMMARY?.end_time && (
            <div class="details-data-row mb-2">
              <h5 class="details-data-label">Duration:</h5>
              <h5 class="details-data-value">
                {`${moment(
                  props?.queryDoc.SUMMARY.start_time,
                  'YYYY-MM-DD HH:mm:ss Z z'
                ).format('MM/DD/YYYY, hh:mm:ss A')} - ${moment(
                  props?.queryDoc.SUMMARY.end_time,
                  'YYYY-MM-DD HH:mm:ss Z z'
                ).format('MM/DD/YYYY, hh:mm:ss A')}`}
              </h5>
            </div>
          )}
      </CommonCard>

      {/* SQL Statement: */}
      <CommonCard className="query-card-section mb-4">
        <div className="section-header">
          <h5>SQL Statement</h5>
          <div className="icons-section">
            <div
              className="query-icon"
              title="Copy to Clipboard"
              onClick={() => onCopy(props?.queryDoc.SQL_QUERY, 1)}
            >
              {isCopy === 1 ? (
                <span
                  style={{
                    fontSize: '12px',
                  }}
                >
                  Copied
                </span>
              ) : (
                <FaCopy />
              )}
            </div>
            <div
              className="query-icon"
              onClick={() => setIsSQLStatementVisible((prevData) => !prevData)}
            >
              {isSQLStatementVisible ? (
                <i className="bi bi-chevron-up" />
              ) : (
                <i className="bi bi-chevron-down" />
              )}
            </div>
          </div>
        </div>
        {isSQLStatementVisible && (
          <div className="section-body statement">
            <pre>
              <SqlStatement
                onFormat={handleFormattedQuery}
                SQL_QUERY={props?.queryDoc.SQL_QUERY}
              />
            </pre>
          </div>
        )}
      </CommonCard>

      {/* Statement Plan: */}
      <CommonCard className="query-card-section mb-4">
        <div className="section-header">
          <h5>Statement Plan</h5>
          <div className="icons-section">
            <div
              className="query-icon"
              title="Copy to Clipboard"
              onClick={() => onCopy(props?.queryDoc.TEXT_PLAN, 2)}
            >
              {isCopy === 2 ? (
                <span
                  style={{
                    fontSize: '12px',
                  }}
                >
                  Copied
                </span>
              ) : (
                <FaCopy />
              )}
            </div>
            <div
              className="query-icon"
              onClick={() => setIsStatementPlanVisible((prevData) => !prevData)}
            >
              {isStatementPlantVisible ? (
                <i className="bi bi-chevron-up" />
              ) : (
                <i className="bi bi-chevron-down" />
              )}
            </div>
          </div>
        </div>

        {isStatementPlantVisible && (
          <div className="section-body statement p-2">
            <pre>{props?.queryDoc.TEXT_PLAN}</pre>
          </div>
        )}
      </CommonCard>

      {/* Query Plan Profile Summary: */}
      <CommonCard className="query-card-section mb-4">
        <div className="section-header">
          <h5>Query Plan Profile Summary</h5>
          <div className="icons-section">
            <div
              className="query-icon"
              onClick={() => setIsDFGsSummaryVisible((prevData) => !prevData)}
            >
              {isDFGsSummaryVisible ? (
                <i className="bi bi-chevron-up" />
              ) : (
                <i className="bi bi-chevron-down" />
              )}
            </div>
          </div>
        </div>

        {isDFGsSummaryVisible && (
          <div className="section-body graph-section">
            <FlowProviderDFGs
              pdfName={
                props?.queryDoc
                  ? props?.queryDoc?.SUMMARY?.connection_id +
                    '-' +
                    props?.queryDoc?.SUMMARY?.statement_id
                  : '-'
              }
              GRAPH_PLAN={props?.queryDoc?.GRAPH_PLAN}
            />
          </div>
        )}
      </CommonCard>

      <CommonCard className="query-card-section">
        <div className="section-header">
          <h5>Step timing chart</h5>
          <div className="icons-section">
            <div
              className="query-icon"
              onClick={() => setIsTimingChartVisible((prevData) => !prevData)}
            >
              {isTimingChartVisible ? (
                <i className="bi bi-chevron-up" />
              ) : (
                <i className="bi bi-chevron-down" />
              )}
            </div>
          </div>
        </div>

        {isTimingChartVisible && (
          <div className="section-body p-3" style={{ height: '700px' }}>
            <TimeSeriesChart
              TIMESERIES_DATA={props?.queryDoc.TIMESERIES_DATA}
            />
          </div>
        )}
      </CommonCard>
    </>
  );
}
