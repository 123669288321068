import React, { Component } from 'react';
import Layout from '../../components/layout/SideBar/Layout';
import CommonCard from '../../components/CommonCard/CommonCard';
import EndPoints from './ClusterTabs/EndPoints';
import Properties from './ClusterTabs/Properties';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import routeConstant, {
  CLUSTER_DETAILS_PATH,
  CLUSTER_PATH,
} from '../../router/PathConst';
import TabPanel from '../../components/TabPanel';
import ApiConstant from '../../utils/apiConstant';
import { get, deleteAPI, put } from '../../utils/apiMethods';
import { getReadableDate } from '../../utils/common';
import DeleteModal from '../../modals/Common/DeleteModal';
import { FaCopy } from 'react-icons/fa';
import SkeletonLoader from '../../components/Loader/SkeletonLoader';
import ActivityLoader from '../../components/Loader/ActivityLoader';
import Notifications from './ClusterTabs/Notifications';
import SuspendResumeClusterModal from '../../modals/cluster/SuspendResumeClusterModal';
import { toast } from 'react-toastify';
import { getClusterStatusTextClassName } from '../../utils/helperFunctions';

export default class ClusterDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      value: 0,
      data: {},
      isDone: false,
      isDelete: false,
      isCopy: false,
      loading: true,
      actionDone: true,
      isSuspend: false,
      clusterNotifications: [],
      isSuspendResumeModal: false,
      clusterLoadingStatus: [
        'Suspending Cluster',
        'Resuming Cluster',
        'Updating Cluster',
      ],
      isConnectToClusterAPILoading: false,
    };
  }

  componentDidMount() {
    this.getClusterDetails();
    this.getClusterNotifications();
  }

  getClusterDetails = async () => {
    const id = this.props.match.params.id;

    const URL = `${ApiConstant.GET_CLUSTER}/${id}`;

    const response = await get({ url: URL });

    if (response && response !== undefined) {
      const newProps = response?.props?.filter(
        (data) => data.id !== 'CLUSTER_CREATION_ERROR'
      );

      const newResponse = {
        ...response,
        props: [
          { id: 'Cloud Profile', name: response?.cloud_config_name },
          { id: 'Cloud Provider', name: response?.cloud_type },
          { id: 'Compute', name: response?.scale },
          {
            id: 'Parsing And Planning',
            name: `Instance Type: ${response?.parsing_and_planning_nodegroup?.instance_type},  Count: ${response?.parsing_and_planning_nodegroup?.desired_node_count}`,
          },
          {
            id: 'Execution',
            name: `Instance Type: ${response?.execution_nodegroup?.instance_type},  Count: ${response?.execution_nodegroup?.desired_node_count}`,
          },
          ...(newProps ?? []),
        ],
      };

      this.setState({ data: newResponse, loading: false, isDone: true });
    } else {
      this.setState({ loading: false });
    }
  };

  getClusterNotifications = async () => {
    const id = this.props.match.params.id;

    const URL = `${ApiConstant.GET_CLUSTER_NOTIFICATION(id)}`;

    const response = await get({ url: URL });

    if (response && response?.cluster_id !== undefined) {
      this.setState({ clusterNotifications: response?.jobs ?? [] });
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  deleteCluster = async () => {
    this.setState({ actionDone: false });

    const id = this.props.match.params.id;

    const response = await deleteAPI({
      url: ApiConstant.DELETE_CLUSTER(id),
      type: 'v2',
    });

    if (response.status === 200) {
      toast.success('Cluster deletion triggered.');

      setTimeout(() => {
        this.props.history.push(CLUSTER_PATH);
      }, 1000);
    } else {
      toast.warning(
        response?.response?.data?.message || 'Something went wrong.'
      );
    }

    this.setState({ actionDone: true, isDelete: !this.state.isDelete });
  };

  onCopy = (id) => {
    navigator.clipboard.writeText(id);
    this.setState({ isCopy: true }, () => {
      setTimeout(() => {
        this.setState({ isCopy: false });
      }, 1000);
    });
  };

  toggleSuspendResumeClusterModal = async (isOpen) => {
    this.setState({
      isSuspendResumeModal: isOpen,
    });
  };

  suspendResumeCluster = async () => {
    this.setState({
      isSuspend: true,
    });

    const clusterInfo = this.state?.data;

    if (
      !(
        clusterInfo?.status === 'Available' ||
        clusterInfo?.status === 'Suspended' ||
        clusterInfo?.status === 'Suspend Failed' ||
        clusterInfo?.status === 'Resume Failed'
      )
    ) {
      this.setState({
        isSuspend: false,
      });
      return;
    }

    try {
      const URL =
        clusterInfo?.status === 'Available' ||
        clusterInfo?.status === 'Suspend Failed'
          ? `/v2/clusters/${clusterInfo.id}/suspend`
          : `/v2/clusters/${clusterInfo.id}/resume`;

      const response = await put({
        url: URL,
        payload: 'v2',
      });

      if (response.status === 200) {
        this.getClusterDetails();
      } else {
        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }
    } catch (e) {
      console.log(e ?? 'Something went wrong');
    } finally {
      this.toggleSuspendResumeClusterModal(false);
      this.setState({
        isSuspend: false,
      });
    }
  };

  connectToClusterHandler = async () => {
    try {
      this.setState({
        isConnectToClusterAPILoading: true,
      });

      const id = this.props.match.params.id;

      const URL = ApiConstant.GET_CONNECT_TO_CLUSTER(id);

      const response = await get({
        url: URL,
        type: "v2",
      });

      if (response.status === 200 && response?.data?.jupyterhub_url) {
        window.open(response.data.jupyterhub_url, "_blank");
      }
    } catch (error) {
      console.log(error ?? "Something went wrong");

      toast.warning(error?.response?.data?.message || "Something went wrong.");
    } finally {
      this.setState({
        isConnectToClusterAPILoading: false,
      });
    }
  };

  render() {
    const {
      loading,
      value,
      isDone,
      data,
      isDelete,
      isCopy,
      actionDone,
      isSuspend,
      clusterNotifications,
      isSuspendResumeModal,
      clusterLoadingStatus,
      isConnectToClusterAPILoading,
    } = this.state;

    const crumbs = [
      {
        title: 'Cluster',
        path: CLUSTER_PATH,
        active: false,
      },
      {
        title: 'Cluster Details',
        path: CLUSTER_DETAILS_PATH,
        active: true,
      },
    ];

    const menu = ['Endpoints', 'Properties', 'Notifications'];

    return (
      <Layout crumbs={crumbs} {...this.props}>
        <div className="cluster-details">
          {loading && !isDone ? (
            <SkeletonLoader />
          ) : !loading && isDone && data ? (
            <>
              <div className="details-section">
                <div className="details-section-1">
                  <div>
                    <div className="details-data-row">
                      <h6 className="details-data-label">Name:</h6>
                      <h6
                        data-testid="cluster-Name"
                        className="details-data-value"
                      >
                        {data && data.name}
                      </h6>
                    </div>
                    <div className="details-data-row">
                      <h6 className="details-data-label">Cluster ID:</h6>
                      <h6 className="details-data-value copy-id">
                        <span
                          title="Copy to Clipboard"
                          onClick={() => this.onCopy(data.id)}
                        >
                          {data && data.id}
                        </span>

                        {isCopy ? (
                          <span
                            style={{
                              marginLeft: '10px',
                              color: 'green',
                              fontSize: '12px',
                            }}
                          >
                            Copied
                          </span>
                        ) : (
                          <span
                            data-testid="copyIcon"
                            title="Copy to Clipboard"
                            className="copy-icon"
                            onClick={() => this.onCopy(data.id)}
                          >
                            {' '}
                            <FaCopy />
                          </span>
                        )}
                      </h6>
                    </div>
                  </div>

                  <div>
                    <div className="details-data-row">
                      <h6 className="details-data-label">Created:</h6>
                      <h6 className="details-data-value">
                        {getReadableDate(data && data.created_timestamp)}
                      </h6>
                    </div>

                    <div className="details-data-row">
                      <h6 className="details-data-label">Updated:</h6>
                      <h6 className="details-data-value">
                        {getReadableDate(data && data.modified_timestamp)}
                      </h6>
                    </div>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Status:</h6>
                    <h6
                      className={`details-data-value ${getClusterStatusTextClassName(
                        data?.status
                      )}
                      `}
                    >
                      {data && clusterLoadingStatus.includes(data.status) ? (
                        <>
                          <div
                            class="spinner-border spinner-border-sm me-1"
                            role="status"
                            style={{ color: 'var(--clr-warning)' }}
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                          {data.status}
                        </>
                      ) : (
                        data.status
                      )}
                    </h6>
                  </div>
                </div>
                <div className="details-section-2">
                  {actionDone ? (
                    <div className="details-button-group">
                      <button
                        className="buttonX details-button"
                        onClick={this.connectToClusterHandler}
                        disabled={isConnectToClusterAPILoading}
                      >
                        <i className="bi bi-pencil-square me-2" />
                        {!isConnectToClusterAPILoading
                          ? "Connect to Cluster"
                          : "Connecting..."}
                      </button>

                      <button
                        className="buttonX details-button"
                        onClick={() =>
                          this.props.history.push({
                            pathname: `${CLUSTER_PATH}/${data.id}`,
                            state: {
                              id: data.id,
                            },
                          })
                        }
                      >
                        <i className="bi bi-pencil-square me-2" />
                        Edit Cluster
                      </button>

                      <button
                        className="buttonX details-button"
                        onClick={() =>
                          this.props.history.push(
                            `${routeConstant.QUERY_PROFILE(data?.id)}`
                          )
                        }
                      >
                        <i className="bi bi-list-ul me-2" />
                        View Query History
                      </button>

                      <button
                        className="buttonX white details-button"
                        onClick={this.handleDeleteModal}
                      >
                        <i className="bi bi-trash3 me-2" />
                        Delete Cluster
                      </button>
                      {(data.status === 'Available' ||
                        data.status === 'Suspended' ||
                        data.status === 'Suspend Failed' ||
                        data.status === 'Resume Failed') && (
                        <button
                          className="buttonX white details-button"
                          onClick={() =>
                            data.status === 'Available' ||
                            data.status === 'Suspend Failed'
                              ? this.toggleSuspendResumeClusterModal(true)
                              : this.suspendResumeCluster()
                          }
                          disabled={
                            !(
                              data.status === 'Available' ||
                              data.status === 'Suspended' ||
                              data.status === 'Suspend Failed' ||
                              data.status === 'Resume Failed'
                            )
                          }
                        >
                          <i
                            className={`me-2 ${
                              data.status === 'Available' ||
                              data.status === 'Suspend Failed'
                                ? 'bi bi-pause-fill'
                                : 'bi bi-play-fill'
                            }`}
                          />
                          {isSuspend
                            ? 'Loading...'
                            : data.status === 'Available' ||
                                data.status === 'Suspend Failed'
                              ? 'Suspend Cluster'
                              : data.status === 'Suspended' ||
                                  data.status === 'Resume Failed'
                                ? 'Resume Cluster'
                                : 'Resume Cluster'}
                        </button>
                      )}
                    </div>
                  ) : (
                    <div>
                      <ActivityLoader />
                    </div>
                  )}
                </div>
              </div>

              <CommonCard className="mt-4">
                <Tabs
                  variant="fullWidth"
                  scrollButtons="auto"
                  allowScrollButtonsMobile={true}
                  value={value}
                  onChange={this.handleChange}
                  aria-label="scrollable auto tabs example"
                >
                  {menu.map((item, i) => {
                    return (
                      <Tab
                        className={`tabX-tab-head ${
                          i === value ? 'active' : ''
                        }`}
                        key={i}
                        label={item}
                      />
                    );
                  })}
                </Tabs>

                <SwipeableViews
                  axis={useTheme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={value}
                  onChangeIndex={this.handleChangeIndex}
                >
                  <TabPanel
                    className="tabX-tab-body"
                    value={value}
                    index={0}
                    dir={useTheme.direction}
                  >
                    <EndPoints
                      clusterID={data && data.id}
                      history={this.props.history}
                    />
                  </TabPanel>
                  <TabPanel
                    className="tabX-tab-body"
                    value={value}
                    index={1}
                    dir={useTheme.direction}
                  >
                    <Properties
                      data={data && data.props}
                      cloudProfileId={data?.cloud_config_id}
                      history={this.props.history}
                    />
                  </TabPanel>
                  <TabPanel
                    className="tabX-tab-body"
                    value={value}
                    index={2}
                    dir={useTheme.direction}
                  >
                    <Notifications
                      clusterData={data}
                      clusterNotifications={clusterNotifications}
                      history={this.props.history}
                    />
                  </TabPanel>
                </SwipeableViews>
              </CommonCard>
            </>
          ) : (
            <h4 className="text_color_main text-center no-data-msg mt-2">
              No Data Found
            </h4>
          )}
        </div>

        {isDelete && (
          <DeleteModal
            title={data.name}
            modalType="cluster"
            show={isDelete}
            handleClose={this.handleDeleteModal}
            onDelete={this.deleteCluster}
          />
        )}

        {isSuspendResumeModal && (
          <SuspendResumeClusterModal
            show={isSuspendResumeModal}
            handleClose={() => this.toggleSuspendResumeClusterModal(false)}
            data={data}
            onConfirm={this.suspendResumeCluster}
          />
        )}
      </Layout>
    );
  }
}
