import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import MultiSelect from '../../../components/MultiSelect/MultiSelect';
import { EnhancedTableHead } from '../../../components/TableComponent/TableComponent';
import {
  getComparator,
  stableSort,
} from '../../../components/TableComponent/TableComponent';
import Layout from '../../../components/layout/SideBar/Layout';
import CommonCard from '../../../components/CommonCard/CommonCard';
import { TENANT_DETAILS_PATH, TENANT_PATH } from '../../../router/PathConst';
import TenantMenu from './TenantMenu';
import { deleteAPI, get, put } from '../../../utils/apiMethods';

import ApiConstant, {
  GET_TENANT,
  GET_TENANTS,
} from '../../../utils/apiConstant';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import { getReadableDate } from '../../../utils/common';
import DeleteModal from '../../../modals/Common/DeleteModal';
import UserContext from '../../../context/user/UserContext';

const crumbs = [
  {
    title: 'Tenant',
    path: TENANT_PATH,
    active: true,
  },
];

const options = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Available', label: 'Available' },
];

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'created_timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Created',
  },
  {
    id: 'modified_timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Updated',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

export default class Tenant extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      page: 0,
      rowsPerPage: 10,
      tenantList: [],
      loading: true,
      isDone: false,
      tenantRefList: [],
      selectedTenants: [],
      isDelete: false,
      UUID: [],
    };
  }

  componentDidMount() {
    if (this.context) {
      const { currentUser } = this.context;
      const { props } = currentUser;

      if (props && 'sorting' in props) {
        const sort = JSON.parse(props.sorting);
        if ('Tenant' in sort && sort.Tenant.length > 0) {
          this.setState({ orderBy: sort.Tenant[0], order: sort.Tenant[1] });
        } else {
          this.setState({ orderBy: 'name' });
        }
      } else {
        this.setState({ orderBy: 'name' });
      }
    }
    this.getTenantId();
  }

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
    this.onSaveSort(property, isAsc ? 'desc' : 'asc');
  };

  onSaveSort = (value, order) => {
    const { currentUser } = this.context;
    const { props } = currentUser;

    let array = { ...props };
    if (props && props.sorting) {
      let { sorting } = props;
      const obj = JSON.parse(sorting);
      if (sorting !== undefined) {
        obj.Tenant = [value, order];
      } else {
        obj.Tenant = [value, order];
      }
      array.sorting = JSON.stringify(obj);

      this.callAPi(array);
    } else {
      const obj = {
        Tenant: value,
      };

      currentUser.props = { sorting: JSON.stringify(obj) };
      this.callAPi(currentUser.props);
    }
  };

  callAPi = async (properties) => {
    const { currentUser, storeCurrentUser } = this.context;

    const { username, firstName, lastName, email, password, phone, id } =
      currentUser;

    const payload = {
      id: id,
      username: username,
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      phone: phone,
      props: properties,
    };

    const url = ApiConstant.PUT_USER(id);

    const response = await put({
      url: url,
      payload: payload,
    });

    if (response && response.id) {
      if ('sorting' in response.props) {
        storeCurrentUser(response);
      }
    }
  };

  getTenantId = async () => {
    const response = await get({ url: GET_TENANTS });

    this.setState({ UUID: response }, () => {
      this.getAPICALl();
    });
  };

  getAPICALl = () => {
    const { UUID } = this.state;
    if (UUID && UUID.length > 0) {
      for (let i = 0; i < UUID.length; i++) {
        this.getTenantById(UUID[i]);
      }
    } else {
      this.setState({ loading: false });
    }
  };

  getTenantById = async (id) => {
    let array = this.state.tenantList;
    const response = await get({
      url: `${GET_TENANT}/${id}`,
    });
    if (response && response !== undefined) {
      array.push(response);
      this.setState({
        tenantList: array,
        tenantRefList: array,
        isDone: true,
        loading: false,
      });
    } else {
      this.setState({ isDone: true });
    }
  };

  // All select
  // handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = this.state.tenantList.map((n) => n.id);

  //     this.setState({ selected: newSelecteds,selectedTenants:newSelecteds });
  //     newSelecteds.forEach((item)=>{
  //       this.tenantToDelete(item)
  //     })

  //     return;
  //   }
  //   this.setState({ selected: [],selectedTenants:[] });
  // };

  // Table selection
  // handleClick = (event, name) => {

  //   const { selected } = this.state;
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   this.tenantToDelete(name)
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   this.setState({ selected: newSelected });
  // };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  onSearchData = (value) => {
    const { tenantRefList } = this.state;
    if (value !== '' && value !== undefined) {
      const result = tenantRefList.filter(
        (data) =>
          data.name.toLowerCase().includes(value.toLowerCase()) ||
          data.id.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({ tenantList: result });
    } else {
      this.setState({ tenantList: tenantRefList });
    }
  };

  onFilter = (status) => {
    const { tenantRefList } = this.state;

    const filterList = tenantRefList.filter(
      (data) => data.status.toLowerCase() === status.toLowerCase()
    );

    if (filterList && filterList.length > 0) {
      return filterList;
    }
  };

  onFilterData = (value) => {
    let resArray = [];
    const { tenantRefList } = this.state;
    if (value && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        const result = this.onFilter(value[i].value);
        if (result !== undefined) {
          if (result && result.length > 1) {
            for (let k = 0; k < result.length; k++) {
              resArray.push(result[k]);
            }
          } else {
            resArray.push(result[0]);
          }
        }
      }
      this.setState({ tenantList: resArray });
    } else {
      this.setState({ tenantList: tenantRefList });
    }

    // const filter = clusterList.filter((data)=>data.status  )
  };

  // DELETE
  tenantToDelete = (id) => {
    const { selectedTenants } = this.state;
    let choosedTenants = [...selectedTenants];
    const isPresent = selectedTenants.includes(id);
    const index = selectedTenants.indexOf(id);

    if (!isPresent) {
      choosedTenants.push(id);
      this.setState({ selectedTenants: choosedTenants });
    } else {
      choosedTenants.splice(index, 1);
      this.setState({ selectedTenants: choosedTenants });
    }
  };

  onDeleteTenant = async () => {
    const { selectedTenants } = this.state;

    for (let z = 0; z < selectedTenants.length; z++) {
      await deleteAPI({
        url: `${GET_TENANT}${selectedTenants[z]}`,
        type: 'Tenant',
      });
    }
    this.onDeleteDone();
  };

  onDeleteDone = () => {
    this.setState(
      {
        tenantList: [],
        selectedTenants: [],
        selected: [],
        page: 0,
        loading: true,
      },
      () => {}
    );
  };

  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  render() {
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      loading,
      page,
      tenantList,
      isDone,
      isDelete,
      selectedTenants,
    } = this.state;

    return (
      <Layout crumbs={crumbs} {...this.props}>
        <div className="text-right my-4">
          {/* <button
            className="buttonX"
            onClick={() =>
              this.props.history.push(`${CREATE_TENANT_PATH}/create`)
            }
          >
            <i className="bi bi-plus-lg "></i> Create Tenant
          </button>
          <button disabled={selectedTenants.length===0} onClick={this.handleDeleteModal} className="delete-btn">
            {" "}
            <i className="bi bi-trash3"></i>Delete Tenant{" "}
          </button> */}
        </div>
        <CommonCard>
          <div className="row">
            <div className="col-md-8">
              <div className="text-left mb-3">
                <input
                  type="search"
                  placeholder="Search By ID, Name"
                  className="input-boxes"
                  onChange={(e) => this.onSearchData(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="text-right ">
                <MultiSelect
                  closeMenuOnSelect={false}
                  isMulti
                  options={options}
                  handleChange={(value) => this.onFilterData(value)}
                  placeholder="Filter By Status"
                />
              </div>
            </div>
          </div>
          <div className="desktop-table">
            <TableContainer>
              <Table
                className="tableX"
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={tenantList.length}
                  headCells={headCells}
                />
                {isDone && tenantList && tenantList.length > 0 ? (
                  <TableBody className="table Table">
                    {stableSort(tenantList, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((tenantData, index) => {
                        const isItemSelected = this.isSelected(tenantData.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                          >
                            {/* <TableCell padding="checkbox">
                              <Checkbox
                                style={{paddingLeft:'.7rem'}}
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": `tenant-check ${index}`,
                                }}
                                onClick={(event) =>
                                  this.handleClick(event, row.id)
                                }
                                name={`tenant-check ${index}`}
                              />
                            </TableCell> */}

                            <TableCell
                              className="id"
                              onClick={() =>
                                this.props.history.push(
                                  `${TENANT_DETAILS_PATH}/${tenantData.id}`
                                )
                              }
                            >
                              <span
                                data-testid={`tenant-id ${index}`}
                                className="id"
                              >
                                {tenantData.name}
                              </span>
                            </TableCell>

                            <TableCell>
                              {getReadableDate(tenantData.created_timestamp)}
                            </TableCell>
                            <TableCell>
                              {getReadableDate(tenantData.modified_timestamp)}
                            </TableCell>
                            {/* <TableCell>
                              {row.description}
                            </TableCell> */}

                            <TableCell>
                              <span
                                data-testid={`tenant-status ${index}`}
                                className={
                                  tenantData.status === 'Pending' ||
                                  tenantData.status === 'pending'
                                    ? 'pending'
                                    : tenantData.status === 'Available' ||
                                        tenantData.status === 'available'
                                      ? 'available'
                                      : 'disable'
                                }
                              >
                                {tenantData.status}
                              </span>
                            </TableCell>
                            <TableCell>
                              <TenantMenu
                                title={tenantData.name}
                                id={tenantData.id}
                                history={this.props.history}
                                data={tenantData}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>

            {loading ? (
              <SkeletonLoader />
            ) : isDone && tenantList && tenantList.length > 0 ? (
              <TablePagination
                className="table-pagination"
                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                component="div"
                count={tenantList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            ) : !loading && tenantList && tenantList.length === 0 ? (
              <p className="text_color text-center no-data-msg mt-2">
                No Data Found
              </p>
            ) : null}
          </div>
          <div className="mobile-table">
            {loading ? (
              <SkeletonLoader />
            ) : isDone && tenantList && tenantList.length ? (
              tenantList.map((tenantData, index) => {
                return (
                  <div key={index} className="mob-main">
                    <div className="mob-rows">
                      <div className="keys">Name</div>
                      <div
                        onClick={() =>
                          this.props.history.push(
                            `${TENANT_DETAILS_PATH}/${tenantData.id}`
                          )
                        }
                        className="values id"
                      >
                        {tenantData.name}
                      </div>
                    </div>

                    {/* <div className="mob-rows">
                      <div className="keys">Description</div>
                      <div className="values">{data.description}</div>
                    </div> */}

                    <div className="mob-rows">
                      <div className="keys">Created</div>
                      <div className="values">
                        {getReadableDate(tenantData.created_timestamp)}
                      </div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">Updated</div>
                      <div className="values">
                        {getReadableDate(tenantData.modified_timestamp)}
                      </div>
                    </div>
                    <div className="mob-rows">
                      <div className="keys">Status</div>

                      <div
                        className={
                          tenantData.status === 'Pending'
                            ? 'pending values'
                            : tenantData.status === 'Available'
                              ? 'available values'
                              : 'disable values'
                        }
                      >
                        {tenantData.status}
                      </div>
                    </div>

                    <div className="mob-rows">
                      <div className="keys">Actions</div>
                      <div className="text_color_main">
                        <TenantMenu
                          title={tenantData.name}
                          id={tenantData.id}
                          history={this.props.history}
                          data={tenantData}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : !loading && tenantList && tenantList.length === 0 ? (
              <p className="text_color text-center no-data-msg mt-2">
                No Data Found
              </p>
            ) : null}
          </div>
        </CommonCard>

        {isDelete && (
          <DeleteModal
            deleteCount={selectedTenants.length}
            title="Tenants"
            show={isDelete}
            handleClose={this.handleDeleteModal}
            onDelete={this.onDeleteTenant}
          />
        )}
      </Layout>
    );
  }
}
