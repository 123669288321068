import React, { Component } from 'react';
import { FaCopy } from 'react-icons/fa';
import ActivityLoader from '../../../components/Loader/ActivityLoader';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import Layout from '../../../components/layout/SideBar/Layout';
import DeleteModal from '../../../modals/Common/DeleteModal';
import PermissionList from './PermissionList';
import {
  CREATE_ROLES_PATH,
  ROLES_PATH,
  ROLE_DETAILS_PATH,
} from '../../../router/PathConst';
import {
  ADD_ROLES,
  CREATE_PERMISSION,
  DELETE_PERMISSION,
  GET_ROLES_UUID,
} from '../../../utils/apiConstant';
import { deleteAPI, get, put } from '../../../utils/apiMethods';
import {
  createMessage,
  deleteMessage,
  getReadableDate,
  deleteRoleById,
} from '../../../utils/common';
import AddNewPermissionModal from '../../../modals/Roles/AddNewPermissionModal';

export default class RoleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isData: false,
      permissions: [],
      permissionUUID: [],
      isDeletePermission: false,
      selectedPermissions: [],
      assignPermissionModal: false,
      loading: true,
      actionDone: true,
      isAddNewPermissionModal: false,
      searchString: '',
    };
  }

  componentDidMount() {
    const modal = localStorage.getItem('modal');
    this.getRoleDetails();
    this.getPermissionId();

    if (modal !== undefined && modal !== null && modal) {
      this.setState({ assignPermissionModal: true }, () => {
        localStorage.removeItem('modal');
      });
    }
  }
  getRoleDetails = async () => {
    const id = this.props.match.params.id;

    const URL = `${GET_ROLES_UUID}/${id}`;
    const response = await get({ url: URL });

    if (response && response !== undefined) {
      if (response.status === 'active') {
        response['status'] = 'Active';
      } else {
        response['status'] = 'Inactive';
      }

      this.setState({ data: response, loading: false, isData: true });
    } else {
      this.setState({ loading: false });
    }
  };
  // getPermissionList = async () => {
  //   const id = this.props.match.params.id;

  //   const URL = `${GET_ROLES_UUID}/${id}/permissions`;
  //   const response = await get({ url: URL });

  //   if (response && response !== undefined) {
  //     this.setState({ permissions: response, isData: true });
  //   }
  // };

  // Get List
  getPermissionId = async () => {
    const id = this.props.match.params.id;

    const URL = `${GET_ROLES_UUID}/${id}/permissions`;
    const response = await get({ url: URL });

    this.setState({ permissionUUID: response }, () => {
      this.getPermissionById();
    });
  };

  getPermissionById = async () => {
    const { permissionUUID } = this.state;

    if (permissionUUID && permissionUUID.length) {
      for (let i = 0; i < permissionUUID.length; i++) {
        await this.getPermission(permissionUUID[i]);
      }
    }
  };

  getPermission = async (id) => {
    let array = this.state.permissions;

    const URL = `${CREATE_PERMISSION}/${id}`;

    const response = await get({ url: URL });

    if (response && response !== undefined) {
      array.push(response);
      this.setState({ permissions: array, loading: false, actionDone: true });
    }
  };

  handleSelect = (i, data) => {
    const { permissions } = this.state;
    this.permissionToUnassign(data.id);
    const array = [...permissions];
    array[i].selected = !array[i].selected;
    this.setState({ permissions: array });
  };

  permissionToUnassign = (id) => {
    const { selectedPermissions } = this.state;
    let selectedPermission = [...selectedPermissions];
    const isPresent = selectedPermissions.includes(id);
    const index = selectedPermissions.indexOf(id);

    if (!isPresent) {
      selectedPermission.push(id);
      this.setState({ selectedPermissions: selectedPermission });
    } else {
      selectedPermission.splice(index, 1);
      this.setState({ selectedPermissions: selectedPermission });
    }
  };

  onUnassign = async (type) => {
    const { selectedPermissions } = this.state;

    for (let i = 0; i < selectedPermissions.length; i++) {
      if (type === 'delete') {
        await this.onDeletePermission(selectedPermissions[i]);
      } else {
        await this.onUnassignPermission(selectedPermissions[i], false);
      }
    }

    this.setState(
      {
        permissions: [],
        permissionUUID: [],
        selectedPermissions: [],
        isDeletePermission: false,
      },
      () => {
        this.getPermissionId();
      }
    );
  };

  handleDeleteModal = () => {
    this.setState({ isDelete: !this.state.isDelete });
  };

  onDeleteRole = async () => {
    const { data } = this.state;
    // await deleteAPI({ url: `${ADD_ROLES}/${data.id}` });
    const response = await deleteRoleById(data.id);

    deleteMessage(response, 'Role deleted');
    setTimeout(() => {
      this.props.history.push(ROLES_PATH);
    }, 1000);
  };

  //unassign permission /permissions/{id}
  onUnassignPermission = async (permission, flag) => {
    const id = this.props.match.params.id;

    const response = await deleteAPI({
      url: `${ADD_ROLES}/${id}/permissions/${permission}`,
      type: 'Permission',
    });

    deleteMessage(response, 'Permission unassigned');

    if (flag === undefined) {
      this.setState(
        { permissions: [], permissionUUID: [], selectedPermissions: [] },
        () => {
          this.getPermissionId();
        }
      );
    }
  };

  handlePermissionDeleteModal = (data) => {
    this.setState({
      isDeletePermission: !this.state.isDeletePermission,
      permission: data,
    });
  };

  onDeletePermission = async (id) => {
    const { data } = this.state;

    const response = await deleteAPI({
      url: `${DELETE_PERMISSION}/${data.id}/permissions/${id}`,
      type: 'Permission',
    });

    deleteMessage(response, 'Permission Deleted');

    return response;
  };

  handlePermissionModal = () => {
    this.setState({ assignPermissionModal: !this.state.assignPermissionModal });
  };

  onPermissionAssign = async (data, permissions) => {
    const id = this.props.match.params.id;
    const response = await put({
      url: `${GET_ROLES_UUID}/${id}/permissions`,
      payload: permissions,
      type: 'Role',
    });

    if (response === 201) {
      createMessage(200, 'Permission assigned');
    } else {
      createMessage(response);
    }
    this.setState(
      { permissions: [], permissionUUID: [], assignPermissionModal: false },
      () => {
        this.getPermissionId();
      }
    );
  };

  onCopy = (id) => {
    navigator.clipboard.writeText(id);
    this.setState({ isCopy: true }, () => {
      setTimeout(() => {
        this.setState({ isCopy: false });
      }, 1000);
    });
  };

  onStoreValues = () => {
    const { assignPermissionModal } = this.state;
    localStorage.setItem('modal', assignPermissionModal);
  };

  onPermissionSelect = (list) => {
    this.setState({ selectedPermissions: list });
  };

  toggleAddNewPermissionModal = () => {
    this.setState({
      isAddNewPermissionModal: !this.state.isAddNewPermissionModal,
    });
  };

  addNewPermissionsInList = (permissionList) => {
    this.setState({
      permissions: [...permissionList, ...this.state.permissions],
    });
  };

  onSearchData = (search) => {
    this.setState({ searchString: search });
  };

  render() {
    const {
      data,
      assignPermissionModal,
      permissions,
      isData,
      selectedPermissions,
      isDelete,
      isDeletePermission,
      isCopy,
      loading,
      actionDone,
      isAddNewPermissionModal,
      searchString,
    } = this.state;

    const crumbs = [
      {
        title: 'Roles',
        path: ROLES_PATH,
        active: false,
      },
      {
        title: 'Role Details',
        path: ROLE_DETAILS_PATH,
        active: true,
      },
    ];

    return (
      <Layout crumbs={crumbs} {...this.props}>
        {loading && !isData ? (
          <div className="mt-3">
            <SkeletonLoader />
          </div>
        ) : !loading && isData ? (
          <div className="role-details">
            <div className="details-section">
              <div className="details-section-1">
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Name:</h6>
                    <h6 data-testid="name" className="details-data-value">
                      {data.name ? data.name : '-'}{' '}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label"> ID:</h6>
                    <h6 className="details-data-value copy-id">
                      <span
                        title="Copy to Clipboard"
                        onClick={() => this.onCopy(data.id)}
                      >
                        {data.id ? data.id : '-'}
                      </span>
                      {isCopy ? (
                        <span
                          style={{
                            marginLeft: '10px',
                            color: 'green',
                            fontSize: '12px',
                          }}
                        >
                          Copied
                        </span>
                      ) : (
                        <span
                          data-testid="copyIcon"
                          title="Copy to Clipboard"
                          className="copy-icon"
                          onClick={() => this.onCopy(data.id)}
                        >
                          {' '}
                          <FaCopy />
                        </span>
                      )}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Created:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(data && data.created_timestamp)}
                    </h6>
                  </div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Updated:</h6>
                    <h6 className="details-data-value">
                      {getReadableDate(data && data.modified_timestamp)}
                    </h6>
                  </div>
                </div>
                <div>
                  <div className="details-data-row">
                    <h6 className="details-data-label">Description:</h6>
                    <h6 className="details-data-value">
                      {data ? data.description : '-'}
                    </h6>
                  </div>
                  {/* <div className="details-data-row">
                    <h6 className="details-data-label">Status:</h6>
                    <h6
                      className={
                        data.status === "inactive" || data.status === "Inactive"
                          ? "disable details-data-value"
                          : "available details-data-value"
                      }
                    >
                      {data && data.status}
                    </h6>
                  </div> */}
                </div>
              </div>
              <div className="details-section-2">
                <div className="details-button-group">
                  {!data.is_system_role && (
                    <button
                      className="buttonX details-button"
                      onClick={() =>
                        this.props.history.push({
                          pathname: `${CREATE_ROLES_PATH}/${data.id}`,
                          state: {
                            id: data.id,
                          },
                        })
                      }
                    >
                      <i className="bi bi-pencil-square mr-1" />
                      Edit Role
                    </button>
                  )}

                  {!data.is_system_role && (
                    <button
                      className="buttonX white details-button"
                      onClick={this.handleDeleteModal}
                    >
                      <i className="bi bi-trash3" />
                      Delete Role{' '}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row gap-3 mt-4 mb-3">
              <div className="col text-left">
                <h4 className="text_color_main mb-1">Permissions</h4>
                <p className="mb-0">Assigned to this role</p>
              </div>
              <div className="me-0 me-lg-2 mb-2 col-md-8 col-xs-12 text-right d-flex flex-column flex-sm-row justify-content-end align-items-center flex-wrap gap-3">
                <input
                  type="search"
                  placeholder="Search By Name"
                  className="input-boxes"
                  onChange={(e) => this.onSearchData(e.target.value)}
                />
                {actionDone ? (
                  <>
                    <button
                      className="buttonX"
                      type="button"
                      onClick={() => this.toggleAddNewPermissionModal()}
                    >
                      <i className="bi bi-pencil-square me-2" />
                      Add New Permission
                    </button>

                    <button
                      className="buttonX outline"
                      onClick={this.handlePermissionDeleteModal}
                      disabled={selectedPermissions.length === 0}
                    >
                      <i className="bi bi-trash3 me-2" />
                      Delete Permission
                    </button>
                  </>
                ) : (
                  <ActivityLoader />
                )}
              </div>
            </div>

            {permissions && permissions.length > 0 ? (
              <PermissionList
                permissionList={permissions.filter((permission) => {
                  if (searchString === '') {
                    return true;
                  }
                  return permission.name
                    .toLowerCase()
                    .includes(searchString.toLowerCase());
                })}
                onPermissionSelect={this.onPermissionSelect}
                history={this.props.history}
                onDeletePermission={this.onDeletePermission}
              />
            ) : (
              <p className="text_color_main text-center no-data-msg mt-2">
                No permissions found
              </p>
            )}
          </div>
        ) : (
          <h4 className="text_color_main text-center no-data-msg mt-2">
            No Data Found
          </h4>
        )}

        {isDelete && (
          <DeleteModal
            title={data.name}
            modalType="role"
            show={isDelete}
            handleClose={this.handleDeleteModal}
            onDelete={this.onDeleteRole}
          />
        )}

        {isDeletePermission && (
          <DeleteModal
            deleteCount={selectedPermissions.length}
            title="Permissions"
            show={isDeletePermission}
            handleClose={this.handlePermissionDeleteModal}
            onDelete={() => this.onUnassign('delete')}
          />
        )}

        <AddNewPermissionModal
          show={isAddNewPermissionModal}
          handleClose={this.toggleAddNewPermissionModal}
          roleId={this.props?.match?.params?.id}
          addNewPermissionsInList={this.addNewPermissionsInList}
          currentPermissions={permissions}
        />
      </Layout>
    );
  }
}
