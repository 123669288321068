import { ReactFlowProvider } from 'reactflow';
import DFGsSummary from './DFGsSummary';

function FlowProviderDFGs(props) {
  return (
    <ReactFlowProvider>
      <DFGsSummary {...props} />
    </ReactFlowProvider>
  );
}

export default FlowProviderDFGs;
