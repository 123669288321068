import { Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteModal from '../../../modals/Common/DeleteModal';
import routeConstant from '../../../router/PathConst';
import {
  deleteCloudProfileById,
  forceDeleteCloudProfileById,
} from '../../../utils/common';
import { toast } from 'react-toastify';
import NormalModal from '../../../modals/Common/NormalModal';
import { post } from '../../../utils/apiMethods';
import ApiConstant from '../../../utils/apiConstant';

export default function CloudProfileMenu({
  id,
  history,
  onComplete,
  title,
  status,
  data,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [cloudProfileId, setCloudProfileId] = React.useState('');
  const [isAfterDeleteModalVisible, setIsAfterDeleteModalVisible] =
    useState(false);
  const [normalModalData, setNormalModalData] = useState('');
  const [isDeletePermission, setIsDeletePermission] = useState(false);
  const [isForceDeleteAllowed, setIsForceDeleteAllowed] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    checkDeletePermission();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setCloudProfileId(id);
  }, [id]);

  const openDelete = () => {
    setAnchorEl(null);
    setIsDelete(true);
  };

  const handleNormalModal = () => {
    setIsAfterDeleteModalVisible((prevState) => !prevState);
  };

  const closeDelete = () => setIsDelete(false);

  const onDeleteProfile = async () => {
    setNormalModalData('');

    setIsDelete(false);

    const response = await deleteCloudProfileById(id);

    if (response.status === 200) {
      toast.success('Delete cloud profile triggered.');

      onComplete();
    } else {
      if (response?.response?.status === 409) {
        if (response?.response?.data?.clusters?.length > 0) {
          let clusterNames = '';

          await response?.response?.data?.clusters?.forEach(
            (cluster) =>
              (clusterNames = (
                <>
                  {clusterNames}
                  <li className="mb-0">{cluster.name}</li>
                </>
              ))
          );

          setNormalModalData(
            <>
              <h5 className="fw-bold">Clusters:</h5>
              <div className="overflow-y-scroll" style={{ maxHeight: '150px' }}>
                <ul>{clusterNames}</ul>
              </div>
            </>
          );

          clusterNames = '';
        }

        if (response?.response?.data?.dependent_clusters) {
          let dependentCloudProfileNames = '';

          await Object.entries(
            response?.response?.data?.dependent_clusters
          ).forEach(
            ([cloudProfileId, clusterList]) =>
              (dependentCloudProfileNames = (
                <>
                  {dependentCloudProfileNames}
                  <li className="mb-0">
                    {
                      response?.response?.data?.cloud_profiles?.find(
                        (data) => data?.id === cloudProfileId
                      )?.name
                    }
                  </li>
                  <ul>
                    {clusterList?.map((cluster) => (
                      <li className="mb-0">{cluster.name}</li>
                    ))}
                  </ul>
                </>
              ))
          );

          setNormalModalData((prevState) => (
            <>
              {prevState}
              <h5 className="fw-bold">
                Dependent Cloud Profiles and Clusters:
              </h5>
              <div className="overflow-y-scroll" style={{ maxHeight: '150px' }}>
                <ul>{dependentCloudProfileNames}</ul>
              </div>
            </>
          ));

          dependentCloudProfileNames = '';
        }

        if (
          response?.response?.data?.clusters?.length > 0 ||
          response?.response?.data?.dependent_clusters
        ) {
          handleNormalModal();
        }
      }

      if (
        response?.response?.status === 409 &&
        response?.response?.data?.message
      ) {
        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }

      if (response?.response?.status !== 409) {
        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }
    }
  };

  async function checkDeletePermission() {
    const response = await post({
      url: ApiConstant.POST_AUTH_CHECK,
      payload: {
        resource_id: id,
        resource_type: 'CLOUDCONFIG',
        OPERATION: 'delete',
      },
      type: 'v2',
    });

    if (response.status === 200) {
      if (response.data.is_allowed) {
        setIsDeletePermission(true);
      } else {
        setIsDeletePermission(false);
      }

      if (response.data.force_delete_allowed) {
        setIsForceDeleteAllowed(true);
      } else {
        setIsForceDeleteAllowed(false);
      }
    } else {
      setIsDeletePermission(false);
    }
  }

  const onForceDeleteProfile = async () => {
    setNormalModalData('');

    setIsDelete(false);

    const response = await forceDeleteCloudProfileById(id);

    if (response.status === 200) {
      toast.success('Delete cloud profile triggered.');

      onComplete();
    } else {
      if (response?.response?.status !== 409) {
        toast.warning(
          response?.response?.data?.message || 'Something went wrong.'
        );
      }
    }
  };

  return (
    <>
      <i
        className="bi bi-three-dots-vertical dots"
        data-testid="user-dots"
        style={{ cursor: 'pointer' }}
        onClick={handleClick}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() =>
            history.push({
              pathname: `${routeConstant.CLOUD_PROFILE_DETAILS(id)}`,
              state: {
                id: id,
              },
            })
          }
        >
          View Details
        </MenuItem>

        <MenuItem
          onClick={() =>
            history.push({
              pathname: `${routeConstant.CLOUD_PROFILE_ADD_EDIT(
                cloudProfileId
              )}`,
              state: {
                id: cloudProfileId,
              },
            })
          }
          disabled={status !== 'Pending Completion'}
        >
          Prepare Profile
        </MenuItem>

        <MenuItem
          onClick={() =>
            history.push({
              pathname: routeConstant.CLOUD_PROFILE_ACTIVATE(cloudProfileId),
              state: {
                id: cloudProfileId,
              },
            })
          }
          disabled={status !== 'Pending Completion'}
        >
          Activate Profile
        </MenuItem>
        <MenuItem
          onClick={openDelete}
          disabled={
            status === 'Deleting Resources' ||
            status === 'Creating Resources' ||
            !isDeletePermission
          }
        >
          Delete Profile
        </MenuItem>
      </Menu>

      {isDelete && (
        <DeleteModal
          title={title}
          modalType="cloud profile"
          show={isDelete}
          handleClose={closeDelete}
          onDelete={onDeleteProfile}
          onForceDelete={onForceDeleteProfile}
          isForceDeleteAllowed={isForceDeleteAllowed}
          failedToDeleteResource={[
            ...(data?.failed_to_delete || []),
            ...(data?.owner_metastore_configuration?.details
              ?.failed_to_delete || []),
            ...(data?.owner_deltalake_configuration?.details
              ?.failed_to_delete || []),
          ]}
        />
      )}

      {isAfterDeleteModalVisible && (
        <NormalModal
          show={isAfterDeleteModalVisible}
          handleClose={handleNormalModal}
          title="Delete failed, Resources using cloud profile."
          description={normalModalData}
        />
      )}
    </>
  );
}
