import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layout/SideBar/Layout';
import routeConstant from '../../../router/PathConst';
import CommonCard from '../../../components/CommonCard/CommonCard';
import { MenuItem, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FloatingLabelDropdown from '../../../components/FloatingLabelDropdown/FloatingLabelDropdown';
import Checkbox from '@mui/material/Checkbox';
import { get, post } from '../../../utils/apiMethods';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import ApiConstant from '../../../utils/apiConstant';
import { toast } from 'react-toastify';

export default function IdentityProvider(props) {
  const crumbs = [
    {
      title: 'Identity Provider Settings',
      path: routeConstant.IDENTITY_PROVIDER,
      active: true,
    },
  ];

  /// Variables

  const [isSensitiveEdit, setIsSensitiveEdit] = useState(false);

  const [names] = useState([
    {
      title: 'GOOGLE',
      value: 'GOOGLE',
    },
    {
      title: 'OKTA',
      value: 'OKTA',
    },
    {
      title: 'CYBERARK',
      value: 'CYBERARK',
    },
  ]);

  const [protocols] = useState([
    {
      title: 'OIDC',
      value: 'OIDC',
    },
    {
      title: 'SAML',
      value: 'SAML',
    },
  ]);

  const [statusList] = useState([
    {
      title: 'Enable',
      value: 'active',
    },
    {
      title: 'Disable',
      value: 'inactive',
    },
  ]);

  const [serviceProviderData, setServiceProviderData] = useState({
    name: '',
    protocol: '',
    issuerURL: '',
    clientID: '',
    clientSecret: '',
    singleSignOnURl: '',
    iDPEntityID: '',
    iDPX509Certificate: '',
    allowAllIdpUsers: false,
    allowMembers: '',
    status: '',
  });
  const [isAPILoading, setIsAPILoading] = useState(false);

  /// Form states

  const initialValues = {
    name: serviceProviderData?.name || '',
    protocol: serviceProviderData?.protocol || 'SAML',
    issuerURL: serviceProviderData?.issuerURL || '',
    clientID: serviceProviderData?.clientID || '',
    clientSecret: serviceProviderData?.clientSecret || '',
    singleSignOnURl: serviceProviderData?.singleSignOnURl || '',
    iDPEntityID: serviceProviderData?.iDPEntityID || '',
    iDPX509Certificate: serviceProviderData?.iDPX509Certificate || '',
    allowAllIdpUsers: serviceProviderData?.allowAllIdpUsers || false,
    allowMembers: serviceProviderData?.allowMembers || '',
    status: serviceProviderData?.status || '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    protocol: Yup.string().required('Required'),
    status: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: cloudServiceProviderHandler,
    enableReinitialize: true,
  });

  /// Use Effects

  useEffect(() => {
    getCloudServiceProviderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /// Functions

  async function getCloudServiceProviderData() {
    try {
      setIsAPILoading(true);

      const URL = ApiConstant.GET_IDENTITY_PROVIDER;

      const response = await get({ url: URL });

      if (response?.id) {
        setServiceProviderData((prevData) => ({
          ...prevData,
          name: response?.name || '',
          protocol: response?.protocol || '',
          issuerURL: response?.idp_details?.oidc?.issuer_url || '',
          clientID: response?.idp_details?.oidc?.client_id || '',
          clientSecret: response?.idp_details?.oidc?.client_secret || '',
          singleSignOnURl: response?.idp_details?.saml?.idp_sso_url || '',
          iDPEntityID: response?.idp_details?.saml?.idp_entity_id || '',
          iDPX509Certificate:
            response?.idp_details?.saml?.idp_certificate || '',
          allowAllIdpUsers: response?.allow_all_idp_users || false,
          allowMembers: response?.mandatory_group || '',
          status: response?.status || '',
        }));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsAPILoading(false);
    }
  }

  async function cloudServiceProviderHandler(values, { setSubmitting }) {
    try {
      const bodyObj = {
        name: values.name,
        protocol: values.protocol,
        allow_all_idp_users: values.allowAllIdpUsers,
        mandatory_group: values.allowMembers,
        status: values.status,
        is_legacy: values.name === 'GOOGLE' ? true : false,
        idp_details: {
          saml: {
            idp_sso_url: values.singleSignOnURl,
            idp_entity_id: values.iDPEntityID,
            idp_certificate: values.iDPX509Certificate,
          },
          oidc: {
            issuer_url: values.issuerURL,
            client_id: values.clientID,
            client_secret: values.clientSecret,
          },
        },
      };

      const URL = ApiConstant.POST_IDENTITY_PROVIDER;

      const response = await post({ url: URL, payload: bodyObj, type: 'v2' });

      if (response.status === 200) {
        getCloudServiceProviderData();
        toast.success(
          'Cloud service provider information updated successfully!'
        );
      } else {
        toast.error(response?.response?.data.message);
      }
    } catch (error) {
      if (
        error instanceof TypeError &&
        error.message.includes('Failed to fetch')
      ) {
        toast.error('Network error. Please check your internet connection.');
      } else {
        toast.error('An unexpected error occurred.');
      }

      console.error('Error:', error);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Layout crumbs={crumbs} {...props}>
      <CommonCard className="add-edit-page card-wrapper" isForm>
        {isAPILoading ? (
          <SkeletonLoader />
        ) : (
          <div className="form-wrapper">
            <div
              className={`position-relative ${
                isSensitiveEdit
                  ? 'edit-sensitive-border1'
                  : 'edit-sensitive-border1 edit-sensitive-disable'
              }`}
            >
              <div className="mt-4 edit-sensitive-field" style={{ right: 0 }}>
                <Checkbox
                  id="unlock-check"
                  color="primary"
                  style={{ color: 'var(--clr-text-300)' }}
                  checked={isSensitiveEdit}
                  onClick={() => {
                    formik.resetForm();
                    setIsSensitiveEdit((prevState) => !prevState);
                  }}
                />

                <label htmlFor="unlock-check">
                  {isSensitiveEdit ? 'Lock to cancel edit' : 'Unlock to edit'}
                </label>
              </div>

              <form
                className="d-flex flex-column"
                onSubmit={formik.handleSubmit}
              >
                <h3>Identity Provider Settings</h3>
                <h5 className="mt-3">1. Cloud Service Provider Information</h5>
                <FloatingLabelDropdown
                  className="mt-3"
                  label="Identity Provider Name"
                  name="name"
                  inputProps={{ 'data-testid': 'name' }}
                  value={formik.values.name}
                  handleChange={formik.handleChange}
                  error={formik.touched.name && formik.errors.name}
                  required
                  disabled={!isSensitiveEdit}
                >
                  {names.map((name) => (
                    <MenuItem key={name?.value} value={name?.value}>
                      {name?.title}
                    </MenuItem>
                  ))}
                </FloatingLabelDropdown>
                <FloatingLabelDropdown
                  className="mt-3"
                  label="SSO Protocol"
                  name="protocol"
                  inputProps={{ 'data-testid': 'protocol' }}
                  value={formik.values.protocol}
                  handleChange={formik.handleChange}
                  error={formik.touched.protocol && formik.errors.protocol}
                  required
                  disabled={!isSensitiveEdit}
                >
                  {protocols.map((protocol) => (
                    <MenuItem key={protocol?.value} value={protocol?.value}>
                      {protocol?.title}
                    </MenuItem>
                  ))}
                </FloatingLabelDropdown>

                {formik.values.protocol === 'SAML' && (
                  <>
                    <TextField
                      className={`mt-4`}
                      label="Single Sign on URL"
                      name="singleSignOnURl"
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      inputProps={{ 'data-testid': 'single-sign-on-URl' }}
                      autoComplete="off"
                      InputLabelProps={{
                        required: false,
                      }}
                      value={formik.values.singleSignOnURl}
                      onChange={formik.handleChange}
                      error={
                        Boolean(formik.errors.singleSignOnURl) &&
                        formik.touched.singleSignOnURl
                      }
                      helperText={formik.errors.singleSignOnURl}
                      required={formik.values.protocol === 'SAML'}
                      disabled={!isSensitiveEdit}
                    />

                    <TextField
                      className={`mt-3`}
                      label="IDP Entity ID"
                      name="iDPEntityID"
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      inputProps={{ 'data-testid': 'IDP-entity-ID' }}
                      autoComplete="off"
                      InputLabelProps={{
                        required: false,
                      }}
                      value={formik.values.iDPEntityID}
                      onChange={formik.handleChange}
                      error={
                        Boolean(formik.errors.iDPEntityID) &&
                        formik.touched.iDPEntityID
                      }
                      helperText={formik.errors.iDPEntityID}
                      required={formik.values.protocol === 'SAML'}
                      disabled={!isSensitiveEdit}
                    />

                    <TextField
                      className={`mt-3`}
                      label="IDP X509 Certificate"
                      name="iDPX509Certificate"
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      multiline
                      rows={4}
                      inputProps={{ 'data-testid': 'IDP-X509-certificate' }}
                      autoComplete="off"
                      InputLabelProps={{
                        required: false,
                      }}
                      value={formik.values.iDPX509Certificate}
                      onChange={formik.handleChange}
                      error={
                        Boolean(formik.errors.iDPX509Certificate) &&
                        formik.touched.iDPX509Certificate
                      }
                      helperText={formik.errors.iDPX509Certificate}
                      required={formik.values.protocol === 'SAML'}
                      disabled={!isSensitiveEdit}
                    />
                  </>
                )}

                {formik.values.protocol === 'OIDC' && (
                  <>
                    <TextField
                      className={`mt-4`}
                      label="Issuer URL"
                      name="issuerURL"
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      inputProps={{ 'data-testid': 'issue-URL' }}
                      autoComplete="off"
                      InputLabelProps={{
                        required: false,
                      }}
                      value={formik.values.issuerURL}
                      onChange={formik.handleChange}
                      error={
                        Boolean(formik.errors.issuerURL) &&
                        formik.touched.issuerURL
                      }
                      helperText={formik.errors.issuerURL}
                      required={formik.values.protocol === 'OIDC'}
                      disabled={!isSensitiveEdit}
                    />

                    <TextField
                      className={`mt-3`}
                      label="Client ID"
                      name="clientID"
                      type="text"
                      id="outlined-basic"
                      variant="outlined"
                      inputProps={{ 'data-testid': 'client-ID' }}
                      autoComplete="off"
                      InputLabelProps={{
                        required: false,
                      }}
                      value={formik.values.clientID}
                      onChange={formik.handleChange}
                      error={
                        Boolean(formik.errors.clientID) &&
                        formik.touched.clientID
                      }
                      helperText={formik.errors.clientID}
                      required={formik.values.protocol === 'OIDC'}
                      disabled={!isSensitiveEdit}
                    />

                    <TextField
                      className={`mt-3`}
                      label="Client Secret"
                      name="clientSecret"
                      type={
                        serviceProviderData.clientSecret === ''
                          ? 'text'
                          : 'password'
                      }
                      id="outlined-basic"
                      variant="outlined"
                      inputProps={{ 'data-testid': 'client-secret' }}
                      autoComplete="off"
                      InputLabelProps={{
                        required: false,
                      }}
                      value={formik.values.clientSecret}
                      onChange={formik.handleChange}
                      error={
                        Boolean(formik.errors.clientSecret) &&
                        formik.touched.clientSecret
                      }
                      helperText={formik.errors.clientSecret}
                      required={formik.values.protocol === 'OIDC'}
                      disabled={!isSensitiveEdit}
                    />
                  </>
                )}

                <div
                  className={`mt-4 p-4 ${
                    formik.values.allowAllIdpUsers
                      ? 'edit-sensitive-border'
                      : 'edit-sensitive-border edit-sensitive-disable'
                  }`}
                >
                  <div className="">
                    <Checkbox
                      id="access-for-auth-users-check"
                      color="primary"
                      name="allowAllIdpUsers"
                      style={{ color: 'var(--clr-text-300)' }}
                      checked={formik.values.allowAllIdpUsers}
                      onClick={(e) =>
                        formik.setFieldValue(
                          e.target.name,
                          Boolean(e.target.checked)
                        )
                      }
                      disabled={!isSensitiveEdit}
                    />

                    <label htmlFor="access-for-auth-users-check">
                      All access for all authenticated users
                    </label>
                  </div>
                  <TextField
                    className={`w-100 mt-2`}
                    label="Allow only members of the following group"
                    name="allowMembers"
                    type="text"
                    id="outlined-basic"
                    variant="outlined"
                    inputProps={{ 'data-testid': 'allow-members' }}
                    autoComplete="off"
                    InputLabelProps={{
                      required: false,
                    }}
                    value={
                      formik.values.allowAllIdpUsers
                        ? ''
                        : formik.values.allowMembers
                    }
                    onChange={formik.handleChange}
                    error={
                      Boolean(formik.errors.allowMembers) &&
                      formik.touched.allowMembers
                    }
                    helperText={formik.errors.allowMembers}
                    required={!formik.values.allowAllIdpUsers}
                    disabled={
                      !isSensitiveEdit || formik.values.allowAllIdpUsers
                    }
                  />
                  <p style={{ color: 'var(--clr-text-500)' }}>
                    Ensure the Group Name matches the Group Name in IDP, Names
                    are case sensitive.
                  </p>
                </div>

                <FloatingLabelDropdown
                  className="mt-3"
                  label="Status"
                  name="status"
                  inputProps={{ 'data-testid': 'status' }}
                  value={formik.values.status}
                  handleChange={formik.handleChange}
                  error={formik.touched.status && formik.errors.status}
                  required
                  disabled={!isSensitiveEdit}
                >
                  {statusList?.map((status) => (
                    <MenuItem value={status?.value} key={status?.value}>
                      {status?.title}
                    </MenuItem>
                  ))}
                </FloatingLabelDropdown>

                <div className="mt-5 d-flex justify-content-end gap-3">
                  <button
                    className="buttonX"
                    type="submit"
                    disabled={!isSensitiveEdit}
                  >
                    {formik.isSubmitting ? 'Loading...' : 'Save'}
                  </button>
                  <button
                    className="buttonX white"
                    type="reset"
                    onClick={() => {
                      formik.resetForm();
                      setIsSensitiveEdit((prevState) => !prevState);
                    }}
                    disabled={!isSensitiveEdit}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </CommonCard>
    </Layout>
  );
}
