import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '@mui/material';
import Layout from '../../../components/layout/SideBar/Layout';
import CommonCard from '../../../components/CommonCard/CommonCard';
import './Tenant.scss';
import { CREATE_TENANT_PATH, TENANT_PATH } from '../../../router/PathConst';
import { ADD_TENANT, UPDATE_TENANT } from '../../../utils/apiConstant';
import { get, post, put } from '../../../utils/apiMethods';
import { createMessage } from '../../../utils/common';

export default function AddEditTenant(props) {
  const [propsArray, setPropsArray] = useState([
    {
      id: '',
      name: '',
    },
  ]);
  const [type, setType] = useState('add');
  const [data, setData] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [isFile, setIsFile] = useState(false);

  const validationSchema = Yup.object().shape({
    tenantName: Yup.string().required('Please enter Name'),
  });

  useEffect(() => {
    if (props.match.params.type === 'create') {
      setType(props.match.params.type);
    } else {
      setType('edit');
    }
  }, [props.match.params.type]);

  useEffect(() => {
    const getTenantData = async () => {
      const id = props.match.params.type;
      const URL = `${ADD_TENANT}/${id}`;

      const response = await get({ url: URL });

      if (response && response !== undefined) {
        setData(response);
      }
    };
    if (props.match.params.type !== 'create') {
      getTenantData();
    }
  }, [props.match.params.type, props.location.state]);

  const formik = useFormik({
    initialValues: {
      tenantName: type === 'edit' ? data.name : isFile ? jsonData.name : '',
    },
    validationSchema: isFile ? null : validationSchema,
    onSubmit: (values) => {
      onSubmitCall(values);
    },
    enableReinitialize: true,
  });

  const addProps = () => {
    const array = [...propsArray];
    const obj = {
      id: '',
      name: '',
    };
    array.push(obj);
    setPropsArray(array);
  };
  const deleteProps = (i) => {
    const array = [...propsArray];
    array.splice(i, 1);
    setPropsArray(array);
  };
  const hadleChangeProps = (e, index, type) => {
    const array = [...propsArray];
    if (type === 'id') {
      array[index].id = e.target.value;
    } else {
      array[index].name = e.target.value;
    }
    setPropsArray(array);
  };

  const onJsonUpload = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      console.log('e.target.result', e.target.result);
      const response = JSON.parse(e.target.result);
      console.log('Import data', response);
      setJsonData(response);
      setIsFile(true);
    };
  };

  //

  // new
  const getTenantData = async () => {
    const id = props.location.state.id;
    const URL = `${ADD_TENANT}/${id}`;
    const response = await get({ url: URL });
    if (response && response !== undefined) {
      setData(response);
    }
  };

  //
  const onSubmitCall = async (values) => {
    const payload = [
      {
        name: values.tenantName,
        props: propsArray,
      },
    ];

    if (type === 'edit') {
      const id = props.location.state.id;
      const url = `${UPDATE_TENANT}/${id}`;
      const response = await put({
        url: url,
        payload: payload,
        type: 'Tenant',
      });
      if (response && response.id) {
        createMessage(200, 'Tenant updated');
      } else {
        createMessage(response);
      }
      if (response && response.id) {
        getTenantData();
      }
    } else {
      const response = await post({
        url: ADD_TENANT,
        payload: payload,
        type: 'Tenant',
      });
      if (response && response.id) {
        createMessage(200, 'Tenant created');
      } else {
        createMessage(response);
      }
    }
  };
  const crumbs = [
    {
      title: 'Tenant',
      path: TENANT_PATH,
      active: false,
    },
    {
      title: type === 'edit' ? 'Update Tenant' : 'Create Tenant',
      path: CREATE_TENANT_PATH,
      active: true,
    },
  ];
  //
  return (
    <Layout crumbs={crumbs} {...props}>
      <div className="create-tenant">
        <CommonCard className="card-wrapper" isForm>
          <div className="form-wrapper">
            <h4 className="text_primary text-left mx-2">
              {type === 'edit' ? 'Update Tenant' : 'Create Tenant'}
            </h4>

            <form onSubmit={formik.handleSubmit}>
              <div>
                <TextField
                  autoComplete="off"
                  style={{ width: '100%' }}
                  id="outlined-basic"
                  variant="outlined"
                  name="tenantName"
                  label="Tenant Name"
                  value={formik.values.tenantName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.tenantName &&
                    Boolean(formik.errors.tenantName)
                  }
                  helperText={
                    formik.touched.tenantName && formik.errors.tenantName
                  }
                />
              </div>

              <div className="props-wrapper">
                <p className="prop-label mt-2 ">Properties</p>
                <div className="props-box">
                  {propsArray &&
                    propsArray.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className="d-flex justify-content-between align-items-center gap-5 mt-2"
                        >
                          <div className="inputs w-100">
                            <input
                              data-testid={`name-prop ${i}`}
                              className="form-control id-input"
                              placeholder="name"
                              onChange={(e) => hadleChangeProps(e, i, 'id')}
                              value={item.id}
                            />
                          </div>
                          <div className="inputs w-100">
                            <input
                              data-testid={`value-prop ${i}`}
                              className="form-control id-input"
                              placeholder="value"
                              value={item.name}
                              onChange={(e) => hadleChangeProps(e, i, 'name')}
                            />
                          </div>

                          <div style={{ width: '80px', display: 'flex' }}>
                            <i
                              data-testid={`delete-prop ${i}`}
                              onClick={i === 0 ? null : () => deleteProps(i)}
                              className={
                                i === 0
                                  ? 'bi bi-dash-circle disable'
                                  : 'bi bi-dash-circle close-icon'
                              }
                            ></i>

                            <i
                              data-testid={`add-prop ${i}`}
                              onClick={() => addProps()}
                              className={
                                i < propsArray.length - 1
                                  ? 'bi bi-plus-circle vis-hidden'
                                  : 'bi bi-plus-circle add-icon'
                              }
                            ></i>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="mt-5 d-flex justify-content-end gap-2">
                <button className="buttonX" type="submit">
                  {type === 'edit' ? 'Update Tenant' : 'Create Tenant'}
                </button>
                <button
                  className="buttonX white"
                  type="button"
                  onClick={() =>
                    type === 'edit'
                      ? props.history.goBack()
                      : props.history.push(TENANT_PATH)
                  }
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </CommonCard>
      </div>
    </Layout>
  );
}
