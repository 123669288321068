import React, { Component } from 'react';
import Layout from '../../components/layout/SideBar/Layout';
import CommonCard from '../../components/CommonCard/CommonCard';
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@mui/material';
import {
  EnhancedTableHead,
  getComparator,
  stableSort,
} from '../../components/TableComponent/TableComponent';
import './Cluster.scss';
import {
  CLUSTER_DETAILS_PATH,
  CLUSTER_PATH,
  CREATE_CLUSTER_PATH,
} from '../../router/PathConst';
import { getReadableDate } from '../../utils/common';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import ClusterMenu from './ClusterMenu';
import { get, put } from '../../utils/apiMethods';
import ApiConstant from '../../utils/apiConstant';

import SkeletonLoader from '../../components/Loader/SkeletonLoader';
import { getClusterStatusTextClassName } from '../../utils/helperFunctions';
import UserContext from '../../context/user/UserContext';

const crumbs = [
  {
    title: 'Clusters',
    path: CLUSTER_PATH,
    active: true,
  },
];

const options = [
  { value: 'Available', label: 'Available' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Inactive', label: 'Inactive' },
  { value: 'Create_Started', label: 'Create Started' },
  { value: 'Create_Failed', label: 'Create Failed' },
  { value: 'Deployment_Failed', label: 'Deployment_Failed' },
];

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'created_timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Created',
  },
  {
    id: 'modified_timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Updated',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

export default class Cluster extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      clusterList: [],
      order: 'asc',
      orderBy: 'name',
      rowsPerPage: 10,
      page: 0,
      filter: [],
      uuId: [],
      loading: true,
      isDone: false,
      clusterRefList: [],
    };
  }

  componentDidMount() {
    if (this.context) {
      const { currentUser } = this.context;
      const { props } = currentUser;

      if (props && props.sorting && props.sorting !== undefined) {
        const sort = JSON.parse(props.sorting);

        if (sort.cluster !== undefined && sort.cluster.length > 0) {
          this.setState({ orderBy: sort.cluster[0], order: sort.cluster[1] });
        } else {
          this.setState({ orderBy: 'name', order: 'asc' });
        }
      } else {
        this.setState({ orderBy: 'name', order: 'asc' });
      }
    }

    this.getClusterList();

    // this.setState({ clusterList: data });
  }

  getClusterList = async () => {
    const response = await get({ url: ApiConstant.GET_CLUSTERS });

    const newResponse = response?.filter((cluster) => cluster);

    this.setState({
      clusterList: newResponse ?? [],
      clusterRefList: newResponse ?? [],
      loading: false,
      isDone: true,
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property }, () => {
      this.onSaveSort(property, isAsc ? 'desc' : 'asc');
    });
  };

  // save sort
  onSaveSort = (value, order) => {
    const { currentUser } = this.context;

    const { props } = currentUser;

    let array = { ...props };

    if (props && props.sorting) {
      let { sorting } = props;

      const obj = JSON.parse(sorting);
      if (sorting !== undefined) {
        obj.cluster = [value, order];
      } else {
        obj.cluster = [value, order];
      }
      array.sorting = JSON.stringify(obj);

      this.callAPi(array);
    } else {
      const obj = {
        cluster: value,
      };

      currentUser.props = { sorting: JSON.stringify(obj) };
      this.callAPi(currentUser.props);
    }
  };

  callAPi = async (properties) => {
    const { currentUser, storeCurrentUser } = this.context;

    const { username, firstName, lastName, email, password, phone, id } =
      currentUser;

    const payload = {
      id: id,
      username: username,
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      phone: phone,
      props: properties,
    };

    const url = ApiConstant.PUT_USER(id);

    const response = await put({
      url: url,
      payload: payload,
    });

    if (response && response.id) {
      if ('sorting' in response.props) {
        storeCurrentUser(response);
      }
    }
  };

  onFilter = (status) => {
    const { clusterRefList } = this.state;

    const filterList = clusterRefList?.filter(
      (data) => data.status.toLowerCase() === status.toLowerCase()
    );

    if (filterList && filterList.length > 0) {
      return filterList;
    }
  };

  onFilterData = (value) => {
    let resArray = [];
    const { clusterRefList } = this.state;
    if (value && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        const result = this.onFilter(value[i].value);
        if (result !== undefined) {
          if (result && result.length > 1) {
            for (let k = 0; k < result.length; k++) {
              resArray.push(result[k]);
            }
          } else {
            resArray.push(result[0]);
          }
        }
      }
      this.setState({ clusterList: resArray, page: 0 });
    } else {
      this.setState({ clusterList: clusterRefList });
    }

    // const filter = clusterList.filter((data)=>data.status  )
  };

  onSearchData = (value) => {
    const { clusterRefList } = this.state;
    if (value !== '' && value !== undefined) {
      const result = clusterRefList?.filter(
        (data) =>
          data.name.toLowerCase().includes(value.toLowerCase()) ||
          data.id.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({ clusterList: result, page: 0 });
    } else {
      this.setState({ clusterList: clusterRefList });
    }
  };

  onDeleteDone = () => {
    this.setState({ clusterList: [], loading: true, page: 0 }, () => {
      this.getClusterList();
    });
  };

  render() {
    const { order, orderBy, rowsPerPage, page, clusterList, loading, isDone } =
      this.state;

    return (
      <Layout crumbs={crumbs} {...this.props}>
        <div className="text_color cluster-main">
          <div className="text-right mb-4">
            <button
              className="buttonX"
              onClick={() => this.props.history.push(CREATE_CLUSTER_PATH)}
            >
              <i className="bi bi-plus-lg" />
              Create Cluster
            </button>
          </div>
          <div className="row ">
            <div className="col-md-12">
              <CommonCard>
                <div className="row">
                  <div className="col-md-8">
                    <div className="text-left mb-3">
                      <input
                        type="search"
                        placeholder="Search By Name"
                        className="input-boxes"
                        onChange={(e) => this.onSearchData(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="text-right ">
                      <MultiSelect
                        closeMenuOnSelect={false}
                        isMulti
                        options={options}
                        handleChange={(value) => this.onFilterData(value)}
                        placeholder="Filter By Status"
                      />
                    </div>
                  </div>
                </div>

                <>
                  <div className="desktop-table">
                    <Table
                      className="tableX"
                      sx={{
                        minWidth: 750,
                        borderCollapse: 'separate',
                      }}
                      aria-labelledby="tableTitle"
                    >
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        rowCount={clusterList.length}
                        headCells={headCells}
                      />
                      <>
                        {isDone && clusterList && clusterList.length > 0 ? (
                          <>
                            <TableBody className="table Table">
                              {stableSort(
                                clusterList,
                                getComparator(order, orderBy)
                              )
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((clusterData, index) => {
                                  return (
                                    <TableRow
                                      hover
                                      tabIndex={-1}
                                      key={'pc-' + index}
                                    >
                                      <TableCell
                                        align="left"
                                        onClick={() =>
                                          this.props.history.push(
                                            `${CLUSTER_DETAILS_PATH}/${clusterData.id}`,
                                            clusterData
                                          )
                                        }
                                      >
                                        <span
                                          data-testid={`cluster-id ${index}`}
                                          className="id"
                                        >
                                          {clusterData.name}
                                        </span>
                                      </TableCell>

                                      <TableCell align="left">
                                        <span
                                          className={`text-capitalize  ${getClusterStatusTextClassName(
                                            clusterData?.status
                                          )}`}
                                        >
                                          {clusterData.status}
                                        </span>
                                      </TableCell>

                                      <TableCell align="left">
                                        {getReadableDate(
                                          clusterData.created_timestamp
                                        )}
                                      </TableCell>

                                      <TableCell align="left">
                                        {getReadableDate(
                                          clusterData.modified_timestamp
                                        )}
                                      </TableCell>

                                      <TableCell>
                                        <ClusterMenu
                                          clusterData={clusterData}
                                          onComplete={this.onDeleteDone}
                                          history={this.props.history}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </>
                        ) : null}
                      </>
                    </Table>

                    {loading && !isDone ? (
                      <SkeletonLoader />
                    ) : !loading &&
                      isDone &&
                      clusterList &&
                      clusterList.length > 0 ? (
                      <TablePagination
                        className="table-pagination"
                        rowsPerPageOptions={[10, 20, 30, 50, 100]}
                        component="div"
                        count={clusterList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                        showLastButton={true}
                        showFirstButton={true}
                      />
                    ) : !loading && clusterList && clusterList.length === 0 ? (
                      <p className="text_color text-center no-data-msg mt-2">
                        No Data Found
                      </p>
                    ) : null}
                    <></>
                  </div>
                  <div className="mobile-table">
                    {loading ? (
                      <SkeletonLoader />
                    ) : isDone && clusterList && clusterList.length ? (
                      clusterList.map((clusterData, index) => {
                        return (
                          <div key={'mobile-' + index} className="mob-main">
                            <div className="mob-rows">
                              <div className="keys">Name</div>
                              <div
                                onClick={() =>
                                  this.props.history.push(
                                    `${CLUSTER_DETAILS_PATH}/${clusterData.id}`,
                                    clusterData
                                  )
                                }
                                className="values id"
                              >
                                {clusterData.name}
                              </div>
                            </div>

                            <div className="mob-rows">
                              <div className="keys">Status</div>
                              <div
                                className={`values text-capitalize ${
                                  clusterData.status === 'Pending' ||
                                  clusterData.status === 'pending'
                                    ? 'pending'
                                    : clusterData.status === 'Available' ||
                                        clusterData.status === 'available'
                                      ? 'available'
                                      : 'disable'
                                }`}
                              >
                                {clusterData.status}
                              </div>
                            </div>

                            <div className="mob-rows">
                              <div className="keys">Created</div>
                              <div className="values">
                                {getReadableDate(clusterData.created_timestamp)}
                              </div>
                            </div>

                            <div className="mob-rows">
                              <div className="keys">Updated</div>
                              <div className="values">
                                {getReadableDate(
                                  clusterData.modified_timestamp
                                )}
                              </div>
                            </div>

                            <div className="mob-rows">
                              <div className="keys">Actions</div>
                              <div>
                                <ClusterMenu
                                  clusterData={clusterData}
                                  onComplete={this.onDeleteDone}
                                  history={this.props.history}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : !loading && clusterList && clusterList.length === 0 ? (
                      <p className="text_color text-center no-data-msg mt-2">
                        No Data Found
                      </p>
                    ) : null}
                  </div>
                </>
              </CommonCard>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
