export let BASE_URL = '';
export let V1_API = '/v1';
export let V2_API = '/v2';

export const GET_USER_ROLES = `${V1_API}/users/`;

export const GET_ACCESS_TOKEN = `${V1_API}/users/`;
export const CREATE_ACCESS_TOKEN = `${V1_API}/users/`;
export const DELETE_ACCESS_TOKEN = `${V1_API}/users/`;
export const ACTIVATE_ACCESS_TOKEN = `${V1_API}/users/`;
export const DEACTIVATE_ACCESS_TOKEN = `${V1_API}/users/`;

export const CREATE_PERMISSION = `${V1_API}/permissions`;
export const DELETE_PERMISSION = `${V2_API}/roles`;

export const ADD_ROLES = `${V1_API}/roles`;
export const UPDATE_ROLE = `${V1_API}/roles`;
export const GET_ROLES_UUID = `${V1_API}/roles`;

export const ADD_TENANT = `${V1_API}/tenant`;
export const GET_TENANT = `${V1_API}/tenant`;
export const GET_TENANTS = `${V1_API}/tenants`;
export const UPDATE_TENANT = `${V1_API}/tenant`;

export const GET_DATA_SOURCES = `${V1_API}/datasources`;
export const GET_DATA_SOURCE = `${V1_API}/datasource`;
export const ADD_DATA_SOURCES = `${V1_API}/datasources`;
export const UPDATE_DATA_SOURCES = `${V1_API}/datasource`;

export const GET_JOB = `${V1_API}/job`;

export default class ApiConstant {
  static GET_USER_DETAILS = `${V2_API}/auth_details`;

  /// Auth
  static GET_LOGIN_DETAILS = `/auth/login_details`;

  static POST_LOGIN = `/auth/login`;
  static GET_SIGN_IN_VIA_IDP = `/auth/signin_via_idp`;

  static POST_FORGOT_PASSWORD = `/auth/forgot_password`;
  static POST_RESET_PASSWORD = `/auth/reset_password`;

  static GET_SIGN_OUT = `/auth/logout`;

  /// UI Config
  static GET_UI_TABS = `${V2_API}/ui-tabs`;

  /// Get control plane and data-plane version
  static GET_UI_VERSIONS = `${V1_API}/version`;

  /// API operation permission check
  static POST_AUTH_CHECK = `${V2_API}/authcheck`;

  /// Clusters
  static GET_CLUSTERS = `${V2_API}/clusters?offset=0&limit=999`;

  static GET_CLUSTER = `${V2_API}/clusters`;
  static CREATE_CLUSTER = `${V2_API}/clusters`;
  static UPDATE_CLUSTER = `${V2_API}/clusters`;
  static DELETE_CLUSTER = (clusterId) => `${V2_API}/clusters/${clusterId}`;

  static GET_CLUSTER_ENDPOINTS_ID = (clusterId) =>
    `${V1_API}/clusters/${clusterId}/endpoints`;
  static GET_CLUSTER_ENDPOINTS_BY_ID = (clusterId, endpointId) =>
    `${V1_API}/clusters/${clusterId}/endpoints/${endpointId}`;

  static GET_CLUSTER_NOTIFICATION = (clusterId) =>
    `${V2_API}/clusters/${clusterId}/notifications`;

  static GET_QUERY_PROFILES(offset, limit, params = {}) {
    const queryParams = new URLSearchParams({
      offset,
      limit,
    });

    Object.entries(params).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value) {
        queryParams.append(key, value);
      }
    });

    return `${V2_API}/queryprofiles?${queryParams.toString()}`;
  }

  static GET_QUERY_PROFILE_DETAILS = `${V2_API}/queryprofiles/render`;
  static GET_QUERY_PROFILE_DETAILS_SUMMARY = `${V2_API}/queryprofiles/renderjson`;
  static GET_QUERY_PROFILE_SUMMARY_DOWNLOAD = `${V2_API}/queryprofiles/downloadsummary`;

  /// Users
  static GET_USERS = `${V2_API}/users?offset=0&limit=999`;

  static GET_USER = (userId) => `${V2_API}/users/${userId}`;
  static POST_USER = `${V2_API}/users`;
  static PUT_USER = (userId) => `${V2_API}/users/${userId}`;
  static DELETE_USER = (userId) => `${V2_API}/users/${userId}`;

  /// User Role
  static GET_USER_ROLES = (userId) => `${V1_API}/users/${userId}/roles`;
  static PUT_USER_ROLE = (userId) => `${V1_API}/users/${userId}/roles`;
  static DELETE_USER_ROLE = (userId, roleId) =>
    `${V1_API}/users/${userId}/roles/${roleId}`;

  /// Roles
  static GET_ROLES = `${V2_API}/roles?offset=0&limit=999`;

  static POST_ROLE = `${V2_API}/roles`;
  static DELETE_ROLE = (roleId) => `${V2_API}/roles/${roleId}`;

  static CREATE_ROLE_PERMISSION = `${V2_API}/roles`;

  /// Data Sources
  static GET_DATA_SOURCES = `${V2_API}/datasources?offset=0&limit=999`;
  static GET_DATA_SOURCE = `${V2_API}/datasources`;
  static ADD_DATA_SOURCES = `${V2_API}/datasources`;
  static UPDATE_DATA_SOURCES = `${V2_API}/datasources`;
  static DELETE_DATA_SOURCES = `${V2_API}/datasources`;

  /// Identity Provider
  static GET_IDENTITY_PROVIDER = `${V2_API}/tenant/idpConfig`;
  static POST_IDENTITY_PROVIDER = `${V2_API}/tenant/idpConfig`;

  ///  User Groups
  static GET_USER_GROUPS = `${V2_API}/usergroup?offset=0&limit=999`;
  static POST_USER_GROUPS = `${V2_API}/usergroup`;

  static GET_USER_GROUP_DETAILS = (userGroupId) =>
    `${V2_API}/usergroup/${userGroupId}`;
  static PUT_USER_GROUP = (userGroupId) => `${V2_API}/usergroup/${userGroupId}`;
  static DELETE_USER_GROUP = (userGroupId) =>
    `${V2_API}/usergroup/${userGroupId}`;

  static GET_USER_GROUP_ROLES = (userGroupID) =>
    `${V2_API}/usergroup/${userGroupID}/roles`;
  static POST_USER_GROUP_ROLES = (userGroupID) =>
    `${V2_API}/usergroup/${userGroupID}/roles`;
  static DELETE_USER_GROUP_ROLES = (userGroupID) =>
    `${V2_API}/usergroup/${userGroupID}/roles`;

  /// Cloud Profile
  static GET_CLOUD_PROFILES = `${V2_API}/cloud-config?offset=0&limit=999`;

  static GET_REGIONS = `${V2_API}/supported-regions`;

  static GET_CLOUD_PROFILE_DETAILS = (cloudProfileId) =>
    `${V2_API}/cloud-config/${cloudProfileId}`;
  static POST_CLOUD_PROFILE = `${V2_API}/cloud-config`;
  static PUT_CLOUD_PROFILES = (cloudProfileId) =>
    `${V2_API}/cloud-config/${cloudProfileId}`;
  static DELETE_CLOUD_PROFILE = (cloudProfileId) =>
    `${V2_API}/cloud-config/${cloudProfileId}`;
  static DELETE_FORCE_CLOUD_PROFILE = (cloudProfileId) =>
    `${V2_API}/cloud-config/${cloudProfileId}/force`;

  static ACTIVATE_CLOUD_PROFILE = (cloudProfileId) =>
    `${V2_API}/cloud-config/${cloudProfileId}/activate`;

  static GET_DEPENDENT_CLUSTERS = (cloudProfileId) =>
    `${V2_API}/cloud-config/${cloudProfileId}/clusters`;

  /// Logging
  static POST_LOGGING = `${V2_API}/cloud-config`;

  /// Storage Bucket
  static GET_ALL_STORAGE_BUCKET = `${V2_API}/queryprofile-storage-config?offset=0&limit=999`;
  static POST_STORAGE_BUCKET = `${V2_API}/cloud-config`;

  ///  Download CloudFormation Template
  static GENERATE_TENANT_SETUP_CFT = (cloudProfileId) =>
    `${V2_API}/cloud-config/${cloudProfileId}/generate-cft`;

  /// Audit Logs

  static AUDIT_LOGS(limit, offset, params = {}) {
    const queryParams = new URLSearchParams({});

    Object.entries(params).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value) {
        queryParams.append(key, value);
      }
    });

    return `${V2_API}/auditlogs?limit=${limit}&offset=${offset}&${queryParams.toString()}`;
  }

  static GET_CONNECT_TO_CLUSTER = (clusterId) =>
    `${V2_API}/cluster/${clusterId}/connect_to_cluster`;
}
