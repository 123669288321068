import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  EnhancedTableHead,
  getComparator,
  stableSort,
} from '../../../../components/TableComponent/TableComponent';
import { getReadableDate } from '../../../../utils/common';
import SkeletonLoader from '../../../../components/Loader/SkeletonLoader';
import AccessTokenMenu from '../components/AccessesMenu';
import CreateTokenModal from '../../../../modals/Users/CreateTokenModal';
import { deleteAPI, get, post, put } from '../../../../utils/apiMethods';
import {
  CREATE_ACCESS_TOKEN,
  DEACTIVATE_ACCESS_TOKEN,
  DELETE_ACCESS_TOKEN,
  GET_ACCESS_TOKEN,
} from '../../../../utils/apiConstant';
import { toast } from 'react-toastify';

export default function AccessToken(props) {
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'created_by',
      numeric: false,
      disablePadding: false,
      label: 'Created by',
    },
    {
      id: 'created_timestamp',
      numeric: false,
      disablePadding: false,
      label: 'Create Date',
    },
    {
      id: 'modified_timestamp',
      numeric: false,
      disablePadding: false,
      label: 'Last Change',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: 'Action',
    },
  ];

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('username');
  const [loading] = useState(false);
  const [isDone] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const [isCreateTokenModal, setIsCreateTokenModal] = useState(false);

  const [userList, setUserList] = useState([]);

  function toggleCreateTokenModal() {
    setIsCreateTokenModal((currentState) => !currentState);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  }

  useEffect(() => {
    getAccessesAPIHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getAccessesAPIHandler() {
    const URL = `${GET_ACCESS_TOKEN}${props.id}/accessTokens`;

    const response = await get({
      url: URL,
    });

    if (response) {
      setUserList(response);
    }
  }

  async function createAccessesAPIHandler(name) {
    const URL = `${CREATE_ACCESS_TOKEN}${props.id}/accessTokens`;

    const response = await post({
      url: URL,
      payload: { name },
    });

    if (response.id) {
      setUserList((currentList) => [response, ...currentList]);
    } else {
      toggleCreateTokenModal();
      toast.error(response.message ?? 'Something went wrong.');
    }

    return response;
  }

  async function activateAccessesAPIHandler(data) {
    const URL = `${DEACTIVATE_ACCESS_TOKEN}${props.id}/accessTokens/${data.id}/activate`;

    const response = await put({
      url: URL,
      payload: {},
    });

    if (response === 204) {
      setUserList((currentList) =>
        currentList.map((user) =>
          user.id === data.id ? { ...user, status: 'Activated' } : user
        )
      );
    } else {
      toast.error('Something went wrong.');
    }
  }

  async function deactivateAccessTokenAPIHandler(data) {
    const URL = `${DEACTIVATE_ACCESS_TOKEN}${props.id}/accessTokens/${data.id}/deactivate`;

    const response = await put({
      url: URL,
      payload: {},
    });

    if (response === 204) {
      setUserList((currentList) =>
        currentList.map((user) =>
          user.id === data.id ? { ...user, status: 'Deactivated' } : user
        )
      );
    } else {
      toast.error('Something went wrong.');
    }
  }

  async function deleteAccessTokenAPIHandler(accessTokenId) {
    const URL = `${DELETE_ACCESS_TOKEN}${props.id}/accessTokens/${accessTokenId}`;

    const response = await deleteAPI({
      url: URL,
    });

    if (response === 200) {
      setUserList((currentList) => {
        const updatedList = currentList.filter(
          (user) => user.id !== accessTokenId
        );
        return [...updatedList];
      });
    } else {
      toast.error(response.error ?? 'Something went wrong.');
    }
  }

  return (
    <>
      <div className="py-4 d-flex justify-content-end">
        <button className="buttonX" onClick={toggleCreateTokenModal}>
          <i className="bi bi-plus-lg" />
          Create Token
        </button>
      </div>
      <div className="desktop-table">
        <TableContainer>
          <Table
            className="tableX"
            sx={{
              minWidth: 750,
              borderCollapse: 'separate',
            }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={userList.length}
              headCells={headCells}
              disablePadding={false}
            />
            {isDone && userList && userList.length > 0 ? (
              <TableBody className="table Table">
                {stableSort(userList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => {
                    return (
                      <TableRow
                        hover
                        key={'accesses' + index}
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <TableCell className="id" align="left">
                          {data.name}
                        </TableCell>

                        <TableCell align="left">
                          {data.created_by?.username}
                        </TableCell>
                        <TableCell align="left">
                          {getReadableDate(data.created_timestamp)}
                        </TableCell>
                        <TableCell align="left">
                          {getReadableDate(data.modified_timestamp)}
                        </TableCell>
                        <TableCell align="left">
                          <p
                            className={`p-0 mb-0 ${
                              data.status === 'Deactivated'
                                ? 'deactivated'
                                : 'activated'
                            }
                            `}
                          >
                            {data.status}
                          </p>
                        </TableCell>
                        <TableCell align="left">
                          <AccessTokenMenu
                            data={data}
                            activateAccessToken={activateAccessesAPIHandler}
                            deactivateAccessToken={
                              deactivateAccessTokenAPIHandler
                            }
                            deleteAccessToken={deleteAccessTokenAPIHandler}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
        {loading ? (
          <SkeletonLoader />
        ) : isDone && userList && userList.length > 0 ? (
          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[10, 20, 30, 50, 100]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showLastButton={true}
            showFirstButton={true}
          />
        ) : !loading && userList && userList.length === 0 ? (
          <p className="text_color text-center no-data-msg mt-2">
            No Data Found
          </p>
        ) : null}
      </div>
      <div className="mobile-table">
        {loading ? (
          <SkeletonLoader />
        ) : isDone && userList ? (
          userList.map((data, i) => {
            return (
              <div key={data.name + i} className="mob-main">
                <div className="mob-rows">
                  <div className="keys">{headCells[0].label}</div>
                  <div className="values">{data.name}</div>
                </div>

                <div className="mob-rows">
                  <div className="keys">{headCells[1].label}</div>
                  <div className="values">{data.created_by?.username}</div>
                </div>
                <div className="mob-rows">
                  <div className="keys">{headCells[2].label}</div>
                  <div className="values">
                    {getReadableDate(data.created_timestamp)}
                  </div>
                </div>
                <div className="mob-rows">
                  <div className="keys">{headCells[3].label}</div>
                  <div className="values">
                    {getReadableDate(data.modified_timestamp)}
                  </div>
                </div>
                <div className="mob-rows">
                  <div className="keys">{headCells[4].label}</div>
                  <div
                    className={`values ${
                      data.status === 'Deactivated'
                        ? 'deactivated'
                        : 'activated'
                    }`}
                  >
                    {data.status}
                  </div>
                </div>
                <div className="mob-rows">
                  <div className="keys">{headCells[5].label}</div>
                  <div className="text_color_main">
                    <AccessTokenMenu
                      data={data}
                      activateAccessToken={activateAccessesAPIHandler}
                      deactivateAccessToken={deactivateAccessTokenAPIHandler}
                      deleteAccessToken={deleteAccessTokenAPIHandler}
                    />
                  </div>
                </div>
              </div>
            );
          })
        ) : !loading && userList && userList.length === 0 ? (
          <p className="text_color text-center no-data-msg mt-2">
            No Data Found
          </p>
        ) : null}
      </div>

      <CreateTokenModal
        show={isCreateTokenModal}
        handleClose={toggleCreateTokenModal}
        createAccessesHandler={createAccessesAPIHandler}
      />
    </>
  );
}
