import React, { useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';

const CustomNodeComponent = (props) => {
  const [nodeDetails, setNodeDetails] = useState([]);

  useEffect(() => {
    setNodeDetails(props.data);
  }, [props]);

  const extractText = (label, type = null) => {
    const regex = /<b>(.*?)<\/b><br>/;
    const match = label.match(regex);

    if (type === 'head') {
      return match ? match[1].trim() : '';
    }
    return label.replace(/<b>(.*?)<\/b><br>/, '');
  };

  return (
    <div
      className="custom-node"
      style={{
        border: nodeDetails.expanded ? '2px solid var(--clr-primary-500)' : '',
      }}
    >
      <Handle type="target" position={Position.Top} />
      <div className="node-head">
        <h5
          className="fw-bold"
          dangerouslySetInnerHTML={{
            __html: nodeDetails.label
              ? `${extractText(nodeDetails.label, 'head')} [${props.id}]`
              : '',
          }}
        />

        {nodeDetails.isSubNode && (
          <div
            className="node-head-expand"
            onClick={(event) => {
              event.stopPropagation();
              nodeDetails.onClick(props.id);
            }}
          >
            {nodeDetails.expanded ? (
              <i className="bi bi-plus-lg" />
            ) : (
              <i className="bi bi-dash-lg" />
            )}
          </div>
        )}
      </div>
      <div
        className="node-body"
        dangerouslySetInnerHTML={{
          __html: nodeDetails.label
            ? extractText(nodeDetails.label)
            : nodeDetails.label,
        }}
        style={{
          ...(nodeDetails.backgroundColor
            ? {
                backgroundColor: nodeDetails.backgroundColor,
                color: nodeDetails.color,
              }
            : {}),
        }}
      />
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export default CustomNodeComponent;
